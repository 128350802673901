import React from 'react';
import PropTypes from 'prop-types';

import { styles } from '../../../config';
import { breadcrumbs } from '../../../utils/enums';
import { objectManager } from '../../../utils/helpers';

import { Icon } from '../../atoms';
import { BreadcrumbBase, Text, Link } from './Breadcrumb.styes';

const { isEmpty } = objectManager;

const Breadcrumb = ({ router, showGoBack }) => {
  const itens = breadcrumbs[router] || breadcrumbs.default;
  const previousRoute = !isEmpty(itens) && itens[0].url ? itens[0].url : '';
  return (
    <BreadcrumbBase>
      {showGoBack && (
        <BreadcrumbBase.Item>
          <Link to={previousRoute}>
            <Icon name="arrow-left" color={styles.colors.primary} />
            <Text spacing>Voltar</Text>
          </Link>
        </BreadcrumbBase.Item>
      )}

      {itens.map(item => (
        <BreadcrumbBase.Item key={item.id}>
          {item.url ? (
            <Link testid={item.id} to={item.url}>
              <Text>{item.label}</Text>
            </Link>
          ) : (
            <Text testid={item.id}>{item.label}</Text>
          )}
        </BreadcrumbBase.Item>
      ))}
    </BreadcrumbBase>
  );
};

Breadcrumb.propTypes = {
  router: PropTypes.string.isRequired,
  showGoBack: PropTypes.bool,
};

Breadcrumb.defaultProps = {
  showGoBack: false,
};

export default Breadcrumb;
