import { apikey } from '../../config/envManager';
import { tokenManager } from '../../utils/helpers';

export default async (config) => {
  const token = tokenManager.get();

  if (token) {
    const tokenJson = JSON.parse(token);
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `${tokenJson.tokenType} ${tokenJson.token}`;
  }

  if (apikey) {
    // eslint-disable-next-line no-param-reassign
    config.headers.apikey = apikey;
  }

  return config;
};
