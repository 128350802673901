export const getString = input => input.toString();

export const getFirstName = name => (name ? name.split(' ')[0] : '');

export const getFirstLetter = name => (name ? name.split('')[0] : '');

export const getFirstAndLastName = name => {
  if (name) {
    const splitName = name.split(' ');

    return splitName.length > 1
      ? `${splitName[0]} ${splitName[splitName.length - 1]}`
      : `${splitName[0]}`;
  }
  return '';
};

export const getGenderAbbreviation = gender => {
  if (gender === 'Masculino') return 'Dr. ';
  if (gender === 'Feminino') return 'Dra. ';

  return '';
};

export const removeMask = value => value.replace(/[^0-9]/g, '');

export const addCpfMask = (value, clearMaskFn = removeMask) => {
  if (!value) return '';

  const cpf = clearMaskFn(value);

  const firstPart = cpf.slice(0, 3);
  const secondPart = cpf.slice(3, 6);
  const thirdPart = cpf.slice(6, 9);
  const fourthPart = cpf.slice(9);

  return `${firstPart}.${secondPart}.${thirdPart}-${fourthPart}`;
};

export const addRgMask = (value, clearMaskFn) => {
  if (!value) return '';

  const rg = clearMaskFn(value);

  const firstPart = rg.slice(0, 2);
  const secondPart = rg.slice(2, 5);
  const thirdPart = rg.slice(5, 8);
  const fourthPart = rg.slice(8);

  if (fourthPart) return `${firstPart}.${secondPart}.${thirdPart}-${fourthPart}`;

  return `${firstPart}.${secondPart}.${thirdPart}-X`;
};

export const addCellphoneMask = (value, clearMaskFn) => {
  if (!value) return '';

  const cellphone = clearMaskFn(value.trim());

  const ddd = cellphone.slice(0, 2);
  const firstPart = cellphone.slice(2, 7);
  const secondPart = cellphone.slice(7);

  return `(${ddd})${firstPart}-${secondPart}`;
};

export const addTelephoneMask = (value, clearMaskFn) => {
  if (!value) return '';

  const telephone = clearMaskFn(value.trim());

  const ddd = telephone.slice(0, 2);
  const firstPart = telephone.slice(2, 6);
  const secondPart = telephone.slice(6);

  return `(${ddd})${firstPart}-${secondPart}`;
};

export const addCepMask = (value, clearMaskFn) => {
  if (!value) return '';

  const cep = clearMaskFn(value);
  const firstPart = cep.slice(0, 5);
  const secondPart = cep.slice(5);

  return `${firstPart}-${secondPart}`;
};

export const getDdd = value => {
  const regexNumber = /\d+/g;
  const onlyNumber = value.match(regexNumber).join('');
  return onlyNumber.substring(0, 2);
};

export const getPhoneNumber = value => {
  const regexNumber = /\d+/g;
  const onlyNumber = value.match(regexNumber).join('');
  return onlyNumber.substring(2, 11);
};

export default {
  getDdd,
  getPhoneNumber,
  getFirstName,
  getFirstLetter,
  getFirstAndLastName,
  getGenderAbbreviation,
  removeMask,
  addCpfMask,
  addRgMask,
  addCellphoneMask,
  addTelephoneMask,
  addCepMask,
};
