import produce from 'immer';

import { getAssistantInfo } from '~api/services/assistant.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const GET_ASSISTANT_INFO_REQUEST = '@dasa-canal-medico/GET_ASSISTANT_INFO_REQUEST';
export const GET_ASSISTANT_INFO_SUCCESS = '@dasa-canal-medico/GET_ASSISTANT_INFO_SUCCESS';
export const GET_ASSISTANT_INFO_FAILURE = '@dasa-canal-medico/GET_ASSISTANT_INFO_FAILURE';

export const SET_CURRENT_DOCTOR_REQUEST = '@dasa-canal-medico/SET_CURRENT_DOCTOR_REQUEST';
export const SET_CURRENT_DOCTOR_SUCCESS = '@dasa-canal-medico/SET_CURRENT_DOCTOR_SUCCESS';
export const SET_CURRENT_DOCTOR_FAILURE = '@dasa-canal-medico/SET_CURRENT_DOCTOR_FAILURE';

export const RESET_ASSISTANT_INFO_REQUEST = '@dasa-canal-medico/RESET_ASSISTANT_INFO_REQUEST';
export const RESET_ASSISTANT_INFO_SUCCESS = '@dasa-canal-medico/RESET_ASSISTANT_INFO_SUCCESS';
export const RESET_ASSISTANT_INFO_FAILURE = '@dasa-canal-medico/RESET_ASSISTANT_INFO_FAILURE';

// Reducer
export const initialState = {
  data: {
    doctors: [],
    currentDoctor: {
      crms: [],
    },
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ASSISTANT_INFO_REQUEST: {
        draft.loading = true;
        break;
      }
      case GET_ASSISTANT_INFO_SUCCESS: {
        draft.data = action.payload;
        draft.loading = false;
        break;
      }
      case GET_ASSISTANT_INFO_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.loading = false;
        break;
      }
      case SET_CURRENT_DOCTOR_REQUEST: {
        draft.loading = true;
        break;
      }
      case SET_CURRENT_DOCTOR_SUCCESS: {
        draft.data.currentDoctor = action.payload;
        draft.loading = false;
        break;
      }
      case SET_CURRENT_DOCTOR_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.loading = false;
        break;
      }
      case RESET_ASSISTANT_INFO_REQUEST:
      case RESET_ASSISTANT_INFO_SUCCESS: {
        draft.data = initialState.data;
        break;
      }
      case RESET_ASSISTANT_INFO_FAILURE:
      default:
    }
  });
}

// Action Creators
export const assistantInfoRequest = simpleAction(GET_ASSISTANT_INFO_REQUEST);
export const assistantInfoSuccess = simpleAction(GET_ASSISTANT_INFO_SUCCESS);
export const assistantInfoFailure = simpleAction(GET_ASSISTANT_INFO_FAILURE);

export const setCurrentDoctorRequest = simpleAction(SET_CURRENT_DOCTOR_REQUEST);
export const setCurrentDoctorSuccess = simpleAction(SET_CURRENT_DOCTOR_SUCCESS);
export const setCurrentDoctorFailure = simpleAction(SET_CURRENT_DOCTOR_FAILURE);

export const resetAssistantInfoRequest = simpleAction(RESET_ASSISTANT_INFO_REQUEST);
export const resetAssistantInfoSuccess = simpleAction(RESET_ASSISTANT_INFO_SUCCESS);
export const resetAssistantInfoFailure = simpleAction(RESET_ASSISTANT_INFO_FAILURE);

export const getAssistantInfoAction = () => async dispatch => {
  dispatch(assistantInfoRequest());

  try {
    const payload = await getAssistantInfo();

    dispatch(assistantInfoSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(assistantInfoFailure(err));
  }
};

export const setCurrentDoctorAction = doctor => async dispatch => {
  dispatch(setCurrentDoctorRequest());

  try {
    dispatch(setCurrentDoctorSuccess(doctor));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(setCurrentDoctorFailure(err));
  }
};

export const resetAssistantInfoAction = () => async dispatch => {
  dispatch(resetAssistantInfoRequest());

  try {
    dispatch(resetAssistantInfoSuccess());
  } catch (error) {
    const err = errorMessage(error);
    dispatch(resetAssistantInfoFailure(err));
  }
};
