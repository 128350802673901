import styled from 'styled-components';
import { Form, Radio as RadioBase } from 'antd';

export const FormItem = styled(Form.Item)``;

export const Group = styled(RadioBase.Group)`
  && {
    display: flex;
    flex-direction: column;

    .ant-radio-wrapper {
      display: flex;
      align-items: center;
      height: 48px;

      .ant-radio-inner {
        width: 24px;
        height: 24px;
        border-width: 2px;

        &::after {
          width: 14px;
          height: 14px;
        }
      }

      p {
        font-weight: normal;
        margin: 0;
      }
    }
  }
`;

export const Radio = styled(RadioBase)`
  && {
    .ant-radio-wrapper {
      display: flex;
      align-items: center;
    }
  }
`;
