import styled from 'styled-components';
import { Tooltip } from 'antd';
import { lpStyles } from '~/config';

export const TooltipBase = styled(Tooltip)``;

export const TooltipWrapper = styled.div`
  .ant-tooltip .ant-tooltip-content {
    background: ${lpStyles.colors.black};
    border-radius: 4px;
    border: 1px solid ${props => props.type === 'large' ? lpStyles.colors.border : 'transparent'};
  }

  .ant-tooltip-placement-bottom, 
  .ant-tooltip-placement-bottomLeft, 
  .ant-tooltip-placement-bottomRight {
    padding-top: 0;
  }

  .ant-tooltip .ant-tooltip-inner {
    font-family: ${p => p.type === 'large' ? lpStyles.fontFamily.default : ''};
    min-height: auto;
    padding: ${props => props.type === 'large' ? '8px' : '2px'};
    background: transparent;
    color: ${props => props.type === 'large' ? lpStyles.colors.black : ''};
    font-size: ${props => props.type === 'large' ? lpStyles.fontSize.medium : lpStyles.fontSize.small};
  }

  .ant-tooltip .ant-tooltip-arrow {
    display: ${props => props.type === 'large' ? 'block' : 'none'};
  }

  .anticon.ant-tooltip-open {
    color: ${lpStyles.colors.black};
  }

  .anticon.ant-tooltip-open svg {
    border-radius: 50%;
  }

  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 0;
  }

  .ant-tooltip .ant-tooltip-content {
    background-color: ${props => props.type === 'large' ? 'white' : ''};
  }
`;
