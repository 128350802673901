import React from 'react';
import PropTypes from 'prop-types';

import { mask, types } from '~/utils/enums';
import { formValidation } from '~/utils/helpers';
import { Input, Button, Title, Text, TextArea } from '~/components/atoms';

import { ButtonWrapper, FormWrapper, FieldsWrapper, TitleWrapper, TextAlert } from './Form.styles';

const { focusOnFirstError } = formValidation;

const Form = ({
  handleBlur,
  handleChange,
  handleSubmit,
  values,
  errors,
  isLoading,
  touched,
  errorServer,
}) => (
  <FormWrapper>
    <TitleWrapper>
      <Title testId="contact-us-title" htmlType="h3">
        Entrar em contato
      </Title>
    </TitleWrapper>

    <form name="contactUsForm" onSubmit={handleSubmit}>
      <Text>
        <strong>Precisa de ajuda?</strong> Estamos aqui para atender você. Envie seus comentários ou
        sugestões.
      </Text>
      <FieldsWrapper>
        <Input
          testId="contact-us-name"
          label="Nome e sobrenome"
          placeholder="Digite seu nome e sobrenome"
          name="fullName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.fullName}
          error={errors.fullName}
          touched={touched.fullName}
        />

        <Input
          testId="contact-us-email"
          label="E-mail"
          placeholder="Digite seu endereço de email"
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={errors.email}
          touched={touched.email}
        />

        <Input
          testId="contact-us-cellphone"
          label="Celular"
          placeholder="Digite seu número de celular"
          name="cellphone"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.cellphone}
          error={errors.cellphone}
          touched={touched.cellphone}
          mask={mask.cellphone}
        />

        <TextArea
          testId="contact-us-message"
          label="Mensagem"
          placeholder="Insira sua mensagem aqui..."
          name="message"
          autoSize={{ minRows: 5, maxRows: 5 }}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.message}
          error={errors.message}
          touched={touched.message}
        />
      </FieldsWrapper>

      <ButtonWrapper>
        <TextAlert>{errorServer}</TextAlert>

        <Button
          isBlock
          htmlType="submit"
          testId="contact-us-save-btn"
          type={types.primary}
          isLoading={isLoading}
          onClick={() => focusOnFirstError(errors, document.forms.contactUsForm)}
        >
          Enviar!
        </Button>
      </ButtonWrapper>
    </form>
  </FormWrapper>
);

Form.propTypes = {
  errorServer: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    cellphone: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    cellphone: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    fullName: PropTypes.bool,
    email: PropTypes.bool,
    cellphone: PropTypes.bool,
    message: PropTypes.bool,
  }).isRequired,
};

Form.defaultProps = {
  errorServer: '',
};

export default Form;
