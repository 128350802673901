import React from 'react';
import PropTypes from 'prop-types';

import { examsStatus } from '~utils/enums';
import { Table, Skeleton } from '~components/atoms';
import { Empty, ExamsActions } from '~components/molecules';
import { Badge, Text } from './ExamsHistoryTable.styles';

const ExamsHistoryTable = ({
  data,
  total,
  isAdmin,
  isLoading,
  isLoadingPdf,
  isLoadingImage,
  isLoadingPdfDowload,
  onClickImage,
  onClickPDF,
  onClickPDFDownload,
  validateDownload,
  openShareReportModal,
}) => {
  const hasData = !!data.length;
  const columns = [
    {
      title: 'Exame',
      dataIndex: 'name',
      key: 'name',
      render: text => <Text>{text}</Text>,
    },
    {
      title: 'Protocolo',
      dataIndex: 'protocol',
      key: 'protocol',
    },
    {
      title: 'Status',
      dataIndex: 'isComplete',
      key: 'isComplete',
      render: isComplete => (
        <Badge
          status={isComplete ? examsStatus.success.status : examsStatus.processing.status}
          text={isComplete ? examsStatus.success.text : examsStatus.processing.text}
        />
      ),
    },
    {
      title: 'Data de atendimento',
      dataIndex: 'dataOfVisit',
      key: 'dataOfVisit',
    },
    {
      title: '',
      dataIndex: 'reports',
      key: 'reports',
      render: (report, item) => (
        <ExamsActions
          isAdmin={isAdmin}
          item={item}
          report={report}
          onClickPDF={onClickPDF}
          onClickImage={onClickImage}
          onClickPDFDownload={onClickPDFDownload}
          validateDownload={validateDownload}
          isLoadingPdf={isLoadingPdf}
          isLoadingImage={isLoadingImage}
          isLoadingPdfDowload={isLoadingPdfDowload}
          openShareReportModal={openShareReportModal}
        />
      ),
    },
  ];

  if (isLoading) {
    return <Skeleton />;
  }

  return hasData ? (
    <Table
      data={data}
      columns={columns}
      total={total}
      trackId="exam-table"
      testId="exam-table"
      className="table-without-hover"
    />
  ) : (
    <Empty
      testId="empty-exam"
      iconName="file-search"
      title="Não foram encontrados resultados para a sua pesquisa…"
    />
  );
};

ExamsHistoryTable.propTypes = {
  data: PropTypes.arrayOf([PropTypes.shape({})]),
  total: PropTypes.number,
  isAdmin: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingPdf: PropTypes.bool,
  isLoadingImage: PropTypes.bool,
  isLoadingPdfDowload: PropTypes.bool,
  onClickImage: PropTypes.func,
  onClickPDF: PropTypes.func,
  onClickPDFDownload: PropTypes.func,
  validateDownload: PropTypes.func,
  openShareReportModal: PropTypes.func,
};

ExamsHistoryTable.defaultProps = {
  data: [],
  total: 0,
  isAdmin: false,
  isLoading: false,
  isLoadingPdf: false,
  isLoadingImage: false,
  isLoadingPdfDowload: false,
  onClickImage: () => undefined,
  onClickPDF: () => undefined,
  onClickPDFDownload: () => undefined,
  validateDownload: () => undefined,
  openShareReportModal: () => undefined,
};

export default ExamsHistoryTable;
