import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { styles } from '~/config';
import { status, badgeColors } from '~/utils/enums';

import { Icon, IconCustom, Skeleton } from '~/components/atoms';
import { ExamsActionsDrawer, ClinicalDiscussion } from '~/components/molecules';

import {
  Text,
  List,
  Badge,
  Title,
  Header,
  Drawer,
  ListItem,
  ExamContent,
  ReturnButton,
  ActionsContent,
  HeaderContentWrapper,
} from './ExamsDrawer.styles';

const ExamsDrawer = ({
  exams,
  visible,
  isLoading,
  isLoadingImage,
  onClickImage,
  onClickPDF,
  closeDrawer,
  showActions,
  isAssistant,
  isNurse,
  toggleShowActions,
  onClickPDFDownload,
  isLoadingPdfDowload,
  openShareReportModal,
  onSubmitClinicalDiscussion,
}) => {
  const [exam, setExam] = useState({});

  const openActions = item => () => {
    setExam(item);
    toggleShowActions();
  };

  const isAC = item => item.title && item.title.includes('Analises Clinicas (PDF)');
  const isEvo = item => item.title && item.title.includes('Analises Clinicas Evolutivo (PDF)');
  const isRDI = item => item.title && item.title.includes('Exames de imagem');
  const isImage = item => item.title && item.title.includes('(IMAGENS)');

  return (
    <Drawer visible={visible} onClose={closeDrawer} closable={false} mask={false} destroyOnClose>
      <ReturnButton onClick={closeDrawer}>
        <Icon name="arrow-left" color={styles.colors.primary} />
        <Text spacing>Voltar</Text>
      </ReturnButton>

      <Title>Exames</Title>

      {isLoading ? (
        <>
          <Skeleton rows={2} />
          <Skeleton rows={2} />
          <Skeleton rows={2} />
        </>
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={exams}
          renderItem={item => (
            <>
              {item.isParent && (
                <ListItem isParent>
                  <ExamContent isParent className="parent-exam-content">
                    <Header>
                      <HeaderContentWrapper isEvo={isAssistant || isNurse || isEvo(item)}>
                        <Text isParent>{item.title}</Text>
                        <Text isParent>
                          {item.totalExams > 1
                            ? `${item.totalExams} exames`
                            : `${item.totalExams} exame`}
                        </Text>
                      </HeaderContentWrapper>
                      {!isAssistant && !isNurse && isAC(item) && !isEvo(item) && (
                        <ClinicalDiscussion
                          testId="clinical-discussion"
                          patient={item}
                          exams={exams.filter(examItem => !isEvo(examItem) && !examItem.isRDI)}
                          onSubmit={onSubmitClinicalDiscussion}
                        />
                      )}
                      {!isAssistant && !isNurse && isRDI(item) && !isEvo(item) && (
                        <ClinicalDiscussion
                          testId="clinical-discussion"
                          patient={item}
                          exams={exams.filter(
                            examItem => !examItem.isParent && examItem.isRDI && !isImage(examItem),
                          )}
                          onSubmit={onSubmitClinicalDiscussion}
                        />
                      )}
                    </Header>
                  </ExamContent>
                </ListItem>
              )}

              <ListItem isParent={item.isParent} isRDI={item.isRDI}>
                <ExamContent isParent={item.isParent}>
                  <Header>
                    {!item.isParent && <Text>{item.title}</Text>}
                    {item.isParent && item.isComplete && <Text>Resultado completo</Text>}
                    {item.isParent && !item.isComplete && <Text>Resultados parciais</Text>}
                    {item.isParent || item.isRDI ? (
                      <Badge
                        className="child-exam-status"
                        data-testid="exams-history-isComplete"
                        text={item.isComplete ? 'Liberado' : 'Processando'}
                        status={item.isComplete ? status.success : status.warning}
                      />
                    ) : (
                      <Badge
                        className="child-exam-status"
                        data-testid="exams-history-isComplete"
                        text={item.status === 'Parcial' ? 'Processamento' : item.status}
                        color={badgeColors[item.status]}
                      />
                    )}
                  </Header>
                </ExamContent>
                <ActionsContent isParent={item.isParent} onClick={openActions(item)}>
                  <IconCustom name={svg.iconDotsVertical} size="22px" />
                </ActionsContent>
              </ListItem>
            </>
          )}
        />
      )}

      <ExamsActionsDrawer
        report={exam.reports}
        isLoadingImage={isLoadingImage}
        visible={showActions}
        item={exam}
        onClickImage={onClickImage}
        onClose={toggleShowActions}
        onClickPDF={onClickPDF}
        onClickPDFDownload={onClickPDFDownload}
        isLoadingPdfDowload={isLoadingPdfDowload}
        openShareReportModal={openShareReportModal}
      />
    </Drawer>
  );
};

ExamsDrawer.propTypes = {
  visible: PropTypes.bool,
  showActions: PropTypes.bool,
  isLoading: PropTypes.bool,
  isAssistant: PropTypes.bool,
  isNurse: PropTypes.bool,
  closeDrawer: PropTypes.func,
  exams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleShowActions: PropTypes.func,
  onClickPDF: PropTypes.func,
  onClickPDFDownload: PropTypes.func,
  onClickImage: PropTypes.func,
  isLoadingPdfDowload: PropTypes.bool,
  isLoadingImage: PropTypes.bool,
  openShareReportModal: PropTypes.func,
  onSubmitClinicalDiscussion: PropTypes.func.isRequired,
};

ExamsDrawer.defaultProps = {
  visible: false,
  showActions: false,
  isLoading: true,
  isAssistant: false,
  isNurse: false,
  isLoadingPdfDowload: false,
  isLoadingImage: false,
  closeDrawer: () => undefined,
  toggleShowActions: () => undefined,
  onClickPDF: () => undefined,
  onClickPDFDownload: () => undefined,
  onClickImage: () => undefined,
  openShareReportModal: () => undefined,
};

export default ExamsDrawer;
