import { sendStatusApplication } from '~/api/services/userManagementDasa.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

export const STATUS_APPLICATION_REQUEST = '@dasa-canal-medico/STATUS_APPLICATION_REQUEST';
export const STATUS_APPLICATION_SUCCESS = '@dasa-canal-medico/STATUS_APPLICATION_SUCCESS';
export const STATUS_APPLICATION_FAILURE = '@dasa-canal-medico/STATUS_APPLICATION_FAILURE';

export const initialState = {
  data: {
    message: '',
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STATUS_APPLICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case STATUS_APPLICATION_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: {
          message: action.payload.message,
        },
        isLoading: false,
      };
    case STATUS_APPLICATION_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}

export const updateStatusApplicationRequest = simpleAction(STATUS_APPLICATION_REQUEST);
export const updateStatusApplicationSuccess = simpleAction(STATUS_APPLICATION_SUCCESS);
export const updateStatusApplicationFailure = simpleAction(STATUS_APPLICATION_FAILURE);

export const updateStatusApplicationAction = params => async dispatch => {
  dispatch(updateStatusApplicationRequest());

  try {
    const data = await sendStatusApplication(params);
    dispatch(updateStatusApplicationSuccess(data));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(updateStatusApplicationFailure(err));
  }
};
