import React, { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { image, svg } from '~/assets';
import { banner } from '~/utils/enums';
import { windowSize } from '~/utils/helpers';
import { styles } from '~/config';
import { IconCustom } from '~/components/atoms';

import {
  Banner,
  Carousel,
  Container,
  CovidLink,
  WrapperLink,
  CarouselItem,
  EducationLink,
  WrapperEducationLink,
  ModalCovidVideoAction,
  ModalEducationVideoAction,
  ButtonClose,
} from './BannersCarousel.styles';

const BannersCarousel = ({
  pause,
  setCurrentBanner,
  setShowVideoModal,
  closeBannersCarrousel,
  sendDataTracking,
}) => {
  const size = windowSize();
  const isDesktop = size.width > 768;

  const slider = useRef();

  useEffect(() => {
    const {
      current: {
        slick: { innerSlider },
      },
    } = slider;

    return pause ? innerSlider.pause('paused') : innerSlider.autoPlay('playing');
  }, [pause]);

  const openVideoModal = bannerItem => {
    sendDataTracking(bannerItem.events.video);
    setCurrentBanner(bannerItem);
    setShowVideoModal(true);
  };

  return (
    <Container>
      <ButtonClose onClick={() => closeBannersCarrousel()}>
        <IconCustom
          name={svg.iconClose}
          color={styles.colors.white}
          size={isDesktop ? '16px' : '10px'}
        />
      </ButtonClose>
      <Carousel dots autoplay ref={slider} autoplaySpeed={10000}>
        <CarouselItem key={banner.covid.id}>
          <ModalCovidVideoAction onClick={() => openVideoModal(banner.covid)} />
          <Banner cover={isDesktop ? image.covidBanner2x : image.covidBanner} />
          <WrapperLink>
            <CovidLink href={banner.covid.link} target="blank" />
          </WrapperLink>
        </CarouselItem>

        <CarouselItem key={banner.dasaEduca.id}>
          <ModalEducationVideoAction onClick={() => openVideoModal(banner.dasaEduca)} />
          <Banner cover={isDesktop ? image.dasaEducaBanner2x : image.dasaEducaBanner} />
          <WrapperEducationLink>
            <EducationLink href={banner.dasaEduca.link} target="blank" />
          </WrapperEducationLink>
        </CarouselItem>
      </Carousel>
    </Container>
  );
};

BannersCarousel.propTypes = {
  pause: PropTypes.bool,
  sendDataTracking: PropTypes.func.isRequired,
  setCurrentBanner: PropTypes.func.isRequired,
  setShowVideoModal: PropTypes.func.isRequired,
};

BannersCarousel.defaultProps = {
  pause: false,
};

export default memo(BannersCarousel);
