import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { styles } from '../../../config';

export const LinkBase = styled(Link)`
  && {
    color: ${styles.colors.link};
    font-weight: bold;

    &:hover,
    &:active {
      color: ${styles.colors.linkHover};
    }

    &.button {
      display: inline-block;
      padding: 8px 16px;
      border: 1px solid;
      border-radius: 4px;
      font-weight: normal;
    }
  }
`;
