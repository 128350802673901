import { objectManager } from '~/utils/helpers';

const { isEmpty, isObject, isArray } = objectManager;

export const toApi = (data = {}) => {
    if (!isObject(data)) {
        return { id: undefined };
    }

    return { id: data.id || undefined };
};

export const fromApi = (data = {}) => {
    if (!isObject(data)) {
        return {
            id: undefined,
            idSislu: '',
            birthDate: '',
            cpf: '',
            email: '',
            name: '',
            gender: '',
            isActive: false,
            role: '',
            rg: '',
            specialty: '',
            congregation: '',
            congregationNumber: '',
            congregationState: '',
            login: '',
            address: {
                address: '',
                addressComplement: '',
                addressNumber: '',
                cep: '',
                city: '',
                neightborhood: '',
                state: '',
            },
            telephone: '',
            cellphone: '',
        };
    }

    const mapperAddress = addresses => {
        if(!addresses)
            return {
                address: '',
                addressComplement: '',
                addressNumber: '',
                cep: '',
                city: '',
                neightborhood: '',
                state: '',
            }
        const { street, complement, number, zipCode, city, neighborhood, state } = addresses.find((address)=> {return address.principal})

        return {
            address: street || '',
            addressComplement: complement || '',
            addressNumber: number || '',
            cep: zipCode || '',
            city: city || '',
            neightborhood: neighborhood || '',
            state: state.match(/([A-Z]*|[a-z]*){2}/) || '',
        }
    };

    const getTelephoneAndCellphone = contacts => {
        let telephone, cellphone, email = '';
        if (!contacts) return { telephone, cellphone, email }
        contacts.map((contact) => {
            switch (contact.contactType) {
                case 'CELULAR':
                    cellphone = contact.value;
                    break;
                case 'TELEFONE':
                    telephone = contact.value;
                    break;
                case 'EMAIL':
                    email = contact.value;
                    break;
            }
        })
        return { telephone, cellphone, email }
    };


    const getDocuments = (documents) => {
        let cpf, rg = '';
        if (!documents) return { cpf, rg }
        documents.map((document) => {
            switch (document.type) {
                case 'CPF':
                    cpf = document.value;
                    break;
                case 'RG':
                    rg = document.value;
                    break;
            }
        })
        return { cpf, rg }
    }

    const isUndefined = (value) => {
        return (value == 'NAO_INFORMADO') ? undefined : value
    }

    const listSpecialities = (values) => {
        if(!values) return '';
        const specialities = values.map((value) => {
            return value.specialityDescription
        })

        return specialities.join(', ')
    }

    const listProfessions = (values) => {
        const professions = values.map((value) => {
            return captalize(value.description);
        })
        return professions.join(', ')
    }

    const getCongregationNumber = (documents) => {
        const [firstDocument] = documents
        const [councilState] = firstDocument.councilRegistryUFs
        return {
            congregation: firstDocument.regionalCouncilAbbreviation || '',
            congregationNumber: firstDocument.registryNumber || '',
            congregationState: councilState || ''
        }
    }

    const captalize = (value) => (value.slice(0, 1) + value.slice(1).toLowerCase())

    const mapper = {
        id: data.id || undefined,
        idSislu: isUndefined(data.idSislu) || '',
        birthDate: isUndefined(data.birthDate) || '',
        name: data.name || '',
        socialName: isUndefined(data.socialName) || '',
        gender: (isUndefined(data.gender)) ? captalize(data.gender) : '',
        // isActive: data.active === 'ATIVO',
        isActive: true,
        role: listProfessions(data.professions) || '',
        specialty: listSpecialities(data.specialities) || '',
        login: data.login || '',
        address: mapperAddress(data.addresses),
        ...getDocuments(data.documents),
        ...getCongregationNumber(data.councilRegistries),
        ...getTelephoneAndCellphone(data.contacts)
    };

    return mapper;
};

export const fromApiWithErros = ()=>{
    return {
        id: undefined,
        idSislu: '',
        birthDate: '',
        name: '',
        socialName: '',
        gender: '',
        isActive: true,
        role: '',
        specialty: '',
        login: '',
        address: ''
    }
}