/* eslint-disable indent */
import api from '~/api';
import { POST } from '~/api/httpMethods';
import { apiDoctors } from '~/config/envManager';

export const createRating = async params => {
  const { data } = await api({
    url: `${apiDoctors}/private/functionality-rating`,
    method: POST,
    data: params,
  });

  return data;
};
