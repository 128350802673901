export const telephone = '(99)9999-9999';
export const cellphone = '(99)99999-9999';
export const rg = '99.999.999-99';
export const cpf = '999.999.999-99';
export const cep = '99999-999';
export const date = '99/99/99999';
export const crm = 'aa-aaa-999999999';
export const congregationNumber = '9999999999';
export const cipNumber = '999999999999999999';
export const state = 'aa';

export default {
  telephone,
  cellphone,
  rg,
  cpf,
  cep,
  date,
  crm,
  congregationNumber,
  cipNumber,
  state,
};
