export const get = () => localStorage.getItem('@canalmedico:token');

export const remove = () => localStorage.removeItem('@canalmedico:token');

export const save = (token = '') => localStorage.setItem('@canalmedico:token', token);

export const toString = token => {
  return JSON.stringify({
    token: token.access_token,
    tokenType: token.token_type,
    refreshToken: token.refresh_token,
  });
};

export const toObject = token => JSON.parse(token);

export const getObjectToken = () => toObject(get());

export default {
  get,
  save,
  remove,
  toString,
  toObject,
  getObjectToken,
};
