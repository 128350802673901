import { status } from '~/utils/enums';

export default ({ data = [] }) => ({
  data,
  error: {
    message: '',
    status: '',
  },
  loading: status.none,
});
