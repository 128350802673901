import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 40px;

  h3 {
    margin: 24px 0;
  }
`;

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    min-width: 128px;
    margin-top: 24px;
  }
`;
