import styled from 'styled-components';
import { Row, Col } from 'antd';

import { mediaQuery } from '~/utils/helpers';
import { styles } from '~/config';

export const Wrapper = styled(Row)`
  && {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 36px;
    border-bottom: 1px solid #e1e4ea;
    align-items: center;

    ${mediaQuery.inDesktop(`
      padding: 22px 0 18px;
      margin-top: 36px;
      margin-right: 0 !important;
      margin-left: 0 !important;
    `)}
    ${mediaQuery.inMobile(`
      padding-bottom: 18px;
  `)}
  }
`;

export const Title = styled.h3`
  margin-bottom: 0;
  font-size: ${styles.fontSize.xlarge};
  line-height: 100%;

  ${mediaQuery.inDesktop(`
    width: 273px;
  `)}
  ${mediaQuery.inMobile(`
    margin-bottom: 18px;
    margin-top: 34px;
  `)}
`;

export const Item = styled(Col)`
  display: flex;
  flex-direction: row;
  ${mediaQuery.inMobile(`
    flex-direction: column;
    padding-bottom: 18px;
  `)}
`;

export const Actions = styled(Col)`
  && {
    display: flex;
    justify-content: flex-end;
    padding-right: 0 !important;

    ${mediaQuery.inMobile(`
      position: absolute;
      top: 0;
      right: 20px;
      height: 48px;
      width: 48px;
    `)}
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 14px;
  && i {
    ${mediaQuery.inMobile(`
      display: none;
    `)}
  }
`;
export const Bold = styled.strong``;

export const Text = styled.p`
  margin-bottom: 0;
  margin-left: 24px;
  margin-right: 14px;
  & .is-span {
    margin-left: 10px;
    color: ${styles.colors.textSecondary};
    line-height: 1.38;
  }
  ${mediaQuery.inMobile(`
    margin-top: 8px;
    margin-left: 0;
    & .is-mobile-bold {
      color:  ${styles.colors.text}!important;
      font-weight: bold;
      margin-left: 0px;
    }
    & .is-span{
      color: ${styles.colors.textSecondary};
    }
    & .is-mobile-hidden {
      display: none;
    }
  `)}
`;

export const DropdownButton = styled.button`
  display: ${({ isDropdownHidden }) => (isDropdownHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  appearance: none;
  height: 48px;
  width: 48px;
  border-radius: 4px;
  background-color: #dcdddd;
  cursor: pointer;
  border: none;
`;
