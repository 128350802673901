import styled from 'styled-components';
import { Form, Radio as RadioBase } from 'antd';

import { lpStyles } from '~/config';

export const FormItem = styled(Form.Item)``;

export const Group = styled(RadioBase.Group)`
  && {
    display: flex;
    flex-direction: column;

    .ant-radio-wrapper {
      display: flex;
      align-items: center;
      height: 48px;
      .ant-radio-inner {
        width: 20px;
        height: 20px;
        border: 2px solid
          ${props => (props.validateStatus ? lpStyles.colors.error : lpStyles.colors.border)};

        &::after {
          width: 10px;
          height: 10px;
          background-color: ${lpStyles.colors.primary};
        }
      }

      .ant-radio-checked {
        .ant-radio-inner {
          border-color: ${lpStyles.colors.border};
        }
      }

      p {
        color: ${lpStyles.colors.text};
        margin: 0;
        font-size: 0.8rem;
        font-weight: 600;
        font-family: ${lpStyles.fontFamily.default};
      }
    }
  }
`;

export const Radio = styled(RadioBase)`
  && {
    .ant-radio-wrapper {
      display: flex;
      align-items: center;
    }
  }
`;
