import styled from 'styled-components';
import { Divider as DividerBase } from 'antd';

import { styles } from '../../../config';

export const CounterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 47px;
  border: 1px solid ${styles.colors.primary};
`;

export const CounterText = styled.div`
  color: ${styles.colors.primary};
  font-size: ${styles.fontSize.default};
  font-weight: normal;
`;

export const CounterDivider = styled(DividerBase)`
  &&.ant-divider.ant-divider-vertical {
    height: 30px;
    background-color: ${styles.colors.primary};
    color: ${styles.colors.primary};
  }
`;
