import api from '~/api';
import { POST } from '~/api/httpMethods';
import { apiUsers, apikey } from '~/config/envManager';
import { toApi, fromApi, toRequestTokenApi, fromRequestTokenApi } from '~/api/dto/users/auth.dto';

export const checkUser = async params => {
  const body = toApi(params);

  const { data } = await api({
    url: `${apiUsers}/public/check-roles`,
    method: POST,
    data: body,
  });

  const response = fromApi(data);

  return response;
};

export const requestToken = async params => {
  const body = toRequestTokenApi(params);

  const { data } = await api({
    url: `${apiUsers}/public/new-login/request-token`,
    method: POST,
    data: body,
    headers: {
      Authorization: `Bearer ${params.profile.token || ''}`,
      apikey,
    },
  });

  const response = fromRequestTokenApi(data);

  return response;
};

export const logout = async () => {
  const { data } = await api({
    url: `${apiUsers}/private/logout`,
    method: POST,
  });

  return data;
};
