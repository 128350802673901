import React from 'react';
import PropTypes from 'prop-types';

import { LPButton, LPRadioGroup } from '~/components/atoms';

import { ButtonWrapper } from './Form.styles';

const Form = ({
  testId,
  options,
  values,
  errors,
  onClose,
  isLoading,
  handleSubmit,
  handleChange,
  ...rest
}) => (
  <form testId={`${testId}-form`} name="selectProfileForm" onSubmit={handleSubmit}>
    <LPRadioGroup
      {...rest}
      name="profile"
      testId={`${testId}-radio-group`}
      value={values.profile}
      error={errors.profile}
      options={options}
      onChange={handleChange}
    />

    <ButtonWrapper>
      <LPButton
        testId={`${testId}-cancel-button`}
        type="secondary"
        onClick={onClose}
        isDisabled={isLoading}
      >
        Voltar
      </LPButton>

      <LPButton
        testId={`${testId}-submit-button`}
        type="primary"
        htmlType="submit"
        isLoading={isLoading}
        isDisabled={!values.profile}
      >
        Continuar
      </LPButton>
    </ButtonWrapper>
  </form>
);

Form.propTypes = {
  testId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf({}).isRequired,
  onClose: PropTypes.func.isRequired,
  values: PropTypes.shape({
    profile: {},
  }).isRequired,
  errors: PropTypes.shape({
    profile: {},
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Form;
