import { GET, POST } from '~/api/httpMethods';
import { apiDoctors, apiAssistant } from '~/config/envManager';
import { fromApi } from '~/api/dto/assistant/group.dto';
import { toApi as toApiRegister } from '~/api/dto/assistant/register.dto';
import { fromApi as fromApiProfile } from '~/api/dto/assistant/profile.dto';
import api from '~api';

export const checkTokenAssistant = async token => {
  const { data } = await api({
    url: `${apiAssistant}/public/validate-register-token`,
    method: GET,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const completeRegisterAssistant = async ({
  fullName,
  cpf,
  cellphone,
  password,
  confirmationPassword,
  token,
}) => {
  const body = toApiRegister({
    fullName,
    cpf,
    cellphone,
    password,
    confirmationPassword,
  });

  const { data } = await api({
    url: `${apiAssistant}/public/complete-register`,
    method: POST,
    data: body,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const getAssistantInfo = async () => {
  const { data } = await api({
    url: `${apiAssistant}/private/user-info`,
    method: GET,
  });

  const response = fromApiProfile(data);

  return response;
};

export const getAssistants = async () => {
  const { data } = await api({
    url: `${apiDoctors}/private/assistants`,
    method: GET,
  });

  const response = fromApi(data);

  return response;
};

export const createAssistant = async param => {
  const { data } = await api({
    url: `${apiDoctors}/private/assistants`,
    method: POST,
    data: param,
  });

  return data;
};
