import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import { Modal } from '~/components/atoms';

import { Container, ModalContent } from './ModalVideo.styles';

const ModalVideo = ({ testId, isVisible, setShowVideoModal, url }) => {
  return (
    <Modal
      width={688}
      testId={testId}
      isVisible={isVisible}
      onCancel={() => setShowVideoModal(false)}
      isClosable
      removePadding
      destroyOnClose
    >
      <Container>
        <ModalContent>
          <ReactPlayer url={url} playing />
        </ModalContent>
      </Container>
    </Modal>
  );
};

ModalVideo.defaultProps = {
  url: '',
  testId: '',
  isVisible: false,
};

ModalVideo.propTypes = {
  url: PropTypes.string,
  testId: PropTypes.string,
  isVisible: PropTypes.bool,
  setShowVideoModal: PropTypes.func.isRequired,
};

export default memo(ModalVideo);
