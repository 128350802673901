import React, { memo } from 'react';
import { Row, Col } from 'antd';

import { Text } from '~components/atoms';
import { Container } from './ListHeader.styles';

const ListHeader = () => (
  <Container>
    <Row>
      <Col span={8}>
        <Text>Protocolo</Text>
      </Col>
      <Col span={5}>
        <Text>Abertura da ficha</Text>
      </Col>
      <Col span={4}>
        <Text>Status do exame</Text>
      </Col>
      <Col span={6}>
        <Text>Origem do exame</Text>
      </Col>
    </Row>
  </Container>
);

export default memo(ListHeader);
