import React from 'react';
import PropTypes from 'prop-types';

import { SkeletonBase } from './SkeletonList.styles';

const SkeletonList = ({ rows }) => {
  const allRows = new Array(rows);

  return allRows.map(() => (
    <>
      <SkeletonBase />
      <hr />
    </>
  ));
};

SkeletonList.propTypes = {
  rows: PropTypes.number,
};

SkeletonList.defaultProps = {
  rows: 3,
};

export default SkeletonList;
