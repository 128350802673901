import statusApplicationEnum from '~/utils/enums/statusApplication.enum';
import { addCpfMask } from '../stringManager'

export const formatRequestNewGetUser = params => {
    const response = {...params}
    response.status = formatStatus(params.status);
    //response.active = (params.active === 'ATIVO') ? "active" : "inactive";
    response.initialPerPage = (response.initialPerPage) ? ++response.initialPerPage : 1
    return response
}

export const formatListData = (data) =>{
    const response = [...data];
    response.map((element)=>{
        element.cpf = addCpfMask(element.cpf);
        element.name = formatLengthName(element.name)
        Object.keys(element).forEach((value)=>{
          const currentValue = element[value]
          if(value != 'documentFrontUrl' && value != 'documentBackUrl') 
            if((!currentValue && typeof currentValue != 'boolean') || currentValue.length == 0)
            element[value] = '--'
        })
    })
    return response;
}

const formatLengthName = (fullName) => {
    const names = fullName.split(' ');
    names.forEach((value, index)=>{
        if(index != 0 && index != (names.length - 1) )
            names[index] = `${value.charAt(0)}.`;
    });
    return names.join(' ')
}

const formatStatus = (status) => {
    if(Object.values(statusApplicationEnum).includes(status)) return status;
    return (status==='aprovado') ? statusApplicationEnum.approved : statusApplicationEnum.review;
}

export default formatRequestNewGetUser;
