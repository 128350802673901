import produce from 'immer';

import { downloadCovidExams, resultCovidExams } from '~/api/services/reports.service';
import { simpleAction, errorMessage, makeInitialState } from '~/utils/helpers';

export const DOWNLOAD_COVID_EXAMS_REQUEST = '@dasa-canal-medico/DOWNLOAD_COVID_EXAMS_REQUEST';
export const DOWNLOAD_COVID_EXAMS_SUCCESS = '@dasa-canal-medico/DOWNLOAD_COVID_EXAMS_SUCCESS';
export const DOWNLOAD_COVID_EXAMS_FAILURE = '@dasa-canal-medico/DOWNLOAD_COVID_EXAMS_FAILURE';

// Reducer
export const initialState = makeInitialState({ data: {} });

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case DOWNLOAD_COVID_EXAMS_REQUEST: {
        draft.loading = true;
        draft.error = {
          message: '',
          status: '',
        };
        break;
      }
      case DOWNLOAD_COVID_EXAMS_SUCCESS: {
        draft.data = {
          ...draft.data,
          message: action.payload.message,
          type: action.payload.type,
          covidResults: {
            hasCovidResults: action.payload.hasCovidResults,
            results: action.payload.results,
          },
        };
        draft.loading = false;
        break;
      }
      case DOWNLOAD_COVID_EXAMS_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

// Action Creators
export const downloadCovidExamsRequest = simpleAction(DOWNLOAD_COVID_EXAMS_REQUEST);
export const downloadCovidExamsSuccess = simpleAction(DOWNLOAD_COVID_EXAMS_SUCCESS);
export const downloadCovidExamsFailure = simpleAction(DOWNLOAD_COVID_EXAMS_FAILURE);

export const downloadCovidExamsAction = params => async dispatch => {
  dispatch(downloadCovidExamsRequest());

  let payload = {};
  try {
    if (params?.covidResultsResume) {
      payload = await resultCovidExams(params);
      payload.hasCovidResults = !!payload?.results?.length;
    } else {
      payload = await downloadCovidExams(params);
    }

    return dispatch(downloadCovidExamsSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    payload.hasCovidResults = false;

    return dispatch(downloadCovidExamsFailure(err));
  }
};
