import React from 'react';
import { notification } from 'antd';
import PropTypes from 'prop-types';

import { Title, Button } from './NotificationRegister.styles';

const handleClickUpdateRegister = (key, fn) => () => {
  fn();
  notification.close(key);
};

const NotificationRegister = ({ showUpdateRegister }) => {
  const key = `notification-${Date.now()}`;

  notification.open({
    key,
    duration: 0,
    className: 'notification-complete-register',
    message: <Title>Complete o seu Cadastro</Title>,
    description: (
      <Button onClick={handleClickUpdateRegister(key, showUpdateRegister)}>
        São apenas 2 min, clique aqui.
      </Button>
    ),
  });

  return <></>;
};

NotificationRegister.propTypes = {
  showUpdateRegister: PropTypes.func,
};

NotificationRegister.defaultProps = {
  showUpdateRegister: () => undefined,
};

export default NotificationRegister;
