import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 48%;
  }
`;
