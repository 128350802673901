import styled from 'styled-components';

import { styles } from '~/config';

export const FormWrapper = styled.section`
  position: relative;
  height: 100%;
  padding-bottom: 0;
`;

export const TitleWrapper = styled.div``;

export const ButtonWrapper = styled.div`
  background-color: white;
  width: 100%;
  position: relative;
`;

export const FieldsWrapper = styled.div``;

export const TextAlert = styled.p`
  position: absolute;
  top: 0;
  left: 24px;
  right: 24px;
  color: ${styles.colors.error};
`;
