import { status } from '~/utils/enums';
import { getPatientExams } from '~/api/services/patients.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const PATIENT_ITEM_REQUEST = '@dasa-canal-medico/PATIENT_ITEM_REQUEST';
export const PATIENT_ITEM_SUCCESS = '@dasa-canal-medico/PATIENT_ITEM_SUCCESS';
export const PATIENT_ITEM_FAILURE = '@dasa-canal-medico/PATIENT_ITEM_FAILURE';
export const PATIENT_ITEM_RESET = '@dasa-canal-medico/PATIENT_ITEM_RESET';
export const SET_PATIENT_EXAMS = '@dasa-canal-medico/SET_PATIENT_EXAMS';

// Reducer
export const initialState = {
  data: [],
  error: {
    message: '',
    status: '',
  },
  isLoading: status.processing,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PATIENT_ITEM_REQUEST:
      return {
        ...initialState,
        isLoading: status.processing,
      };

    case PATIENT_ITEM_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: action.payload,
        isLoading: status.success,
      };

    case PATIENT_ITEM_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: status.error,
      };

    case PATIENT_ITEM_RESET:
      return {
        ...state,
        ...initialState,
      };
    case SET_PATIENT_EXAMS:
      return {
        ...state,
        ...initialState,
        data: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export const getPatientItemRequest = simpleAction(PATIENT_ITEM_REQUEST);
export const getPatientItemSuccess = simpleAction(PATIENT_ITEM_SUCCESS);
export const getPatientItemFailure = simpleAction(PATIENT_ITEM_FAILURE);

export const resetPatientItem = simpleAction(PATIENT_ITEM_RESET);

export const setPatientExams = simpleAction(SET_PATIENT_EXAMS);

export const getPatientAction = urlParams => async (dispatch, getState) => {
  dispatch(getPatientItemRequest());

  try {
    // eslint-disable-next-line operator-linebreak
    const togglePerformanceImprovementPatientDetails =
      getState()?.featureToggle?.data?.toggles?.performanceImprovementPatientDetails || false;
    const exams = getState()?.patient?.item?.data || [];
    const examsParameters = { ...urlParams };
    if (togglePerformanceImprovementPatientDetails) {
      examsParameters.grouped = togglePerformanceImprovementPatientDetails;
    }

    if (!togglePerformanceImprovementPatientDetails || exams.length === 0) {
      const payload = await getPatientExams(examsParameters);
      dispatch(getPatientItemSuccess(payload));
    } else {
      dispatch(getPatientItemSuccess(exams));
    }
  } catch (error) {
    const err = errorMessage(error);
    dispatch(getPatientItemFailure(err));
  }
};

export const resetPatientAction = () => dispatch => {
  dispatch(resetPatientItem());
};

export const setPatientExamsAction = exams => dispatch => {
  dispatch(setPatientExams(exams));
};
