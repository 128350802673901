import styled from 'styled-components';
import { Row } from 'antd';

import { styles } from '../../../config';

export const Title = styled.h4`
  && {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
    letter-spacing: 0.09px;
  }
`;

export const Text = styled.p`
  margin-bottom: 14px;
  line-height: 21px;
  opacity: ${({ hasValue }) => (hasValue ? '1' : '0.4')};
`;

export const Bold = styled.b``;

export const Status = styled.b`
  color: ${({ isActive }) => (isActive ? styles.colors.success : styles.colors.default)};

  &:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: ${({ isActive }) => (isActive ? styles.colors.success : styles.colors.default)};
    margin-right: 6px;
    display: inline-block;
    padding: 0;
  };
`;

export const RowFeature = styled(Row)`
  && {
    padding-top: 43px;
    padding-bottom: 47px;
    margin-bottom: 42px;
    border-bottom: 1px solid #999b9e;
  }
`;
