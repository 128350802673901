import { getReleasedLaboratory } from '~/api/services/laboratories.service';
import { simpleAction, errorMessage } from '~/utils/helpers';

export const GET_RELEASED_LAB_REQUEST = '@dasa-canal-medico/GET_RELEASED_LAB_REQUEST';
export const GET_RELEASED_LAB_SUCCESS = '@dasa-canal-medico/GET_RELEASED_LAB_SUCCESS';
export const GET_RELEASED_LAB_FAILURE = '@dasa-canal-medico/GET_RELEASED_LAB_FAILURE';

// Reducer
export const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RELEASED_LAB_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_RELEASED_LAB_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: action.payload,
        isLoading: false,
      };
    case GET_RELEASED_LAB_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

// Action Creators
export const getReleasedLabRequest = simpleAction(GET_RELEASED_LAB_REQUEST);
export const getReleasedLabSuccess = simpleAction(GET_RELEASED_LAB_SUCCESS);
export const getReleasedLabFailure = simpleAction(GET_RELEASED_LAB_FAILURE);

export const getReleasedLabAction = params => async dispatch => {
  dispatch(getReleasedLabRequest());

  try {
    const payload = await getReleasedLaboratory(params);

    dispatch(getReleasedLabSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);

    dispatch(getReleasedLabFailure(err));
  }
};
