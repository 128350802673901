import styled from 'styled-components';

import { styles } from '~/config';
import { mediaQuery } from '~/utils/helpers';

export const Container = styled.div`
  padding: 24px;

  img {
    margin-right: 5px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    width: 100%;

    & + button {
      margin-left: 40px;
    }
  }
`;

export const ExamInfo = styled.div`
  margin: 30px 0 20px;

  p {
    margin: 0;
  }
`;

export const FooterMessage = styled.div`
  margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;

  .ant-btn {
    padding: 0 0 50px;

    .anticon {
      color: ${styles.colors.primary};
    }

    span {
      color: ${styles.colors.default};
      font-weight: normal;
    }
  }

  ${mediaQuery.inDesktop(`
    display: none; 
  `)}
`;
