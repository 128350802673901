import React from 'react';
import PropTypes from 'prop-types';
import { LPText } from '~/components/atoms';
import { Wrapper, Link } from './PageAction.styles';

const PageAction = ({ testId, trackId, name, url, onClick, className }) => (
  <Wrapper className={className}>
    <LPText testId="login-subtitle">Você é médico e não tem uma conta?</LPText>
    <Link trackId={trackId} testId={testId} to={url} onClick={onClick} type="button">
      {name}
    </Link>
  </Wrapper>
);

PageAction.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  testId: PropTypes.string,
  trackId: PropTypes.string,
  onClick: PropTypes.func,
};

PageAction.defaultProps = {
  testId: 'page-action',
  trackId: '',
  className: '',
  onClick: () => undefined,
};

export default PageAction;
