import { errorMessage, simpleAction } from '~/utils/helpers';
import { getAppUserInfo, saveOnboardingByUser } from '~/api/services/appInfo.service';

// Action Types
export const USER_CONFIG_REQUEST = '@dasa-canal-medico/USER_CONFIG_REQUEST';
export const USER_CONFIG_SUCCESS = '@dasa-canal-medico/USER_CONFIG_SUCCESS';
export const USER_CONFIG_FAILURE = '@dasa-canal-medico/USER_CONFIG_FAILURE';

export const USER_CONFIG_ONBOARDING_REQUEST = '@dasa-canal-medico/USER_CONFIG_ONBOARDING_REQUEST';
export const USER_CONFIG_ONBOARDING_SUCCESS = '@dasa-canal-medico/USER_CONFIG_ONBOARDING_SUCCESS';
export const USER_CONFIG_ONBOARDING_FAILURE = '@dasa-canal-medico/USER_CONFIG_ONBOARDING_FAILURE';

export const SHOW_NOTIFICATION_REGISTER = '@dasa-canal-medico/SHOW_NOTIFICATION_REGISTER_CONFIG';

// Reducer
export const initialState = {
  data: {
    enableTour: false,
    enableTourFirstAccess: false,
    enableTourMyAssistants: false,
    enableTourNewListOfPatients: false,
    enableTourExamsResquestHistory: false,
    enableTourExamsHistoryAvailable: false,
    enableTourExamsHistoryOtherDoctors: false,
    enableNotificationTerms: false,
    enableNotificationCompleteRegister: false,
    isNotificationCompleteRegisterVisible: false,
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_CONFIG_REQUEST:
      return {
        ...state,
        ...initialState,
        isLoading: true,
      };
    case USER_CONFIG_SUCCESS:
      return {
        ...state,
        data: {
          enableTour: action.payload.enableTour,
          enableTourFirstAccess: action.payload.enableTourFirstAccess,
          enableTourMyAssistants: action.payload.enableTourMyAssistants,
          enableTourNewListOfPatients: action.payload.enableTourNewListOfPatients,
          enableTourExamsResquestHistory: action.payload.enableTourExamsResquestHistory,
          enableTourExamsHistoryAvailable: action.payload.enableTourExamsHistoryAvailable,
          enableTourExamsHistoryOtherDoctors: action.payload.enableTourExamsHistoryOtherDoctors,
          enableNotificationTerms: action.payload.enableNotificationTerms,
          enableNotificationCompleteRegister: action.payload.enableNotificationCompleteRegister,
          enableForcedUpdatePassword: action.payload.enableForcedUpdatePassword,
        },
        isLoading: false,
      };
    case USER_CONFIG_FAILURE:
      return {
        ...state,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };

    case USER_CONFIG_ONBOARDING_REQUEST:
      return {
        ...state,
        ...initialState,
        isLoading: true,
      };
    case USER_CONFIG_ONBOARDING_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          enableTourFirstAccess: action.payload.enableTourFirstAccess,
          enableTourMyAssistants: action.payload.enableTourMyAssistants,
          enableTourNewListOfPatients: action.payload.enableTourNewListOfPatients,
          enableTourExamsResquestHistory: action.payload.enableTourExamsResquestHistory,
          enableTourExamsHistoryAvailable: action.payload.enableTourExamsHistoryAvailable,
          enableTourExamsHistoryOtherDoctors: action.payload.enableTourExamsHistoryOtherDoctors,
        },
        isLoading: false,
      };
    case USER_CONFIG_ONBOARDING_FAILURE:
      return {
        ...state,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };

    case SHOW_NOTIFICATION_REGISTER: {
      return {
        ...state,
        data: {
          ...state.data,
          isNotificationCompleteRegisterVisible: true,
        },
      };
    }

    default:
      return state;
  }
}

// Action Creators
export const userConfigRequest = simpleAction(USER_CONFIG_REQUEST);
export const userConfigSuccess = simpleAction(USER_CONFIG_SUCCESS);
export const userConfigFailure = simpleAction(USER_CONFIG_FAILURE);

export const userConfigOnboardingRequest = simpleAction(USER_CONFIG_ONBOARDING_REQUEST);
export const userConfigOnboardingSuccess = simpleAction(USER_CONFIG_ONBOARDING_SUCCESS);
export const userConfigOnboardingFailure = simpleAction(USER_CONFIG_ONBOARDING_FAILURE);

export const userRegisterNoticicationVisible = simpleAction(SHOW_NOTIFICATION_REGISTER);

export const getUserInfoAction = () => async dispatch => {
  dispatch(userConfigRequest());

  try {
    const payload = await getAppUserInfo();
    dispatch(userConfigSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(userConfigFailure(err));
  }
};

export const updateNotificationCompleteRegisterVisibleAction = () => dispatch => {
  dispatch(userRegisterNoticicationVisible());
};

export const updateOnboardingStatusAction = onboardingId => async dispatch => {
  dispatch(userConfigOnboardingRequest());

  try {
    const payload = await saveOnboardingByUser(onboardingId);
    dispatch(userConfigOnboardingSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(userConfigOnboardingFailure(err));
  }
};
