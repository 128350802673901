import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Title, Text } from '~components/atoms';

import { Container } from './TourContent.styles';

const TourContent = ({ title, text }) => (
  <Container>
    <Title htmlType="h3">{title}</Title>
    <Text>{text}</Text>
  </Container>
);

TourContent.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

TourContent.defaultProps = {
  title: '',
  text: '',
};

export default memo(TourContent);
