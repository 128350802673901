import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: min-content;
  background: white;
  box-sizing: border-box;
  border-bottom: 2px solid rgba(0, 0, 0, 0.24);
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const TitleLabel = styled.span`
  color: black;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 16px 0;
`;

export const Content = styled.div`
  width: 100%;
  height: min-content;
  padding: 8px 0 16px 0;
  box-sizing: border-box;

  p {
    margin: 0;
    padding: 0;
    line-height: 1.1;
  }
`;
