import React from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { Modal, IconCustom, Button, Text, Bold, Title } from '~/components/atoms';

import { Container, Content, TitleWrapper } from './ModalPanicNotification.styles';

const ModalPanicNotification = ({ patient, testId, isVisible, setHasNewPanicNotification }) => {
  const onClose = () => setHasNewPanicNotification(false);

  return (
    <Modal isVisible={isVisible} width={450} testId={`${testId}-modal`} destroyOnClose>
      <Container>
        <IconCustom name={svg.iconSiren} testId={`${testId}-siren-icon`} size="120px" />
        <TitleWrapper>
          <IconCustom name={svg.iconPanicWarn} testId={`${testId}-alert-icon`} size="28px" />
          <Title htmlType="h3" testId={`${testId}-title`}>
            Pânico
          </Title>
        </TitleWrapper>
        <Content>
          <Text testId={`${testId}-text`}>
            O exame <Bold>{patient.exam}</Bold> de <Bold>{patient.name}</Bold> possui uma alteração
            crítica. Por favor, entre em contato com o seu paciente o quanto antes.
          </Text>
        </Content>
        <Button type="primary" testId={`${testId}-button`} onClick={onClose}>
          Entendi
        </Button>
      </Container>
    </Modal>
  );
};

ModalPanicNotification.propTypes = {
  patient: PropTypes.shape({
    name: PropTypes.string,
    exam: PropTypes.string,
  }),
  testId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setHasNewPanicNotification: PropTypes.func.isRequired,
};

ModalPanicNotification.defaultProps = {
  patient: {
    name: '',
    exam: '',
  },
};

export default ModalPanicNotification;
