import styled from 'styled-components';
import { Drawer as DrawerBase, List as ListBase } from 'antd';
import { Icon } from '~/components/atoms';

import { mediaQuery } from '~/utils/helpers';

export const Drawer = styled(DrawerBase)`
  && {
    height: calc(100vh - 64px);
    top: 64px;

    h3 {
      margin-left: 20px;
      margin-bottom: 30px;
    }

    .ant-drawer-body {
      padding: 30px 0;
    }

    .ant-drawer-mask {
      background-color: transparent;
    }

    .ant-drawer-content-wrapper {
      min-width: 480px;

      ${mediaQuery.inMobile(`
        min-width: 100vw;
      `)}
    }
  }
`;

export const List = styled(ListBase)``;

export const Item = styled(List.Item)`
  && {
    display: block;
    padding: 5px 0;
    margin: 20px 20px 0 20px;
  }
`;

export const InfoIcon = styled(Icon)``;

export const IconWrapper = styled.span`
  position: relative;
  left: 5px;
`;
