export default (data = {}) => ({
  validToken: data.validToken || false,
  doctor: {
    name: data.doctor.name || '',
    gender:
      (data.doctor.gender === 'M' && 'o Dr.') ||
      (data.doctor.gender === 'F' && 'a Dra.') ||
      'o Dr(a)',
  },
});
