import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { svg } from '~/assets';
import { FormWrapper, CovidResultsTable } from '~/components/molecules';
import { Modal, Button, IconCustom } from '~/components/atoms';

import Form from './Form/Form';
import formSchema from './Form/Form.schema';
import { ButtonWrapper, TableCovidExamsHolder } from './CovidExamsDownload.styles';

import {
  columnsCovidResults as covidColumns,
} from '~/utils/enums';
import { windowSize } from '~/utils/helpers';

const initialValues = {
  startDate: '',
  endDate: '',
};

const CovidExamsDownload = ({ testId, onSubmit }) => {
  const [visible, setVisible] = useState(false);

  const { loading } = useSelector(state => state.exams.reports.downloadCovidExams);
  
  const handleSubmit = async values => {
    const { error } = await onSubmit(values);
    const errorToBool = {
      fail: false,
      success: true,
    };
    if (!values.covidResultsResume) {
      setVisible(!errorToBool[error]);
    }
    return errorToBool[error];
  };

  const size = windowSize();
  const isMobile = size.width <= 768;
  const {
    data: { covidResults },
  } = useSelector(state => state.exams.reports.downloadCovidExams);

  return (
    <>
      <Modal
        isClosable
        fullScreen
        destroyOnClose
        testId={`${testId}-modal`}
        isVisible={visible}
        maskClosable={false}
        onCancel={() => setVisible(false)}
        removePadding
      >
        <FormWrapper
          enableReinitialize
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={handleSubmit}
          render={props => (
            <Form
              {...props}
              className={"exams-covid-form"}
              testId={`${testId}-form`}
              isLoading={loading}
              closeModal={() => setVisible(false)}
            />
          )}
        />
        {covidResults && covidResults.hasCovidResults && (
          <TableCovidExamsHolder>
            <CovidResultsTable
              data={covidResults.results}
              columns={covidColumns}
              isMobileScreen={isMobile}
            />
          </TableCovidExamsHolder>
        )}
      </Modal>
      <ButtonWrapper>
        <Button type="primary" testId={`${testId}-button`} onClick={() => setVisible(true)}>
          <IconCustom size="18px" testId={`${testId}-download-icon`} name={svg.iconDowloadSolid} />
          Exames de Covid-19
        </Button>
      </ButtonWrapper>
    </>
  );
};

CovidExamsDownload.propTypes = {
  testId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CovidExamsDownload;
