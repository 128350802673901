import React from 'react';
import PropTypes from 'prop-types';

import { AvatarBase } from './Avatar.style';

const Avatar = ({ id, testId, size, icon, image, bg, color, children }) => (
  <AvatarBase
    id={id}
    data-testid={testId}
    size={size}
    icon={icon}
    src={image}
    bg={bg}
    color={color}
  >
    {children}
  </AvatarBase>
);

Avatar.propTypes = {
  id: PropTypes.string,
  testId: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  icon: PropTypes.string,
  bg: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
};

Avatar.defaultProps = {
  id: '',
  testId: '',
  size: '',
  icon: '',
  image: '',
  bg: '',
  color: '',
  children: <></>,
};

export default Avatar;
