import moment from 'moment';
import 'moment/locale/pt-br';

const dateManager = moment;
const TODAY = new Date();
const DEFAULT_FORMAT = 'DD/MM/YYYY';

dateManager.locale('pt-BR');

export const getCurrentDate = () => dateManager(TODAY, DEFAULT_FORMAT).format(DEFAULT_FORMAT);

export const getLastYear = (numOfYear = 1) => {
  return dateManager().startOf('day').subtract(numOfYear, 'year').format(DEFAULT_FORMAT);
};

export const getLastMonth = (numOfMonth = 1) => {
  return dateManager().startOf('day').subtract(numOfMonth, 'month').format(DEFAULT_FORMAT);
};

export const getDate = date => dateManager(date).format('L');

export const getHours = date => dateManager(date).format('LT');

export const toStamp = date => {
  const myDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');

  return new Date(myDate);
};

export const getAge = birthdate => {
  const BIRTHDATE = moment(birthdate, 'DDMMYYYY');
  const age = moment.duration(TODAY - BIRTHDATE).as('years');

  return Math.floor(age);
};

export const getRelativeTime = date => {
  return moment(date, 'DDMMYYYY').fromNow().replace(/há /gi, '');
};

export const aWeekBefore= date => {
  const momentDate = dateManager(date, DEFAULT_FORMAT).subtract(7, 'days').format('YYYY-MM-DD');

  return new Date(momentDate);
}

export const aWeekLater = date => {
  const momentDate = dateManager(date, DEFAULT_FORMAT).add(8, 'days').format('YYYY-MM-DD');

  return new Date(momentDate);
}

export default {
  getCurrentDate,
  getLastYear,
  getLastMonth,
  getDate,
  getHours,
  toStamp,
  getAge,
  getRelativeTime,
  aWeekBefore,
  aWeekLater,
};
