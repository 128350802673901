import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { init as initApm } from '@elastic/apm-rum';

import configureStore from './store/configureStore';
import GlobalStyles from './App.styles';
import AppRouter from './AppRouter';
import './config/appPublicApi';
import './AppTracking';

initApm({
    serviceName: process.env.REACT_APP_ELASTIC_SERVICE_NAME,
    serverUrl: process.env.REACT_APP_ELASTIC_SERVER_URL,
    active: true,
    environment: process.env.REACT_APP_ELASTIC_ENV,
    serviceVersion: process.env.REACT_APP_VERSION,
});

const { store, persistor } = configureStore();

const App = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <GlobalStyles />
            <AppRouter />
        </PersistGate>
    </Provider>
);

export { store };
export default App;
