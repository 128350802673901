import React from 'react';
import PropTypes from 'prop-types';

import { IconCustom } from '~components/atoms';
import { svg } from '~assets';
import { styles } from '~config';

import { Drawer, Text, Button, ShareReportButton } from './ExamsActionsDrawer.styles';

const ExamsDrawer = ({
  visible,
  report,
  item,
  isLoadingImage,
  onClose,
  onClickPDF,
  onClickPDFDownload,
  openShareReportModal,
  onClickImage,
}) => {
  const shareReport = reportItem => () => {
    onClose();
    openShareReportModal(reportItem);
  };

  return (
    <Drawer visible={visible} placement="bottom" closable={false} onClose={onClose} destroyOnClose>
      <ShareReportButton
        disabled={item.isParent && item.isComplete && !item.isRDI}
        onClick={shareReport(item)}
      >
        <IconCustom
          name={
            item.isParent && item.isComplete && !item.isRDI
              ? svg.iconShareActive
              : svg.iconShareInactive
          }
          size="26px"
        />
        <Text>Compartilhar laudo</Text>
      </ShareReportButton>
      <Button
        disabled={
          !report.image.isActive || isLoadingImage || (!report.image.isActive && item.isParent)
        }
        type="link"
        color={styles.colors.default}
        onClick={() => onClickImage(item)}
      >
        {isLoadingImage ? (
          <IconCustom name={svg.iconLoading} size="20px" className="rotate" />
        ) : (
          <>
            <IconCustom
              name={
                !report.image.isActive || isLoadingImage || (!report.image.isActive && item.isParent)
                  ? svg.iconMovieSecondary
                  : svg.iconMovie
              }
              size="16px"
            />
            <Text>Abrir imagem</Text>
          </>
          )}
      </Button>
      <Button className="open-report-btn" onClick={() => onClickPDF(item)}>
        <IconCustom name={svg.iconPdf} size="24px" />
        <Text>Abrir laudo</Text>
      </Button>
      <Button onClick={() => onClickPDFDownload(item)}>
        <IconCustom name={svg.iconDowload} size="22px" />
        <Text>Baixar laudo</Text>
      </Button>
    </Drawer>
  );
};

ExamsDrawer.propTypes = {
  onClickImage: PropTypes.func,
  item: PropTypes.shape({
    isRDI: PropTypes.bool,
    isParent: PropTypes.bool,
    isComplete: PropTypes.bool,
  }),
  isLoadingImage: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onClickPDF: PropTypes.func,
  onClickPDFDownload: PropTypes.func,
  openShareReportModal: PropTypes.func,
  report: PropTypes.shape({
    pdf: PropTypes.shape({
      isActive: PropTypes.bool,
    }),
    image: PropTypes.shape({
      isActive: PropTypes.bool,
    }),
  }),
};

ExamsDrawer.defaultProps = {
  item: {
    isRDI: false,
    isParent: false,
    isComplete: false,
  },
  isLoadingImage: false,
  visible: false,
  onClose: () => undefined,
  onClickPDF: () => undefined,
  onClickPDFDownload: () => undefined,
  openShareReportModal: () => undefined,
  onClickImage: () => undefined,
  report: {
    pdf: {
      isActive: false,
    },
    image: {
      isActive: false,
    },
  },
};

export default ExamsDrawer;
