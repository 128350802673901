import { parseObjectToString } from '~/utils/helpers/queryStringParser';
import { tokenManager } from '~/utils/helpers';
import { apikey } from '~/config/envManager';

export const fromApi = ({ data = {}, url, params = {}, isAdmin = true }) => {
  if (data.report) return data.report;

  const urlParams = parseObjectToString(params);
  const { token } = tokenManager.toObject(tokenManager.get());

  const path = isAdmin
    ? `${url}?registerAction=true&apikey=${apikey}&t=${token}`
    : `${url}?${urlParams}&registerAction=true&apikey=${apikey}&t=${token}`;

  return path;
};
