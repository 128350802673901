import styled from 'styled-components';
import { List as ListBase } from 'antd';

import { styles } from '~/config';

export const Header = styled.header`
  border-bottom: 1px solid #cacfd9;
  margin-bottom: 1px;
`;

export const HeaderTitle = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  margin: 0px 0 12px 0;
`;

export const List = styled(ListBase)`
  list-style: none;
  padding: 0;

  .ant-list-pagination {
    .ant-pagination-item-active {
      background-color: ${styles.colors.primary};

      a {
        color: ${styles.colors.white};
      }
    }

    .ant-pagination-prev {
      margin-right: 8px !important;
    }

    .ant-pagination-next {
      margin-left: 8px !important;
    }

    .ant-pagination-total-text {
      width: 100%;
      margin-bottom: 8px;
    }
  }
`;

export const ListItem = styled(ListBase.Item)`
  margin-bottom: 15px;
  margin-bottom: 1.2em;
  border-bottom: 1px solid transparent !important;
`;

export const ContentUser = styled.div`
  width: 65%;
`;

export const Title = styled.h4`
  margin-bottom: 0;
  font-weight: bold;
  font-size: 1rem;
`;

export const ContentExam = styled.div`
  width: 35%;
  align-self: flex-end;
  text-align: right;
`;

export const Text = styled.p`
  font-size: 0.9rem;
  margin-bottom: 0;
`;

export const Time = styled.time`
  font-size: 0.9rem;
  color: #a0a9ba;
`;
