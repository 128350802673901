import React from 'react';
import PropTypes from 'prop-types';

import { CounterWrapper, CounterText, CounterDivider } from './Counter.styles';

const Counter = ({ text, total }) => (
  <CounterWrapper>
    <CounterText>{text}</CounterText>
    <CounterDivider type="vertical" />
    <CounterText>{total}</CounterText>
  </CounterWrapper>
);

Counter.propTypes = {
  text: PropTypes.string,
  total: PropTypes.string,
};

Counter.defaultProps = {
  text: '',
  total: '0',
};

export default Counter;
