import React from 'react';
import PropTypes from 'prop-types';
import { Anchor } from 'antd';

import { IconCustom, Text } from '~components/atoms';
import { svg } from '~assets';

import { Tabs, TabPane } from './ExamsHistoryTab.styles';

const { Link } = Anchor;

const ExamsHistoryTab = ({
  exams,
  otherExams,
  approved,
  onTabChange,
  showOtherExamsTab,
  isAssistant,
  isNurse,
  isDesktop,
}) => {
  const tabsType = isDesktop ? 'card' : '';

  return (
    <Tabs type={tabsType} onChange={onTabChange} className={isDesktop ? 'primary' : ''}>
      <TabPane
        tab={
          <Text className="tab-text">
            {isAssistant ? 'Exames prescritos' : 'Prescritos por mim'}
          </Text>
        }
        key="exams"
      >
        {exams}
      </TabPane>
      {!isAssistant && !isNurse && (
        <TabPane
          tab={
            <div data-tour="exams-tab">
              <Text className="tab-text">
                {!approved && (
                  <Anchor title="Prescritos por outros médicos" />
                )}
                Prescritos por outros médicos
                {!approved && (
                  <IconCustom
                    name={showOtherExamsTab ? svg.iconPadlockRegular : svg.iconPadlockSolid}
                    size="20px"
                  />
                )}
              </Text>
            </div>
          }
          key="other-exams"
        >
          {otherExams}
        </TabPane>
      )}
    </Tabs>
  );
};

ExamsHistoryTab.propTypes = {
  exams: PropTypes.node.isRequired,
  approved: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isAssistant: PropTypes.bool,
  isNurse: PropTypes.bool,
  showOtherExamsTab: PropTypes.bool,
  onTabChange: PropTypes.func,
  otherExams: PropTypes.node.isRequired,
};

ExamsHistoryTab.defaultProps = {
  approved: false,
  isDesktop: false,
  isAssistant: false,
  isNurse: false,
  showOtherExamsTab: false,
  onTabChange: () => undefined,
};

export default ExamsHistoryTab;
