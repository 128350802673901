import produce from 'immer';

import { simpleAction, makeInitialState } from '~/utils/helpers';
import { verifyToken, generateNewPassword } from '~/api/services/passwordRecovery.service';

export const RESET_PASSWORD_REQUEST = '@dasa-canal-medico/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = '@dasa-canal-medico/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = '@dasa-canal-medico/RESET_PASSWORD_FAILURE';

export const initialState = makeInitialState({ data: {} });

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case RESET_PASSWORD_REQUEST: {
        draft.error = {
          status: '',
          message: '',
        };
        draft.loading = true;
        break;
      }
      case RESET_PASSWORD_SUCCESS: {
        draft.data = action.payload;
        draft.loading = false;
        break;
      }
      case RESET_PASSWORD_FAILURE: {
        draft.error = {
          status: action.payload.status,
          message: action.payload.message,
        };
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

export const resetPasswordRequest = simpleAction(RESET_PASSWORD_REQUEST);
export const resetPasswordSuccess = simpleAction(RESET_PASSWORD_SUCCESS);
export const resetPasswordFailure = simpleAction(RESET_PASSWORD_FAILURE);

export const validateTokenAction = headers => async dispatch => {
  dispatch(resetPasswordRequest());
  try {
    const data = await verifyToken(headers);

    dispatch(resetPasswordSuccess(data));
  } catch (err) {
    const error = {
      status: err.response.status || '',
      message: err.response.data.message || '',
    };
    dispatch(resetPasswordFailure(error));
  }
};

export const newPasswordAction = (params, headers) => async dispatch => {
  dispatch(resetPasswordRequest());

  try {
    const { data } = await generateNewPassword(params, headers);

    dispatch(resetPasswordSuccess(data));
  } catch (err) {
    const error = {
      status: err.response.status || '',
      message: err.response.data.message || '',
    };

    dispatch(resetPasswordFailure(error));
  }
};
