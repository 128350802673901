import styled from 'styled-components';
import { Carousel as CarouselBase } from 'antd';
import { mediaQuery } from '~/utils/helpers';

export const Container = styled.section`
  position: relative;
  height: 214px;

  ${mediaQuery.inMobile(`
    height: 90px;
  `)}

  .ant-carousel {
    ${mediaQuery.inDesktop(`
      position: absolute;
    `)}

    ${mediaQuery.inTablet(`
      width: 50vw;
    `)}

    ${mediaQuery.inMobile(`
      position: absolute;
    `)}
  }
`;

export const Banner = styled.div`
  display: block;
  background: #241b6c url(${({ cover }) => cover || ''}) no-repeat;
  background-size: 100% 100%;
  height: 214px;
  min-width: 100vw;
  position: relative;

  ${mediaQuery.inTablet(`
    height: 214px;
    min-width: 100vw;
    position: relative;
  `)}

  ${mediaQuery.inMobile(`
    width: 100vw;
    height: 90px;
    left: 0;
  `)}
`;

export const CovidLink = styled.a`
  display: block;
  height: 214px;
  width: 30vw;
  position: absolute;
  left: 170vw;
  top: 7vw;
  z-index: 1;
  cursor: pointer;

  ${mediaQuery.inTablet(`
    top: 40px;
    left: 166vw;
    width: 30vw;
    height: 214px;
  `)}

  ${mediaQuery.inMobile(`
    width: 39vw;
    height: 50px;
    top: 10vw;
    left: 158vw;
  `)}
`;

export const ModalCovidVideoAction = styled.div`
  width: 44vw;
  height: 214px;
  position: absolute;
  left: 102vw;
  top: 6px;
  cursor: pointer;
  z-index: 2;

  ${mediaQuery.inTablet(`
    left: 104vw;
  `)}

  ${mediaQuery.inMobile(`
    left: 102vw;
    height: 90px;
    width: 50vw;
    top: 5px;
  `)}
`;

export const ModalEducationVideoAction = styled.div`
  width: 20vw;
  height: 214px;
  position: absolute;
  left: 211vw;
  top: 40px;
  cursor: pointer;
  z-index: 2;

  ${mediaQuery.inTablet(`
    left: 210vw;
  `)}

  ${mediaQuery.inMobile(`
    left: 200vw;
    height: 90px;
    width: 50vw;
    top: 0px;
  `)};
`;

export const WrapperLink = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonClose = styled.button`
  position: absolute;
  z-index: 2;
  top: -20px;
  width: 26px !important;
  height: 26px !important;
  left: 95vw;
  border-radius: 50%;
  padding: 0px;
  border: none;
  background-color: rgba(255, 255, 255, 0.32);
  ${mediaQuery.inMobile(`
    top: -27px;
    left: 87vw;
    width: 24px !important;
    height: 24px !important;
    .anticon {
      vertical-align: 0px;
    }
`)};
`;

export const Carousel = styled(CarouselBase)`
  && {
    max-width: 100vw;
    position: relative;
    top: -33px;
    left: -16px;
    height: 90px;
    ${mediaQuery.inDesktop(`
      left: -38px;
      height: 214px;
    `)}

    ${mediaQuery.inTablet(`
      left: -105px;
      top: -35px;
      min-width: 100vw;
      height: 214px;
    `)}
  }
`;

export const CarouselItem = styled.section`
  display: flex !important;
  justify-content: center;
  max-width: 100vw;
  height: 214px;
  ${mediaQuery.inMobile(`
    height: 90px;
  `)}
`;

export const WrapperModalAction = styled.div`
  position: relative;
`;

export const WrapperEducationLink = styled.div``;

export const EducationLink = styled.a`
  display: block;
  height: 10vw;
  width: 30vw;
  position: absolute;
  left: 270vw;
  top: 7vw;
  z-index: 1;
  cursor: pointer;

  ${mediaQuery.inTablet(`
    top: 0px;
    left: 262vw;
    width: 348px;
    height: 14vw;
  `)}

  ${mediaQuery.inMobile(`
    width: 50vw;
    height: 18vw;
    top: 10vw;
    left: 248vw;
  `)}
`;
