import { hotjar } from 'react-hotjar';
import { appEnv, hotjarHJID, hotjarHJSV } from '~/config/envManager';
import { environmentManager } from './utils/helpers';

const getAppConfig = async () => {
  if (environmentManager.isProd(appEnv)) {
    hotjar.initialize(hotjarHJID, hotjarHJSV);
  }
};

getAppConfig();
