import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { IconCustom } from '~/components/atoms';

import { AlertBadge, PanicBadge } from './Notifications.styles';

const Notifications = ({
  testId,
  isOpenNotifications,
  unreadNotificationsCount: { panicCount, alertCount },
}) => (
  <>
    <PanicBadge testId={`${testId}-panic-badge`} count={panicCount} overflowCount={9} />
    <AlertBadge testId={`${testId}-alert-badge`} count={alertCount} overflowCount={9}>
      <IconCustom
        testId={`${testId}-bell-icon`}
        name={
          !!alertCount || !!panicCount || isOpenNotifications
            ? svg.iconBellSolid
            : svg.iconBellRegular
        }
        size="24px"
      />
    </AlertBadge>
  </>
);

Notifications.propTypes = {
  isOpenNotifications: PropTypes.bool,
  unreadNotificationsCount: PropTypes.shape({
    alertCount: PropTypes.number,
    panicCount: PropTypes.number,
  }),
  testId: PropTypes.string.isRequired,
};

Notifications.defaultProps = {
  unreadNotificationsCount: {
    alertCount: 0,
    panicCount: 0,
  },
  isOpenNotifications: false,
};

export default memo(Notifications);
