import { downloadExamReport } from '~/api/services/exams.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const EXAM_REPORT_REQUEST = '@dasa-canal-medico/EXAM_REPORT_DOWNLOAD_REQUEST';
export const EXAM_REPORT_SUCCESS = '@dasa-canal-medico/EXAM_REPORT_DOWNLOAD_SUCCESS';
export const EXAM_REPORT_FAILURE = '@dasa-canal-medico/EXAM_REPORT_DOWNLOAD_FAILURE';
export const EXAM_REPORT_RESET = '@dasa-canal-medico/EXAM_REPORT_DOWNLOAD_RESET';

// Reducer
export const initialState = {
  data: {},
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EXAM_REPORT_REQUEST:
      return {
        ...state,
        ...initialState,
        isLoading: true,
      };

    case EXAM_REPORT_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: action.payload,
        isLoading: false,
      };

    case EXAM_REPORT_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };

    case EXAM_REPORT_RESET:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}

// Action Creators
export const downloadExamReportRequest = simpleAction(EXAM_REPORT_REQUEST);
export const downloadExamReportSuccess = simpleAction(EXAM_REPORT_SUCCESS);
export const downloadExamReportFailure = simpleAction(EXAM_REPORT_FAILURE);

export const resetExamsPatientDetailReset = simpleAction(EXAM_REPORT_RESET);

export const downloadExamReportAction = ({
  protocol,
  laudoId,
  crm,
  origem,
  index,
}) => async dispatch => {
  dispatch(downloadExamReportRequest());

  try {
    const payload = await downloadExamReport({ protocol, laudoId, crm, origem, index });
    dispatch(downloadExamReportSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(downloadExamReportFailure(err));
  }
};

export const resetExamReportAction = () => dispatch => {
  dispatch(resetExamsPatientDetailReset());
};
