import 'antd/dist/antd.less';

import { createGlobalStyle } from 'styled-components';

import { styles } from '~/config';
import { mediaQuery } from '~/utils/helpers';

export default createGlobalStyle`
  html,
  body,
  #app {
    display: flex;
    flex: 1;
    width: 100vw;
    min-height: 100vh;
    height: auto;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 16px;
    letter-spacing: 0.25px;
    background: ${styles.colors.defaultBackground};
    -webkit-font-smoothing: antialiased !important;

    /* HACK EI */
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  strong,
  div > a,
  .ant-table-column-title {
    font-weight: 700;
    }

  @media only screen and (max-width: 768px) {
    .ant-pagination-options {
      display: block;
      margin-top: 20px;
    }
  }

  .ant-menu-item img {
    filter: grayscale(100%) opacity(70%);
  }

  .ant-menu-item-selected img {
    filter: grayscale(0%) opacity(100%);
  }

  .ant-menu-item-selected:hover img {
    filter: grayscale(100%) opacity(70%);
  }

  /* TODO: Remove styles global */
  /* Input */
  input.ant-input {
    height: 48px;
    font-size: 16px;
  }

  .ant-select-selection__placeholder,
  input.ant-input::placeholder {
    color: #747679;
    font-size: 16px;
  }

  .ant-select-dropdown-menu {
    padding: 0;
  }

  .ant-select-dropdown-menu-item {
    font-size: 1rem;

    ${mediaQuery.inMobile(`
      padding: 8px;
    `)}
  }

  .ant-form-item-label {
    line-height: 1.2;
    font-size: 12px;
    margin-bottom: 7px;
  }

  .ant-input-password-icon {
    color: #747679;
  }

  .ant-btn {
    font-size: 16px;
  }

  .ant-btn.ant-btn-sm {
    font-size: 12px;
  }

  .ant-input-group-addon {
    background-color: #fff;
  }

  .ant-input-group-addon .ant-form-item {
    margin-bottom: 0;
    min-width: 50px;
    text-align: left;
  }

  .ant-input-group-addon .ant-select-selection-selected-value {
    color: #0037FF;
  }

  /* */
  .ant-select-lg .ant-select-selection--single {
    height: 48px;
  }

  .ant-select-lg .ant-select-selection__rendered {
    line-height: 48px;
  }

  /* */
  .ant-form-explain {
    margin-top: 4px;
    color: #ff3867;
    font-size: 12px;
    text-align: right;
  }

  .ant-form-explain::before {
    margin-right: 4px;
    content: '';
    width: 10px;
    height: 10px;
    background-image: url('./assets/sgvs/warn.svg');
    background-size: cover;
    display: inline-block;
  }

  /* Pagination */
  .ant-pagination-item {
    border: none;
    background-color: transparent;
  }

  .ant-pagination-item:hover {
    background-color: #cec9e4;
  }

  .ant-pagination-item:hover a {
    color: #000;
  }

  /* Table */
  table {
    font-size: 1rem;
  }

  th.ant-table-column-has-actions.ant-table-column-has-sorters {
    padding-left: 0px;
  }

  td.ant-table-column-has-actions.ant-table-column-has-sorters {
    padding-left: 0px;
  }

  .ant-table-thead > tr > th {
    border-color: rgba(160, 169, 186, 0.25);
    border-bottom-width: 1px;
    color: ${styles.colors.primary};
    margin-bottom: 3px;
  }

  .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover {
    color: #747679;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .ant-table-thead
    > tr
    > th
    .ant-table-header-column
    .ant-table-column-sorters
    > .ant-table-column-title {
    font-weight: bold;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: #d4d6d7;
    cursor: pointer;
  }

  .table-without-hover .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .table-without-hover .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .table-without-hover .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .table-without-hover .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: transparent;
    cursor: default;
  }

  .ant-table-tbody > tr > td {
    padding: 13px 0px;
  }

  .ant-table-thead > tr > th {
    padding: 15px 0px;
  }

  .ant-table-tbody > tr[data-row-key="0"] > td{
    padding: 16px 0px 13px;
  }

  .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before {
    background: none;
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .ant-table-thead
    > tr
    > th
    .ant-table-header-column
    .ant-table-column-sorters
    > .ant-table-column-title {
    font-weight: bold;
  }

  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
    background-color: transparent;
  }

  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover
    .ant-table-column-title {
    color: #747679;
  }

  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(25%, #d9d9d9),
      color-stop(37%, #e6e6e6),
      color-stop(63%, #d9d9d9)
    );
    background: linear-gradient(90deg, #d9d9d9 25%, #e6e6e6 37%, #d9d9d9 63%);
    background-size: 400% 100%;
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    animation: ant-skeleton-loading 1.4s ease infinite;
  }

  /* Checkbox */
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  /* Notification */
  @media screen and (max-width: 768px) {
    .ant-notification.ant-notification-topRight {
      margin-right: 16px;
    }
  }

  .notification-complete-register {
    background: #0037FF;
    height: 69px;
    top: 64px;
  }

  .notification-complete-register .ant-notification-notice-close {
    color: #fff;
    top: 20px;
  }

  .notification-complete-register .ant-notification-notice-description {
    font-size: 12px;
  }

  .notification-complete-register .ant-notification-notice-message {
    font-size: 24px;
    margin-bottom: 0;
  }

  .custom-error,
  .custom-error .anticon.ant-notification-notice-icon-error,
  .custom-error .ant-notification-notice-with-icon .ant-notification-notice-message,
  .custom-error .anticon svg {
    background-color: #f8371e;
    color: #fff;
  }

  .ant-select-clear-icon {
    svg {
      color: ${styles.colors.primary};
      height: 16px;
      width: 16px;
      position: relative;
      top: -2px;
      left: -1px;
    }
  }

  /* UTILS */
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .rotate {
    animation: rotation 1s infinite linear;
  }
`;
