import styled from 'styled-components';
import { Button as ButtonBase } from 'antd';

import { mediaQuery } from '~/utils/helpers';
import { styles } from '~config';

export const Actions = styled.div`
  text-align: right;
`;

export const ButtonText = styled.b`
  display: inline-block;
  position: absolute;
  width: 100%;
  bottom: -8px;
  left: 0;
  padding: 2px;
  border-radius: 4px;
  background-color: ${styles.colors.black};
  color: ${styles.colors.white};
  font-size: ${styles.fontSize.small};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Button = styled(ButtonBase)`
  && {
    position: relative;
    width: 50px;
    height: 50px;
    padding: 0;
    color: ${styles.colors.primary};

    ${ButtonText} {
      display: none;
    }

    &:hover {
      background: ${({ disabled }) => !disabled && 'rgba(227, 227, 227, 1)'};

      &:disabled {
        background: none;

        ${ButtonText} {
          display: none;
        }
      }

      ${ButtonText} {
        display: inline-block;
      }
    }

    svg {
      font-size: 24px;
    }

    ${mediaQuery.inMobile(`
      width: 35px;
      height: 35px;

      &:hover {
        background: none;
      }
    `)}
  }
`;
