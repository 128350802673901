import { notification } from 'antd';
import { status } from '../enums';

export const notificationBase = ({
  btn,
  title,
  description,
  duration = 5,
  className = '',
  style,
  onClose,
  closeIcon,
  icon = '',
}) => {
  const args = {
    btn,
    style,
    onClose,
    className,
    description,
    duration,
    message: title,
    icon,
    closeIcon,
  };

  notification.open(args);
};

export const notificationWithIcon = ({
  title,
  description,
  type,
  style,
  icon,
  duration = 5,
  className,
}) => {
  const t = status[type] || status.error;

  notification[t]({
    message: title,
    description,
    style,
    duration,
    icon,
    className,
  });
};

export const notificationClose = id => notification.close(id);

export default {
  open: notificationBase,
  close: notificationClose,
  withIcon: notificationWithIcon,
};
