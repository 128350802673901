import gaTypeEvents from './gaTypeEvents.enum';

export default {
  covid: {
    id: 'covid',
    link: 'http://cloud.dasa-mail.com/dasa-covid19',
    video: 'https://www.youtube.com/watch?v=4uCfdKLfJME&feature=youtu.be',
    events: {
      link: gaTypeEvents.private.patients.banner.covid.link,
      video: gaTypeEvents.private.patients.banner.covid.video,
    },
  },
  dasaEduca: {
    id: 'dasa-educa',
    link: 'https://www.dasaeduca.com.br/',
    video: 'https://www.youtube.com/watch?v=owEK7vqUn-w&feature=youtu.be',
    events: {
      link: gaTypeEvents.private.patients.banner.dasaEduca.link,
      video: gaTypeEvents.private.patients.banner.dasaEduca.video,
    },
  },
};
