import React from 'react';
import PropTypes from 'prop-types';

import { Modal, LPTitle, LPText } from '~/components/atoms';
import { FormWrapper } from '~/components/molecules';

import Form from './Form/Form';
import { Container } from './ModalSelectProfile.styles';

const initialValues = {
  profile: '',
};

const ModalSelectProfile = ({
  testId,
  profiles,
  onSubmit,
  isLoading,
  clearUser,
  multiProfiles,
  setShowSelectProfileModal,
}) => {
  const options = profiles
    .filter(x => !['assistente', 'enfermeiro', 'medico'].includes(x.role))
    .map(profile => ({
      id: profile.id,
      value: profile,
      text: profile.crm
    }));

  const onClose = () => {
    setShowSelectProfileModal(false);
    clearUser();
  };

  return (
    <Modal testId={`${testId}-modal`} width="510px" isVisible={multiProfiles} removePadding>
      <Container>
        <LPTitle testId={`${testId}-title`} htmlType="h3">
          Você possui mais de um registro
        </LPTitle>

        <LPText className="select-profile-modal-text" type="secondary" testId={`${testId}-text`}>
          Escolha com qual registro e/ou perfil quer acessar
        </LPText>

        <FormWrapper
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={props => (
            <Form
              {...props}
              testId={testId}
              options={options}
              onClose={onClose}
              isLoading={isLoading}
            />
          )}
        />
      </Container>
    </Modal>
  );
};

ModalSelectProfile.propTypes = {
  testId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  clearUser: PropTypes.func.isRequired,
  setShowSelectProfileModal: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf({}),
  isLoading: PropTypes.bool,
  multiProfiles: PropTypes.bool,
};

ModalSelectProfile.defaultProps = {
  profiles: [],
  isLoading: false,
  multiProfiles: false,
};

export default ModalSelectProfile;
