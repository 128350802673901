import produce from 'immer';

import { getAssistants } from '~api/services/assistant.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const ASSISTANT_GROUP_REQUEST = '@dasa-canal-medico/ASSISTANT_GROUP_REQUEST';
export const ASSISTANT_GROUP_SUCCESS = '@dasa-canal-medico/ASSISTANT_GROUP_SUCCESS';
export const ASSISTANT_GROUP_FAILURE = '@dasa-canal-medico/ASSISTANT_GROUP_FAILURE';
export const ASSISTANT_GROUP_RESET = '@dasa-canal-medico/ASSISTANT_GROUP_RESET';

// Reducer
export const initialState = {
  data: [],
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case ASSISTANT_GROUP_REQUEST: {
        draft.isLoading = true;
        break;
      }
      case ASSISTANT_GROUP_SUCCESS: {
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      }
      case ASSISTANT_GROUP_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.isLoading = false;
        break;
      }
      default:
    }
  });
}

// Action Creators
export const getAssistantGroupRequest = simpleAction(ASSISTANT_GROUP_REQUEST);
export const getAssistantGroupSuccess = simpleAction(ASSISTANT_GROUP_SUCCESS);
export const getAssistantGroupFailure = simpleAction(ASSISTANT_GROUP_FAILURE);

export const getAssistantGroupAction = () => async dispatch => {
  dispatch(getAssistantGroupRequest());

  try {
    const payload = await getAssistants();
    dispatch(getAssistantGroupSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(getAssistantGroupFailure(err));
  }
};
