import styled from 'styled-components';
import { mediaQuery } from '~/utils/helpers';

export const Header = styled.header`
  margin-bottom: 35px;

  ${mediaQuery.inMobile(`
    margin-bottom: 16px;
  `)}
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;

  ${mediaQuery.inDesktop(`
    padding-top: 26px;
  `)}

  ${mediaQuery.inTablet(`
    font-size: 1.6rem;
  `)}

  ${mediaQuery.inMobile(`
    font-size: 1.6rem;
  `)}
`;

export const Description = styled.p`
  margin: 0;
`;
