import React from 'react';
import PropTypes from 'prop-types';

import { LinkBase } from './Link.styles';

const Link = ({ children, testid, trackId, to, type, onClick }) => (
  <LinkBase data-testid={testid} to={to} onClick={onClick} data-collect={trackId} className={type}>
    {children}
  </LinkBase>
);

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  testid: PropTypes.string,
  trackId: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  testid: '',
  trackId: '',
  type: '',
  onClick: () => undefined,
};

export default Link;
