const objectType = {
  NULL: '[object Null]',
  ARRAY: '[object Array]',
  NUMBER: '[object Number]',
  OBJECT: '[object Object]',
  STRING: '[object String]',
  BOLLEAN: '[object Boolean]',
  FUNCTION: '[object Function]',
  UNDEFINED: '[object Undefined]',
};

export const getType = obj => Object.prototype.toString.call(obj);

export const isArray = obj => getType(obj) === objectType.ARRAY;

export const isNumber = obj => getType(obj) === objectType.NUMBER;

export const isFunction = obj => getType(obj) === objectType.FUNCTION;

export const isObject = obj => getType(obj) === objectType.OBJECT;

export const isString = obj => getType(obj) === objectType.STRING;

export const isBoolean = obj => getType(obj) === objectType.BOLLEAN;

export const isNull = obj => {
  const type = getType(obj);

  return type === objectType.NULL || type === objectType.UNDEFINED;
};

export const isEmpty = obj => {
  if (isNull(obj) || isNumber(obj)) return true;
  if (isArray(obj) || isString(obj)) return obj.length === 0;
  if (isObject(obj)) return Object.keys(obj).length === 0;

  return false;
};

export const Not = param => !param;

export const orderByKey = (a, b, key) => {
  if (a[key] > b[key]) return -1;
  if (a[key] < b[key]) return 1;
  return 0;
};

export const removeFalsy = obj => {
  const newObj = {};

  Object.keys(obj).forEach(prop => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });

  return newObj;
};

export const fromEntries = entries => {
  if (!entries) throw new Error('It requires a single iterable argument');

  return Array.from(entries).reduce((acc, [key, val]) => Object.assign(acc, { [key]: val }), {});
};

export const objectMapper = value => {
  if (typeof value === 'object') {
    return fromEntries(Object.entries(value).map(([key, val]) => [key, val || '']));
  }

  return value || '';
};

export const objectManager = {
  isArray,
  isNumber,
  isFunction,
  isObject,
  isString,
  isBoolean,
  isNull,
  isEmpty,
  orderByKey,
  Not,
  removeFalsy,
  fromEntries,
  objectMapper,
};
