import styled from 'styled-components';
import { Tabs as TabsBase } from 'antd';

import { mediaQuery } from '~/utils/helpers';
import { styles } from '~/config';

export const Tabs = styled(TabsBase)`
  && {
    .ant-tabs-tabpane {
      display: flex !important;
      border-radius: 6px;
      background: ${({ approved }) => !approved && `${styles.colors.white}`};
      justify-content: ${({ approved }) => (approved ? 'space-between' : 'center')};
    }

    .ant-tabs-card {
      margin-bottom: 0;
      background-color: #e8e8e8;

      .tab-exams {
        background-color: #e8e8e8;
      }
    }

    .ant-tabs-tab {
      background: #ffffff !important;
      color: #747679;
      margin-right: 0 !important;
      border-color: ${styles.colors.listItemLine} !important;
    }

    .ant-tabs-bar {
      margin-bottom: 0;
    }

    .ant-tabs-content {
      border: 1px solid #cfd3dc;
      border-radius: 8px;
      border-top-left-radius: 0;
    }

    .tab-primary {
      background-color: ${styles.colors.white};
    }

    .ant-tabs-card-bar {
      margin-right: 0;
    }

    .ant-tabs-bar {
      border-bottom: none;
    }

    .ant-tabs-nav-wrap {
      display: flex;
    }

    .ant-tabs-tab-arrow-show {
      display: none;
    }

    .ant-tabs-tab-active {
      background-color: ${styles.colors.white};
      font-weight: bold;
    }

    .ant-tabs-ink-bar {
      height: 3px;
    }

    .ant-tabs-tab-active {
      border-color: ${styles.colors.listItemLine} !important;
      background: ${styles.colors.white} !important;
    }

    .ant-tabs-tab-active {
      border-bottom: 1px solid ${styles.colors.white} !important;
    }

    .tab-text {
      margin: 0;

      img {
        margin-left: 5px;
      }
    }

    .ant-anchor-wrapper {
      position: absolute;
      background: transparent;

      .ant-anchor-ink {
        display: none;
      }

      .ant-anchor-link-title {
        color: transparent;
        height: 34px;
        width: 264px;
        left: -22px;
        top: -5px;
      }
    }

    ${mediaQuery.inMobile(`
      max-width: calc(100vw - 32px);
      ;
      
      .ant-tabs-tabpane {
        flex-direction: column;
      }

      .ant-tabs-content {
        border: none;
      }

      .ant-tabs-nav-container {
        padding: 0;
      }

      .ant-tabs-tab,
      .ant-tabs-tab-active {
        margin: 0;
        border-color: red;
        background: transparent !important;
      }

      .ant-tabs-tab-active {
        color: ${styles.colors.primary};
      }
    `)}
  }
`;

export const TabPane = styled(TabsBase.TabPane)``;
