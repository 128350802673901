import { getUserDetails, getUserDetailsV2 } from '~/api/services/users.service';
import { simpleAction, errorMessage, cpfValidator } from '~/utils/helpers';
import { getFeatureToggle } from '~/api/services/featureToggle.service';

export const USER_ADMIN_DETAILS_REQUEST = '@dasa-canal-medico/USER_ADMIN_DETAILS_REQUEST';
export const USER_ADMIN_DETAILS_SUCCESS = '@dasa-canal-medico/USER_ADMIN_DETAILS_SUCCESS';
export const USER_ADMIN_DETAILS_FAILURE = '@dasa-canal-medico/USER_ADMIN_DETAILS_FAILURE';
export const USER_ADMIN_DETAILS_RESET = '@dasa-canal-medico/USER_ADMIN_DETAILS_RESET';

// Reducer
const initialState = {
  data: {},
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_ADMIN_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case USER_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: action.payload,
        isLoading: false,
      };
    case USER_ADMIN_DETAILS_FAILURE:
      return {
        ...state,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };
    case USER_ADMIN_DETAILS_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}

// Action Creators
const getUserDetailsRequest = simpleAction(USER_ADMIN_DETAILS_REQUEST);
const getUserDetailsSuccess = simpleAction(USER_ADMIN_DETAILS_SUCCESS);
const getUserDetailsFailure = simpleAction(USER_ADMIN_DETAILS_FAILURE);
const getUserDetailsReset = simpleAction(USER_ADMIN_DETAILS_FAILURE);

export const getUserDetailsAction = params => async dispatch => {
  dispatch(getUserDetailsRequest());

  const isCPF = cpfValidator(params.id);
  const { enabled: controlInfoMDM } = await getFeatureToggle('controlInfoMDM');
  try {
    const payload = (controlInfoMDM && isCPF) ? await getUserDetailsV2(params) : await getUserDetails(params);
    dispatch(getUserDetailsSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(getUserDetailsFailure(err));
  }
};

export const resetUserDetailsAction = () => dispatch => {
  dispatch(getUserDetailsReset());
};
