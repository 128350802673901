export const isNam = role => role === 'nam';

export const isAdmin = role => role === 'admin';

export const isDoctor = role => role === 'medico';

export const isVisitor = role => role === 'visitador';

export const isAssistant = role => role === 'assistente';

export const isNurse = role => role === 'enfermeiro';

export const isMarketing = role => role === 'marketing';

export const adminValidator = role => isAdmin(role) || isNam(role);

export const roleManager = {
  isNam,
  isAdmin,
  isDoctor,
  isVisitor,
  isAssistant,
  isNurse,
  adminValidator,
};
