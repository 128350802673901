import React from 'react';
import PropTypes from 'prop-types';

import { TextBase } from './LPText.styles';

const LPText = ({ children, testId, strong, className, type }) => (
  <TextBase type={type} data-testid={testId} strong={strong} className={className}>
    {children}
  </TextBase>
);

LPText.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  strong: PropTypes.bool,
  testId: PropTypes.string,
  className: PropTypes.string,
};

LPText.defaultProps = {
  type: 'primary',
  testId: '',
  strong: false,
  className: undefined,
};

export default LPText;
