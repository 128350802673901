import { mapper } from '~utils/decorators';

@mapper
class ExamNotification {
  constructor(data = {}) {
    this.id = data.idNotification;
    this.notificationDate = data.notificationDate;
    this.viewDate = data.notificationDate;
    this.type = data.type;
    this.isRead = data.isRead;
    this.url = data.url;
    this.message = data.message;
    this.patient = {
      name: data.patient ? data.patient.name : '',
      exam: data.patient ? data.patient.exam : '',
      identifier: data.patient ? data.patient.identifier : '',
      birthdate: data.patient ? data.patient.birthdate : '',
    };
  }
}

export default ExamNotification;
