import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';

import { namContacts } from '~/utils/enums';
import { Title, Text, Tooltip } from '~/components/atoms';
import { CopyIcon } from '~/components/molecules';

import { Drawer, List, Item, InfoIcon, IconWrapper } from './ContactsList.styles';

const ContactsList = ({ visible, onClose, testId }) => (
  <Drawer testId={testId} visible={visible} onClose={onClose} closable={false}>
    <Title htmlType="h3">Telefones de Contato</Title>
    <List
      dataSource={namContacts}
      renderItem={item => (
        <Item>
          <Row justify="center" gutter={24}>
            <Col span={8} style={{ textAlign: 'left' }}>
              <Text>
                <strong>{item.name}:</strong>
              </Text>
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              <div>
                {item.contacts.map(contact => (
                  contact.info ? (
                    <Text>
                      <Tooltip text={contact.info} placement="right" trigger={['hover', 'click']}>
                        {contact.text}
                        <IconWrapper>
                          <InfoIcon name="question-circle" />
                        </IconWrapper>
                      </Tooltip>
                    </Text>
                  ) : <Text>{contact.text}</Text>
                ))}
              </div>
            </Col>
            <Col
              span={8}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
            >
              {item.contacts.map(contact => (
                <CopyIcon testId="nam-contacts-copy-icon" value={contact.value} />
              ))}
            </Col>
          </Row>
        </Item>
      )}
    />
  </Drawer>
);

ContactsList.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  testId: PropTypes.string.isRequired,
};

ContactsList.defaultProps = {
  visible: false,
};

export default ContactsList;
