import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { Modal, Button, Title } from '~/components/atoms';

import CovidLetter from './CovidLetter/CovidLetter';
import {
  Container,
  ModalContent,
  ModalFooter,
  TextContent,
  FooterContent,
  HeaderTextWrapper,
  HeaderImageWrapper,
  LogoNav,
} from './ModalCovidLetter.styles';

const ModalCovidLetter = ({ testId, isVisible, closeModal }) => {
  return (
    <Modal isVisible={isVisible} width={800} removePadding destroyOnClose>
      <Container>
        <ModalContent>
          <Title htmlType="h3" testId={`${testId}-title`}>
            <HeaderTextWrapper>
              Atualização COVID-19
              <br />
              Carta aos Médicos(as)
            </HeaderTextWrapper>
            <HeaderImageWrapper>
              <LogoNav src={svg.logoPrimary} alt="Logo Nav" />
            </HeaderImageWrapper>
          </Title>

          <TextContent>
            <div>
              <CovidLetter testId={`${testId}-text`} />
            </div>
          </TextContent>

          <ModalFooter>
            <FooterContent>
              <Button testId={`${testId}-btn`} onClick={closeModal} type="primary">
                Entendi
              </Button>
            </FooterContent>
          </ModalFooter>
        </ModalContent>
      </Container>
    </Modal>
  );
};

ModalCovidLetter.defaultProps = {
  testId: '',
  isVisible: false,
};

ModalCovidLetter.propTypes = {
  testId: PropTypes.string,
  isVisible: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

export default memo(ModalCovidLetter);
