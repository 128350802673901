import { getExamReport } from '~/api/services/exams.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const EXAM_REPORT_REQUEST = '@dasa-canal-medico/EXAM_REPORT_REQUEST';
export const EXAM_REPORT_SUCCESS = '@dasa-canal-medico/EXAM_REPORT_SUCCESS';
export const EXAM_REPORT_FAILURE = '@dasa-canal-medico/EXAM_REPORT_FAILURE';
export const EXAM_REPORT_RESET = '@dasa-canal-medico/EXAM_REPORT_RESET';

// Reducer
export const initialState = {
  data: {},
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EXAM_REPORT_REQUEST:
      return {
        ...state,
        ...initialState,
        isLoading: true,
      };

    case EXAM_REPORT_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: action.payload,
        isLoading: false,
      };

    case EXAM_REPORT_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };

    case EXAM_REPORT_RESET:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}

// Action Creators
export const getExamReportRequest = simpleAction(EXAM_REPORT_REQUEST);
export const getExamReportSuccess = simpleAction(EXAM_REPORT_SUCCESS);
export const getExamReportFailure = simpleAction(EXAM_REPORT_FAILURE);

export const resetExamsPatientDetailReset = simpleAction(EXAM_REPORT_RESET);

export const getExamReportAction = ({
  protocol,
  laudoId,
  crm,
  origem,
  isMotion = false,
  isAdmin,
  ...doctorInfo
}) => async dispatch => {
  dispatch(getExamReportRequest());

  try {
    const payload = await getExamReport({
      protocol,
      laudoId,
      crm,
      origem,
      isMotion,
      isAdmin,
      ...doctorInfo,
    });
    dispatch(getExamReportSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(getExamReportFailure(err));
  }
};

export const resetExamReportAction = () => dispatch => {
  dispatch(resetExamsPatientDetailReset());
};
