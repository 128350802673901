import styled from 'styled-components';
import { Badge as BadgeBase } from 'antd';

import { styles } from '~config';

export const AlertBadge = styled(BadgeBase)`
  && {
    .ant-badge-count {
      background: ${styles.colors.success};
      top: 24px;
      min-width: auto;
      width: 14px;
      height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.6rem;
      font-weight: bold;
    }
  }
`;

export const PanicBadge = styled(AlertBadge)`
  && {
    .ant-badge-count {
      background: ${styles.colors.alert};
      top: -16px;
      left: 28px;
      z-index: 1;
      box-shadow: none;
    }
  }
`;
