import api from '../api';
import { GET } from '../httpMethods';
import { fromApi } from '../dto/users/loggedUserInfo.dto';
import { apiUsers } from '../../config/envManager';

export const loggedUserInfo = async () => {
  const { data } = await api({
    url: `${apiUsers}/private/user-info`,
    method: GET,
  });

  const response = fromApi(data);

  return response;
};
