import { Onboarding } from '~/utils/entities';

const onboardingManager = onboarding => {
  if (!onboarding) throw new Error('Onboarding param is required');

  const {
    enableTourFirstAccess,
    enableTourMyAssistants,
    enableTourNewListOfPatients,
    enableTourExamsResquestHistory,
    enableTourExamsHistoryAvailable,
    enableTourExamsHistoryOtherDoctors,
  } = onboarding;

  const assistants = new Onboarding(enableTourMyAssistants);
  const firstAccess = new Onboarding(enableTourFirstAccess);
  const patientList = new Onboarding(enableTourNewListOfPatients);
  const examsRequestHistory = new Onboarding(enableTourExamsResquestHistory);
  const examsHistoryAvaiable = new Onboarding(enableTourExamsHistoryAvailable);
  const examsHistoryOtherDoctors = new Onboarding(enableTourExamsHistoryOtherDoctors);

  const showAssistantsOnboarding = !patientList.show && !firstAccess.show && assistants.show;
  const showFirstAccessOnboarding = firstAccess.show;
  const showPatientListOnboarding = !firstAccess.show && patientList.show;
  const showExamsRequestHistoryOnboarding = examsRequestHistory.show;

  const showExamsHistoryOnboarding =
    !examsRequestHistory.show && !examsHistoryOtherDoctors.show && examsHistoryAvaiable.show;

  const showExamsHistoryOtherDoctorsOnboarding =
    !examsRequestHistory.show && examsHistoryOtherDoctors.show;

  return {
    assistants: {
      id: assistants.id,
      show: showAssistantsOnboarding,
    },
    firstAccess: {
      id: firstAccess.id,
      show: showFirstAccessOnboarding,
    },
    patientList: {
      id: patientList.id,
      show: showPatientListOnboarding,
    },
    examsHistoryAvaiable: {
      id: examsHistoryAvaiable.id,
      show: showExamsHistoryOnboarding,
    },
    examsRequestHistory: {
      id: examsRequestHistory.id,
      show: showExamsRequestHistoryOnboarding,
    },
    examsHistoryOtherDoctors: {
      id: examsHistoryOtherDoctors.id,
      show: showExamsHistoryOtherDoctorsOnboarding,
    },
  };
};

export default onboardingManager;
