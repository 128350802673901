import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { lpStyles } from '~/config';

export const LinkBase = styled(Link)`
  && {
    color: ${lpStyles.colors.link};
    font-size: 0.9em;
    font-weight: normal;
    font-family: ${lpStyles.fontFamily.default};
    text-decoration: underline;

    &:hover,
    &:active {
      opacity: 0.8;
    }

    &.button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      border: 1px solid;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
      border-radius: 8px;
      text-decoration: none;
      color: ${lpStyles.colors.gray};
    }
  }
`;
