/* eslint-disable no-console */
import { tokenManager, deepFreeze, objectManager, userManager } from '../utils/helpers';
import webRouter from './webRouter';

const { isNull } = objectManager;

const Api = {
  getToken() {
    return tokenManager.getObjectToken();
  },
  getUrl: {
    patientDetails({ crm, cip, protocol } = {}, isAbsolutePath = false) {
      try {
        if (isNull(crm) && isNull(protocol) && isNull(cip)) {
          throw new Error('Param `crm, cip, protocol` is required');
        }
        if (isNull(crm)) throw new Error('Param `crm` is required');
        if (isNull(protocol)) throw new Error('Param `protocol` is required');
        if (isNull(cip)) throw new Error('Param `cip` is required');

        const url = userManager.isBetaTester(crm) && webRouter.PATIENTS;

        return isAbsolutePath
          ? `${window.location.origin}${url}/detail/${protocol}/${cip}`
          : `${url}/detail/${protocol}/${cip}`;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
  redirect: {
    patientDetails({ crm, protocol, cip } = {}) {
      try {
        const url = window.App.getUrl.patientDetails({ crm, protocol, cip }, true);
        if (url) window.location = url;
      } catch (err) {
        console.warn(err);
      }
    },
  },
};

// Public Api
deepFreeze(Api);
window.App = Api;
