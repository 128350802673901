import styled from 'styled-components';
import { Button } from 'antd';

import { styles } from '../../../config';
import { status } from '../../../utils/enums';

const isDisabled = disabled => !!disabled;
const isOutline = outline => !!outline;
const isError = (type = '') => type === status.error;
const isSuccess = (type = '') => type === status.success;

const hasDisabled = (disabled, css) => (isDisabled(disabled) ? css : '');
const hasOutline = (outline, css) => (isOutline(outline) ? css : '');
const hasError = (type, css) => (isError(type) ? css : '');
const hasSuccess = (type, css) => (isSuccess(type) ? css : '');

export const Btn = styled(Button)`
  && {
    height: 48px;

    &.ant-btn-sm {
      height: 32px;
    }

    background-color: ${({ buttonStyles }) => buttonStyles && buttonStyles.bg};
    border-color: ${({ buttonStyles }) => buttonStyles && buttonStyles.borderColor};
    color: ${({ buttonStyles }) => buttonStyles && buttonStyles.fontColor};
    width: ${({ buttonStyles }) => buttonStyles && buttonStyles.width};
    height: ${({ buttonStyles }) => buttonStyles && buttonStyles.height};
    margin-left: ${({ buttonStyles }) => buttonStyles && buttonStyles.marginLeft};

    ${({ disabled, outline }) =>
      hasDisabled(
        disabled,
        `
      && {
        color: #fff;
        background: #B4B5B8;
        border-color: #B4B5B8;
      }

      ${hasOutline(
        outline,
        `
        && {
          color: #A0A9BA;
          background: ${styles.colors.white};
          border-color: #A0A9BA;
        }
      `,
      )}
    `,
      )}

    ${({ type }) => `
      ${hasError(
        type,
        `
        border-color: ${styles.colors.error};
        background-color: ${styles.colors.error};
        color: ${styles.colors.white};

        > .anticon {
          color: ${styles.colors.white};
        }

        &:hover,
        &:focus,
        &:active {
          border-color: ${styles.colors.error};
          color: ${styles.colors.white};
          background-color: ${styles.colors.error};

          > .anticon {
            color: ${styles.colors.white};
          }
        }
      `,
      )}

      ${hasSuccess(
        type,
        `
        border-color: ${styles.colors.success};
        background-color: ${styles.colors.success};
        color: ${styles.colors.white};

        > .anticon {
          color: ${styles.colors.white};
        }

        &:hover,
        &:focus,
        &:active {
          border-color: ${styles.colors.success};
          color: ${styles.colors.white};
          background-color: ${styles.colors.success};

          > .anticon {
            color: ${styles.colors.white};
          }
        }
      `,
      )}
    `}

    ${({ outline, type }) =>
      hasOutline(
        outline,
        `
        background: transparent;
        border: 1px solid ${styles.colors.primary};
        color: ${styles.colors.primary};

        &:hover {
          color: ${styles.colors.white};
          background: ${styles.colors.primary};
          border-color: ${styles.colors.primary};
        }

        ${hasError(
          type,
          `
          border-color: ${styles.colors.error};
          color: ${styles.colors.error};
          background-color: transparent;

          > .anticon {
            color: ${styles.colors.error};
          }

          &:hover,
          &:focus,
          &:active {
            border-color: ${styles.colors.error};
            color: ${styles.colors.white};
            background-color: ${styles.colors.error};

            > .anticon {
              color: ${styles.colors.white};
            }
          }
        `,
        )}

        ${hasSuccess(
          type,
          `
          border-color: ${styles.colors.success};
          color: ${styles.colors.success};
          background-color: transparent;

          > .anticon {
            color: ${styles.colors.success};
          }

          &:hover,
          &:focus,
          &:active {
            border-color: ${styles.colors.success};
            color: ${styles.colors.white};
            background-color: ${styles.colors.success};

            > .anticon {
              color: ${styles.colors.white};
            }
          }
        `,
        )}
    `,
      )}

    span {
      font-weight: bold;
    }
  }
`;
