import styled from 'styled-components';

import { lpStyles } from '~/config';
import { LPLink as LinkBase } from '~/components/atoms';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  p {
    font-size: 0.9em;
    color: ${lpStyles.colors.gray};
    text-align: center;
  }
`;

export const Link = styled(LinkBase)`
  width: 100%;
`;
