import styled from 'styled-components';
import { Carousel as CarouselBase } from 'antd';

import { styles } from '~/config';
import { mediaQuery } from '~/utils/helpers';

export const Carousel = styled(CarouselBase)`
  && {
    margin-top: 50px;
    .slick-dots {
      top: -28px;

      li {
        margin: 0 4px;
      }

      .slick-active {
        button {
          background: ${styles.colors.borderColorSecondary};
          width: 10px;
        }
      }

      button {
        height: 10px;
        width: 10px;
        border: 1px solid ${styles.colors.borderColorSecondary};
        border-radius: 30px;
        opacity: 1;
        cursor: pointer;
      }
    }

    ${mediaQuery.inTablet(`
      width: calc(100vw - 32px);
      max-width: calc(100vw - 32px);
    `)}

    ${mediaQuery.inMobile(`
      width: calc(100vw - 32px);
      max-width: calc(100vw - 32px);
    `)}

    ${mediaQuery.inDesktop(`
      margin-top: 48px;
      width: calc(60vw - 32px);
      max-width: calc(60vw - 32px);
    `)}
  }
`;

export const CarouselItem = styled.section`
  display: flex !important;
  justify-content: center;

  div {
    display: flex !important;
    justify-content: center;
  }
`;
