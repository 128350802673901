import React from 'react';
import PropTypes from 'prop-types';

import { SkeletonBase } from './Skeleton.styles';

const Skeleton = ({ showTitle, showAvatar, rows }) => (
  <SkeletonBase active avatar={showAvatar} title={showTitle} paragraph={{ rows }} />
);

Skeleton.propTypes = {
  showAvatar: PropTypes.bool,
  showTitle: PropTypes.bool,
  rows: PropTypes.number,
};

Skeleton.defaultProps = {
  showAvatar: false,
  showTitle: false,
  rows: 5,
};

export default Skeleton;
