import api from '../api';
import { GET, POST, PUT } from '../httpMethods';
import { apiUsers, apiDoctors, apiAdmins } from '../../config/envManager';
import { toApi as toRegisterApi } from '../dto/users/register.dto';
import { toApi as toAdminRegisterApi } from '../dto/admin/adminRegister.dto';
import { toApi as toAdminUpdateRegisterApi } from '../dto/admin/userProfileAdmin.dto';
import { toApi as toUsersApi, fromApi as fromUsersApi } from '../dto/users/users.dto';
import { toApi as toApproveApi } from '../dto/users/userStatus.dto';
import { toApi as toDetailApi, fromApi as fromDetailApi } from '../dto/users/userDetails.dto';
import { toApi as toDetailApiV2, fromApi as fromDetailApiV2, fromApiWithErros } from '../dto/users/userDetailsV2.dto';
import { fromApi as fromProfileApi, toApi as toProfileApi } from '../dto/users/userProfile.dto';
import { toApi as toReproveApi } from '../dto/users/userReprove.dto';
import { toApi as toRequestApiDto } from '../dto/exams/createExamViewRequest.dto';
import fromUserViewOtherExamsStatusApi from '../dto/exams/userViewOtherExamsStatus.dto';

export const sendRegister = async apiParams => {
  const params = toRegisterApi(apiParams);

  const { data } = await api({
    url: `${apiUsers}/public/register-new`,
    method: POST,
    data: params,
  });

  return data;
};

export const sendAdminRegister = async apiParams => {
  const params = toAdminRegisterApi(apiParams);

  const { data } = await api({
    url: `${apiAdmins}/private/user`,
    method: POST,
    data: params,
  });

  return data;
};

export const updateAdminRegister = async apiParams => {
  const params = toAdminUpdateRegisterApi(apiParams);

  const { data } = await api({
    url: `${apiAdmins}/private/user`,
    method: PUT,
    data: params,
  });

  return data;
};

export const updateStatusUser = async apiParams => {
  const params = toApproveApi(apiParams);

  const { data } = await api({
    url: `${apiAdmins}/private/user/approve-new`,
    method: PUT,
    data: params,
  });

  return data;
};

export const reproveUser = async apiParams => {
  const params = toReproveApi(apiParams);

  const { data } = await api({
    url: `${apiAdmins}/private/user/reprove`,
    method: PUT,
    data: params,
  });

  return data;
};

export const getUsers = async apiParams => {
  const params = toUsersApi(apiParams);
  const { data } = await api({
    url: `${apiAdmins}/private/list-users`,
    method: GET,
    params,
  });

  const response = fromUsersApi(data);

  return response;
};

export const getUserDetails = async apiParams => {
  const params = toDetailApi(apiParams);

  const { data } = await api({
    url: `${apiAdmins}/private/user/${params.id}`,
    method: GET,
  });

  const response = fromDetailApi(data.user);

  return response;
};

export const getUserDetailsV2 = async apiParams => {

  const params = toDetailApiV2(apiParams);
  try{
    const { data } = await api({
      url: `${apiAdmins}/v2/private/user/${params.id}`,
      method: GET,
    });
    const response = fromDetailApiV2(data[0]);
    return response;
  }
  catch{
    return fromApiWithErros();
  }
}

export const getUserProfileInfo = async () => {
  const { data } = await api({
    url: `${apiUsers}/private/profile`,
    method: GET,
  });

  const response = fromProfileApi(data);

  return response;
};

export const updateUserProfileInfo = async apiParams => {
  const params = toProfileApi(apiParams);

  const { data } = await api({
    url: `${apiUsers}/private/profile`,
    method: PUT,
    data: params,
  });

  return data;
};

export const getUseTerms = async () => {
  const { data } = await api({
    url: `${apiUsers}/private/terms/info`,
    method: GET,
  });

  return data.result.termoAceite;
};

export const updateAcceptUseTerms = async () => {
  const { data } = await api({
    url: `${apiUsers}/private/terms/accept`,
    method: PUT,
  });

  return data;
};

export const getOtherStatusExams = async ({ identifier, name, birthdate }) => {
  const { data } = await api({
    url: `${apiDoctors}/private/patients/status?patientName=${name}&identifier=${identifier}&birthDate=${birthdate}`,
    method: GET,
  });

  return fromUserViewOtherExamsStatusApi(data);
};

export const createExamsViewRequest = async params => {
  const toApi = toRequestApiDto(params);
  const { data } = await api({
    url: `${apiDoctors}/private/patients/request`,
    method: POST,
    data: toApi,
  });

  return data;
};
