import api from '../api';
import { GET } from '../httpMethods';
import { apiPatients } from '../../config/envManager';
import { toApi, fromApi } from '../dto/admin/adminPatients.dto';

export const getPatients = async urlParams => {
  const params = toApi(urlParams);
  const { data } = await api({
    url: `${apiPatients}/private/admin/list`,
    method: GET,
    params,
  });

  const response = fromApi(data.patients);

  return response;
};
