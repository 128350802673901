export default [
  { text: 'Acupuntura', value: 'Acupuntura' },
  { text: 'Alergia e imunologia', value: 'Alergia e imunologia' },
  { text: 'Anestesiologia', value: 'Anestesiologia' },
  { text: 'Angiologia', value: 'Angiologia' },
  { text: 'Cardiologia', value: 'Cardiologia' },
  { text: 'Cirurgia cardiovascular', value: 'Cirurgia cardiovascular' },
  { text: 'Cirurgia da mão', value: 'Cirurgia da mão' },
  { text: 'Cirurgia de cabeça e pescoço', value: 'Cirurgia de cabeça e pescoço' },
  { text: 'Cirurgia de aparelho digestivo', value: 'Cirurgia de aparelho digestivo' },
  { text: 'Cirurgia geral', value: 'Cirurgia geral' },
  { text: 'Cirurgia oncológica', value: 'Cirurgia oncológica' },
  { text: 'Cirurgia pediátrica', value: 'Cirurgia pediátrica' },
  { text: 'Cirurgia toracica', value: 'Cirurgia toracica' },
  { text: 'Cirurgia vascular', value: 'Cirurgia vascular' },
  { text: 'Clínica médica', value: 'Clínica médica' },
  { text: 'Coloproctologia', value: 'Coloproctologia' },
  { text: 'Dermatologia', value: 'Dermatologia' },
  { text: 'Endocrinologia e metabologia', value: 'Endocrinologia e metabologia' },
  { text: 'Endoscopia', value: 'Endoscopia' },
  { text: 'Enfermagem', value: 'Enfermagem' },
  { text: 'Gastroenterologia', value: 'Gastroenterologia' },
  { text: 'Genética médica', value: 'Genética médica' },
  { text: 'Geriatria', value: 'Geriatria' },
  { text: 'Ginecologia e obstetrícia', value: 'Ginecologia e obstetrícia' },
  { text: 'Hematologia e hemoterapia', value: 'Hematologia e hemoterapia' },
  { text: 'Homeopatia', value: 'Homeopatia' },
  { text: 'Infectologia', value: 'Infectologia' },
  { text: 'Mastologia', value: 'Mastologia' },
  { text: 'Medicina de emergência', value: 'Medicina de emergência' },
  { text: 'Medicina de familia e comunidade', value: 'Medicina de familia e comunidade' },
  { text: 'Medicina do trabalho', value: 'Medicina do trabalho' },
  { text: 'Medicina de tráfego', value: 'Medicina de tráfego' },
  { text: 'Medicina esportiva', value: 'Medicina esportiva' },
  { text: 'Medicina fisica e reabilitação', value: 'Medicina fisica e reabilitação' },
  { text: 'Medicina intensiva', value: 'Medicina intensiva' },
  { text: 'Medicina legal e perícia médica', value: 'Medicina legal e perícia médica' },
  { text: 'Medicina nuclear', value: 'Medicina nuclear' },
  { text: 'Medicina preventiva e social', value: 'Medicina preventiva e social' },
  { text: 'Nefrologia', value: 'Nefrologia' },
  { text: 'Neurocirurgia', value: 'Neurocirurgia' },
  { text: 'Neurologia', value: 'Neurologia' },
  { text: 'Nutrologia', value: 'Nutrologia' },
  { text: 'Oftalmologia', value: 'Oftalmologia' },
  { text: 'Oncologia clínica', value: 'Oncologia clínica' },
  { text: 'Ortopedia e traumatologia', value: 'Ortopedia e traumatologia' },
  { text: 'Otorrinolaringologia', value: 'Otorrinolaringologia' },
  { text: 'Patologia', value: 'Patologia' },
  {
    text: 'Patologia clínica / Medicina laboratorial',
    value: 'Patologia clínica / Medicina laboratorial',
  },
  { text: 'Pediatria', value: 'Pediatria' },
  { text: 'Pneumologia', value: 'Pneumologia' },
  { text: 'Psiquiatria', value: 'Psiquiatria' },
  { text: 'Radiologia e diagnóstico por imagem', value: 'Radiologia e diagnóstico por imagem' },
  { text: 'Radioterapia', value: 'Radioterapia' },
  { text: 'Reumatologia', value: 'Reumatologia' },
  { text: 'Urologia', value: 'Urologia' },
];
