/* eslint-disable operator-linebreak */
import { requestPasswordReset } from '~/api/services/passwordRecovery.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const ADMIN_USER_RESEND_PASSWORD_REQUEST =
  '@dasa-canal-medico/ADMIN_USER_RESEND_PASSWORD_REQUEST';
export const ADMIN_USER_RESEND_PASSWORD_SUCCESS =
  '@dasa-canal-medico/ADMIN_USER_RESEND_PASSWORD_SUCCESS';
export const ADMIN_USER_RESEND_PASSWORD_FAILURE =
  '@dasa-canal-medico/ADMIN_USER_RESEND_PASSWORD_FAILURE';

// Reducer
export const initialState = {
  data: {
    message: '',
    status: '',
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_USER_RESEND_PASSWORD_REQUEST:
      return {
        ...state,
        ...initialState,
        isLoading: true,
      };
    case ADMIN_USER_RESEND_PASSWORD_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };
    case ADMIN_USER_RESEND_PASSWORD_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}

// Action Creators
export const adminResendPasswordRequest = simpleAction(ADMIN_USER_RESEND_PASSWORD_REQUEST);
export const adminResendPasswordSuccess = simpleAction(ADMIN_USER_RESEND_PASSWORD_SUCCESS);
export const adminResendPasswordFailure = simpleAction(ADMIN_USER_RESEND_PASSWORD_FAILURE);

export const adminResendPasswordAction = params => async dispatch => {
  dispatch(adminResendPasswordRequest());

  try {
    const payload = await requestPasswordReset(params);
    dispatch(adminResendPasswordSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(adminResendPasswordFailure(err));
  }
};
