import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import { objectManager } from '../../../utils/helpers';
import { Skeleton } from '../../atoms';

import { Title, Text, Bold, Status, RowFeature } from './UserDetails.styles';

const { isEmpty } = objectManager;

const getData = (data, feedback) => data || feedback;

const feedback = 'Sem dados';

const UserDetails = ({ testId, user, isLoading }) => {
  if (isLoading) return (
    <>
      <Skeleton />
      <hr />
      <Skeleton />
    </>
  );

  return (
    <>
      <RowFeature>
        <Col md={8} xs={{ span: 24 }}>
          <Text hasValue data-testid={`${testId}-name`}>
            <Bold>Nome:</Bold> {getData(user.name, feedback)}
          </Text>
          {user.socialName != '' && <Text hasValue data-testid={`${testId}-social-name`}>
            <Bold>Nome Social:</Bold> {getData(user.socialName, feedback)}
          </Text>}
          <Text hasValue data-testid={`${testId}-perfil`}>
            <Bold>Perfil:</Bold> {getData(user.role, feedback)}
          </Text>
          <Text hasValue data-testid={`${testId}-status`}>
            <Bold>Status:</Bold>{' '}
            <Status isActive={user.isActive}>{user.isActive ? 'Ativo' : 'Inativo'}</Status>
          </Text>
        </Col>

        <Col md={8} xs={{ span: 24 }}>
          <Text hasValue={!isEmpty(user.congregationState)} data-testid={`${testId}-uf`}>
            <Bold>UF:</Bold> {getData(user.congregationState, feedback)}
          </Text>
          <Text hasValue={!isEmpty(user.congregation)} data-testid={`${testId}-crm`}>
            <Bold>Conselho:</Bold> {getData(user.congregation, feedback)}
          </Text>
          <Text hasValue={!isEmpty(user.congregationNumber)} data-testid={`${testId}-crm-number`}>
            <Bold>Nº de registro:</Bold> {getData(user.congregationNumber, feedback)}
          </Text>
        </Col>

        <Col md={8} xs={{ span: 24 }}>
          <Text hasValue={!isEmpty(user.cpf)} data-testid={`${testId}-cpf`}>
            <Bold>CPF:</Bold> {getData(user.cpf, feedback)}
          </Text>
          <Text hasValue={!isEmpty(user.cellphone)} data-testid={`${testId}-cellphone`}>
            <Bold>Celular:</Bold> {getData(user.cellphone, feedback)}
          </Text>
          <Text hasValue={!isEmpty(user.email)} data-testid={`${testId}-email`}>
            <Bold>E-mail:</Bold> {getData(user.email, feedback)}
          </Text>
        </Col>
      </RowFeature>

      <Title htmlType="h4" testId={`${testId}-subtitle`}>
        Outros dados do usúario
      </Title>

      <Row>
        <Col md={8} xs={{ span: 24 }}>
          <Text hasValue={!isEmpty(user.specialty)} data-testid={`${testId}-specialty`}>
            <Bold>Especialidade:</Bold> {getData(user.specialty, feedback)}
          </Text>
          <Text hasValue={!isEmpty(user.rg)} data-testid={`${testId}-rg`}>
            <Bold>RG:</Bold> {getData(user.rg, feedback)}
          </Text>
          <Text hasValue={!isEmpty(user.telephone)} data-testid={`${testId}-telephone`}>
            <Bold>Telefone:</Bold> {getData(user.telephone, feedback)}
          </Text>
          <Text hasValue={!isEmpty(user.gender)} data-testid={`${testId}-gender`}>
            <Bold>Sexo:</Bold> {getData(user.gender, feedback)}
          </Text>
        </Col>

        <Col md={8} xs={{ span: 24 }}>
          <Text hasValue={!isEmpty(user.address && user.address.cep)} data-testid={`${testId}-cep`}>
            <Bold>CEP:</Bold> {getData(user.address && user.address.cep, feedback)}
          </Text>
          <Text
            hasValue={!isEmpty(user.address && user.address.address)}
            data-testid={`${testId}-address`}
          >
            <Bold>Endereço:</Bold> {getData(user.address && user.address.address, feedback)}
          </Text>
          <Text
            hasValue={!isEmpty(user.address && user.address.addressNumber)}
            data-testid={`${testId}-addressNumber`}
          >
            <Bold>Número:</Bold> {getData(user.address && user.address.addressNumber, feedback)}
          </Text>
          <Text
            hasValue={!isEmpty(user.address && user.address.addressComplement)}
            data-testid={`${testId}-addressComplement`}
          >
            <Bold>Complemento:</Bold>{' '}
            {getData(user.address && user.address.addressComplement, feedback)}
          </Text>
        </Col>

        <Col md={8} xs={{ span: 24 }}>
          <Text
            hasValue={!isEmpty(user.address && user.address.neightborhood)}
            data-testid={`${testId}-neightborhood`}
          >
            <Bold>Bairro:</Bold> {getData(user.address && user.address.neightborhood, feedback)}
          </Text>
          <Text
            hasValue={!isEmpty(user.address && user.address.state)}
            data-testid={`${testId}-state`}
          >
            <Bold>Estado:</Bold> {getData(user.address && user.address.state, feedback)}
          </Text>
          <Text
            hasValue={!isEmpty(user.address && user.address.city)}
            data-testid={`${testId}-city`}
          >
            <Bold>Cidade:</Bold> {getData(user.address && user.address.city, feedback)}
          </Text>
        </Col>
      </Row>
    </>
  );
};

UserDetails.propTypes = {
  testId: PropTypes.string,
  isLoading: PropTypes.bool,
  user: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    cpf: PropTypes.string,
    isActive: PropTypes.bool,
    congregationState: PropTypes.string,
    congregation: PropTypes.string,
    congregationNumber: PropTypes.string,
    cellphone: PropTypes.string,
    email: PropTypes.string,
    specialty: PropTypes.string,
    rg: PropTypes.string,
    telephone: PropTypes.string,
    gender: PropTypes.string,
    address: PropTypes.shape({
      cep: PropTypes.string,
      address: PropTypes.string,
      addressNumber: PropTypes.string,
      addressComplement: PropTypes.string,
      neightborhood: PropTypes.string,
      state: PropTypes.string,
      city: PropTypes.string,
    }),
  }),
};

UserDetails.defaultProps = {
  testId: 'user-details',
  isLoading: false,
  user: {
    name: 'Sem dados',
    role: 'Sem dados',
    cpf: 'Sem dados',
    isActive: 'Sem dados',
    congregationState: 'Sem dados',
    congregation: 'Sem dados',
    congregationNumber: 'Sem dados',
    cellphone: 'Sem dados',
    email: 'Sem dados',
    specialty: 'Sem dados',
    rg: 'Sem dados',
    telephone: 'Sem dados',
    gender: 'Sem dados',
    address: {
      cep: 'Sem dados',
      address: 'Sem dados',
      addressNumber: 'Sem dados',
      addressComplement: 'Sem dados',
      neightborhood: 'Sem dados',
      state: 'Sem dados',
      city: 'Sem dados',
    },
  },
};

export default UserDetails;
