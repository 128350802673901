import { approveExamViewRequest, rejectExamViewRequest } from '~/api/services/patients.service';
import { simpleAction, errorMessage } from '~/utils/helpers';

export const EXAMS_VIEW_ACTION_REQUEST = '@dasa-canal-medico/EXAMS_VIEW_ACTION_REQUEST';
export const EXAMS_VIEW_ACTION_SUCCESS = '@dasa-canal-medico/EXAMS_VIEW_ACTION_SUCCESS';
export const EXAMS_VIEW_ACTION_FAILURE = '@dasa-canal-medico/EXAMS_VIEW_ACTION_FAILURE';

// Reducer
export const initialState = {
  data: {},
  error: {
    messsage: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EXAMS_VIEW_ACTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EXAMS_VIEW_ACTION_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: action.payload,
        isLoading: false,
      };
    case EXAMS_VIEW_ACTION_FAILURE:
      return {
        ...state,
        data: {},
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

// Action Creators
export const approveExamViewActionRequest = simpleAction(EXAMS_VIEW_ACTION_REQUEST);
export const approveExamViewActionSuccess = simpleAction(EXAMS_VIEW_ACTION_SUCCESS);
export const approveExamViewActionFailure = simpleAction(EXAMS_VIEW_ACTION_FAILURE);

export const approveExamViewRequestAction = params => async dispatch => {
  dispatch(approveExamViewActionRequest());
  try {
    const payload = await approveExamViewRequest(params);

    dispatch(approveExamViewActionSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);

    dispatch(approveExamViewActionFailure(err));
  }
};

export const rejectExamViewRequestAction = params => async dispatch => {
  dispatch(approveExamViewActionRequest());

  try {
    const payload = await rejectExamViewRequest(params);

    dispatch(approveExamViewActionSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);

    dispatch(approveExamViewActionFailure(err));
  }
};
