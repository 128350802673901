export default {
  login: {
    name: 'Login',
    path: '/laudo-medico/login',
    type: 'Laudo Médico',
  },
  forgotPassword: {
    name: 'Esqueci minha senha',
    path: '/laudo-medico/login/reset',
    type: 'Laudo Médico',
  },
  register: {
    name: 'Formulário de cadastro',
    path: '/laudo-medico/cadastro',
    type: 'Laudo Médico',
  },
  notifications: {
    name: 'Notificações',
    path: '/laudo-medico/notificacoes',
    type: 'Laudo Médico',
  },
  contacts: {
    name: 'Contatos',
    path: '/laudo-medico/contatos',
    type: 'Laudo Médico',
  },
  downloadCovidExams: {
    name: 'Download de exames Covid-19',
    path: '/laudo-medico/download-exames-covid',
    type: 'Laudo Médico',
  },
  updateRegister: {
    name: 'Atualizar cadastro',
    path: '/laudo-medico/atualizar-cadastro',
    type: 'Laudo Médico',
  },
  home: {
    name: 'Home',
    path: '/laudo-medico/home',
    type: 'Laudo Médico',
  },
  patient: {
    name: 'Página do paciente',
    path: '/laudo-medico/pagina-do-paciente',
    type: 'Laudo Médico',
  },
  patientSearch: {
    name: 'Resultado de busca',
    path: '/laudo-medico/resultado-de-busca',
    type: 'Laudo Médico',
  },
  patientDetail: {
    requestExamsHistory: {
      name: 'Solicitar histórico de exames',
      path: '/laudo-medico/pagina-do-paciente/solicitar-historico-de-exames',
      type: 'Laudo Médico',
    },
    clinicalDiscussion: {
      name: 'Discussão clínica',
      path: '/laudo-medico/pagina-do-paciente/discussao-clinica',
      type: 'Laudo Médico',
    },
    otherExamsTab: {
      name: 'Prescritos por outros médicos',
      path: '/laudo-medico/pagina-do-paciente/prescritos-por-outros-medicos',
      type: 'Laudo Médico',
    },
    examsAction: {
      pdf: {
        name: 'Visualizar laudo',
        path: '/laudo-medico/pagina-do-paciente/visualizar-laudo',
        type: 'Laudo Médico',
      },
      download: {
        name: 'Baixar laudo',
        path: '/laudo-medico/pagina-do-paciente/baixar-laudo',
        type: 'Laudo Médico',
      },
      share: {
        name: 'Compartilhar laudo',
        path: '/laudo-medico/pagina-do-paciente/compartilhar laudo',
        type: 'Laudo Médico',
      },
      image: {
        name: 'Visualizar exame de imagem',
        path: '/laudo-medico/pagina-do-paciente/visualizar-exame-de-imagem',
        type: 'Laudo Médico',
      },
    },
  },
  default: {},
};
