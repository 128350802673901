import styled from 'styled-components';

import { lpStyles } from '~/config';

export const TextBase = styled.p`
  font-size: 1rem;
  font-family: ${lpStyles.fontFamily.default};
  font-weight: ${({ strong }) => strong && 'bold'};
  color: ${({ type }) => type === 'secondary' && lpStyles.colors.text};
`;
