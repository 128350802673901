export const fromApiUserInfo = (data = {}) => {
  const { usuario } = data;
  const { onboarding } = usuario;

  const mapper = {
    enableTour: usuario.showTour || false,
    enableTourFirstAccess: {
      id: onboarding.primeiroAcesso ? onboarding.primeiroAcesso.id : 0,
      show: onboarding.primeiroAcesso ? onboarding.primeiroAcesso.show : false,
    },
    enableTourMyAssistants: {
      id: onboarding.meusAssistentes ? onboarding.meusAssistentes.id : 0,
      show: onboarding.meusAssistentes ? onboarding.meusAssistentes.show : false,
    },
    enableTourNewListOfPatients: {
      id: onboarding.pacienteLista ? onboarding.pacienteLista.id : 0,
      show: onboarding.pacienteLista ? onboarding.pacienteLista.show : false,
    },
    enableTourExamsResquestHistory: {
      id: onboarding.exameHistoricoSolicitar ? onboarding.exameHistoricoSolicitar.id : 0,
      show: onboarding.exameHistoricoSolicitar ? onboarding.exameHistoricoSolicitar.show : false,
    },
    enableTourExamsHistoryAvailable: {
      id: onboarding.exameHistoricoLiberado ? onboarding.exameHistoricoLiberado.id : 0,
      show: onboarding.exameHistoricoLiberado ? onboarding.exameHistoricoLiberado.show : false,
    },
    enableTourExamsHistoryOtherDoctors: {
      id: onboarding.examsHistoricoOutrosMedicos ? onboarding.examsHistoricoOutrosMedicos.id : 0,
      show: onboarding.examsHistoricoOutrosMedicos
        ? onboarding.examsHistoricoOutrosMedicos.show
        : false,
    },
    enableNotificationTerms: usuario.termoAceite || false,
    enableNotificationCompleteRegister: data.pendingValues || false,
    enableForcedUpdatePassword: usuario.trocarSenhaProximoLogin || false,
  };

  return mapper;
};

export const fromApiOnboardingUserInfo = (data = {}) => {
  const mapper = {
    enableTourFirstAccess: {
      id: data.primeiroAcesso ? data.primeiroAcesso.id : 0,
      show: data.primeiroAcesso ? data.primeiroAcesso.show : false,
    },
    enableTourMyAssistants: {
      id: data.meusAssistentes ? data.meusAssistentes.id : 0,
      show: data.meusAssistentes ? data.meusAssistentes.show : false,
    },
    enableTourNewListOfPatients: {
      id: data.pacienteLista ? data.pacienteLista.id : 0,
      show: data.pacienteLista ? data.pacienteLista.show : false,
    },
    enableTourExamsResquestHistory: {
      id: data.exameHistoricoSolicitar ? data.exameHistoricoSolicitar.id : 0,
      show: data.exameHistoricoSolicitar ? data.exameHistoricoSolicitar.show : false,
    },
    enableTourExamsHistoryAvailable: {
      id: data.exameHistoricoLiberado ? data.exameHistoricoLiberado.id : 0,
      show: data.exameHistoricoLiberado ? data.exameHistoricoLiberado.show : false,
    },
    enableTourExamsHistoryOtherDoctors: {
      id: data.examsHistoricoOutrosMedicos ? data.examsHistoricoOutrosMedicos.id : 0,
      show: data.examsHistoricoOutrosMedicos ? data.examsHistoricoOutrosMedicos.show : false,
    },
  };

  return mapper;
};
