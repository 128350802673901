import styled from 'styled-components';
import { Form, Radio } from 'antd';

import { styles } from '~/config';

export const FormItem = styled(Form.Item)``;

export const Group = styled(Radio.Group)`
  && {
    display: flex;
    margin: 0 0 30px;

    .ant-radio-button-wrapper-checked {
      background: ${styles.colors.secondary};
      p {
        color: ${styles.colors.white};
      }
    }

    .ant-radio-button-wrapper {
      span {
        color: ${styles.colors.secondary};
        border-color: ${styles.colors.secondary};
      }
    }

    .ant-radio-button-wrapper::before {
      display: none;
    }
  }
`;

export const Button = styled(Radio.Button)`
  && {
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    border-radius: 30px;
    border: 1px solid ${styles.colors.secondary};

    p {
      font-size: 0.8rem;
      margin: 0;
    }
  }
`;
