import styled from 'styled-components';

import { mediaQuery } from '~/utils/helpers';

export const TableCovidExamsHolder = styled.div`
  padding: 24px;
  overflow: hidden;
  clear: left;
`

export const ButtonWrapper = styled.div`
  margin-top: 22px;

  ${mediaQuery.inTablet(`
    margin: 0 0 30px;
    width: 100%;
    left: 0;

    button {
      position: static;
      width: 100%;
    }
  `)}

  ${mediaQuery.inMobile(`
    margin: 0 0 30px;
    width: 100%;
    left: 0;

    button {
      position: static;
      width: 100%;
    }
  `)}
`;
