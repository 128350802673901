import styled from 'styled-components';
import { Avatar } from 'antd';

import { styles } from '../../../config';

export const AvatarBase = styled(Avatar)`
  && {
    background-color: ${styles.colors.primary};
    font-weight: bold;
  }
`;
