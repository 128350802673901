const getDdd = value => {
  if (value) {
    const regexNumber = /\d+/g;
    const onlyNumber = value.match(regexNumber).join('');
    return onlyNumber.substring(0, 2);
  }
};

const getNumber = value => {
  if (value) {
    const regexNumber = /\d+/g;
    const onlyNumber = value.match(regexNumber).join('');
    return onlyNumber.substring(2, 11);
  }
};

export const toApi = (data = {}) => ({
  fullName: data.fullName || undefined,
  cpf: data.cpf || undefined,
  email: data.email || undefined,
  role: data.role || 'admin',
  telephone: [
    {
      ddd: getDdd(data.cellphone) || undefined,
      telephone: getNumber(data.cellphone) || undefined,
      type: data.type || 'celular',
    },
  ],
  address: data.address || [],
});
