import styled from 'styled-components';
import { Upload, Form } from 'antd';

const { Dragger } = Upload;

export const UploadItem = styled(Form.Item)`
  position: relative;
`;

export const UploadBase = styled(Dragger)`
  min-height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;

  && {
    transition: background-color 350ms ease, border 350ms ease;
    background-color: ${({error, hasData}) => {
      if (error) {
        return "#FDF1F4"
      } else if (hasData) {
        return "#F0F3FF"
      } else {
        return "#fafafa"
      }
    }};
    border: 1px dashed ${({error, hasData}) => {
      if (error) {
        return "#ff3867"
      } else if (hasData) {
        return "#6687FF"
      } else {
        return "#d9d9d9"
      }
    }};
  }

  & .ant-upload-disabled {
    opacity: 0.25;
  }
`;

export const UploadFilename = styled.p`
  font-family: Montserrat, sans-serif;
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
  text-align: left;
`;


export const UploadFilenameWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

export const UploadText = styled.p`
  font-family: Montserrat, sans-serif;
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
`;

export const UploadHint = styled.p`
  font-family: Montserrat, sans-serif;
  color: #A0A9BA;
  font-size: 8px;
  text-transform: uppercase;
`;

export const UploadFloatingControl = styled.div`
`;

export const UploadWrapper = styled.div`
  position: relative;
  & > label {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 9;
    font-size: 12px;
  }

`;



