import styled from 'styled-components';
import { Drawer as DrawerBase } from 'antd';

import { Text as TextBase } from '~components/atoms';
import { styles } from '~config';

export const Drawer = styled(DrawerBase)`
  && {
    .ant-drawer-mask {
      &::before {
        content: 'Fechar';
        color: ${styles.colors.white};
        top: 70px;
        display: flex;
        position: relative;
        padding-right: 20px;
        justify-content: flex-end;
      }
    }

    .ant-drawer-content-wrapper {
      max-height: 185px;

      .ant-drawer-body {
        padding: 16px 24px 0;

        .open-report-btn {
          margin-left: 3px;

          p {
            margin-left: 18px;
          }
        }
      }
    }
  }
`;

export const Text = styled(TextBase)`
  margin: 0;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  p {
    margin-left: 16px;
  }
`;

export const ShareReportButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  pointer-events: ${({ disabled }) => !disabled && 'none'};

  p {
    margin-left: 16px;
    opacity: ${({ disabled }) => !disabled && '0.5'};
  }
`;
