import React from 'react';
import PropTypes from 'prop-types';

import { types } from '~utils/enums';
import { Input, Button, Title } from '~components/atoms';

import { Container, Footer } from './Form.styles';

const Form = ({
  handleBlur,
  handleChange,
  handleSubmit,
  values,
  errors,
  touched,
  testId,
  isLoading,
  closeModal,
}) => (
  <Container>
    <Title testId={`${testId}-title`} htmlType="h3">
      Compartilhar laudo
    </Title>

    <form name="shareReportForm" onSubmit={handleSubmit}>
      <Input
        testId={`${testId}-input-email`}
        name="email"
        label="E-mail"
        placeholder="Insira o endereço de e-mail"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={errors.email}
        touched={touched.email}
      />

      <Footer>
        <Button
          testId={`${testId}-cancel-btn`}
          isDisabled={isLoading}
          isOutline
          onClick={closeModal}
        >
          Cancelar
        </Button>

        <Button
          testId={`${testId}-submit-btn`}
          htmlType="submit"
          type={types.primary}
          isLoading={isLoading}
        >
          Enviar
        </Button>
      </Footer>
    </form>
  </Container>
);

Form.propTypes = {
  testId: PropTypes.string,
  isLoading: PropTypes.bool,
  closeModal: PropTypes.func,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    email: PropTypes.bool,
  }).isRequired,
};

Form.defaultProps = {
  testId: '',
  isLoading: false,
  closeModal: () => undefined,
};

export default Form;
