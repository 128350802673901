import api from '~/api';
import { POST } from '~/api/httpMethods';
import { toApi } from '~/api/dto/users/support.dto';
import { apiUsers } from '~/config/envManager';

export const sendContactInfo = async params => {
  const body = toApi(params);

  const { data } = await api({
    url: `${apiUsers}/public/support`,
    method: POST,
    data: body,
  });

  return data;
};
