import styled from 'styled-components';
import { Breadcrumb } from 'antd';

import { styles } from '../../../config';
import { Icon as IconBase, Link as LinkBase } from '../../atoms';

export const BreadcrumbBase = styled(Breadcrumb)`
  && {
    margin-bottom: ${styles.spacing.default};
  }
`;

export const Item = styled(Breadcrumb.Item)``;

export const Text = styled.span`
  display: inline-block;
  font-weight: normal;
  color: ${styles.colors.textSecondary};
  margin-left: ${({ spacing }) => (spacing ? styles.spacing.default : '0')};
  :hover {
    text-decoration: underline;
  }
`;

export const Icon = styled(IconBase)``;

export const Link = styled(LinkBase)``;
