import styled from 'styled-components';
import { AutoComplete, Input, Table } from 'antd';

import { styles } from '../../../config';
import { mediaQuery } from '~/utils/helpers';

export const TableCovidExamsResults = styled(Table)`
  && {
    tr td {
      cursor: default!important;
    }

    thead tr th {
      background-color: transparent;
      font-weight: bold;
    }

    .ant-pagination {
      display: flex;
      width: 100%;
      height: 40px;
      align-items: center;
      flex-direction: row;

      .ant-pagination-item-active {
        background-color: ${styles.colors.primary};

        a {
          color: ${styles.colors.white};
        }
      }

      .ant-pagination-total-text {
        order: 2;
        flex: 1 30%;
        margin-left: 16px;
        text-align: right;
      }

      .ant-pagination-options {
        order: 3;
        border-left: 1px solid ${styles.colors.borderColorSecondary};
        text-align: right;

        &:before {
          content: 'Itens por página: ';
          margin-left: 16px;
        }
      }
    }
  }
`;

export const CovidPatientNameSearch = styled(AutoComplete)`
  && {
    width: 100%;
    ${mediaQuery.inDesktop(`
      padding-right: 10px;
    `)}
    @media (max-width: 1100px) {
      padding-right: 0px;
    }

    .ant-input-suffix {
      width: 46px;
      height: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      border-radius: 6px;

      ${mediaQuery.inMobile(`
        background: none;
      `)};

      .anticon svg {
        font-size: 24px;
        color: ${styles.colors.primary};
      }
    }
    .ant-select-selection, .ant-input {
      height: 48px;
    }
    .ant-input {
      font-size: 16px;
      padding-right: 50px !important;

      &::placeholder {
        font-size: 16px;
        color: ${styles.colors.textSecondary};
      }

      ${mediaQuery.inMobile(`
        padding-right: 40px !important;
      `)};
    }
  }    
`