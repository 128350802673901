/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { stringManager } from '~/utils/helpers';

import {
  DropdownText,
  DropdownIcon,
  DropdownAvatar,
  DropdownWrapper,
} from './HeaderDropdownContent.styles';

const HeaderDropdownContent = ({ testId, user }) => (
  <>
    <DropdownText data-testid={`${testId}-dropdown-text`}>
      Olá, {stringManager.getGenderAbbreviation(user.gender)}{' '}
      {stringManager.getFirstName(user.name)}
    </DropdownText>
    <DropdownWrapper>
      <DropdownAvatar data-testid={`${testId}-dropdown-avatar`} size="32px">
        {stringManager.getFirstLetter(user.name)}
      </DropdownAvatar>
      <DropdownIcon type="down" />
    </DropdownWrapper>
  </>
);

HeaderDropdownContent.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    gender: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  testId: PropTypes.string.isRequired,
};

export default memo(HeaderDropdownContent);
