export default {
  searchPatients: {
    patientName: 'Digite o nome do paciente',
    identifier: 'Digite o número da CIP',
    protocol: 'Digite o número de protocolo',
  },
  searchApprovedUsers: {
    userName: 'Digite o nome do usuário',
    crm: 'Digite o número de registro',
    cro: 'Digite o número de registro',
  },
};
