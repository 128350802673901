import styled from 'styled-components';
import { Dropdown, Menu as MenuBase } from 'antd';

import { styles } from '~/config';

export const DropdownBase = styled(Dropdown)`
  && {
    .ant-dropdown-menu-item {
      background-color: #cec9e4;
    }
  }
`;

export const Menu = styled(MenuBase)`
  && {
    .ant-dropdown-menu-item-disabled {
      background: ${({ tourId }) => tourId === 'request-history-open' && styles.colors.white};
      color: ${({ tourId }) => tourId === 'request-history-open' && styles.colors.black};
    }
  }
`;
