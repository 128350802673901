import styled from 'styled-components';
import { Drawer as DrawerBase, List as ListBase, Badge as BadgeBase } from 'antd';
import { styles } from '~/config';
import { Text as TextBase } from '~/components/atoms';

export const Drawer = styled(DrawerBase)`
  && {
    transform: translateX(0) !important;

    .ant-drawer-content-wrapper {
      margin-top: 64px;
      min-width: 100vw;

      .ant-drawer-content {
        background-color: ${styles.colors.defaultBackground};

        .ant-skeleton {
          margin: 20px 0 0;
        }
      }
    }
  }
`;

export const List = styled(ListBase)`
  && {
    padding-bottom: 60px;
  }
`;

export const ListItem = styled(ListBase.Item)`
  && {
    display: flex;
    align-items: center;
    padding: 20px 0;
    height: 86px;
    display: ${({ isParent, isRDI }) => isParent && isRDI && 'none'};
    margin-left: ${({ isParent, isRDI }) => !isParent && !isRDI && '20px'};
    & + li {
      border-top: 1px solid ${styles.colors.listItemLine};
    }

    .parent-exam-content {
      width: 100%;

      button {
        margin-top: 10px;
      }
    }
  }
`;

export const ReturnButton = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${styles.colors.textSecondary};
    font-size: 0.9rem;
    margin: 0 5px 0;
  }
`;

export const Text = styled(TextBase)`
  font-weight: ${({ isParent }) => isParent && 'bold'};
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Header = styled.div``;

export const Title = styled(Text)`
  margin: 30px 0;
  font-weight: bold;
  color: ${styles.colors.textSecondary};
`;

export const Badge = styled(BadgeBase)`
  && {
    margin-bottom: 4px;

    .ant-badge-status-dot {
      width: 17px;
      height: 17px;
    }

    .ant-badge-status-text {
      font-size: 1rem;
    }
  }
`;

export const ExamContent = styled.div`
  height: ${({ isParent }) => isParent && '86px'};
  width: 80%;
`;

export const ActionsContent = styled.div`
  position: relative;
  bottom: ${({ isParent }) => isParent && '18px'};
`;

export const HeaderContentWrapper = styled.div`
  display: ${({ isEvo }) => !isEvo && 'flex'};
  justify-content: space-between;
`;
