/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export const itemRender = (current, type, originalElement) => {
  if (type === 'prev') return <a>&lsaquo; Anterior</a>;
  if (type === 'next') return <a>Próximo &rsaquo;</a>;
  return originalElement;
};

export const showTotal = (total, current) => {
  const textCurrent = current.join(' - ');
  return `Exibindo ${textCurrent} de ${total} itens`;
};

const totalMinToShowPagination = 10;

const pageSizeOptions = ['10', '20', '30'];

export default {
  totalMinToShowPagination,
  pageSizeOptions,
  itemRender,
  showTotal,
};
