import { apiReports, apikey } from '~config/envManager';
import { parseObjectToString } from '../queryStringParser';
import { getObjectToken } from '../token';

export const getUrlDownloadReport = (
  {
    protocol,
    laudoId,
    crm,
    origem = 'pdf',
    index,
    isMotion = false,
    uf,
    conselho,
    number,
    registerAction = true,
  },
  isAdmin = true,
) => {
  const { token } = getObjectToken();
  const params = {
    index,
    laudoId,
    crm,
    protocol,
    system: origem,
    motion: isMotion,
    conselho: conselho || '',
    estado: uf || '',
    numero: number || '',
    registerAction,
  };

  const origin = isMotion ? 'motion' : origem;
  const urlParams = parseObjectToString(params);

  const url = isAdmin
    ? `${apiReports}/private/admin/protocol/${protocol}/report/${laudoId}/crm/${crm}/origem/${origin}/download?registerAction=${registerAction}&apikey=${apikey}&t=${token}`
    : `${apiReports}/private/doctor/download/?${urlParams}&apikey=${apikey}&t=${token}`;

  return url;
};

export default {
  getUrlDownloadReport,
};
