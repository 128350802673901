export default {
  DEFAULT: '/',
  DEFAULT_WITH_HASH: '/operador',

  // APP
  APP_CONFIG: '/operador/app-config',

  // PUBLIC
  LOGIN: '/',
  REGISTER: '/cadastro',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_WITH_HASH: '#forgot-password',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_ADM: '/reset-password/admin',
  PATIENT_VIEW_EXAMS_APPROVE: '/patient/view-exams/approved',
  PATIENT_VIEW_EXAMS_CONFIRMATION: '/patient/view-exams/confirmation',
  PATIENT_VIEW_EXAMS_REJECT: '/patient/view-exams/rejected',
  PATIENT_VIEW_EXAMS_ERROR: '/patient/view-exams/error',
  DISCUSSION_CONFIRMATION: '/discussion/confirmation/:id',

  // ASSISTANT
  ASSISTANT_REGISTER: '/register/assistant',

  // PRIVATE
  PATIENTS: '/my-patients',
  PATIENTS_WITH_HASH: '#my-patients',
  PATIENT_DETAILS: '/my-patients/detail/:protocol/:identifier',
  ASSISTANTS: '/my-assistants',
  ASSISTANTS_WITH_HASH: '#my-assistants',

  // ASSISTANT
  ASSISTANT_HOME_PRIVATE: '/dashboard/assistant/doctor-patients',
  ASSISTANT_PATIENT_DETAILS: '/dashboard/assistant/doctor-patients/detail/:protocol/:identifier',

  // ASSISTANT
  NURSE_HOME_PRIVATE: '/dashboard/nurse/patients',
  NURSE_PATIENT_DETAILS: '/dashboard/nurse/patients/detail/:protocol/:identifier',

  // ADMIN
  ADMIN_HOME_PRIVATE: '/dashboard/admin',
  ADMIN_HEALTH_USERS: '/dashboard/admin/users',
  ADMIN_OPERATOR_USERS: '/dashboard/admin/operators',
  ADMIN_USER_DETAILS: '/dashboard/admin/users/:id',
  ADMIN_EXAMS: '/dashboard/admin/exams',
  ADMIN_PATIENT_DETAILS: '/dashboard/admin/exams/detail/:protocol/:identifier',

  // VISITOR
  VISITOR_HOME_PRIVATE: '/dashboard/visitor',
  VISITOR_HEALTH_USERS: '/dashboard/visitor/users',
  VISITOR_OPERATOR_USERS: '/dashboard/visitor/operators',
  VISITOR_USER_DETAILS: '/dashboard/visitor/users/:id',

  // ERROR
  SERVER_ERROR: '/500',
  NOT_FOUND: '/400',
};
