/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import { styles } from '~/config';

import { IconBase } from './IconCustom.style';

const IconCustom = ({
  testId,
  name,
  size,
  className,
  onMouseEnter,
  onMouseOver,
  onMouseOut,
  onClick
}) => (
  <IconBase
    className={`anticon ${className}`}
    data-testid={testId}
    src={name}
    size={size}
    onMouseEnter={onMouseEnter}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    onClick={onClick}
  />
);

IconCustom.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  testId: PropTypes.string,
  size: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  onClick: PropTypes.func,
};

IconCustom.defaultProps = {
  testId: '',
  className: '',
  size: styles.fontSize.default,
  onMouseEnter: () => undefined,
  onMouseOver: () => undefined,
  onMouseOut: () => undefined,
  onClick: () => undefined,
};

export default IconCustom;
