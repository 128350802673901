import { objectManager } from '../helpers';

const { isEmpty } = objectManager;

class PatientGroup {
  constructor(data = []) {
    if (isEmpty(data)) return [];

    return data.map(item => ({
      namePatient: item.name,
      birthdate: item.birthdate,
      protocol: item.protocol,
      dataOfVisit: item.visitdate,
      origin: item.origin,
      crm: item.crm,
      identifier: item.identifier,
      requestId: item.requestId,
      exams: item.exams,
    }));
  }
}

export default PatientGroup;
