import { requestToken, logout } from '~/api/services/auth.service';
import { errorMessage, simpleAction, tokenManager } from '~/utils/helpers';
import { clearFeatureToggles }  from '~/store/ducks/configs/featureToggles';

// Action Types
export const LOGIN_REQUEST = '@dasa-canal-medico/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@dasa-canal-medico/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@dasa-canal-medico/LOGIN_FAILURE';
export const LOGIN_RESET = '@dasa-canal-medico/LOGIN_RESET';

export const LOGOUT_REQUEST = '@dasa-canal-medico/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = '@dasa-canal-medico/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = '@dasa-canal-medico/LOGOUT_FAILURE';

// Reducer
export const initialState = {
  data: {
    isAuthenticated: false,
    user: {
      login: '',
    },
  },
  error: {
    status: '',
    message: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        data: {
          isAuthenticated: true,
          user: {
            id: action.payload.user.id,
            name: action.payload.user.name,
            role: action.payload.user.role,
            login: action.payload.user.login,
            gender: action.payload.user.gender,
            forcedUpdatePassword: action.payload.user.forcedUpdatePassword,
          },
        },
        isLoading: false,
      };
    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
      return {
        ...state,
        error: {
          status: action.payload.status,
          message: action.payload.message || action.payload,
        },
        isLoading: false,
      };
    case LOGIN_RESET:
    case LOGOUT_REQUEST:
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}

// Action Creators
export const loginRequest = simpleAction(LOGIN_REQUEST);
export const loginSuccess = simpleAction(LOGIN_SUCCESS);
export const loginFailure = simpleAction(LOGIN_FAILURE);
export const loginReset = simpleAction(LOGIN_RESET);

export const logoutRequest = simpleAction(LOGOUT_REQUEST);
export const logoutSuccess = simpleAction(LOGOUT_SUCCESS);
export const logoutFailure = simpleAction(LOGOUT_FAILURE);

export const loginAction = params => async dispatch => {
  dispatch(loginRequest());

  try {
    const payload = await requestToken(params);

    const token = JSON.stringify({
      token: payload.token,
      tokenType: payload.tokenType,
      refreshToken: payload.refreshToken,
    });

    tokenManager.save(token);

    dispatch(loginSuccess(payload));
  } catch (err) {
    const error = { message: err.response?.data, status: err.response?.status };
    dispatch(loginFailure(error));
  }
};

export const resetLoginAction = () => async dispatch => {
  tokenManager.remove();
  clearFeatureToggles();
  localStorage.removeItem('persist:root');

  dispatch(loginReset());
};

export const logoutAction = () => async dispatch => {
  dispatch(logoutRequest());

  try {
    const payload = await logout();
    dispatch(logoutSuccess(payload));
  } catch (err) {
    const error = errorMessage(err);
    dispatch(logoutFailure(error));
  }

  tokenManager.remove();
  clearFeatureToggles();
  localStorage.removeItem('persist:root');
};
