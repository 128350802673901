import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { svg } from '~/assets';
import { styles, webRouter } from '~/config';
import { roleManager, stringManager, ga } from '~/utils/helpers';

import { Avatar, Link, IconCustom } from '~/components/atoms';

import {
  Menu,
  MenuItem,
  MenuText,
  MenuItemHeader,
  MenuItemWrapper,
  UserWrapper,
  MenuItemContentWrapper,
  IconWrapper,
  UserName,
  WrapperAssistantTour,
} from './MenuHeader.styles';
import { gaTypeEvents } from '~/utils/enums';

const { adminValidator, isVisitor: visitorValidator } = roleManager;

const MenuHeader = (
  logout,
  user,
  setShowUpdateUserRegisterModal,
  isAssistant,
  isNurse,
  onClickAssistantsNextTour,
) => {
  const { user: loggedUser } = useSelector(state => state.auth.data);

  const isAdmin = adminValidator(user.role);
  const isVisitor = visitorValidator(user.role);

  const sendDataTracking = event => {
    ga.dataLayer({
      event,
      user: loggedUser,
    });
  };

  return (
    <Menu data-testid="menu-header-group">
      <MenuItemHeader onItemHover={() => {}} onMouseEnter={() => {}} onMouseLeave={() => {}}>
        <Avatar testId="menu-header-avatar" color={styles.colors.primary} size="39px">
          {stringManager.getFirstLetter(user.name)}
        </Avatar>
        <UserWrapper>
          <UserName data-testid="menu-header-username-text">
            {stringManager.getGenderAbbreviation(user.gender)}{' '}
            {stringManager.getFirstAndLastName(user.name)}
          </UserName>
        </UserWrapper>
      </MenuItemHeader>
      <MenuItemWrapper>
        {!isAdmin && !isAssistant && !isVisitor && (
          <>
            <MenuItem
              onItemHover={() => {}}
              onMouseEnter={() => {}}
              onMouseLeave={() => {}}
              data-testid="menu-header-edit-profile-item"
              onClick={() => sendDataTracking(gaTypeEvents.private.header.menu.editProfile)}
            >
              <MenuItemContentWrapper
                testid="menu-header-edit-profile"
                onClick={() => setShowUpdateUserRegisterModal(true)}
              >
                <IconWrapper>
                  <IconCustom
                    testId="menu-header-edit-profile-icon"
                    name={svg.iconUser}
                    size="24px"
                  />
                </IconWrapper>
                <MenuText data-testid="menu-header-edit-profile-text">Editar perfil</MenuText>
              </MenuItemContentWrapper>
            </MenuItem>
            {!isNurse && (
              <MenuItem
                onItemHover={() => {}}
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
                data-testid="menu-header-my-assistants-item"
                onClick={onClickAssistantsNextTour}
              >
                <WrapperAssistantTour
                  data-tour="my-assistants"
                  onClick={() => sendDataTracking(gaTypeEvents.private.header.menu.myAssistants)}
                >
                  <Link testid="menu-header-my-assistants-link" to={webRouter.ASSISTANTS}>
                    <MenuItemContentWrapper>
                      <IconWrapper>
                        <IconCustom
                          testId="menu-header-my-assistants-icon"
                          name={svg.iconUserPlus}
                          size="24px"
                        />
                      </IconWrapper>
                      <MenuText data-testid="menu-header-my-assistants-text">
                        Meus assistentes
                      </MenuText>
                    </MenuItemContentWrapper>
                  </Link>
                </WrapperAssistantTour>
              </MenuItem>
            )}
          </>
        )}

        <MenuItem
          onItemHover={() => {}}
          onMouseEnter={() => {}}
          onMouseLeave={() => {}}
          key="logout"
          data-testid="menu-header-logout-item"
          onClick={logout}
        >
          <MenuItemContentWrapper>
            <IconWrapper>
              <IconCustom testId="menu-header-logout-icon" name={svg.iconLogout} size="21px" />
            </IconWrapper>
            <MenuText data-testid="menu-header-logout-text">Sair</MenuText>
          </MenuItemContentWrapper>
        </MenuItem>
      </MenuItemWrapper>
    </Menu>
  );
};

MenuHeader.propTyes = {
  isAssistant: PropTypes.bool,
  onClickAssistantsNextTour: PropTypes.func,
  logout: PropTypes.func.isRequired,
};

MenuHeader.propTyes = {
  isAssistant: false,
  onClickAssistantsNextTour: () => undefined,
};

export default MenuHeader;
