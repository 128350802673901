import { dateManager } from '~/utils/helpers';

export default [
  { id: 'period-0', value: dateManager.getLastMonth(), text: '1 mês' },
  { id: 'period-1', value: dateManager.getLastMonth(3), text: '3 meses' },
  { id: 'period-2', value: dateManager.getLastMonth(6), text: '6 meses' },
  { id: 'period-3', value: dateManager.getLastYear(), text: '1 ano' },
  { id: 'period-4', value: dateManager.getLastYear(3), text: '3 anos' },
  { id: 'period-5', value: dateManager.getLastYear(6), text: '6 anos' },
];
