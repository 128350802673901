import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { Title, Text, Button } from '~/components/atoms';

import { Container, Icon, ButtonWrapper } from './CreateAssistant.styles';

const CreateAssistant = ({ openModalCreateAssistant }) => (
  <Container>
    <Icon name={svg.iconAssistant} size="160px" />
    <Title htmlType="h3">Você ainda não possui nenhum assistente adicionado</Title>
    <Text>Adicione assistentes que poderão visualizar os exames prescritos por você.</Text>
    <ButtonWrapper data-tour="create-assistant">
      <Button type="primary" onClick={openModalCreateAssistant}>
        Adicionar um novo assistente
      </Button>
    </ButtonWrapper>
  </Container>
);

CreateAssistant.propTypes = {
  openModalCreateAssistant: PropTypes.func.isRequired,
};

export default memo(CreateAssistant);
