import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import IconCustom from '../IconCustom/IconCustom';
import { svg } from '~/assets';
import {
  InputBase,
  InputItem,
  InputTooltip,
  InputIcon,
  WrapperIconCustom,
  TipBelowField,
} from './Input.styles';

const Input = ({
  isDisabled,
  onBlur,
  onChange,
  onSearch,
  name,
  placeholder,
  htmlType,
  label,
  size,
  value,
  error,
  testId,
  touched,
  suffix,
  mask,
  maxLength,
  minLength,
  hasTipBelowField,
  textTipBelowField,
}) => {
  const [showSuffix, setShowSuffix] = useState(false);

  const hasMask = !!mask;
  const hasError = !!error;
  const hasTouched = !!touched;

  const setSuffix = bool => setShowSuffix(bool);

  return (
    <InputItem
      label={label}
      validateStatus={hasTouched && hasError ? 'error' : ''}
      help={hasTouched && hasError && error}
    >
      {!hasMask ? (
        <InputBase
          autoComplete="off"
          disabled={isDisabled}
          data-testid={testId}
          size={size}
          name={name}
          onFocus={() => setSuffix(true)}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={e => {
            onBlur(e);
            setSuffix(false);
          }}
          onPressEnter={() => onSearch()}
          value={value}
          defaultValue={value}
          maxLength={maxLength}
          minLength={minLength}
          suffix={
            <InputTooltip
              visible={suffix.isVisible && showSuffix}
              trigger="click"
              placement="bottomRight"
              title={suffix.text}
              onClick={() => onSearch()}
            >
              <InputIcon
                type={suffix.iconName}
                style={{ color: `${suffix.color}`, fontSize: `${suffix.size}` }}
              />
            </InputTooltip>
          }
        />
      ) : (
        <InputMask
          autoComplete="off"
          className={`ant-input ant-input-lg ${isDisabled && 'ant-input-disabled'}`}
          data-testid={testId}
          disabled={isDisabled}
          type={htmlType}
          placeholder={placeholder}
          name={name}
          mask={mask}
          maskChar={null}
          maxLength={maxLength}
          minLength={minLength}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
      {hasTipBelowField && !(hasTouched && hasError) && (
        <TipBelowField>
          <WrapperIconCustom>
            <IconCustom testId="menu-header-logout-icon" name={svg.iconAlertCircle} size="12px" />
          </WrapperIconCustom>
          {textTipBelowField}
        </TipBelowField>
      )}
    </InputItem>
  );
};

Input.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  testId: PropTypes.string,
  size: PropTypes.string,
  touched: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isWhitelist: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.string,
  mask: PropTypes.string,
  htmlType: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  suffix: PropTypes.shape({
    text: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    iconName: PropTypes.string,
    isVisible: PropTypes.bool,
  }),
  hasTipBelowField: PropTypes.bool,
  textTipBelowField: PropTypes.string,
};

Input.defaultProps = {
  onSearch: () => undefined,
  placeholder: '',
  label: '',
  testId: '',
  size: 'large',
  htmlType: 'text',
  touched: false,
  isDisabled: false,
  isWhitelist: false,
  error: '',
  value: '',
  mask: '',
  maxLength: undefined,
  minLength: undefined,
  suffix: {
    text: '',
    size: '',
    color: '',
    iconName: '',
    isVisible: false,
  },
  textTipBelowField: '',
  hasTipBelowField: false,
};

export default Input;
