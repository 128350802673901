import React from 'react';
import PropTypes from 'prop-types';

import { InputSearch } from '~/components/atoms';

import { Container } from './Form.styles';

const Form = ({ handleSubmit, handleChange, values, errors, touched, testId, ...rest }) => {
  return (
    <Container>
      <form name="searchExamForm" onSubmit={handleSubmit}>
        <InputSearch
          {...rest}
          name="search"
          label="Pesquisar por nome do exame"
          placeholder="Pesquisar"
          testId={`${testId}-input-search`}
          onSearch={value => handleSubmit(value)}
          onChange={handleChange}
          value={values.search}
          error={errors.search}
          touched={touched.search}
        />
      </form>
    </Container>
  );
};

Form.propTypes = {
  testId: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    search: PropTypes.bool,
  }).isRequired,
};

Form.defaultProps = {
  testId: '',
};

export default Form;
