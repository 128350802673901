import React from 'react';
import PropTypes from 'prop-types';

import { Button, IconCustom } from '~/components/atoms';

import { Onboarding, CloseButton } from './TourGuide.styles';
import { svg } from '~/assets';

const TourGuide = ({
  testId,
  visible,
  closeTour,
  navigation,
  steps,
  startAt,
  goToStep,
  showModal,
  maskSpace,
  onClickGoBack,
  onClickGoNext,
  getCurrentStep,
  hidePrevButton,
}) => (
  <Onboarding
    steps={steps}
    isOpen={visible}
    onRequestClose={closeTour}
    closeWithMask={false}
    rounded={8}
    startAt={startAt}
    getCurrentStep={getCurrentStep}
    showModal={showModal}
    maskSpace={maskSpace}
    showNumber={false}
    showButtons
    goToStep={goToStep}
    showCloseButton={false}
    showNavigation={navigation}
    showNavigationNumber={false}
    hidePrevButton={hidePrevButton}
    prevButton={
      !hidePrevButton && (
        <Button isOutline testId={`${testId}-back`} onClick={onClickGoBack}>
          Voltar
        </Button>
      )
    }
    nextButton={
      <Button type="primary" testId={`${testId}-next`} onClick={onClickGoNext}>
        Próximo
      </Button>
    }
    lastStepNextButton={
      <Button testId={`${testId}-finish`} type="primary">
        Entendi
      </Button>
    }
  >
    <CloseButton onClick={closeTour}>
      <IconCustom testId={`${testId}-close`} name={svg.iconClose} size="26px" />
    </CloseButton>
  </Onboarding>
);

TourGuide.propTypes = {
  testId: PropTypes.string,
  steps: PropTypes.shape({}).isRequired,
  showModal: PropTypes.bool,
  visible: PropTypes.bool,
  startAt: PropTypes.number,
  closeTour: PropTypes.func.isRequired,
  navigation: PropTypes.bool,
  hidePrevButton: PropTypes.bool,
  maskSpace: PropTypes.number,
  goToStep: PropTypes.number,
  onClickGoBack: PropTypes.func,
  onClickGoNext: PropTypes.func,
  getCurrentStep: PropTypes.func,
};

TourGuide.defaultProps = {
  testId: 'tour-guide',
  startAt: 0,
  visible: false,
  goToStep: undefined,
  showModal: true,
  navigation: false,
  hidePrevButton: false,
  maskSpace: 0,
  onClickGoBack: () => undefined,
  onClickGoNext: () => undefined,
  getCurrentStep: () => undefined,
};

export default TourGuide;
