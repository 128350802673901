import axios from 'axios';
import api from '../api';
import { apiPatients, apiDoctors, apikey as apiKey } from '../../config/envManager';
import { toApi as toGroupApi, fromApi as fromGroupApi } from '../dto/exams/patient.dto';
import { fromApi as fromDetailApi } from '../dto/exams/patientDetail.dto';
import { toApi as toDetailApi } from '../dto/exams/patient.detail';
import { toApi, fromApi, fromChildrenApi } from '../dto/exams/patientsItem.dto';
import fromValidateViewExamTokenApi from '../dto/exams/patientsViewExamsValidateToken.dto';
import { GET } from '../httpMethods';

export const getPatientExams = async urlParams => {
  const params = toApi(urlParams);

  const { data } = await api({
    url: `${apiPatients}/private/doctor/history/exams/search`,
    method: GET,
    params,
  });

  const response = fromApi(data);

  return response;
};

export const getOtherPatientExams = async urlParams => {
  const params = toApi(urlParams);

  const { data } = await api({
    url: `${apiPatients}/private/doctor/history/other-exams/search`,
    method: GET,
    params,
  });

  const response = fromApi(data);

  return response;
};

export const getPatientDetail = async urlParams => {
  if (urlParams.name) {
    return urlParams;
  }
  const params = toDetailApi(urlParams);

  const { data } = await api({
    url: `${apiPatients}/private/doctor/history/patient-detail`,
    method: GET,
    params,
  });

  const response = fromDetailApi(data);

  return response;
};

const sanitizeParameters = object => {
  const objectCopy = { ...object };
  Object.keys(objectCopy).forEach(key => {
    if (objectCopy[key] === undefined) delete objectCopy[key];
  });
  return objectCopy;
};

const getPatientsLegacy = async (urlParams, canGroupExams = false) => {
  const params = sanitizeParameters(toGroupApi(urlParams));
  const url = `${apiPatients}/private/doctor/list`;
  if (canGroupExams) params.grouped = true;
  const { data } = await api({
    url,
    params,
    method: GET,
  });

  const response = await fromGroupApi(data.patients, canGroupExams);
  return response;
};

export const getPatients = async (urlParams, canGroupExams = false) => {
  const { isLegacy } = urlParams;
  if (isLegacy || !canGroupExams) {
    const response = await getPatientsLegacy(urlParams, canGroupExams);
    return response;
  }

  const params = sanitizeParameters(urlParams);
  const url = `${apiPatients}/v2/private/doctor/list`;
  if (canGroupExams) params.grouped = true;
  const { data } = await api({
    url,
    params,
    method: GET,
  });

  const response = await fromGroupApi(data.patients, canGroupExams);
  return response;
};

export const getPatientChildrens = async urlParams => {
  const { data } = await api({
    url: `${apiPatients}/private/doctor/history/exam-detail`,
    params: {
      laudoId: urlParams.laudoId,
      protocol: urlParams.protocol,
      estado: urlParams.uf || undefined,
      numero: urlParams.number || undefined,
      conselho: urlParams.conselho || undefined,
    },
  });

  const response = fromChildrenApi(data, urlParams);

  return response;
};

export const validateExamViewToken = async headers => {
  const { data } = await axios({
    url: `${apiDoctors}/public/validate-exams-view-token`,
    method: 'GET',
    headers,
    params: { apiKey },
  });

  return fromValidateViewExamTokenApi(data);
};

export const approveExamViewRequest = async headers => {
  const { data } = await axios({
    url: `${apiDoctors}/public/patients/approve`,
    method: 'PUT',
    headers,
    params: { apiKey },
  });

  return data;
};

export const rejectExamViewRequest = async headers => {
  const { data } = await axios({
    url: `${apiDoctors}/public/patients/reject`,
    method: 'PUT',
    headers,
    params: { apiKey },
  });

  return data;
};
