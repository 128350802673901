import styled from 'styled-components';
import { Form, Input, Tooltip, Icon } from 'antd';

import { styles } from '~/config';

const { TextArea } = Input;

export const InputItem = styled(Form.Item)``;

export const TextAreaBase = styled(TextArea)`
  && {
    font-size: 1rem;
  }
`;

export const InputTooltip = styled(Tooltip)``;

export const InputIcon = styled(Icon)`
  && {
    color: #747679;

    :hover {
      color: #000;
    }
  }
`;

export const MessageCount = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;

  span {
    color: ${({ isNegative }) => isNegative && styles.colors.error};
    font-size: 0.8rem;
    position: absolute;
  }
`;
