import styled from 'styled-components';

export const Title = styled.h4`
  color: #fff;
  font-size: 1rem;
  margin: 0;
`;

export const Button = styled.button`
  color: #17d5d5;
  font-size: 12px;
  margin: 0;
  cursor: pointer;
  background: transparent;
  border: transparent;
  padding: 0;
`;
