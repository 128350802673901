import styled from 'styled-components';

import { mediaQuery } from '~/utils/helpers';

export const OuterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3%;
  align-items: center;
`;

export const InnerWrapper = styled.div`
  width: min-content;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${mediaQuery.inDesktop(`
    display: grid;
    grid-template-columns: 237px 1fr;
    grid-template-rows: 1fr;
    column-gap: 34px;
    align-content: center;
  `)}

  ${mediaQuery.inMobile(`
    margin: 60px 0;
    white-space: normal;
  `)}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  ${mediaQuery.inMobile(`
    align-items: center;
  `)}
`;

export const WrapperIcon = styled.div`
  margin-bottom: 27px;
`;
