import { reproveUser } from '~/api/services/users.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const ADMIN_REPROVE_USER_REQUEST = '@dasa-canal-medico/ADMIN_REPROVE_USER_REQUEST';
export const ADMIN_REPROVE_USER_SUCCESS = '@dasa-canal-medico/ADMIN_REPROVE_USER_SUCCESS';
export const ADMIN_REPROVE_USER_FAILURE = '@dasa-canal-medico/ADMIN_REPROVE_USER_FAILURE';

// Reducer
export const initialState = {
  data: {
    message: '',
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_REPROVE_USER_REQUEST:
      return {
        ...state,
        ...initialState,
        isLoading: true,
      };
    case ADMIN_REPROVE_USER_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: {
          message: action.payload.message,
        },
        isLoading: false,
      };
    case ADMIN_REPROVE_USER_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}

// Action Creators
export const adminReproveUserRequest = simpleAction(ADMIN_REPROVE_USER_REQUEST);
export const adminReproveUserSuccess = simpleAction(ADMIN_REPROVE_USER_SUCCESS);
export const adminReproveUserFailure = simpleAction(ADMIN_REPROVE_USER_FAILURE);

export const adminReproveUserAction = params => async dispatch => {
  dispatch(adminReproveUserRequest());

  try {
    const payload = await reproveUser(params);
    dispatch(adminReproveUserSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(adminReproveUserFailure(err));
  }
};
