import api from '~/api';
import { toApi } from '~/api/dto/reports/downloadCovidExams.dto';

import { GET } from '~/api/httpMethods';
import { apiReports } from '~/config/envManager';

export const validateReportDownload = async () => {
  const { data } = await api({
    url: `${apiReports}/private/user/download/validate`,
    method: GET,
  });

  return data;
};

export const downloadCovidExams = async apiParams => {
  const params = toApi(apiParams);

  const { data } = await api({
    url: `${apiReports}/private/doctor/request-covid-reports`,
    method: GET,
    params,
  });

  return data;
};

export const resultCovidExams = async apiParams => {
  const params = toApi(apiParams);

  const { data } = await api({
    url: `${apiReports}/private/doctor/request-covid-results`,
    method: GET,
    params,
  });

  return data;
};
