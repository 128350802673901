import styled from 'styled-components';

export const Container = styled.section`
  text-align: justify;
`;

export const Date = styled.time`
  display: flex;
  justify-content: flex-end;
`;

export const Items = styled.ol`
  margin-left: 20px;
`;

export const Credits = styled.section`
  display: flex;
  justify-content: center;
  text-align: center;
`;
