import produce from 'immer';

import { getPatients } from '~/api/services/patients.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

export const PATIENT_GROUP_REQUEST = '@dasa-canal-medico/PATIENT_GROUP_REQUEST';
export const PATIENT_GROUP_SUCCESS = '@dasa-canal-medico/PATIENT_GROUP_SUCCESS';
export const PATIENT_GROUP_FAILURE = '@dasa-canal-medico/PATIENT_GROUP_FAILURE';
export const PATIENT_GROUP_RESET = '@dasa-canal-medico/PATIENT_GROUP_RESET';

export const initialState = {
  data: [],
  error: {
    message: '',
    status: '',
  },
  params: {},
  isLoading: true,
  isLoadingMore: false,
  hasMoreDataToLoad: false,
};

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case PATIENT_GROUP_REQUEST: {
        draft.isLoading = !action.payload.params.isLoadingMore;
        draft.isLoadingMore = action.payload.params.isLoadingMore;
        break;
      }
      case PATIENT_GROUP_SUCCESS: {
        draft.data = action.payload.data;
        draft.params = action.payload.params;
        draft.isLoading = false;
        draft.isLoadingMore = false;
        draft.hasMoreDataToLoad = action.payload.data.length === action.payload.params.postPerPage;
        break;
      }
      case PATIENT_GROUP_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.isLoading = false;
        draft.isLoadingMore = false;
        break;
      }
      case PATIENT_GROUP_RESET: {
        draft.data = initialState.data;
        draft.error = initialState.error;
        draft.isLoading = initialState.isLoading;
        draft.isLoadingMore = initialState.isLoadingMore;
        draft.hasMoreDataToLoad = initialState.hasMoreDataToLoad;
        break;
      }
      default:
    }
  });
}

export const getPatientsRequest = simpleAction(PATIENT_GROUP_REQUEST);
export const getPatientsSuccess = simpleAction(PATIENT_GROUP_SUCCESS);
export const getPatientsFailure = simpleAction(PATIENT_GROUP_FAILURE);

export const resetPatientGroup = simpleAction(PATIENT_GROUP_RESET);

export const getPatientsAction = params => async (dispatch, getState) => {
  dispatch(getPatientsRequest({ params: { isLoadingMore: false, ...params } }));
  // eslint-disable-next-line
  const performanceToggle = getState()?.featureToggle?.data?.toggles?.performanceImprovementPatientDetails
  const canGroupExams = performanceToggle || false;
  try {
    const data = await getPatients(params, true);

    dispatch(getPatientsSuccess({ data, params }));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(getPatientsFailure(err));
  }
};

export const resetPatientGroupAction = () => dispatch => {
  dispatch(resetPatientGroup());
};
