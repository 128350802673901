import React from 'react';
import PropTypes from 'prop-types';

import { Container, Title, TitleLabel, Content } from './AccordionItem.styles';
import { IconCustom } from '~/components/atoms';
import { svg } from '~/assets';

const AccordionItem = ({ title, content }) => {
  const [open, setOpen] = React.useState(false);

  const renderContent = state => {
    if (!state) return null;
    return <Content>{content}</Content>;
  };

  const renderIcon = state => {
    if (!state) return <IconCustom name={svg.iconKeyboardArrowDown} size="24px" />;
    return <IconCustom name={svg.iconKeyboardArrowUp} size="24px" />;
  };

  return (
    <Container>
      <Title onClick={() => setOpen(!open)}>
        <TitleLabel>{title}</TitleLabel>
        {renderIcon(open)}
      </Title>
      {renderContent(open)}
    </Container>
  );
};

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

export default React.memo(AccordionItem);
