import React from 'react';
import PropTypes from 'prop-types';

import { BadgeBase } from './Badge.styles';

const Badge = ({ testId, text, status, size, children }) => (
  <BadgeBase testId={testId} text={text} status={status} size={size}>
    {children}
  </BadgeBase>
);

Badge.propTypes = {
  testId: PropTypes.string,
  text: PropTypes.string,
  status: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
};

Badge.defaultProps = {
  testId: 'badge',
  text: '',
  status: '',
  children: <></>,
};

export default Badge;
