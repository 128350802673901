import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';
import { status, badgeColors } from '~/utils/enums';
import { Skeleton } from '~/components/atoms';
import { ExamsActions, Empty, ProtocolsList, ClinicalDiscussion } from '~/components/molecules';

import {
  Wrapper,
  WrapperTitle,
  WrapperHeader,
  List,
  ListItem,
  Text,
  ExamContent,
  Header,
  Badge,
  Icon,
  TotalExams,
  WrapperExams,
  WrapperEmpty,
  WrapperActions,
  WrapperSkeleton,
  WrapperTotalContent,
} from './ProtocolsHistory.styles';

const ProtocolsHistory = ({
  data,
  exams,
  examsSelected,
  isAssistant,
  isNurse,
  isOtherExams,
  onClickPDF,
  isApproved,
  isLoadingPdf,
  isLoadingImage,
  isLoadingProtocols,
  isLoadingPdfDowload,
  isLoadingExamsByProtocol,
  onClickImage,
  onClickShare,
  onClickProtocol,
  onClickPDFDownload,
  openShareReportModal,
  closeShareReportModal,
  onSubmitClinicalDiscussion,
}) => {
  const hasData = !!data.length;
  const hasExams = !!exams.length;

  const isAC = exam => exam.title && exam.title.includes('Analises Clinicas (PDF)');
  const isEvo = exam => exam.title && exam.title.includes('Analises Clinicas Evolutivo (PDF)');
  const isRDI = exam => exam.title && exam.title.includes('Exames de imagem');
  const isImage = exam => exam.title && exam.title.includes('(IMAGENS)');

  if (isLoadingProtocols === status.error || (isLoadingProtocols === status.success && !hasData)) {
    return (
      <WrapperEmpty>
        <Empty
          testId="empty-protocols"
          iconName="file-search"
          title="Não existe exames disponíveis"
          description={
            isOtherExams
              ? 'Caso seu paciente passe com outros médicos do laboratório Dasa os exames serão liberados nesta guia.'
              : 'O paciente não realizou estes exames nos laboratórios Dasa.'
          }
        />
      </WrapperEmpty>
    );
  }

  return (
    <>
      <Wrapper isApproved={isApproved}>
        <WrapperHeader>
          <WrapperTitle>Protocolos</WrapperTitle>
        </WrapperHeader>

        {isLoadingProtocols === status.processing && (
          <WrapperSkeleton>
            <Skeleton rows={2} />
            <Skeleton rows={2} />
            <Skeleton rows={2} />
          </WrapperSkeleton>
        )}

        {hasData && (
          <ProtocolsList
            data={data}
            isOtherExams={isOtherExams}
            examsSelected={examsSelected}
            onClickProtocol={onClickProtocol}
            isLoadingExams={isLoadingExamsByProtocol}
          />
        )}
      </Wrapper>

      <WrapperExams>
        <WrapperHeader isExam>
          <WrapperTitle>Exames</WrapperTitle>
        </WrapperHeader>

        {!hasExams && (
          <WrapperSkeleton>
            <Skeleton rows={2} />
            <Skeleton rows={2} />
            <Skeleton rows={2} />
          </WrapperSkeleton>
        )}

        {hasExams && (
          <List
            itemLayout="horizontal"
            dataSource={exams}
            renderItem={item => (
              <>
                {item.isParent && (
                  <ListItem isParent>
                    <ExamContent isParent>
                      <Header firstHeader>
                        <Text isParent>{item.title}</Text>
                        <WrapperTotalContent>
                          <TotalExams isEvo={isAssistant || isNurse || isEvo(item)}>
                            {item.totalExams > 1
                              ? `${item.totalExams} exames`
                              : `${item.totalExams} exame`}
                          </TotalExams>
                          {!isAssistant && !isNurse && isAC(item) && !isEvo(item) && (
                            <ClinicalDiscussion
                              testId="clinical-discussion"
                              patient={item}
                              exams={exams.filter(examItem => !isEvo(examItem) && !examItem.isRDI)}
                              onSubmit={onSubmitClinicalDiscussion}
                            />
                          )}

                          {!isAssistant && !isNurse && isRDI(item) && !isEvo(item) && (
                            <ClinicalDiscussion
                              testId="clinical-discussion"
                              patient={item}
                              exams={exams.filter(
                                examItem =>
                                  // eslint-disable-next-line implicit-arrow-linebreak
                                  !examItem.isParent && examItem.isRDI && !isImage(examItem),
                              )}
                              onSubmit={onSubmitClinicalDiscussion}
                            />
                          )}
                        </WrapperTotalContent>
                      </Header>
                    </ExamContent>
                  </ListItem>
                )}

                <ListItem isParent={item.isParent}>
                  <ExamContent isParent={item.isParent}>
                    <Header>
                      {!item.isParent && <Text>{item.title}</Text>}
                      {item.isParent && item.isComplete && <Text>Resultado completo</Text>}
                      {item.isParent && !item.isComplete && <Text>Resultados parciais</Text>}
                      <WrapperActions>
                        <ExamsActions
                          className="child-exam-action"
                          item={item}
                          report={item.reports}
                          onClickPDF={onClickPDF}
                          onClickShare={onClickShare}
                          onClickImage={onClickImage}
                          onClickPDFDownload={onClickPDFDownload}
                          isLoadingPdf={isLoadingPdf}
                          isLoadingImage={isLoadingImage}
                          isLoadingPdfDowload={isLoadingPdfDowload}
                          openShareReportModal={openShareReportModal}
                          closeShareReportModal={closeShareReportModal}
                        />
                        {item.isParent || item.isRDI ? (
                          <Badge
                            className="child-exam-status"
                            data-testid="exams-history-isComplete"
                            text={item.isComplete ? 'Liberado' : 'Processando'}
                            status={item.isComplete ? status.success : status.warning}
                          />
                        ) : (
                          <>
                            <Badge
                              className="child-exam-status"
                              data-testid="exams-history-isComplete"
                              text={item.status === 'Parcial' ? 'Processamento' : item.status}
                              color={badgeColors[item.status]}
                            />

                            {(item.status === 'Cancelado' || item.status === 'Suspenso') && (
                              <Tooltip
                                placement="leftTop"
                                title={
                                  item.status === 'Cancelado'
                                    ? 'O exame sofreu alterações e precisará ser refeito'
                                    : 'Exame possui inconsistência de informações e está em revisão'
                                }
                              >
                                <Icon type="info-circle" theme="twoTone" twoToneColor="#AFB1B6" />
                              </Tooltip>
                            )}
                          </>
                        )}
                      </WrapperActions>
                    </Header>
                  </ExamContent>
                </ListItem>
              </>
            )}
          />
        )}
      </WrapperExams>
    </>
  );
};

ProtocolsHistory.propTypes = {
  data: PropTypes.arrayOf([PropTypes.shape({})]),
  exams: PropTypes.arrayOf([PropTypes.shape({})]),
  examsSelected: PropTypes.bool,
  isApproved: PropTypes.bool,
  isLoadingPdf: PropTypes.bool,
  isLoadingImage: PropTypes.bool,
  isLoadingProtocols: PropTypes.string,
  isLoadingPdfDowload: PropTypes.bool,
  isLoadingExamsByProtocol: PropTypes.bool,
  isAssistant: PropTypes.bool,
  isNurse: PropTypes.bool,
  isOtherExams: PropTypes.bool,
  onClickPDF: PropTypes.func,
  onClickImage: PropTypes.func,
  onClickShare: PropTypes.func,
  onClickProtocol: PropTypes.func,
  onClickPDFDownload: PropTypes.func,
  openShareReportModal: PropTypes.func,
  closeShareReportModal: PropTypes.func,
  onSubmitClinicalDiscussion: PropTypes.func.isRequired,
};

ProtocolsHistory.defaultProps = {
  data: [],
  exams: [],
  examsSelected: false,
  isApproved: false,
  isLoadingPdf: false,
  isLoadingImage: false,
  isLoadingProtocols: status.processing,
  isLoadingPdfDowload: false,
  isLoadingExamsByProtocol: false,
  isAssistant: false,
  isNurse: false,
  isOtherExams: false,
  onClickPDF: () => undefined,
  onClickShare: () => undefined,
  onClickImage: () => undefined,
  onClickProtocol: () => undefined,
  onClickPDFDownload: () => undefined,
  openShareReportModal: () => undefined,
  closeShareReportModal: () => undefined,
};

export default ProtocolsHistory;
