import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '~components/atoms';
import { FormWrapper } from '~components/molecules';

import Form from './Form/Form';
import formSchema from './Form/Form.schema';

const initialValues = {
  email: '',
};

const ModalShareReport = ({ testId, isVisible, isLoading, onSubmit, closeModal }) => (
  <Modal
    testId={`${testId}-modal`}
    isVisible={isVisible}
    onCancel={closeModal}
    width="400px"
    maskClosable={false}
    isClosable
    removePadding
    destroyOnClose
  >
    <FormWrapper
      enableReinitialize
      initialValues={initialValues}
      validationSchema={formSchema}
      isLoading={isLoading}
      onSubmit={values => onSubmit(values)}
      render={props => (
        <Form {...props} testId={testId} isLoading={isLoading} closeModal={closeModal} />
      )}
    />
  </Modal>
);

ModalShareReport.defaultProps = {
  testId: '',
  isVisible: false,
  isLoading: false,
  onSubmit: () => {},
  closeModal: () => {},
};

ModalShareReport.propTypes = {
  testId: PropTypes.string,
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  closeModal: PropTypes.func,
};

export default ModalShareReport;
