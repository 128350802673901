import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalBase = styled(Modal)`
  && {
    ${({ fullScreen }) =>
      fullScreen &&
      `
      position: absolute;
      width: 100vw;
      height: calc(100vh - 35px);
      top: 35px;
      left: 0;

      .ant-modal-content {
        width: 100vw;
        height: calc(100vh - 59px);
      }

      .ant-modal-body {
        height: calc(100vh - 59px);
        overflow-y: auto;
        .exams-covid-form {
          float left;
          .ant-form-item {
            padding-right: 15px;
          }
        }
      }
      `}

    .ant-modal-content {
      border-radius: ${({ isLP }) => isLP && '8px'};
    }

    .ant-modal-body {
      padding: ${({ removePadding }) => removePadding && '0'};
    }

    .ant-modal-close {
      top: -40px;
    }

    .ant-modal-close-x {
      width: 32px;
      height: 32px;
    }
  }
`;
