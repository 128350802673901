import { updateAdminRegister } from '~/api/services/users.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const ADMIN_UPDPATE_REGISTER_REQUEST = '@dasa-canal-medico/ADMIN_UPDPATE_REGISTER_REQUEST';
export const ADMIN_UPDPATE_REGISTER_SUCCESS = '@dasa-canal-medico/ADMIN_UPDPATE_REGISTER_SUCCESS';
export const ADMIN_UPDPATE_REGISTER_FAILURE = '@dasa-canal-medico/ADMIN_UPDPATE_REGISTER_FAILURE';

// Reducer
export const initialState = {
  data: {
    message: '',
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_UPDPATE_REGISTER_REQUEST:
      return {
        ...state,
        ...initialState,
        isLoading: true,
      };
    case ADMIN_UPDPATE_REGISTER_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: {
          message: action.payload.message,
        },
        isLoading: false,
      };
    case ADMIN_UPDPATE_REGISTER_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}

// Action Creators
export const adminUpdateRegisterRequest = simpleAction(ADMIN_UPDPATE_REGISTER_REQUEST);
export const adminUpdateRegisterSuccess = simpleAction(ADMIN_UPDPATE_REGISTER_SUCCESS);
export const adminUpdateRegisterFailure = simpleAction(ADMIN_UPDPATE_REGISTER_FAILURE);

export const adminUpdateRegisterAction = params => async dispatch => {
  dispatch(adminUpdateRegisterRequest());

  try {
    const payload = await updateAdminRegister(params);
    dispatch(adminUpdateRegisterSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(adminUpdateRegisterFailure(err));
  }
};
