import React from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { IconCustom } from '~/components/atoms';

import {
  List,
  ListItem,
  Text,
  Time,
  Action,
  Wrapper,
  TimeWrapper,
  ReportContent,
  ActionContent,
} from './ProtocolsList.styles';

const ProtocolsList = ({ data, isOtherExams, examsSelected, isLoadingExams, onClickProtocol }) => (
  <List>
    {data.map(item => (
      <ListItem
        className={item.protocol === examsSelected ? 'is-active' : ''}
        onClick={() => onClickProtocol({ item })}
        isLoadingExams={isLoadingExams}
      >
        <Wrapper>
          <ReportContent>
            <Text>{item.protocol}</Text>
            <Text>
              <b>{item.origin.name}</b>
            </Text>
          </ReportContent>
          <ActionContent>
            <TimeWrapper>
              <Time dateTime={item.dataOfVisit}>
                <Text>{item.dataOfVisit}</Text>
              </Time>
              {isOtherExams && <Text>{item.crm}</Text>}
            </TimeWrapper>
            <Action>
              <IconCustom name={svg.iconArrowRight} size="16px" />
            </Action>
          </ActionContent>
        </Wrapper>
      </ListItem>
    ))}
  </List>
);

ProtocolsList.propTypes = {
  data: PropTypes.objectOf({
    title: PropTypes.string,
    dataOfVisit: PropTypes.string,
    isComplete: PropTypes.bool,
    origin: PropTypes.objectOf({
      name: PropTypes.string,
    }),
  }).isRequired,
  isOtherExams: PropTypes.bool,
  examsSelected: PropTypes.bool,
  isLoadingExams: PropTypes.bool,
  onClickProtocol: PropTypes.func,
};

ProtocolsList.defaultProps = {
  isOtherExams: false,
  examsSelected: false,
  isLoadingExams: false,
  onClickProtocol: () => undefined,
};

export default ProtocolsList;
