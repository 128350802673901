import styled from 'styled-components';
import { Drawer as DrawerBase, List as ListBase, Badge as BadgeBase } from 'antd';

import { styles } from '~/config';
import { mediaQuery } from '~/utils/helpers';

export const Drawer = styled(DrawerBase)`
  && {
    height: calc(100vh - 64px);
    top: 64px;

    h3 {
      margin-left: 30px;
    }

    .ant-drawer-body {
      padding: 30px 0;
    }

    .ant-drawer-mask {
      background-color: transparent;
    }

    .ant-drawer-content-wrapper {
      min-width: 30vw;

      ${mediaQuery.inMobile(`
        min-width: 100vw;
      `)}
    }
  }
`;

export const List = styled(ListBase)``;

export const Item = styled(List.Item)`
  && {
    background: ${({ isRead }) => !isRead && styles.colors.defaultBackground};
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;

    &:hover {
      cursor: ${({ isRead }) => !isRead && 'pointer'};
    }
  }
`;

export const TextContent = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;

    span {
      color: ${styles.colors.alert};
    }
  }

  a {
    color: ${styles.colors.primary};
    font-weight: bold;
  }
`;

export const Badge = styled(BadgeBase).attrs(({ isRead }) => ({
  color: `${!isRead ? styles.colors.primary : 'transparent'}`,
}))``;

export const Date = styled.div`
  color: ${styles.colors.default};
  font-size: 0.9rem;
  position: relative;
  left: 14px;
`;

export const Empty = styled.div`
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${styles.colors.default};

  p {
    display: flex;
    flex-direction: column;
    align-items: center;

    &::after {
      content: '';
      width: 60px;
      border-bottom: 1px solid ${styles.colors.default};
      margin-top: 10px;
    }
  }
`;

export const Panic = styled.span`
  color: ${styles.colors.alert};
  font-weight: bold;

  img {
    margin-right: 5px;
  }
`;
