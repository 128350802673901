import styled from 'styled-components';
import { Icon, Avatar } from 'antd';

import { styles } from '~/config';
import { mediaQuery } from '~/utils/helpers';

export const DropdownText = styled.span`
  && {
    color: #1e1e1e;
    display: none;

    ${mediaQuery.inDesktop(`
      display: inline-block;
      margin-right: 10px;
    `)}
  }
`;

export const DropdownWrapper = styled.div``;

export const DropdownAvatar = styled(Avatar)`
  && {
    font-size: 14px;
    font-weight: bold;
    background-color: ${styles.colors.primary};
    margin-right: 10px;
  }
`;

export const DropdownIcon = styled(Icon)`
  && {
    color: ${styles.colors.black};
    font-size: 16px !important;
  }
`;
