import { getPatients } from '~/api/services/adminPatients.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const PATIENTS_GROUP_REQUEST = '@dasa-canal-medico/PATIENTS_GROUP_REQUEST';
export const PATIENTS_GROUP_SUCCESS = '@dasa-canal-medico/PATIENTS_GROUP_SUCCESS';
export const PATIENTS_GROUP_FAILURE = '@dasa-canal-medico/PATIENTS_GROUP_FAILURE';

// Reducer
export const initialState = {
  group: {
    data: [],
    error: {
      message: '',
      status: '',
    },
    isLoading: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PATIENTS_GROUP_REQUEST:
      return {
        ...state,
        group: {
          data: [],
          error: {
            message: '',
            status: '',
          },
          isLoading: true,
        },
      };
    case PATIENTS_GROUP_SUCCESS:
      return {
        ...state,
        group: {
          data: action.payload,
          error: {
            message: '',
            status: '',
          },
          isLoading: false,
        },
      };
    case PATIENTS_GROUP_FAILURE:
      return {
        ...state,
        group: {
          data: [],
          error: {
            message: action.payload.message,
            status: action.payload.status,
          },
          isLoading: false,
        },
      };
    default:
      return state;
  }
}

// Action Creators
export const getPatientsGroupRequest = simpleAction(PATIENTS_GROUP_REQUEST);
export const getPatientsGroupSuccess = simpleAction(PATIENTS_GROUP_SUCCESS);
export const getPatientsGroupFailure = simpleAction(PATIENTS_GROUP_FAILURE);

export const getPatientsAction = ({ search, origin, initialDate }) => async dispatch => {
  dispatch(getPatientsGroupRequest());

  try {
    const payload = await getPatients({ search, origin, initialDate });
    dispatch(getPatientsGroupSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(getPatientsGroupFailure(err));
  }
};
