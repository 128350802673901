import styled from 'styled-components';
import { Typography } from 'antd';

import { lpStyles } from '~/config';

export const TitleStyled = styled(Typography.Title)`
  && {
    font-family: ${lpStyles.fontFamily.default};
    font-size: ${lpStyles.fontSize.xlarge};
  }
`;
