import api from '~api';
import { PUT } from '~api/httpMethods';
import { apiDoctors } from '~config/envManager';

export const updateDiscussion = async id => {
  const { data } = await api({
    url: `${apiDoctors}/public/report/put-discussion/${id}`,
    method: PUT,
  });

  return data;
};
