import styled from 'styled-components';
import { Form, Select } from 'antd';
import { styles } from '~/config';

export const FormItem = styled(Form.Item)``;

export const SelectBase = styled(Select)`
  && {
    height: 48px;
    width: 100%;

    .ant-select-selection__rendered {
      margin-left: ${({ hasPreffixIcon }) => hasPreffixIcon && '44px'};
    }

    .ant-select-selection-selected-value {
      margin-right: 10px;
      color: black;
    }
    .ant-select-arrow-icon {
      transform: rotate(90deg);
      margin-top: 2px;
      width: 8px;
    }

    .ant-select-arrow-icon > svg {
      color: ${styles.colors.primary};
    }
  }
`;

export const Option = styled(Select.Option)`
  && {
    height: 48px;
  }
`;

export const PreffixIcon = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  position: absolute;
  margin: 0 16px;
  z-index: 1;
`;
