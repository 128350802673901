import * as Yup from 'yup';

import { onlyLetter, cellphone } from '~/utils/helpers/regex';

const FormSchema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .min(7, 'Mínimo de 7 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .matches(onlyLetter, 'Formato inválido')
    .required('Não se esqueça de preencher todos os campos'),
  email: Yup.string()
    .trim()
    .email('email inválido')
    .min(10, 'Mínimo de 10 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Não se esqueça de preencher todos os campos'),
  cellphone: Yup.string()
    .trim()
    .matches(cellphone, { message: 'Formato inválido' })
    .required('Não se esqueça de preencher todos os campos'),
  message: Yup.string()
    .trim()
    .min(20, 'Mínimo de 20 caracteres')
    .max(250, 'Limite de caracteres ultrapassado')
    .required('Não se esqueça de preencher todos os campos'),
});

export default FormSchema;
