import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import { Text } from './PanelHeader.styles';

const PanelHeader = ({ id, protocol, dataOfVisit, origin }) => (
  <Row type="flex" align="middle">
    <Col span={8}>
      <Text data-testid={`panel-header-protocol-${id}`}>{protocol}</Text>
    </Col>
    <Col span={5}>
      <Text data-testid={`panel-header-dataOfVisit-${id}`}>{dataOfVisit}</Text>
    </Col>
    <Col span={4} />
    <Col span={5}>
      <Text data-testid={`panel-header-name-${id}`}>{origin.name}</Text>
    </Col>
  </Row>
);

PanelHeader.propTypes = {
  id: PropTypes.number.isRequired,
  protocol: PropTypes.string.isRequired,
  dataOfVisit: PropTypes.string.isRequired,
  origin: PropTypes.objectOf({
    name: PropTypes.string,
  }).isRequired,
};

export default memo(PanelHeader);
