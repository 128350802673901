import React from 'react';
import PropTypes from 'prop-types';

import { Bold, IconCustom } from '~components/atoms';
import { svg } from '~assets';

import {
  Container,
  List,
  Item,
  Text,
  Time,
  Content,
  Actions,
  RemoveButton,
} from './AssistantsList.styles';

const AssistantsList = ({ testId, data }) => (
  <Container>
    <Bold>Nome Completo</Bold>
    <List
      testId={testId}
      dataSource={data}
      renderItem={item => (
        <Item>
          <Content>
            <Text isAssistantName>
              <Bold>{item.name}</Bold>
            </Text>
            <Text>{item.cpf}</Text>
            <Text>{item.email}</Text>
          </Content>
          <Actions>
            <Time dateTime={item.includedAt}>
              <Text>{item.includedAt}</Text>
            </Time>
            <Text>{item.cellphone}</Text>
            <RemoveButton hidden>
              <IconCustom name={svg.iconCloseDanger} size="24px" />
            </RemoveButton>
          </Actions>
        </Item>
      )}
    />
  </Container>
);

AssistantsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  testId: PropTypes.string,
};

AssistantsList.defaultProps = {
  testId: '',
};

export default AssistantsList;
