import styled from 'styled-components';

import { styles } from '~config';

export const Container = styled.div`
  border-bottom: 1px solid ${styles.colors.borderColorSecondary};
  padding-bottom: 14px;
  opacity: 0.75;

  p {
    font-weight: bold;
    margin: 0;
  }
`;
