import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Text, Bold } from '~components/atoms';

import { Container, Credits } from './CovidLetter.styles';

const CovidLetter = ({ testid }) => (
  <Container>
    <Text testid={testid}>
      <br />
      Caro colega,
      <br />
      <br />
      Valorizando a nossa parceria e a importância de mantê-los sempre informados com relação aos
      nossos serviços neste momento sensível, trazemos abaixo atualizações sobre os exames para
      detecção da COVID- 19.
      <br />
      <br />
      Disponibilizamos dois testes de detecção da COVID-19: RT-PCR (biologia molecular) e Sorologia
      IgM e IgG . Ambos passaram por rigoroso processo de validacao técnica e científica na Dasa. O
      RT-PCR , por exemplo, foi desenvolvido e validado em parceria com a USP, no início de
      fevereiro deste ano, seguindo as regras preconizadas pelo College of American Pathologists
      (CAP) , programa internacional de acreditação.
      <br />
      <br />
      Conheça as particularidades de cada um dos exames e suas indicações em nosso site:
      <a
        href="https://dasa.com.br/exames-covid-sorologia-pcr"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://dasa.com.br/exames-covid-sorologia-pcr
      </a>
      <br />
      <br />
      Para mais informações sobre o novo coronavírus, acompanhe os sites:
      <br />
      <a href="https://dasa.com.br/coronavirus" target="_blank" rel="noopener noreferrer">
        www.dasa.com.br/coronavirus
      </a>
      <br />
      <a href="https://dadoscoronavirus.dasa.com.br/" target="_blank" rel="noopener noreferrer">
        dadoscoronavirus.dasa.com.br
      </a>
      <br />
      <br />
      Atenciosamente,
      <br />
      <br />
      <Credits>
        <Bold>
          Gustavo Aguiar Campana
          <br />
          Diretor Médico
        </Bold>
      </Credits>
      <br />
    </Text>
  </Container>
);

CovidLetter.propTypes = {
  testid: PropTypes.string.isRequired,
};

export default memo(CovidLetter);
