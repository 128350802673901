import styled from 'styled-components';
import { Drawer as DrawerBase } from 'antd';

export const ButtonWrapper = styled.div`
  button {
    height: 32px !important;
    padding: 0 10px;

    span {
      font-size: 1rem;
      font-weight: bold;
    }
  }
`;

export const Drawer = styled(DrawerBase)`
  && {
    margin-top: 64px;

    .ant-drawer-content-wrapper {
      width: 100vw !important;
      overflow-y: scroll;

      .ant-drawer-content {
        min-height: min-content !important;
        padding-bottom: 100px;
      }

      .ant-drawer-body {
        padding: 0;
      }
    }
  }
`;
