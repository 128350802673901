import React from 'react';
import PropTypes from 'prop-types';
import { AccordionItem } from '~/components/molecules';

import { Container } from './Accordion.styles';

const Accordion = ({ items }) => {
  const renderItems = (itemList = []) => {
    return itemList.map(item => <AccordionItem title={item.title} content={item.content} />);
  };

  return <Container>{renderItems(items)}</Container>;
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node,
    }),
  ).isRequired,
};

export default React.memo(Accordion);
