import styled from 'styled-components';
import { Rate as RateBase } from 'antd';

import { styles } from '~/config';

export const Container = styled.div`
  strong {
    color: ${styles.colors.primary};
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: 136px;

    & + button {
      margin-left: 16px;
    }
  }

  #rating-back-btn {
    span {
      font-weight: normal;
    }
  }
`;

export const Rate = styled(RateBase)`
  && {
    margin: 30px 0;
    display: flex;
    justify-content: center;

    .anticon-star {
      svg {
        width: 40px;
        height: 40px;
      }
    }

    .ant-rate-star:not(:last-child) {
      margin-right: 34px;
    }
  }
`;

export const NotificationTitle = styled.span`
  color: ${styles.colors.white};
  font-weight: 700;
`;
