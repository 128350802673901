import styled from 'styled-components';
import { Form, Select } from 'antd';
import { styles, lpStyles } from '~/config';

export const FormItem = styled(Form.Item)`
  font-family: ${lpStyles.fontFamily.default};
  label {
    color: ${({ validateStatus }) => (validateStatus === 'error' ? lpStyles.colors.error : '')};
    &::after {
      content: '';
      margin: 0;
    }
  }
  &:hover {
    label {
      color: ${({ validateStatus }) => (validateStatus === 'error' ? lpStyles.colors.error : lpStyles.colors.primary)};
    }
  }
  .ant-form-item-control {
    .ant-select-lg .ant-select-selection__rendered {
      line-height: 54px;
    }
    .ant-select-selection__placeholder,
    .ant-select-selection-selected-value {
      font-size: 14px;
    }
    .ant-select-selection {
      padding-left: 4px;
      height: 56px;
      &:hover,
      &:focus {
        border-color: ${lpStyles.colors.primary};
        label {
          color: ${lpStyles.colors.primary};
        }
      }
    }
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      border-color: ${lpStyles.colors.primary};
    }
    &.has-error {
      .ant-select-selection {
        &:hover {
          border-color: ${lpStyles.colors.error};
          label {
            color: ${lpStyles.colors.error};
          }
        }
        &:focus {
          border-color: ${lpStyles.colors.error};
        }
      }
    }
  }
  .ant-col.ant-form-item-label {
    position: absolute;
    top: -6px;
    left: 12px;
    z-index: 9;
    background-color: ${lpStyles.colors.white};
    padding: 0 4px;
    width: auto;
    &:focus {
      label {
        color: ${lpStyles.colors.primary};
      }
    }
  }
`;

export const SelectBase = styled(Select)`
  && {
    height: 48px;
    width: 100%;

    .ant-select-selection__rendered {
      margin-left: ${({ hasPreffixIcon }) => hasPreffixIcon && '44px'};
    }

    .ant-select-selection-selected-value {
      margin-right: 10px;
      color: black;
    }

    .ant-select-arrow-icon > svg {
      color: ${styles.colors.primary};
    }
  }
`;

export const Option = styled(Select.Option)`
  && {
    height: 48px;
  }
`;

export const PreffixIcon = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  position: absolute;
  margin: 0 16px;
  z-index: 1;
`;
