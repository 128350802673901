export const toApi = (data = {}) => {
  const mapper = {
    id: data.id || '',
    role: data.role || '',
    fullName: data.fullName || '',
    cpf: data.cpf || '',
    congregation: data.conselho || '',
    congregationState: data.uf || '',
    congregationNumber: data.registerNumber || '',
    email: data.email || '',
    rg: data.rg || '',
    specialty: data.specialty || '',
    gender: data.gender || '',
    telephone: [
      {
        type: 'TELEFONE',
        ddd: data.telephone.slice(1, 3) || '',
        telephone: data.telephone.slice(4) || '',
      },
      {
        type: 'CELULAR' || '',
        ddd: data.cellphone.slice(1, 3) || '',
        telephone: data.cellphone.slice(4) || '',
      },
    ],
    address: [
      {
        cep: data.cep,
        city: data.city,
        address: data.address,
        addressComplement: data.complement,
        addressNumber: data.number,
        state: data.state,
      },
    ],
  };

  return mapper;
};
