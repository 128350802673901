import styled from 'styled-components';
import { Icon } from 'antd';

export const IconBase = styled(Icon)`
  && {
    color: ${({ color }) => color};
    font-size: ${({ size }) => size};

    svg {
      color: ${({ color }) => color};
      width: ${({ size }) => size};
      height: ${({ size }) => size};
    }
  }
`;
