import React from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { styles } from '~/config';
import { locale } from '~/utils/enums';
import { IconCustom } from '~/components/atoms';

import { FormItem, DatePickerBase, PreffixIcon } from './DatePicker.styles';

const DatePicker = ({
  name,
  label,
  error,
  value,
  testId,
  touched,
  onChange,
  placeholder,
  disabledDate,
  allowClear,
  defaultValue,
  setFieldValue,
  setFieldTouched,
}) => {
  const hasError = !!error;

  const onSelectDate = date => {
    onChange(date);
    setFieldValue(name, date);
    setFieldTouched(name);
  };

  return (
    <FormItem
      help={touched && hasError && error}
      validateStatus={touched && hasError ? 'error' : ''}
      label={label}
    >
      <PreffixIcon>
        <IconCustom name={svg.iconCalendarRegular} color={styles.colors.primary} size="18px" />
      </PreffixIcon>

      <DatePickerBase
        format="DD/MM/YYYY"
        value={value}
        locale={locale}
        onChange={onSelectDate}
        data-testid={testId}
        placeholder={placeholder}
        disabledDate={disabledDate}
        allowClear={allowClear}
        defaultValue={defaultValue}
        suffixIcon={<IconCustom name={svg.iconArrowRight} size="12px" />}
      />
    </FormItem>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  testId: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabledDate: PropTypes.string,
  allowClear: PropTypes.bool,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
};

DatePicker.defaultProps = {
  name: '',
  label: '',
  value: '',
  error: '',
  testId: 'date-picker',
  touched: false,
  placeholder: '',
  disabledDate: '',
  allowClear: false,
  defaultValue: undefined,
  onChange: () => undefined,
  setFieldValue: () => undefined,
  setFieldTouched: () => undefined,
};

export default DatePicker;
