import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

import Empty from '~components/molecules/Empty/Empty';
import { Skeleton } from '~components/atoms';

import { Collapse, Panel } from './ExamsHistory.styles';

import ListHeader from './ListHeader/ListHeader';
import PanelHeader from './PanelHeader/PanelHeader';
import PanelContent from './PanelContent/PanelContent';

const ExamsHistory = ({
  data,
  isLoading,
  onClickPDF,
  onClickImage,
  onClickPDFDownload,
  onClickUpdateExams,
  openShareReportModal,
  isLoadingImage,
  isLoadingPdf,
  isLoadingPdfDowload,
  isLoadingExams,
}) => {
  const [collapseItens, setCollapseItens] = useState([]);
  const hasData = !!data.length;

  const hasItem = id => collapseItens.includes(id);
  const addCollapseItem = id => setCollapseItens([...collapseItens, id]);
  const removeCollapseItem = id => setCollapseItens(collapseItens.filter(item => item !== id));

  const updateCollapseItens = (id, group) => () => {
    onClickUpdateExams(id, group);

    if (hasItem(id)) return removeCollapseItem(id);

    return addCollapseItem(id);
  };

  const toggleIcon = () => ({ isActive }) => <Icon type="right" rotate={isActive ? 270 : 90} />;

  if (isLoading === 'loading') return <Skeleton />;

  if (isLoading === 'fail') {
    return (
      <Empty testId="empty-exam" iconName="file-search" title="Não foram encontrados resultados…" />
    );
  }

  return (
    hasData && (
      <>
        <ListHeader />
        {data.map((group, index) => (
          <Collapse
            expandIconPosition="right"
            expandIcon={toggleIcon()}
            showBorder={hasItem(index)}
            onChange={updateCollapseItens(index, group)}
          >
            <Panel
              header={
                <PanelHeader
                  id={index}
                  protocol={group.protocol}
                  dataOfVisit={group.dataOfVisit}
                  origin={group.origin}
                />
              }
            >
              <PanelContent
                id={index}
                exams={group.exams}
                isLoading={isLoadingExams}
                isLoadingPdf={isLoadingPdf}
                isLoadingImage={isLoadingImage}
                isLoadingPdfDowload={isLoadingPdfDowload}
                openShareReportModal={openShareReportModal}
                onClickPDF={onClickPDF}
                onClickImage={onClickImage}
                onClickPDFDownload={onClickPDFDownload}
              />
            </Panel>
          </Collapse>
        ))}
      </>
    )
  );
};

ExamsHistory.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  isLoadingPdf: PropTypes.bool,
  isLoadingExams: PropTypes.bool,
  isLoadingImage: PropTypes.bool,
  isLoadingPdfDowload: PropTypes.bool,
  onClickPDF: PropTypes.func,
  onClickImage: PropTypes.func,
  onClickPDFDownload: PropTypes.func,
  openShareReportModal: PropTypes.func,
};

ExamsHistory.defaultProps = {
  data: [],
  isLoading: false,
  isLoadingPdf: false,
  isLoadingExams: false,
  isLoadingImage: false,
  isLoadingPdfDowload: false,
  onClickPDF: () => undefined,
  onClickImage: () => undefined,
  onClickPDFDownload: () => undefined,
  openShareReportModal: () => undefined,
};

export default ExamsHistory;
