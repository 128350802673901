import styled from 'styled-components';
import { Icon as IconBase } from '../../atoms';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ModalIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  background-color: ${({ bg }) => bg};
  margin-bottom: 45px;
`;

export const Icon = styled(IconBase)``;

export const ModalFooter = styled.footer`
  text-align: center;

  button {
    min-width: 128px;
  }
`;

export const WrapperText = styled.div`
  width: ${({ fontBig }) => (fontBig ? '60%' : '100%')};
  p {
    font-size: ${({ fontBig }) => (fontBig ? '2rem' : '1rem')};
    font-weight: ${({ fontBig }) => (fontBig ? 'bold' : 'normal')};
  }
`;
