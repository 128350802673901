import React from 'react';
import PropTypes from 'prop-types';

const Bold = ({ children }) => <b>{children}</b>;

Bold.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Bold;
