import React from 'react';
import PropTypes from 'prop-types';

import { Content, Header, Title, Description, WrapperImage, Image, Link } from './PageError.styles';

const PageError = ({ title, description, image, action }) => (
  <Content>
    <Header>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Header>
    <WrapperImage>
      <Image src={image} alt={title} title={title} />
    </WrapperImage>
    {action.url && <Link to={action.url}>{action.text}</Link>}
  </Content>
);

PageError.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  action: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
  }),
};

PageError.defaultProps = {
  action: {},
};

export default PageError;
