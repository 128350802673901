import { applyMiddleware, createStore, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import monitorReducersEnhancer from './enhancers/monitorReducer';
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'config', 'featureToggle', 'userTerms', 'assistant', 'notifications'],
};

function getMiddlewares() {
  return process.env.NODE_ENV === 'development' ? [logger, thunk] : [thunk];
}

function getComposedEnhancers(enhancers) {
  return process.env.NODE_ENV === 'development'
    ? composeWithDevTools(...enhancers)
    : compose(...enhancers);
}

export default function configureStore(preloadedState = {}) {
  const middlewares = getMiddlewares();
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
  const composedEnhancers = getComposedEnhancers(enhancers);

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, preloadedState, composedEnhancers);
  const persistor = persistStore(store);

  return { store, persistor };
}
