import { objectManager } from '~/utils/helpers';

const { objectMapper } = objectManager;

export default function mapper(Class) {
  return function decorator(data) {
    const instance = new Class(data);

    return objectMapper(instance, objectMapper);
  };
}
