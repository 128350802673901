import styled from 'styled-components';

import { Text as TextBase } from '~components/atoms';
import { styles } from '~config';

export const Title = styled(TextBase)`
  && {
    color: ${styles.colors.textSecondary};
    font-weight: bold;
    margin-top: 24px;
  }
`;

export const EmptyWrapper = styled.div`
  background-color: ${styles.colors.defaultBackground};

  p {
    max-width: 280px;
  }

  svg {
    width: 120px;
    height: 150px;
  }
`;

export const Wrapper = styled.div`
  background-color: ${styles.colors.defaultBackground};
`;

export const SkeletonWrapper = styled.div`
  background-color: ${styles.colors.defaultBackground};
`;
