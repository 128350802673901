import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { windowSize } from '~/utils/helpers';
import { FormWrapper } from '~/components/molecules';
import { Modal, Button } from '~/components/atoms';
import Form from './Form/Form';
import formSchema from './Form/Form.schema';
import { ButtonWrapper, Drawer } from './ClinicalDiscussion.styles';

const initialValues = {
  exam: '',
  message: '',
};

const ClinicalDiscussion = ({ exams, patient, testId, onSubmit }) => {
  const [active, setActive] = useState(false);
  const [visible, setVisible] = useState(false);

  const { loading, error } = useSelector(state => state.exams.createClinicalDiscussion);

  const size = windowSize();
  const isDesktop = size.width > 768;

  const hasError = () => !!error.status;

  const hasOnlyParentAC = options => {
    return options.length === 1 && !!options.find(item => JSON.parse(item.value).isAC);
  };

  const examsOptions = exams
    .filter(exam => (exams.length === 1 && exam.isParent ? exam.isParent : !exam.isParent))
    .map(exam => ({ id: exam.title, value: JSON.stringify(exam), text: exam.title }));

  const handleSubmit = async values => {
    await onSubmit(values);

    if (!hasError()) setVisible(false);
  };

  const textRows = isDesktop ? { minRows: 4, maxRows: 4 } : { minRows: 6, maxRows: 6 };

  const formContent = (
    <FormWrapper
      enableReinitialize
      isLoading={loading}
      isDesktop={isDesktop}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={({ exam, message }) => handleSubmit({ exam: JSON.parse(exam), message })}
      render={props => (
        <Form
          {...props}
          testId={`${testId}-form`}
          patient={patient}
          textRows={textRows}
          isLoading={loading}
          examsOptions={examsOptions}
          closeModal={() => setVisible(false)}
          hasOnlyParentAC={hasOnlyParentAC}
        />
      )}
    />
  );

  return (
    <>
      {isDesktop && (
        <Modal
          isClosable
          removePadding
          destroyOnClose
          width="400px"
          testId={`${testId}-modal`}
          isVisible={visible}
          maskClosable={false}
          onCancel={() => setVisible(false)}
        >
          {formContent}
        </Modal>
      )}

      {!isDesktop && (
        <Drawer destroyOnClose closable={false} visible={visible} mask={false}>
          {formContent}
        </Drawer>
      )}

      <ButtonWrapper onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
        <Button isOutline testId={`${testId}-button`} onClick={() => setVisible(true)}>
          Discussão clínica
        </Button>
      </ButtonWrapper>
    </>
  );
};

ClinicalDiscussion.propTypes = {
  testId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  exams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  patient: PropTypes.shape({
    dataOfVisit: PropTypes.string,
    patientName: PropTypes.string,
  }).isRequired,
};

export default ClinicalDiscussion;
