import React from 'react';
import PropTypes from 'prop-types';

import { Table, Skeleton } from '~components/atoms';

import { Container, WrapperSkeleton } from './AssistantsTable.styles';

const AssistantsTable = ({ testId, data, columns, total, isLoading, onClickItem }) => {
  const hasData = !!data.length;

  if (isLoading) {
    return (
      <WrapperSkeleton>
        <Skeleton rows={2} />
        <Skeleton rows={2} />
        <Skeleton rows={2} />
      </WrapperSkeleton>
    );
  }

  return (
    <Container>
      {hasData && (
        <Table
          className="assistants-table"
          testId={testId}
          data={data}
          columns={columns}
          total={total}
          isLoading={isLoading}
          onRowClick={onClickItem}
        />
      )}
    </Container>
  );
};

AssistantsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  testId: PropTypes.string,
  onClickItem: PropTypes.func,
};

AssistantsTable.defaultProps = {
  total: 0,
  testId: '',
  isLoading: false,
  onClickItem: () => undefined,
};

export default AssistantsTable;
