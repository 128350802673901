import React from 'react';
import { LPText } from '~/components/atoms';

export const contactInfo = {
  'Centro-Oeste': [
    { name: 'Atalaia', number: '(62) 3001-0647' },
    { name: 'Cedic Cedilab', number: '(65) 4003-5333' },
    { name: 'Exame', number: '(61) 2323-4301' },
  ],
  'Rio de Janeiro': [
    { name: 'Alta', number: '(21) 2672-7001' },
    { name: 'Lâmina (AC)', number: '(21) 2672-7001' },
    { name: 'Lâmina (RDI)', number: '(21) 2538-3825' },
    { name: 'Sérgio Franco (AC)', number: '(21) 2672-7001' },
    { name: 'Sérgio Franco (AP)', number: '(21) 2227-8025' },
    { name: 'Bronstein (AC)', number: '(21) 2672-7001' },
    { name: 'Bronstein (RDI)', number: '(21) 2672-8027' },
    { name: 'CDPI', number: '(21) 2672-7001' },
    { name: 'Multi-Imagem', number: '(21) 2672-7001' },
  ],
  'São Paulo': [
    { name: 'Delboni Auriemo (AC/RDI)', number: '(11) 3047-4484' },
    { name: 'Delboni Auriemo (AP)', number: '(11) 2630-3243' },
    { name: 'Salomão Zoppi', number: '(11) 5904-6838' },
    { name: 'Lavoisier', number: '(11) 2250-7676' },
    { name: 'Cytolab', number: '(11) 2250-7677' },
    { name: 'Previlab', number: '(11) 3429-6900' },
    { name: 'Oswaldo Cruz', number: '(12) 3946-3711' },
    { name: 'Valeclin', number: '(12) 2012-2000' },
    { name: 'Deliberato', number: '(11) 4646-7444' },
    { name: 'Gene One', number: '(11) 5904-1234' },
    { name: 'Vital Brasil', number: '(11) 3128-4544' },
    { name: 'Alta', number: '(11) 3003-9999' },
  ],
  Nordeste: [
    { name: 'LabPasteur', number: '(85) 3003-6063' },
    { name: 'Image', number: '(71) 4004-0107' },
    { name: 'Leme', number: '(71) 3338-8555' },
    { name: 'Gaspar', number: '(71) 3338-8555' },
    { name: 'Cerpe', number: '(81) 3416-9922' },
  ],
};

export const accordionItems = [
  {
    title: 'Problemas com a conta',
    content: (
      <LPText type="secondary">
        Contato por email:
        <br />
        <a href="mailto:suporte.canalmedico@dasa.com.br">suporte.canalmedico@dasa.com.br</a>
      </LPText>
    ),
  },
  {
    title: 'Informações sobre exames',
    content: <LPText>Entre em contato com o laboratório responsável</LPText>,
  },
];
