import React from 'react';
import PropTypes from 'prop-types';

import { Skeleton, IconCustom } from '~components/atoms';
import { styles } from '~config';
import examsIconSvg from '~assets/sgvs/exames-realizados.svg';

import { Wrapper, Item, Title, Text, Bold, IconWrapper } from './PatientInfo.styles';

const PatientInfo = ({ data, isLoading }) => {
  return (
    <Wrapper gutter={24} align="bottom">
      {isLoading ? (
        <Skeleton showAvatar rows={2} />
      ) : (
        <>
          <Item md={22} xs={{ span: 24 }}>
            <IconWrapper>
              <IconCustom name={examsIconSvg} color={styles.colors.primary} size="24px" />
            </IconWrapper>
            <Title htmlType="h3">Detalhes do Paciente</Title>
            <Text>
              <Bold className="is-mobile-hidden">Nome:</Bold>{' '}
              <span className="is-mobile-bold is-span">{data.name}</span>
            </Text>
            <Text>
              <Bold>Data de nascimento:</Bold>
              <span className="is-span"> {data.birthdate}</span>
            </Text>
          </Item>
        </>
      )}
    </Wrapper>
  );
};

PatientInfo.propTypes = {
  openCreateReqModal: PropTypes.func,
  data: PropTypes.shape({
    name: PropTypes.string,
    gender: PropTypes.string,
    birthdate: PropTypes.string,
    cpf: PropTypes.string,
    email: PropTypes.string,
  }),
  viewOtherExamsStatus: PropTypes.shape({
    requestQuantity: PropTypes.number,
  }),
  visibilitOfMenu: PropTypes.bool,
  isLoading: PropTypes.bool,
  isAssistant: PropTypes.bool,
  isNurse: PropTypes.bool,
  isDropdownHidden: PropTypes.bool,
};

PatientInfo.defaultProps = {
  openCreateReqModal: () => undefined,
  data: {
    name: '',
    gender: '',
    birthdate: '',
    cpf: '',
    email: '',
  },
  viewOtherExamsStatus: {
    requestQuantity: 0,
  },
  visibilitOfMenu: false,
  isLoading: false,
  isAssistant: false,
  isNurse: false,
  isDropdownHidden: false,
};

export default PatientInfo;
