import { objectManager } from '~/utils/helpers';
import { assistant } from '~/utils/enums';

const { isEmpty, isArray } = objectManager;

export const fromApi = (data = []) => {
  const assistants = data || [];

  const mapperTelephone = phone => {
    if (isEmpty(phone) || !isArray(phone)) return '-';

    const number = phone.find(item => item.type === 'TELEFONE') || {};

    if (isEmpty(number.ddd) || isEmpty(number.number)) return '-';

    return `(${number.ddd.trim()}) ${number.number.trim().slice(0, 4)}-${number.number
      .trim()
      .slice(4)}`;
  };

  const mapperCellphone = phone => {
    if (isEmpty(phone) || !isArray(phone)) return '-';

    const number = phone.find(item => item.type === 'CELULAR') || {};

    if (isEmpty(number.ddd) || isEmpty(number.number)) return '-';

    return `(${number.ddd.trim()}) ${number.number.trim().slice(0, 5)}-${number.number
      .trim()
      .slice(5)}`;
  };

  const mapperAssistants = assistants.map(item => ({
    id: item.id || '',
    cpf: item.cpf || '-',
    name: item.name || assistant.pending,
    email: item.email || '-',
    status: item.status || '',
    includedAt: item.creationDate || '',
    telephone: mapperTelephone(item.telephones),
    cellphone: mapperCellphone(item.telephones),
  }));

  return mapperAssistants;
};
