import React, { Suspense } from 'react';
import { ApmRoute } from '@elastic/apm-rum-react';
import { HashRouter as Router, Redirect, Switch } from 'react-router-dom';

import webRouter from './config/webRouter';

import {
  PrivateRoute,
  AdminPrivateRoute,
  AssistantPrivateRoute,
  VisitorPrivateRouter,
  NursePrivateRoute,
} from './router';

import {
  AssistantRegister,
  DiscussionConfirmation,
  Login,
  Register,
  RequestPassword,
  ResetPassword,
  NotFound,
  InternalServerError,
  PatientViewExamsApprove,
  PatientViewExamsConfirm,
  PatientViewExamsReject,
  PatientViewExamsError,
  LoadingPage,
} from './pages/public';

import {
  Patients,
  PatientDetails,
  AssistantHome,
  AssistantPatientDetails,
  Assistants,
  NurseHome,
  NursePatientDetails,
  AdminHome as AdminHomePrivate,
  AdminHealthUsers,
  AdminOperatorUsers,
  AdminUserDetails,
  AdminExams,
  AdminPatientDetails,
  VisitorApprovedUsers,
  VisitorHome,
  VisitorUserDetails,
  VisitorOperatorUsers
} from './pages/private';

const AppRouter = () => (
  <Router hashType="noslash">
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        <Redirect exact from="/login" to={webRouter.LOGIN} />

        <ApmRoute exact path={webRouter.LOGIN} component={Login} />
        <ApmRoute exact path={webRouter.REGISTER} component={Register} />
        <ApmRoute path={webRouter.FORGOT_PASSWORD} component={RequestPassword} />
        <ApmRoute path={webRouter.RESET_PASSWORD} component={ResetPassword} />

        <ApmRoute exact path={webRouter.ASSISTANT_REGISTER} component={AssistantRegister} />

        <ApmRoute path={webRouter.PATIENT_VIEW_EXAMS_APPROVE} component={PatientViewExamsApprove} />
        <ApmRoute
          path={webRouter.PATIENT_VIEW_EXAMS_CONFIRMATION}
          component={PatientViewExamsConfirm}
        />
        <ApmRoute path={webRouter.PATIENT_VIEW_EXAMS_REJECT} component={PatientViewExamsReject} />
        <ApmRoute path={webRouter.PATIENT_VIEW_EXAMS_ERROR} component={PatientViewExamsError} />

        <ApmRoute path={webRouter.DISCUSSION_CONFIRMATION} component={DiscussionConfirmation} />

        <PrivateRoute exact path={webRouter.PATIENTS} component={Patients} />
        <PrivateRoute path={webRouter.PATIENT_DETAILS} component={PatientDetails} />
        <PrivateRoute path={webRouter.ASSISTANTS} component={Assistants} />

        <AssistantPrivateRoute
          exact
          path={webRouter.ASSISTANT_HOME_PRIVATE}
          component={AssistantHome}
        />
        <AssistantPrivateRoute
          path={webRouter.ASSISTANT_PATIENT_DETAILS}
          component={AssistantPatientDetails}
        />

        <NursePrivateRoute exact path={webRouter.NURSE_HOME_PRIVATE} component={NurseHome} />
        <NursePrivateRoute
          exact
          path={webRouter.NURSE_PATIENT_DETAILS}
          component={NursePatientDetails}
        />

        <VisitorPrivateRouter exact path={webRouter.VISITOR_HOME_PRIVATE} component={VisitorHome} />
        <VisitorPrivateRouter path={webRouter.VISITOR_USER_DETAILS} component={VisitorUserDetails} />
        <VisitorPrivateRouter path={webRouter.VISITOR_HEALTH_USERS} component={VisitorApprovedUsers}/>
        {/*using admin operator component for visitor operator (it's the same)*/} 
        <VisitorPrivateRouter path={webRouter.VISITOR_OPERATOR_USERS} component={VisitorOperatorUsers}/>

        <AdminPrivateRoute path={webRouter.ADMIN_USER_DETAILS} component={AdminUserDetails} />
        <AdminPrivateRoute path={webRouter.ADMIN_HEALTH_USERS} component={AdminHealthUsers} />
        <AdminPrivateRoute path={webRouter.ADMIN_OPERATOR_USERS} component={AdminOperatorUsers} />
        <AdminPrivateRoute path={webRouter.ADMIN_PATIENT_DETAILS} component={AdminPatientDetails} />
        <AdminPrivateRoute path={webRouter.ADMIN_EXAMS} component={AdminExams} />
        <AdminPrivateRoute path={webRouter.ADMIN_HOME_PRIVATE} component={AdminHomePrivate} />

        <ApmRoute path={webRouter.SERVER_ERROR} component={InternalServerError} />
        <ApmRoute component={NotFound} />
      </Switch>
    </Suspense>
  </Router>
);

export default AppRouter;
