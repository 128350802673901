import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  Text,
  Title,
  ContentUser,
  ContentExam,
} from './CovidExamsList.styles';

import PropTypes from 'prop-types';

import { TableCovidExamsResults, CovidPatientNameSearch } from './CovidResultsTable.styles';
import Empty from '../Empty/Empty';

const CovidResultsTable = ({ data, columns, total, isLoading, isMobileScreen }) => {
  const hasData = !!data.length;
  const [resultsData, setResultsData] = useState(data);
  useEffect(() => {
    setResultsData(data);
  }, [data])
  return (
    <>
      {hasData ? (
        <>
          <Title htmlType="h3">{resultsData.length} Resultados de exames Covid-19</Title>
          <CovidPatientNameSearch
            allowClear
            placeholder="Pesquise o nome do paciente"
            options={data.map(patient => patient.nome)}
            filterOption={(inputValue, option) =>
              // eslint-disable-next-line implicit-arrow-linebreak
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={nameSearch => {
              if (nameSearch === undefined) {
                setResultsData(data);
              } else {
                // eslint-disable-next-line
                const filtered = data.filter(person => {
                  if (person.nome.toUpperCase().includes(nameSearch.toUpperCase())) {
                    return person;
                  }
                });
                setResultsData(filtered);
              }
              data.filter(person => person.nome.includes(nameSearch));
            }}
          />
          {isMobileScreen
            ? (
              <>
                <List
                  dataSource={resultsData}
                  renderItem={item => (
                    <ListItem>
                      <ContentUser>
                        <Title className="name-hidden">{item.nome}</Title>
                        <Text>Resultado: {item.valor}</Text>
                        <Text>Protocolo: {item.codigoDestino}</Text>
                      </ContentUser>
                      <ContentExam>
                        <Text>{item.nomeLaudo}</Text>
                      </ContentExam>
                    </ListItem>
                  )}
                />
              </>
            ) : (
              <TableCovidExamsResults
                dataSource={resultsData}
                total={resultsData.length}
                testId={data.requestID}
                pagination={{
                  pagination: {
                    current: 1,
                    pageSize: 10,
                    total,
                  },
                }}
                loading={isLoading}
                columns={columns}
              />
            )}
        </>
      ) : (
        <Empty
          testId="empty-exams"
          iconName="file-search"
          title="Não foram encontrados resultados para a sua pesquisa…"
          description="Verifique se o que foi digitado está correto."
        />
      )}
    </>
  );
};

CovidResultsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CovidResultsTable.defaultProps = {
  data: [],
  total: 0,
  isLoading: false,
};

export default CovidResultsTable;
