export default [
  {
    testId: 'nam-contact-delboni',
    name: 'Delboni',
    contacts: [{ text: '(11)3047-4484', value: '1130474484' }],
  },
  {
    testId: 'nam-contact-lavoisier',
    name: 'Lavoisier',
    contacts: [{ text: '(11)2250-7676', value: '1122507676' }],
  },
  {
    testId: 'nam-contact-previlab',
    name: 'Previlab',
    contacts: [{ text: '(19)3123-0800', value: '1931230800' }],
  },
  {
    testId: 'nam-contact-labsim',
    name: 'Labsim',
    contacts: [{ text: '(11)2250-7676', value: '1122507676' }],
  },
  {
    testId: 'nam-contact-labpasteur',
    name: 'Labpasteur',
    contacts: [
      { text: '(85)3462-6020', value: '8534626020' },
      { text: '(85)9156-0336', value: '8591560336' },
    ],
  },
  {
    testId: 'nam-contact-alvaro',
    name: 'Álvaro',
    contacts: [{ text: '(45)3220-8090', value: '4532208090' }],
  },
  {
    testId: 'nam-contact-exame',
    name: 'Exame',
    contacts: [{ text: '(61)2323-4301', value: '6123234301' }],
  },
  {
    testId: 'nam-contact-cedilab',
    name: 'Cedilab',
    contacts: [{ text: '(65)4003-5333', value: '6540035333' }],
  },
  {
    testId: 'nam-contact-atalaia',
    name: 'Atalaia',
    contacts: [{ text: '(62)3001-0647', value: '6230010647' }],
  },
  {
    testId: 'nam-contact-bronstein',
    name: 'Bronstein',
    contacts: [{ text: '(21)2227-8027', value: '2122278027' }],
  },
  {
    testId: 'nam-contact-lamina',
    name: 'Lâmina',
    contacts: [{ text: '(21)2227-8090', value: '2122278090' }],
  },
  {
    testId: 'nam-contact-sergio-franco',
    name: 'Sérgio Franco',
    contacts: [
      { text: '(21)2672-7001', value: '2126727001', info: 'Análises Clínicas' },
      { text: '(21)2227-8025', value: '2122278025', info: 'Anatomia Patológica' },
    ],
  },
  {
    testId: 'nam-contact-alta-sp',
    name: 'Alta SP',
    contacts: [{ text: '(11)3003-9999', value: '1130039999' }],
  },
  {
    testId: 'nam-contact-alta-rj',
    name: 'Alta RJ',
    contacts: [{ text: '(21)3003-9999', value: '2130039999' }],
  },
  {
    testId: 'nam-contact-frishmann',
    name: 'Frishmann',
    contacts: [{ text: '(41)98427-7944', value: '41984277944' }],
  },
];
