export const toApi = (data = {}) => ({
  initialPerPage: data.initialPerPage || 0,
  totalPerPage: data.totalPerPage || 50,
  searchType: data.searchType || undefined,
  status: (data.status != 'aprovado' && data.status != 'reprovado') ? 'pendente' : data.status,
  active: data.active || undefined,
  fullName: data.fullName || undefined,
  cpf: data.cpf || undefined,
  email: data.email || undefined,
  role: data.role || undefined,
  congregationState: (data.congregationState && data.congregationState.toUpperCase()) || undefined,
  congregation: (data.congregation && data.congregation.toUpperCase()) || undefined,
  congregationNumber: data.congregationNumber || undefined,
});

export const fromApi = (data = {}) => {
  const users = data.users || [];

  const mapCRM = ({ congregationState, congregation, congregationNumber }) => {
    if (congregationState && congregation && congregationNumber) {
      return `${congregationState}-${congregation}-${congregationNumber}`;
    }

    return '';
  };

  const mapperUsers = users.map(item => ({
    id: item.userId || '',
    cpf: item.cpf || '',
    name: item.fullName || '',
    email: item.email || '',
    congregation: mapCRM(item),
    congregationType: item.congregation || '',
    congregationState: item.congregationState || '',
    congregationNumber: item.congregationNumber || '',
    role: item.role || '',
    status: item.status || '',
    active: item.active === 'ATIVO',
    isVisible: true,
    documentFrontUrl: item.documentFrontUrl,
    documentBackUrl: item.documentBackUrl
  }));

  return {
    group: mapperUsers,
    total: data.total,
  };
};
