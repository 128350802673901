import styled from 'styled-components';
import { List as ListBase, Badge as BadgeBase, Icon as IconBase } from 'antd';

import { Text as TextBase } from '~components/atoms';
import { mediaQuery } from '~/utils/helpers';
import { styles } from '~config';

export const List = styled(ListBase)`
  && {
    height: 62vh;
    overflow-y: auto;
    width: 100%;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb:hover {
      background: ${styles.colors.scrollBarColor};
      border-radius: 30px;
    }
  }
`;

export const Wrapper = styled.section`
  height: 75vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: ${({ isApproved }) => (isApproved ? '430px' : '457px')};
  background-color: ${styles.colors.white};
  border-radius: 6px;
`;

export const WrapperHeader = styled.header`
  background: #ffffff;
  padding: ${({ isExam }) => (isExam ? '24px 24px 0' : '24px 16px 0')};
`;

export const WrapperTitle = styled.h4`
  color: ${styles.colors.textSecondary};
  font-size: 1rem;
`;

export const ListItem = styled(ListBase.Item)`
  && {
    display: flex;
    align-items: center;
    padding: 0 24px;
    height: 62px;
    border: none;

    .child-exam-action {
      display: none;
      width: 350px;
    }

    &:hover {
      background: ${styles.colors.listItemLine};

      div {
        border: none;
      }

      .child-exam-action {
        display: contents;
      }

      .child-exam-status {
        display: none;
      }
    }
  }
`;

export const Title = styled(TextBase)`
  margin: 20px 0 0;
  font-weight: bold;
  color: ${styles.colors.textSecondary};
`;

export const Action = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;

  ${mediaQuery.inDesktop(`
    display: none;
  `)};
`;

export const EmptyWrapper = styled.div`
  position: relative;
  top: -40px;

  p {
    max-width: 280px;
  }

  svg {
    width: 120px;
    height: 150px;
  }
`;

export const Text = styled(TextBase)`
  font-weight: ${({ isParent }) => isParent && 'bold'};
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 32vw;
`;

export const TotalExams = styled(TextBase)`
  && {
    display: flex;
    width: ${({ isEvo }) => (isEvo ? '140px' : '100px')};
    font-weight: bold;
    margin: 0;
  }
`;

export const Header = styled.div`
  height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0;
  border-bottom: 1px solid ${styles.colors.listItemLine};
  color: ${({ firstHeader }) => firstHeader && `${styles.colors.primary}`};

  &:hover {
    padding: 4px 0;
  }
`;

export const Badge = styled(BadgeBase)`
  && {
    margin-bottom: 4px;
    width: 140px;

    .ant-badge-status-dot {
      width: 17px;
      height: 17px;
    }

    .ant-badge-status-text {
      font-size: 1rem;
    }
  }
`;

export const ExamContent = styled.div`
  margin-left: ${({ isParent }) => !isParent && '20px'};
  height: ${({ isParent }) => isParent && '62px'};
  width: 100%;
  ${mediaQuery.inMobile(`
    padding-bottom: 10px!important;
  `)}
`;

export const WrapperExams = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-left: 1px solid ${styles.colors.listItemLine};
`;

export const WrapperSkeleton = styled.div`
  padding: 0 24px;
`;

export const WrapperActions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 210px;
`;

export const WrapperEmpty = styled.div`
  margin-bottom: 20px;
  max-width: 50vw;
`;

export const WrapperTotalContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Icon = styled(IconBase)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
