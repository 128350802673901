import styled from 'styled-components';
import { mediaQuery } from '~/utils/helpers';

export const Container = styled.div`
  width: 35%;
  margin: 24px 0 32px;

  .ant-select-selection-selected-value {
    max-width: 86%;
  }

  ${mediaQuery.inMobile(`
    width: 100%;
    max-width: 92vw;
  `)};
`;
