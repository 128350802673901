export const cellphone = /(^$)|(\(\d{2,3}\)\d{5}-\d{4})/;
export const telephone = /(^$)|(\(\d{2,3}\)\d{4,5}-\d{4})/;
export const RG = /\d{2}\.\d{3}\.\d{3}-\d{1,2}/;
export const CPF = /\d{3}\.\d{3}\.\d{3}-\d{2}/;
export const CEP = /\d{5}-\d{3}/;
export const CRM = /\w{2}-\w{3}-\d/;
export const onlyNumber = /^[0-9 ]+$/;
export const onlyText = /^[áãâàéêÁÃÂÀÉÊíóõôúçÍÓÕÔÚÇA-Za-z0-9-//,.&* ]+$/;
export const onlyLetter = /^[áãâàéêÁÃÂÀÉÊíóõôúçÍÓÕÔÚÇA-Za-z ]+$/;
export const hasSpecialCharacters = /[!@#$%^&*(),.?":{}|<>]/g;

export default {
  cellphone,
  telephone,
  RG,
  CPF,
  CEP,
  CRM,
  onlyNumber,
  onlyText,
  onlyLetter,
  hasSpecialCharacters,
};
