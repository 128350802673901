import axios from 'axios';

import { apiAdmins, apiUsers, apikey as apiKey } from '~/config/envManager';

export const requestPasswordReset = async params => {
  const { data } = await axios({
    url: `${apiUsers}/public/forgot-password`,
    method: 'POST',
    data: params,
    params: { apiKey },
  });

  return data;
};

export const verifyToken = async headers => {
  const { data } = await axios({
    url: `${apiUsers}/public/validate-reset-password-token`,
    method: 'GET',
    headers,
    params: { apiKey },
  });

  return data;
};

export const generateNewPassword = async (params, headersConfig) => {
  const data = await axios({
    url: `${apiUsers}/v2/public/reset-password`,
    method: 'PUT',
    data: params,
    headers: {
      ...headersConfig,
      apiKey,
    },
  });

  return data;
};

export const adminRequestPasswordReset = async params => {
  const { data } = await axios({
    url: `${apiAdmins}/public/forgot-password`,
    method: 'POST',
    data: params,
    params: { apiKey },
  });

  return data;
};
