import produce from 'immer';

import { shareReport } from '~/api/services/exams.service';
import { simpleAction, errorMessage } from '~/utils/helpers';

export const SHARE_REPORT_REQUEST = '@dasa-canal-medico/SHARE_REPORT_REQUEST';
export const SHARE_REPORT_SUCCESS = '@dasa-canal-medico/SHARE_REPORT_SUCCESS';
export const SHARE_REPORT_FAILURE = '@dasa-canal-medico/SHARE_REPORT_FAILURE';

export const SET_SHARE_REPORT = '@dasa-canal-medico/SET_SHARE_REPORT';

// Reducer
const initialState = {
  data: {},
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_SHARE_REPORT: {
        draft.data = action.payload;
        break;
      }
      case SHARE_REPORT_REQUEST: {
        draft.isLoading = true;
        draft.error = {
          status: '',
        };
        break;
      }
      case SHARE_REPORT_SUCCESS: {
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      }
      case SHARE_REPORT_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.isLoading = false;
        break;
      }
      default:
    }
  });
}

// Action Creators
const setShareReport = simpleAction(SET_SHARE_REPORT);

const shareReportRequest = simpleAction(SHARE_REPORT_REQUEST);
const shareReportSuccess = simpleAction(SHARE_REPORT_SUCCESS);
const shareReportFailure = simpleAction(SHARE_REPORT_FAILURE);

export const setShareReportAction = item => async dispatch => {
  return dispatch(setShareReport(item));
};

export const shareReportAction = params => async dispatch => {
  dispatch(shareReportRequest());

  try {
    const payload = await shareReport(params);

    return dispatch(shareReportSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);

    return dispatch(shareReportFailure(err));
  }
};
