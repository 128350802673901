import { validateExamViewToken } from '~/api/services/patients.service';
import { simpleAction } from '~/utils/helpers';

export const EXAMS_VIEW_TOKEN_VALID_REQUEST = '@dasa-canal-medico/EXAMS_VIEW_TOKEN_VALID_REQUEST';
export const EXAMS_VIEW_TOKEN_VALID_SUCCESS = '@dasa-canal-medico/EXAMS_VIEW_TOKEN_VALID_SUCCESS';
export const EXAMS_VIEW_TOKEN_VALID_FAILURE = '@dasa-canal-medico/EXAMS_VIEW_TOKEN_VALID_FAILURE';

// Reducer
export const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EXAMS_VIEW_TOKEN_VALID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EXAMS_VIEW_TOKEN_VALID_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: action.payload,
        isLoading: false,
      };
    case EXAMS_VIEW_TOKEN_VALID_FAILURE:
      return {
        ...state,
        data: {
          validToken: action.payload.validToken,
        },
        error: {
          message: action.payload.message,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}

// Action Creators
export const validateExamViewTokenRequest = simpleAction(EXAMS_VIEW_TOKEN_VALID_REQUEST);
export const validateExamViewTokenSuccess = simpleAction(EXAMS_VIEW_TOKEN_VALID_SUCCESS);
export const validateExamViewTokenFailure = simpleAction(EXAMS_VIEW_TOKEN_VALID_FAILURE);

export const validateExamViewTokenAction = params => async dispatch => {
  dispatch(validateExamViewTokenRequest());

  try {
    const payload = await validateExamViewToken(params);

    dispatch(validateExamViewTokenSuccess(payload));
  } catch (error) {
    const { data } = error.response;

    dispatch(validateExamViewTokenFailure(data));
  }
};
