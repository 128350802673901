import { webRouter } from '~/config';

export default {
  [webRouter.ADMIN_USER_DETAILS]: [
    {
      id: 'admin-user',
      label: 'Lista de usuários cadastrados',
      url: webRouter.ADMIN_HEALTH_USERS
    },
    {
      id: 'admin-user-details',
      label: 'Detalhe do usuário',
    },
  ],
  [webRouter.ADMIN_PATIENT_DETAILS]: [
    {
      id: 'exam-detail',
      label: 'Exames realizados',
      url: webRouter.ADMIN_EXAMS,
    },
    {
      id: 'exam-detail-protocol',
      label: 'Detalhe do protocolo',
    },
  ],
  [webRouter.VISITOR_USER_DETAILS]: [
    {
      id: 'visitor-user',
      label: 'Lista de usuários cadastrados',
      url: webRouter.VISITOR_HEALTH_USERS,
    },
    {
      id: 'visitor-user-details',
      label: 'Detalhe do usuário',
    },
  ],
  [webRouter.ASSISTANT_PATIENT_DETAILS]: [
    {
      id: 'assisant-patient-list',
      label: 'Lista de pacientes',
      url: webRouter.ASSISTANT_HOME_PRIVATE,
    },
    {
      id: 'assistant-patient-detail',
      label: 'Detalhe do paciente',
    },
  ],
  [webRouter.PATIENT_DETAILS]: [
    {
      id: 'exam-detail',
      label: 'Meus pacientes',
      url: webRouter.PATIENTS,
    },
    {
      id: 'exam-detail-protocol',
      label: 'Detalhe do paciente',
    },
  ],

  default: [],
};
