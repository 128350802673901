import React from 'react';
import PropTypes from 'prop-types';

import { IconCustom, Tooltip } from '~components/atoms';
import { svg } from '~assets';
import { styles } from '~config';

import { Actions, Button } from './ExamsActions.styles';

const ExamsActions = ({
  item,
  report,
  isAdmin,
  className,
  isLoadingImage,
  isLoadingPdf,
  isLoadingPdfDowload,
  onClickPDF,
  onClickImage,
  onClickPDFDownload,
  openShareReportModal,
}) => (
  <Actions className={className}>
    <Tooltip text="Compartilhar">
      <Button
        disabled={!item.isParent || !item.isComplete || item.isRDI}
        type="link"
        hidden={isAdmin}
        color={styles.colors.default}
        onClick={() => openShareReportModal(item)}
      >
        <IconCustom
          name={
            !item.isRDI && item.isParent && item.isComplete
              ? svg.iconShareActive
              : svg.iconShareInactive
          }
          size="20px"
        />
      </Button>
    </Tooltip>
    <Tooltip text="Imagem">
      <Button
        disabled={
          !report.image.isActive || isLoadingImage || (!report.image.isActive && item.isParent)
        }
        type="link"
        color={styles.colors.default}
        onClick={() => onClickImage(item)}
      >
        {isLoadingImage ? (
          <IconCustom name={svg.iconLoading} size="20px" className="rotate" />
        ) : (
          <IconCustom
            name={
              !report.image.isActive || isLoadingImage || (!report.image.isActive && item.isParent)
                ? svg.iconMovieSecondary
                : svg.iconMovie
            }
            size="16px"
          />
        )}
      </Button>
    </Tooltip>
    <Tooltip text="Laudo">
      <Button
        disabled={!report.pdf.isActive || isLoadingPdf}
        type="link"
        color={styles.colors.default}
        onClick={() => onClickPDF(item)}
      >
        {isLoadingPdf ? (
          <IconCustom name={svg.iconLoading} size="20px" className="rotate" />
        ) : (
          <IconCustom name={report.pdf.isActive ? svg.iconPdf : svg.iconPdfSecondary} size="22px" />
        )}
      </Button>
    </Tooltip>
    <Tooltip text="Download">
      <Button
        onClick={() => onClickPDFDownload(item)}
        disabled={!report.pdf.isActive || isLoadingPdfDowload}
        type="link"
      >
        {isLoadingPdfDowload ? (
          <IconCustom name={svg.iconLoading} size="20px" className="rotate" />
        ) : (
          <IconCustom
            name={report.pdf.isActive ? svg.iconDowload : svg.iconDowloadSecondary}
            size="20px"
          />
        )}
      </Button>
    </Tooltip>
  </Actions>
);

ExamsActions.propTypes = {
  onClickPDF: PropTypes.func,
  onClickImage: PropTypes.func,
  onClickPDFDownload: PropTypes.func,
  openShareReportModal: PropTypes.func,
  item: PropTypes.shape({
    isParent: PropTypes.bool,
    isRDI: PropTypes.bool,
    isComplete: PropTypes.bool,
  }),
  report: PropTypes.shape({
    pdf: PropTypes.shape({
      isActive: PropTypes.bool,
    }),
    image: PropTypes.shape({
      isActive: PropTypes.bool,
    }),
  }),
  isAdmin: PropTypes.bool,
  className: PropTypes.string,
  isLoadingPdf: PropTypes.bool,
  isLoadingImage: PropTypes.bool,
  isLoadingPdfDowload: PropTypes.bool,
};

ExamsActions.defaultProps = {
  onClickPDF: () => undefined,
  onClickImage: () => undefined,
  onClickPDFDownload: () => undefined,
  openShareReportModal: () => undefined,
  item: {
    isParent: false,
    isComplete: false,
  },
  report: {
    pdf: {
      isActive: false,
    },
    image: {
      isActive: false,
    },
  },
  isAdmin: false,
  className: '',
  isLoadingPdf: false,
  isLoadingImage: false,
  isLoadingPdfDowload: false,
};

export default ExamsActions;
