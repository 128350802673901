import produce from 'immer';

import { getUseTerms, updateAcceptUseTerms } from '~/api/services/users.service';
import { simpleAction, errorMessage, makeInitialState } from '~/utils/helpers';

export const USE_TERMS_REQUEST = '@dasa-canal-medico/USE_TERMS_REQUEST';
export const USE_TERMS_SUCCESS = '@dasa-canal-medico/USE_TERMS_SUCCESS';
export const USE_TERMS_FAILURE = '@dasa-canal-medico/USE_TERMS_FAILURE';

export const USE_TERMS_ACCEPT_REQUEST = '@dasa-canal-medico/USE_TERMS_ACCEPT_REQUEST';
export const USE_TERMS_ACCEPT_SUCCESS = '@dasa-canal-medico/ACCEPT_USE_TERMS_SUCCESS';
export const USE_TERMS_ACCEPT_FAILURE = '@dasa-canal-medico/ACCEPT_USE_TERMS_FAILURE';

// Reducer
export const initialState = makeInitialState({ data: { termoAceite: false } });

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case USE_TERMS_REQUEST: {
        draft.loading = true;
        break;
      }
      case USE_TERMS_SUCCESS: {
        draft.data = action.payload;
        draft.loading = false;
        break;
      }
      case USE_TERMS_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

// Action Creators
export const getUseTermsRequest = simpleAction(USE_TERMS_REQUEST);
export const getUseTermsSuccess = simpleAction(USE_TERMS_SUCCESS);
export const getUseTermsFailure = simpleAction(USE_TERMS_FAILURE);

const updateUseTermsRequest = simpleAction(USE_TERMS_ACCEPT_REQUEST);
const updateUseTermsSuccess = simpleAction(USE_TERMS_ACCEPT_SUCCESS);
const updateUseTermsFailure = simpleAction(USE_TERMS_ACCEPT_FAILURE);

export const getUseTermsAction = () => async dispatch => {
  dispatch(getUseTermsRequest());

  try {
    const payload = await getUseTerms();

    dispatch(getUseTermsSuccess({ termoAceite: payload }));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(getUseTermsFailure(err));
  }
};

export const updateAcceptUseTermsAction = () => async dispatch => {
  dispatch(updateUseTermsRequest());

  try {
    const payload = await updateAcceptUseTerms();

    dispatch(updateUseTermsSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(updateUseTermsFailure(err));
  }
};
