import { apiUsers } from '~/config/envManager';
import { GET, PUT } from '~/api/httpMethods';
import { fromApi } from '~/api/dto/notifications/exams.dto';
import api from '~/api';

export const getNotifications = async () => {
  const { data } = await api({
    url: `${apiUsers}/private/notifications`,
    method: GET,
  });

  const response = fromApi(data.notifications);

  return response;
};

export const updateNotificationStatus = async params => {
  const { data } = await api({
    url: `${apiUsers}/private/notifications`,
    method: PUT,
    params,
  });

  return data;
};
