import { objectManager, dateManager } from '../helpers';

const { toStamp } = dateManager;

export default [
  {
    title: 'Nome',
    dataIndex: 'namePatient',
    key: 'namePatient',
    sortDirections: ['ascend', 'descend'],
    sorter: (a, b) => objectManager.orderByKey(a, b, 'namePatient'),
  },
  {
    title: 'Protocolo',
    dataIndex: 'protocol',
    key: 'protocol',
  },
  {
    title: 'Data de atendimento',
    dataIndex: 'dataOfVisit',
    key: 'dataOfVisit',
    sortDirections: ['ascend', 'descend'],
    sorter: (a, b) => toStamp(a.dataOfVisit) - toStamp(b.dataOfVisit),
  },
  {
    title: 'Origem do exame',
    dataIndex: 'origin',
    key: 'origin',
    sortDirections: ['ascend', 'descend'],
    sorter: (a, b) => objectManager.orderByKey(a.origin, b.origin, 'name'),
    render: item => item.name,
  },
];
