import React from 'react';
import PropTypes from 'prop-types';

import { Table } from '~components/atoms';

import Empty from '../Empty/Empty';

const PatientsTable = ({ data, columns, total, isLoading, onClickItem }) => {
  const hasData = !!data.length;

  return (
    <>
      {hasData ? (
        <Table
          data={data}
          total={total}
          testId={data.requestID}
          columns={columns}
          pageSize={data.length}
          isLoading={isLoading}
          onRowClick={onClickItem}
          isDefaultPagination={false}
        />
      ) : (
        <Empty
          testId="empty-exams"
          iconName="file-search"
          title="Não há pacientes atendidos no período selecionado"
          description="Em filtros, aplique um período de atendimento maior para ver seus pacientes"
        />
      )}
    </>
  );
};

PatientsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  onClickItem: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

PatientsTable.defaultProps = {
  data: [],
  total: 0,
  isLoading: false,
  onClickItem: () => undefined,
};

export default PatientsTable;
