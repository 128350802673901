import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import { ExamsActions } from '../..';
import { Badge, Text, List, ListItem } from './PanelContent.styles';

const PanelContent = ({
  id,
  exams,
  onClickPDF,
  onClickShare,
  onClickImage,
  onClickPDFDownload,
  validateDownload,
  isLoadingImage,
  isLoadingPdf,
  isLoadingPdfDowload,
  openShareReportModal,
  closeShareReportModal,
}) => (
  <List
    loading={false}
    itemLayout="horizontal"
    dataSource={exams}
    renderItem={item => (
      <ListItem>
        <Row>
          <Col span={8}>
            <Text data-testid={`panel-content-title-${id}`} isParent={item.isParent}>
              {item.title}
            </Text>
          </Col>
          <Col span={5}>
            <Text data-testid={`panel-content-dataOfVisit-${id}`} isParent={item.isParent}>
              {item.dataOfVisit}
            </Text>
          </Col>
          <Col span={4}>
            <Badge
              data-testid={`panel-content-isComplete-${id}`}
              isParent={item.isParent}
              text={item.isComplete ? 'Liberado' : 'Processando'}
              status={item.isComplete ? 'success' : 'warning'}
            />
          </Col>
          <Col span={2}>
            <Text data-testid={`panel-content-name-${id}`} isParent={item.isParent}>
              {item.origin.name}
            </Text>
          </Col>
          <Col span={5}>
            <ExamsActions
              item={item}
              report={item.reports}
              onClickPDF={onClickPDF}
              onClickShare={onClickShare}
              onClickImage={onClickImage}
              onClickPDFDownload={onClickPDFDownload}
              validateDownload={validateDownload}
              isLoadingPdf={isLoadingPdf}
              isLoadingImage={isLoadingImage}
              isLoadingPdfDowload={isLoadingPdfDowload}
              openShareReportModal={openShareReportModal}
              closeShareReportModal={closeShareReportModal}
            />
          </Col>
        </Row>
      </ListItem>
    )}
  />
);

PanelContent.propTypes = {
  id: PropTypes.string.isRequired,
  isLoadingImage: PropTypes.bool,
  isLoadingPdf: PropTypes.bool,
  isLoadingPdfDowload: PropTypes.bool,
  onClickPDF: PropTypes.func,
  onClickShare: PropTypes.func,
  onClickImage: PropTypes.func,
  onClickPDFDownload: PropTypes.func,
  validateDownload: PropTypes.func,
  exams: PropTypes.shape({
    title: PropTypes.string,
    dataOfVisit: PropTypes.string,
    isComplete: PropTypes.bool,
    origin: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

PanelContent.defaultProps = {
  isLoadingImage: false,
  isLoadingPdf: false,
  isLoadingPdfDowload: false,
  onClickPDF: () => undefined,
  onClickShare: () => undefined,
  onClickImage: () => undefined,
  onClickPDFDownload: () => undefined,
  validateDownload: () => undefined,
};

export default memo(PanelContent);
