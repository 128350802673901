import api from '../api';
import { apiUsers } from '../../config/envManager';
import { GET, POST } from '../httpMethods';
import { fromApiUserInfo, fromApiOnboardingUserInfo } from '../dto/users/appInfo.dto';

export const getAppUserInfo = async () => {
  const { data } = await api({
    url: `${apiUsers}/private/user-info`,
    method: GET,
  });

  const response = fromApiUserInfo(data);

  return response;
};

export const saveOnboardingByUser = async onboardingId => {
  const { data } = await api({
    url: `${apiUsers}/private/user-info/onboarding`,
    method: POST,
    data: {
      id: onboardingId,
    },
  });

  const response = fromApiOnboardingUserInfo(data);

  return response;
};
