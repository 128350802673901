import produce from 'immer';

import { sendContactInfo } from '~/api/services/support.service';
import { simpleAction, errorMessage, makeInitialState } from '~/utils/helpers';

export const CONTACT_US_REQUEST = '@dasa-canal-medico/CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = '@dasa-canal-medico/CONTACT_US_SUCCESS';
export const CONTACT_US_FAILURE = '@dasa-canal-medico/CONTACT_US_FAILURE';

// Reducer
export const initialState = makeInitialState({ data: {} });

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case CONTACT_US_REQUEST: {
        draft.loading = true;
        break;
      }
      case CONTACT_US_SUCCESS: {
        draft.data = action.payload;
        draft.loading = false;
        break;
      }
      case CONTACT_US_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

// Action Creators
export const sendContactUsRequest = simpleAction(CONTACT_US_REQUEST);
export const sendContactUsSuccess = simpleAction(CONTACT_US_SUCCESS);
export const sendContactUsFailure = simpleAction(CONTACT_US_FAILURE);

export const sendContactUsAction = params => async dispatch => {
  dispatch(sendContactUsRequest());

  try {
    const payload = await sendContactInfo(params);

    dispatch(sendContactUsSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(sendContactUsFailure(err));
  }
};
