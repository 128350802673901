import styles from '../../config/styles';

export default {
  actionButton: {
    fontColor: styles.colors.white,
    bg: styles.colors.primary,
    borderColor: styles.colors.primary,
    width: '128px',
    height: '48px',
    marginLeft: '8px',
  },
  cancelButton: {
    fontColor: styles.colors.primary,
    bg: styles.colors.white,
    borderColor: styles.colors.primary,
    width: '128px',
    height: '48px',
    marginLeft: '8px',
  },
};
