import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px 2px 24px 24px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;

  h3 {
    align-self: center;
  }

  iframe {
    max-width: calc(100vw - 66px);
  }
`;
