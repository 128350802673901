import { styles } from '../../config';

export default {
  error: {
    bg: styles.colors.modalErrorBg,
    color: styles.colors.modalErrorColor,
    name: 'close',
  },
  success: {
    bg: styles.colors.modalSuccessBg,
    color: styles.colors.modalSuccessColor,
    name: 'check',
  },
  default: {
    bg: '',
    name: '',
    color: '',
  },
};
