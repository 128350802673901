import React from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { dateManager, objectManager } from '~/utils/helpers';
import { Title, Text, Link, IconCustom } from '~/components/atoms';

import {
  Drawer,
  List,
  Item,
  TextContent,
  Badge,
  Date,
  Empty,
  Panic,
} from './NotificationsList.styles';

const { isEmpty } = objectManager;

const NotificationsList = ({ visible, onClose, notifications, testId, setReadNotification }) => (
  <Drawer testId={testId} visible={visible} onClose={onClose} closable={false}>
    <Title htmlType="h3">Notificações</Title>

    {!isEmpty(notifications) ? (
      <List
        dataSource={notifications}
        renderItem={item => (
          <Item onClick={() => !item.isRead && setReadNotification(item.id)} isRead={item.isRead}>
            <TextContent>
              <Badge isRead={item.isRead} />
              {item.type === 'APROVADO' && (
                <Text>
                  O paciente <strong>{item.patient.name}</strong> autorizou a liberação do histórico
                  de exames. Para visualizar, <Link to={item.url}>clique aqui</Link>.
                </Text>
              )}
              {item.type === 'REPROVADO' && (
                <Text>
                  O paciente <strong>{item.patient.name}</strong> <span>não autorizou</span> a
                  liberação do histórico de exames. Por favor, entre em contato com o seu paciente.
                </Text>
              )}
              {item.type === 'LIBERADO' && (
                <Text>
                  O exame <strong>{item.patient.name}</strong> de{' '}
                  <strong>{item.patient.exam}</strong> possui uma alteração crítica. Para
                  visualizar, <Link to={item.url}>clique aqui</Link>.
                </Text>
              )}
              {item.type === 'PANICO' && (
                <Text>
                  <Panic>
                    <IconCustom name={svg.iconPanicWarn} size="16px" />
                    Pânico{' '}
                  </Panic>
                  - O exame <strong>{item.patient.exam}</strong> de{' '}
                  <strong>{item.patient.name}</strong> possui um valor crítico. Para visualizar,{' '}
                  <Link to={item.url}>clique aqui</Link>.
                </Text>
              )}
              {item.type === 'EXAMES_COVID' && (
                <Text>
                  Os laudos sobre os <strong>exames de Covid-19</strong> estão disponíveis. Para
                  fazer o download, <a href={item.url}>clique aqui</a>.
                </Text>
              )}
              {item.type === 'EXAMES_COVID_EMPTY' && <Text>{item.message}.</Text>}
              {item.type === 'EXAMES_COVID_PARTIAL' && <Text>{item.message}.</Text>}
            </TextContent>
            <Date>
              {dateManager.getDate(item.notificationDate)} -{' '}
              {dateManager.getHours(item.notificationDate)}H
            </Date>
          </Item>
        )}
      />
    ) : (
      <Empty>
        <Text>Não existe nenhuma notificação</Text>
      </Empty>
    )}
  </Drawer>
);

NotificationsList.propTypes = {
  notifications: PropTypes.shape([{}]),
  setReadNotification: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  testId: PropTypes.string.isRequired,
};

NotificationsList.defaultProps = {
  notifications: [],
  visible: false,
};

export default NotificationsList;
