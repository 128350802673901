import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Skeleton } from '~components/atoms';
import { Empty, ExamsDrawer, ProtocolsList } from '~components/molecules';
import { status } from '~utils/enums';

import { Title, Wrapper, EmptyWrapper, SkeletonWrapper } from './ProtocolsHistoryList.styles';

const ProtocolsHistoryList = ({
  data,
  exams,
  isLoading,
  isAssistant,
  isNurse,
  isOtherExams,
  isLoadingExams,
  onClickProtocol,
  onClickPDF,
  onClickPDFDownload,
  openShareReportModal,
  onSubmitClinicalDiscussion,
  onClickImage,
}) => {
  const [showExams, setShowExams] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const hasData = !!data.length;

  const onClickItem = item => {
    onClickProtocol(item);
    setShowExams(true);
  };

  if (isLoading === status.error || (isLoading === status.success && !hasData)) {
    return (
      <EmptyWrapper>
        <Empty
          testId="empty-protocols"
          iconName="file-search"
          title="Não existe exames disponíveis"
          description={
            isOtherExams
              ? 'Caso seu paciente passe com outros médicos do laboratório Dasa os exames serão liberados nesta guia.'
              : 'O paciente não realizou estes exames nos laboratórios Dasa.'
          }
        />
      </EmptyWrapper>
    );
  }

  if (isLoading === status.processing || (isLoading === status.success && !hasData)) {
    return (
      <SkeletonWrapper>
        <Skeleton rows={2} />
        <Skeleton rows={2} />
        <Skeleton rows={2} />
      </SkeletonWrapper>
    );
  }

  return (
    hasData && (
      <Wrapper>
        <Title>Protocolos</Title>

        <ProtocolsList data={data} isOtherExams={isOtherExams} onClickProtocol={onClickItem} />

        <ExamsDrawer
          exams={exams}
          report={exams.report}
          visible={showExams}
          isLoading={isLoadingExams}
          isAssistant={isAssistant}
          isNurse={isNurse}
          showActions={showActions}
          closeDrawer={() => setShowExams(!showExams)}
          toggleShowActions={() => setShowActions(!showActions)}
          onClickPDF={onClickPDF}
          onClickPDFDownload={onClickPDFDownload}
          openShareReportModal={openShareReportModal}
          onSubmitClinicalDiscussion={onSubmitClinicalDiscussion}
          onClickImage={onClickImage}
        />
      </Wrapper>
    )
  );
};

ProtocolsHistoryList.propTypes = {
  data: PropTypes.objectOf({
    title: PropTypes.string,
    dataOfVisit: PropTypes.string,
    isComplete: PropTypes.bool,
    origin: PropTypes.objectOf({
      name: PropTypes.string,
    }),
  }).isRequired,
  isAssistant: PropTypes.bool,
  isNurse: PropTypes.bool,
  isLoading: PropTypes.string,
  isOtherExams: PropTypes.bool,
  showExamsDrawer: PropTypes.bool,
  openExamsDrawer: PropTypes.func,
  closeExamsDrawer: PropTypes.func,
  onClickPDFDownload: PropTypes.func,
  onClickUpdateExams: PropTypes.func,
  openShareReportModal: PropTypes.func,
  onClickImage: PropTypes.func,
};

ProtocolsHistoryList.defaultProps = {
  isAssistant: false,
  isNurse: false,
  isLoading: status.processing,
  isOtherExams: false,
  showExamsDrawer: false,
  openExamsDrawer: () => undefined,
  closeExamsDrawer: () => undefined,
  onClickPDFDownload: () => undefined,
  onClickUpdateExams: () => undefined,
  openShareReportModal: () => undefined,
  onClickImage: () => undefined,
};

export default ProtocolsHistoryList;
