import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { svg } from '~/assets';
import { types } from '~/utils/enums';
import { Button, Title, Text, IconCustom, TextArea, Select } from '~/components/atoms';

import { Container, Footer, ExamInfo, FooterMessage, ButtonWrapper } from './Form.styles';

const Form = ({
  values,
  errors,
  testId,
  touched,
  patient,
  textRows,
  isLoading,
  isDesktop,
  closeModal,
  handleBlur,
  examsOptions,
  handleChange,
  handleSubmit,
  hasOnlyParentAC,
  ...rest
}) => {
  const { setFieldValue } = rest;

  const setExamValue = () => {
    return setFieldValue('exam', hasOnlyParentAC(examsOptions) ? examsOptions[0].value : '');
  };

  useEffect(() => {
    setExamValue();
  }, [setFieldValue]);

  return (
    <Container>
      <ButtonWrapper>
        <Button
          type="link"
          iconName="arrow-left"
          testId={`${testId}-back-button`}
          onClick={closeModal}
        >
          <span>Voltar</span>
        </Button>
      </ButtonWrapper>

      <Title testId={`${testId}-title`} htmlType="h3">
        Discussão clínica
      </Title>

      <ExamInfo>
        <Text>
          <strong>Paciente:</strong> {patient.patientName}
        </Text>
        <Text>
          <strong>Data do exame:</strong> {patient.dataOfVisit}
        </Text>
      </ExamInfo>

      <form name="clinicalDiscussionForm" onSubmit={handleSubmit}>
        <Select
          {...rest}
          name="exam"
          label="Exame para discussão"
          placeholder="Selecione o exame"
          testId={`${testId}-select-input-exam`}
          value={values.exam}
          error={errors.exam}
          touched={touched.exam}
          options={examsOptions}
          showEmptyOption={false}
          isDisabled={hasOnlyParentAC(examsOptions)}
          defaultValue={hasOnlyParentAC(examsOptions) ? examsOptions[0].value : undefined}
        />

        <TextArea
          name="message"
          label="Mensagem"
          placeholder="Deixe uma mensagem (dúvida, comentário ou solicitação)"
          testId={`${testId}-textarea-input-message`}
          autoSize={textRows}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.message}
          error={errors.message}
          touched={touched.message}
        />

        <FooterMessage>
          <Text>
            <IconCustom name={svg.iconWarnSolid} size="18px" />
            Seu comentário será analisado e retornaremos com uma resposta em breve.
          </Text>
        </FooterMessage>

        <Footer>
          <Button
            isOutline
            testId={`${testId}-cancel-button`}
            onClick={closeModal}
            isDisabled={isLoading}
          >
            Cancelar
          </Button>

          <Button
            htmlType="submit"
            testId={`${testId}-submit-button`}
            type={types.primary}
            isLoading={isLoading}
          >
            Enviar
          </Button>
        </Footer>
      </form>
    </Container>
  );
};

Form.propTypes = {
  testId: PropTypes.string,
  isLoading: PropTypes.bool,
  closeModal: PropTypes.func,
  hasOnlyParentAC: PropTypes.bool,
  isDesktop: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    exam: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    exam: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    exam: PropTypes.bool,
    message: PropTypes.bool,
  }).isRequired,
  patient: PropTypes.shape({
    dataOfVisit: PropTypes.string,
    patientName: PropTypes.string,
  }).isRequired,
  examsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.shape({}),
    }),
  ).isRequired,
  textRows: PropTypes.shape({}).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

Form.defaultProps = {
  testId: '',
  isLoading: false,
  closeModal: () => undefined,
  hasOnlyParentAC: false,
};

export default Form;
