import brandGroup1Svg from './sgvs/brand-group-1.svg';
import brandGroup2Svg from './sgvs/brand-group-2.svg';
import brandGroup3Svg from './sgvs/brand-group-3.svg';
import brandGroup4Svg from './sgvs/brand-group-4.svg';
import brandGroup5Svg from './sgvs/brand-group-5.svg';
import contactActiveSvg from './sgvs/contact-active.svg';
import contactInactiveSvg from './sgvs/contact-inactive.svg';
import copyActiveSvg from './sgvs/copy-active.svg';
import copyInactiveSvg from './sgvs/copy-inactive.svg';
import copyHoverSvg from './sgvs/copy-hover.svg';
import error404Svg from './sgvs/404.svg';
import error500Sgv from './sgvs/500.svg';
import iconAssistantSvg from './sgvs/assistant.svg';
import iconArrowRightSvg from './sgvs/arrow-right.svg';
import iconBellRegularSvg from './sgvs/bell-regular.svg';
import iconBellSolidSvg from './sgvs/bell-solid.svg';
import iconCalendarRegularSvg from './sgvs/calendar-regular.svg';
import iconCloseSvg from './sgvs/close.svg';
import iconCloseDangerSvg from './sgvs/close-danger.svg';
import iconCloseSolidSvg from './sgvs/close-solid.svg';
import iconCommentsRegularSvg from './sgvs/comments-regular.svg';
import iconCommentsSolidSvg from './sgvs/comments-solid.svg';
import iconCircleErrorSvg from './sgvs/icon-circle-error.svg';
import iconCircleSuccessSvg from './sgvs/icon-circle-success.svg';
import iconCheckWhiteSvg from './sgvs/icon-check-white.svg';
import iconDotsHorizontalSvg from './sgvs/dots-horizontal.svg';
import iconDotsVerticalSvg from './sgvs/dots-vertical.svg';
import iconDowloadSgv from './sgvs/download.svg';
import iconDowloadSolidSvg from './sgvs/download-solid.svg';
import iconDowloadSecondarySgv from './sgvs/download-disable.svg';
import iconHeadsetSvg from './sgvs/headset.svg';
import iconHeadsetRotateSvg from './sgvs/headset-rotate.svg';
import iconMovieSgv from './sgvs/icon-movie.svg';
import iconMovieSecondarySgv from './sgvs/icon-movie-secondary.svg';
import iconLoadingSvg from './sgvs/loading.svg';
import iconLogoutSvg from './sgvs/sair.svg';
import iconPdfSgv from './sgvs/pdf-enable.svg';
import iconPdfSecondarySgv from './sgvs/pdf-disable.svg';
import iconPadlockRegularSvg from './sgvs/icon-padlock-regular.svg';
import iconPadlockSolidSvg from './sgvs/icon-padlock-solid.svg';
import iconPanicWarnSvg from './sgvs/panic-warn.svg';
import iconSirenSvg from './sgvs/siren.svg';
import iconShareActiveSvg from './sgvs/share-active.svg';
import iconShareInactiveSvg from './sgvs/share-inactive.svg';
import iconUserSvg from './sgvs/user.svg';
import iconUserPlusSvg from './sgvs/user-plus.svg';
import iconUserSolidSvg from './sgvs/user-solid.svg';
import iconWarnSvg from './sgvs/warn.svg';
import iconWarnSolidSvg from './sgvs/warn-solid.svg';
import coverSvg from './sgvs/cover.svg';
import examsIconSvg from './sgvs/exames-realizados.svg';
import padlockOutlineSvg from './sgvs/padlock-outline.svg';
import pendingApprovalsSvg from './sgvs/aprovacoes_pendentes_ativo.svg';
import approvedUsersSvg from './sgvs/usuarios_cadastrados_ativo.svg';
import menuSvg from './sgvs/menu.svg';
import confirmActionSvg from './sgvs/confirm-action.svg';
import approveActionSvg from './sgvs/approve-action.svg';
import rejectActionSvg from './sgvs/reject-action.svg';
import sendIconSvg from './sgvs/send.svg';
import maintenanceIconSvg from './sgvs/maintenance-icon.svg';
import feedbackPositiveSvg from './sgvs/feedback-positive.svg';
import illustrationSvg from './sgvs/public-template-illustration.svg';
import logoPrimarySvg from './sgvs/brand-colored.svg';
import logoBrandDefaultSvg from './sgvs/logo-nav-default.svg';
import logoLoginPrimarySvg from './sgvs/logo-nav-primary.svg';
import iconAlertCircleSvg from './sgvs/icon-alert-circle.svg';
import iconKeyboardArrowUpSvg from './sgvs/keyboard-arrow-up.svg';
import iconKeyboardArrowDownSvg from './sgvs/keyboard-arrow-down.svg';
import questionMarkSvg from './sgvs/question-mark.svg';
import doctorSvg from './sgvs/doctor.svg';

import covidBanner from './images/banner-covid.jpg';
import covidBanner2x from './images/banner-covid@2x.jpg';
import dasaEducaBanner from './images/banner-dasa-educa.jpg';
import dasaEducaBanner2x from './images/banner-dasa-educa@2x.jpg';

import modalUpdateNoticeImageSvg from './sgvs/modal_update_notice_image.svg';
import closeRoundSvg from './sgvs/close-round.svg';

export const svg = {
  iconAssistant: iconAssistantSvg,
  iconArrowRight: iconArrowRightSvg,
  iconBellRegular: iconBellRegularSvg,
  iconBellSolid: iconBellSolidSvg,
  iconCalendarRegular: iconCalendarRegularSvg,
  iconCircleError: iconCircleErrorSvg,
  iconCircleSuccess: iconCircleSuccessSvg,
  iconCheckWhite: iconCheckWhiteSvg,
  iconClose: iconCloseSvg,
  iconCloseDanger: iconCloseDangerSvg,
  iconCloseSolid: iconCloseSolidSvg,
  iconCommentsRegular: iconCommentsRegularSvg,
  iconCommentsSolid: iconCommentsSolidSvg,
  iconDotsHorizontal: iconDotsHorizontalSvg,
  iconDotsVertical: iconDotsVerticalSvg,
  iconDowload: iconDowloadSgv,
  iconDowloadSolid: iconDowloadSolidSvg,
  iconDowloadSecondary: iconDowloadSecondarySgv,
  iconHeadset: iconHeadsetSvg,
  iconHeadsetRotate: iconHeadsetRotateSvg,
  iconMovie: iconMovieSgv,
  iconMovieSecondary: iconMovieSecondarySgv,
  iconPadlockRegular: iconPadlockRegularSvg,
  iconPadlockSolid: iconPadlockSolidSvg,
  iconPanicWarn: iconPanicWarnSvg,
  iconPdf: iconPdfSgv,
  iconPdfSecondary: iconPdfSecondarySgv,
  iconLoading: iconLoadingSvg,
  iconLogout: iconLogoutSvg,
  iconSiren: iconSirenSvg,
  iconShareActive: iconShareActiveSvg,
  iconShareInactive: iconShareInactiveSvg,
  iconUser: iconUserSvg,
  iconUserPlus: iconUserPlusSvg,
  iconUserSolid: iconUserSolidSvg,
  iconWarn: iconWarnSvg,
  iconWarnSolid: iconWarnSolidSvg,
  cover: coverSvg,
  brandGroup1: brandGroup1Svg,
  brandGroup2: brandGroup2Svg,
  brandGroup3: brandGroup3Svg,
  brandGroup4: brandGroup4Svg,
  brandGroup5: brandGroup5Svg,
  contactActive: contactActiveSvg,
  contactInactive: contactInactiveSvg,
  copyActive: copyActiveSvg,
  copyInactive: copyInactiveSvg,
  copyHover: copyHoverSvg,
  menuHeader: menuSvg,
  error404: error404Svg,
  error500: error500Sgv,
  examsIcon: examsIconSvg,
  padlockOutline: padlockOutlineSvg,
  pendingApprovals: pendingApprovalsSvg,
  approvedUsers: approvedUsersSvg,
  confirmAction: confirmActionSvg,
  approveAction: approveActionSvg,
  rejectAction: rejectActionSvg,
  sendIcon: sendIconSvg,
  maintenanceIcon: maintenanceIconSvg,
  iconfeedbackPositive: feedbackPositiveSvg,
  illustration: illustrationSvg,
  logoPrimary: logoPrimarySvg,
  logoBrandDefault: logoBrandDefaultSvg,
  logoLoginPrimary: logoLoginPrimarySvg,
  iconAlertCircle: iconAlertCircleSvg,
  iconKeyboardArrowUp: iconKeyboardArrowUpSvg,
  iconKeyboardArrowDown: iconKeyboardArrowDownSvg,
  modalUpdateNoticeImage: modalUpdateNoticeImageSvg,
  closeRound: closeRoundSvg,
  questionMark: questionMarkSvg,
  doctor: doctorSvg,
};

export const image = {
  covidBanner,
  covidBanner2x,
  dasaEducaBanner,
  dasaEducaBanner2x,
};

export default { svg, image };
