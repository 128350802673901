import produce from 'immer';

import { getFeatureToggle } from '~/api/services/featureToggle.service';
import { simpleAction, errorMessage, makeInitialState, localStorageTTL } from '~/utils/helpers';
import { featureToggle } from '~/utils/enums'

export const GET_FEATURE_TOGGLE_REQUEST = '@dasa-canal-medico/GET_FEATURE_TOGGLE_REQUEST';
export const GET_FEATURE_TOGGLE_SUCCESS = '@dasa-canal-medico/GET_FEATURE_TOGGLE_SUCCESS';
export const GET_FEATURE_TOGGLE_FAILURE = '@dasa-canal-medico/GET_FEATURE_TOGGLE_FAILURE';

export const initialState = makeInitialState({ data: {} });

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_FEATURE_TOGGLE_REQUEST: {
        draft.loading = true;
        break;
      }
      case GET_FEATURE_TOGGLE_SUCCESS: {
        draft.data = {
          ...draft.data,
          toggles: {
            ...draft.data.toggles,
            [action.payload.id]: action.payload.enabled,
          },
        };
        draft.loading = false;
        break;
      }
      case GET_FEATURE_TOGGLE_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

export const getFeatureToggleRequest = simpleAction(GET_FEATURE_TOGGLE_REQUEST);
export const getFeatureToggleSuccess = simpleAction(GET_FEATURE_TOGGLE_SUCCESS);
export const getFeatureToggleFailure = simpleAction(GET_FEATURE_TOGGLE_FAILURE);

export const getFeatureToggleAction = () => async dispatch => {
  dispatch(getFeatureToggleRequest());
  try {
    if (localStorageTTL.checkStorageItemTTL(featureToggle.newPatientList)) {
      const payload = await getFeatureToggle(featureToggle.newPatientList);
      dispatch(getFeatureToggleSuccess(payload));
    }
    if (localStorageTTL.checkStorageItemTTL(featureToggle.performanceImprovementPatientDetails)) {
      const payload = await getFeatureToggle(featureToggle.performanceImprovementPatientDetails);
      dispatch(getFeatureToggleSuccess(payload));
    }
    if (localStorageTTL.checkStorageItemTTL(featureToggle.controlInfoMDM)) {
      const payload = await getFeatureToggle(featureToggle.controlInfoMDM);
      dispatch(getFeatureToggleSuccess(payload));
    }
  } catch (error) {
    const err = errorMessage(error);
    dispatch(getFeatureToggleFailure(err));
  }
};

export const clearFeatureToggles = () => {
  const listFeatureToggles = Object.values(featureToggle)
  for (let feature of listFeatureToggles)
    localStorageTTL.clearItemTTL(feature);
}
