import React from 'react';

import { Avatar, IconCustom } from '~/components/atoms';
import { stringManager } from '~/utils/helpers';
import { assistant } from '~/utils/enums';
import { styles } from '~/config';
import { svg } from '~/assets';

export default [
  {
    title: 'Nome completo',
    dataIndex: 'name',
    key: 'name',
    width: '30%',
    render: item => (
      <>
        <Avatar>
          {item === assistant.pending ? (
            <IconCustom name={svg.iconUserSolid} size="14px" color={styles.colors.white} />
          ) : (
            stringManager.getFirstLetter(item)
          )}
        </Avatar>
        {item}
      </>
    ),
  },
  {
    title: 'CPF',
    dataIndex: 'cpf',
    key: 'cpf',
    width: '15%',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Telefone',
    dataIndex: 'cellphone',
    key: 'cellphone',
  },
  {
    title: 'Data de inclusão',
    dataIndex: 'includedAt',
    key: 'includedAt',
  },
];
