import styled from 'styled-components';

export const ImageBase = styled.img`
  && {
    max-width: 100%;
    max-height: 100vh;
    border-radius: 4px;
    box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.16);
    margin: 0 auto;
  }
`;

export const ImageWrap = styled.div`
  position: relative;

  & img {
    transition: filter 350ms ease;
  }

  &:hover a {
    opacity: 1;
  }
`;

export const ImageLink = styled.a`
  z-index: 99;
  background-color: #dadadadd;
  width: 70px;
  height: 70px;
  opacity: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 4px;
  transition: opacity 350ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;
