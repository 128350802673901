import { simpleAction, errorMessage } from '~/utils/helpers';
import {
  requestPasswordReset,
  adminRequestPasswordReset,
} from '~/api/services/passwordRecovery.service';

export const REQUEST_PASSWORD_REQUEST = '@dasa-canal-medico/REQUEST_PASSWORD_REQUEST';
export const REQUEST_PASSWORD_SUCCESS = '@dasa-canal-medico/REQUEST_PASSWORD_SUCCESS';
export const REQUEST_PASSWORD_FAILURE = '@dasa-canal-medico/REQUEST_PASSWORD_FAILURE';

export const REQUEST_ERROR_MODAL_STATE = '@dasa-canal-medico/REQUEST_ERROR_MODAL_STATE';

// Reducer
export const initialState = {
  data: {
    message: {},
    openModal: false,
  },
  error: {
    message: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PASSWORD_REQUEST:
      return {
        ...state,
        data: {
          message: {},
          openModal: false,
        },
        error: {
          message: '',
        },
        isLoading: true,
      };
    case REQUEST_PASSWORD_SUCCESS:
      return {
        ...state,
        data: {
          message: action.payload.message,
          openModal: true,
        },
        error: {
          message: '',
        },
        isLoading: false,
      };
    case REQUEST_PASSWORD_FAILURE:
      return {
        ...state,
        data: {
          message: {},
          openModal: false,
        },
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

export const requestPasswordRequest = simpleAction(REQUEST_PASSWORD_REQUEST);
export const requestPasswordSuccess = simpleAction(REQUEST_PASSWORD_SUCCESS);
export const requestPasswordFailure = simpleAction(REQUEST_PASSWORD_FAILURE);

export const resetPasswordAction = username => async dispatch => {
  dispatch(requestPasswordRequest());
  try {
    const params = { cpf: username };
    const data = await requestPasswordReset(params);
    const message = { message: data };

    dispatch(requestPasswordSuccess(message));
  } catch (error) {
    const err = errorMessage(error);

    dispatch(requestPasswordFailure(err));
  }
};

export const adminResetPasswordAction = username => async dispatch => {
  dispatch(requestPasswordRequest());
  try {
    const params = { login: username };
    const data = await adminRequestPasswordReset(params);
    const message = { message: data };

    dispatch(requestPasswordSuccess(message));
  } catch (error) {
    const err = errorMessage(error);

    dispatch(requestPasswordFailure(err));
  }
};
