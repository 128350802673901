import React from 'react';
import PropTypes from 'prop-types';

import { TextBase } from './Text.styles';

const Text = ({ children, testId, strong, className }) => (
  <TextBase data-testid={testId} strong={strong} className={className}>
    {children}
  </TextBase>
);

Text.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  testId: PropTypes.string,
  strong: PropTypes.bool,
};

Text.defaultProps = {
  testId: '',
  strong: false,
  className: undefined,
};

export default Text;
