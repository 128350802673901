const betaTesters = [];

export const formatLogin = ({ congregationState, congregation, congregationNumber }) => {
  if (!congregationState || !congregation || !congregationNumber) return undefined;

  return `${congregationState.trim()}-${congregation.trim()}-${congregationNumber.trim()}`;
};

export const isBetaTester = (userLogged = '') => betaTesters.some(user => user === userLogged);

export const getLogin = () => {
  const persist = localStorage.getItem('persist:root');

  if (!persist) return '';

  const object = JSON.parse(persist).auth;
  const auth = JSON.parse(object);

  return auth.data.user.login || '';
};

export default {
  getLogin,
  formatLogin,
  isBetaTester,
};
