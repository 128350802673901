import { roleManager } from '~/utils/helpers';

export const toApi = (data = {}) => ({
  cpf: data.user || undefined,
  password: data.password || undefined,
});

export const fromApi = (data = {}) => {
  const validProfiles = data.message.userProfiles?.filter(profile => profile.role !== 'marketing');

  const profiles = validProfiles.map(profile => {
    const role = profile.role?.toUpperCase() || '';
    const state = profile.estado?.trim() || '';
    const number = profile.numero?.trim() || '';
    const congregation = profile.conselho?.trim() || '';

    const isDoctor = roleManager.isDoctor(profile.role);

    const crm = isDoctor ? `${congregation} ${state} ${number} ${role}` : role;

    return {
      id: profile.id || '',
      name: profile.name || '',
      role: profile.role || '',
      estado: profile.estado || '',
      numero: profile.numero || '',
      conselho: profile.conselho || '',
      crm: crm || '',
      token: data.message.token || '',
    };
  });

  return {
    multiProfiles: data.message.userProfiles?.length > 1,
    authenticated: data.message.authenticated || false,
    profiles,
  };
};

export const toRequestTokenApi = (data = {}) => ({
  id: data.profile.id || '',
  role: data.profile.role || '',
});

export const fromRequestTokenApi = (data = {}) => ({
  token: data.message.access_token || '',
  refreshToken: data.message.refresh_token || '',
  tokenType: data.message.token_type || '',
  user: {
    id: data.message.result.id || '',
    name: data.message.result.name || '',
    role: data.message.result.role || '',
    login: data.message.result.login || '',
    gender: data.message.result.sexo || '',
    forcedUpdatePassword: !!data.message.result.trocarSenhaProximoLogin,
  },
});
