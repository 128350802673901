import React from 'react';
import PropTypes from 'prop-types';

import { Btn } from './Button.style';

const Button = ({
  children,
  id,
  iconName,
  isBlock,
  isDisabled,
  isLoading,
  isOutline,
  testId,
  trackId,
  htmlType,
  size,
  type,
  onClick,
  styles,
}) => (
  <Btn
    id={id}
    data-testid={testId}
    data-collect={trackId}
    htmlType={htmlType}
    block={isBlock}
    disabled={isDisabled}
    loading={isLoading}
    size={size}
    type={type}
    icon={iconName}
    onClick={onClick}
    outline={isOutline}
    buttonStyles={styles}
  >
    {children}
  </Btn>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  iconName: PropTypes.string,
  isBlock: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isOutline: PropTypes.bool,
  testId: PropTypes.string,
  trackId: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  htmlType: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  id: '',
  iconName: '',
  isBlock: false,
  isLoading: false,
  isDisabled: false,
  isOutline: false,
  testId: '',
  trackId: '',
  htmlType: 'button',
  size: 'default',
  type: '',
  onClick: () => undefined,
};

export default Button;
