import { objectManager } from '~/utils/helpers';

const { isEmpty } = objectManager;

export const toApi = (data = {}) => {
  const { origin } = data;
  // eslint-disable-next-line no-param-reassign
  if (origin === 'all') data.origin = '';

  return {
    startDate: data.initialDate || undefined,
    endDate: data.endDate || undefined,
    initialPerPage: data.initialPerPage || 0,
    totalPerPage: data.totalPerPage || 10,
    totalItems: data.totalItems || 10,
    patientName: data.patientName || '',
    protocol: data.protocol || undefined,
    identifier: data.identifier || undefined,
    searchType: data.searchType || 'patientName',
    origin: data.origin || undefined,
    laboratory: data.laboratory || undefined,
    estado: data.uf || undefined,
    numero: data.number || undefined,
    conselho: data.conselho || undefined,
  };
};

export const fromApi = async (data = [], groupedExams) => {
  if (isEmpty(data)) return [];
  return data.map(item => {
    const patientObject = {};
    if (groupedExams) {
      patientObject.exams = item.exams;

      if (Array.isArray(item.exams) && item.exams[0]) {
        patientObject.protocol = item.exams[0].protocol;
        patientObject.visitdate = item.exams[0].dataOfVisit;
        patientObject.dataOfVisitMs = item.exams[0].dataOfVisitMs;
        patientObject.requestId = item.exams[0].requestId;
        patientObject.origin = item.exams[0].origin;
      }

      if (item.patient) {
        patientObject.birthdate = item.patient.birthdate;
        patientObject.birthdateMs = item.patient.birthdateMs;
        patientObject.name = item.patient.name;
        patientObject.identifier = item.patient.identifier;
      }

      if (item.doctor) {
        patientObject.doctorName = item.doctor.name;
        patientObject.crm = item.doctor.crm;
        patientObject.doctorSequence = item.doctor.doctorSequence;
      }
    } else {
      patientObject.crm = item.crm;
      patientObject.name = item.namePatient;
      patientObject.doctorName = item.doctorName;
      patientObject.protocol = item.protocol;
      patientObject.birthdate = item.birthdate;
      patientObject.birthdateMs = item.birthdateMs;
      patientObject.visitdate = item.dataOfVisit;
      patientObject.origin = item.origin;
      patientObject.identifier = item.identifier;
      patientObject.requestId = item.requestId;
      patientObject.exams = [];
    }
    return patientObject;
  });
};
