import produce from 'immer';

import { ExamNotification } from '~/utils/entities';
import { simpleAction, errorMessage } from '~/utils/helpers';
import { getNotifications, updateNotificationStatus } from '~/api/services/notifications.service';

export const NOTIFICATIONS_REQUEST = '@dasa-canal-medico/NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = '@dasa-canal-medico/NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAILURE = '@dasa-canal-medico/NOTIFICATIONS_FAILURE';

export const UPDATE_NOTIFICATION_REQUEST = '@dasa-canal-medico/UPDATE_NOTIFICATION_REQUEST';
export const UPDATE_NOTIFICATION_SUCCESS = '@dasa-canal-medico/UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = '@dasa-canal-medico/UPDATE_NOTIFICATION_FAILURE';

export const ADD_NOTIFICATION_REQUEST = '@dasa-canal-medico/ADD_NOTIFICATION_REQUEST';
export const ADD_NOTIFICATION_SUCCESS = '@dasa-canal-medico/ADD_NOTIFICATION_SUCCESS';
export const ADD_NOTIFICATION_FAILURE = '@dasa-canal-medico/ADD_NOTIFICATION_FAILURE';

export const SET_HAS_NEW_PANIC_NOTIFICATION = '@dasa-canal-medico/SET_HAS_NEW_PANIC_NOTIFICATION';

// Reducer
export const initialState = {
  data: [],
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
  hasNewPanicNotification: false,
};

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case ADD_NOTIFICATION_REQUEST:
      case NOTIFICATIONS_REQUEST: {
        draft.isLoading = true;
        break;
      }
      case ADD_NOTIFICATION_SUCCESS: {
        draft.data = [...state, action.payload];
        draft.isLoading = false;
        break;
      }
      case NOTIFICATIONS_SUCCESS:
      case UPDATE_NOTIFICATION_SUCCESS: {
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      }
      case ADD_NOTIFICATION_FAILURE:
      case NOTIFICATIONS_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.isLoading = false;
        break;
      }
      case SET_HAS_NEW_PANIC_NOTIFICATION: {
        draft.hasNewPanicNotification = action.payload;
        break;
      }
      default:
    }
  });
}

// Action Creators
export const getNotificationsRequest = simpleAction(NOTIFICATIONS_REQUEST);
export const getNotificationsSuccess = simpleAction(NOTIFICATIONS_SUCCESS);
export const getNotificationsFailure = simpleAction(NOTIFICATIONS_FAILURE);

export const updateNotificationRequest = simpleAction(UPDATE_NOTIFICATION_REQUEST);
export const updateNotificationSuccess = simpleAction(UPDATE_NOTIFICATION_SUCCESS);
export const updateNotificationFailure = simpleAction(UPDATE_NOTIFICATION_FAILURE);

export const addNotificationRequest = simpleAction(UPDATE_NOTIFICATION_REQUEST);
export const addNotificationSuccess = simpleAction(UPDATE_NOTIFICATION_SUCCESS);
export const addNotificationFailure = simpleAction(UPDATE_NOTIFICATION_FAILURE);

export const setHasNewPanicNotification = simpleAction(SET_HAS_NEW_PANIC_NOTIFICATION);

export const getExamsNotificationsAction = () => async dispatch => {
  dispatch(getNotificationsRequest());

  try {
    const payload = await getNotifications();

    return dispatch(getNotificationsSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    return dispatch(getNotificationsFailure(err));
  }
};

export const updateExamsNotificationsAction = idNotification => async (dispatch, getState) => {
  dispatch(updateNotificationRequest());

  try {
    await updateNotificationStatus({ idNotification });

    const { notifications } = getState();

    const payload = notifications.exams.data.map(item => {
      if (item.id === idNotification) {
        return { ...item, isRead: true };
      }

      return item;
    });

    return dispatch(updateNotificationSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    return dispatch(updateNotificationFailure(err));
  }
};

export const addExamsNotificationsAction = data => (dispatch, getState) => {
  dispatch(addNotificationRequest());

  try {
    const { notifications } = getState();
    const newNotification = new ExamNotification(data);
    const payload = [newNotification, ...notifications.exams.data];

    dispatch(addNotificationSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(addNotificationFailure(err));
  }
};

export const setHasNewPanicNotificationAction = status => dispatch => {
  dispatch(setHasNewPanicNotification(status));
};
