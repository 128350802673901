import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { TextAreaBase, InputItem, InputTooltip, InputIcon, MessageCount } from './TextArea.styles';

const TextArea = ({
  autoSize,
  isDisabled,
  onBlur,
  onChange,
  onSearch,
  name,
  placeholder,
  label,
  size,
  value,
  error,
  testId,
  touched,
  suffix,
  maxLength,
  minLength,
}) => {
  const [showSuffix, setShowSuffix] = useState(false);
  const [messageCount, setMessageCount] = useState(maxLength);

  const setMessageCountdown = useCallback(() => {
    const currentCount = value.length;

    setMessageCount(currentCount);
  }, [value]);

  useEffect(() => {
    setMessageCountdown();
  }, [setMessageCountdown, value]);

  const hasError = !!error;
  const hasTouched = !!touched;

  const setSuffix = bool => setShowSuffix(bool);

  return (
    <>
      <MessageCount isNegative={messageCount < 0}>
        <div>
          {messageCount}/{maxLength}
        </div>
      </MessageCount>

      <InputItem
        label={label}
        validateStatus={hasTouched && hasError ? 'error' : ''}
        help={hasTouched && hasError && error}
      >
        <TextAreaBase
          autoComplete="off"
          autoSize={autoSize}
          disabled={isDisabled}
          data-testid={testId}
          size={size}
          name={name}
          onFocus={() => setSuffix(true)}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={e => {
            onBlur(e);
            setSuffix(false);
          }}
          onPressEnter={() => onSearch()}
          value={value}
          defaultValue={value}
          maxLength={maxLength}
          minLength={minLength}
          suffix={
            <InputTooltip
              visible={suffix.isVisible && showSuffix}
              trigger="click"
              placement="bottomRight"
              title={suffix.text}
              onClick={() => onSearch()}
            >
              <InputIcon
                type={suffix.iconName}
                style={{ color: `${suffix.color}`, fontSize: `${suffix.size}` }}
              />
            </InputTooltip>
          }
        />
      </InputItem>
    </>
  );
};

TextArea.propTypes = {
  autoSize: PropTypes.shape({}),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  testId: PropTypes.string,
  size: PropTypes.string,
  touched: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isWhitelist: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  suffix: PropTypes.shape({
    text: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    iconName: PropTypes.string,
    isVisible: PropTypes.bool,
  }),
};

TextArea.defaultProps = {
  onSearch: () => undefined,
  autoSize: {},
  placeholder: '',
  label: '',
  testId: '',
  size: 'large',
  touched: false,
  isDisabled: false,
  isWhitelist: false,
  error: '',
  value: '',
  maxLength: 250,
  minLength: 3,
  suffix: {
    text: '',
    size: '',
    color: '',
    iconName: '',
    isVisible: false,
  },
};

export default TextArea;
