import styled from 'styled-components';
import { Menu as MenuBase } from 'antd';

export const Menu = styled(MenuBase)``;

export const MenuItem = styled(MenuBase.Item)`
  && {
    display: flex;
    align-items: center;
    height: 40px;

    :hover {
      cursor: pointer;
    }

    a {
      display: inline-block;
      width: 100%;
      font-weight: normal;
      color: #000;
    }
  }
`;

export const MenuText = styled.span`
  font-size: 1rem;
  display: inline-block;
  margin-left: 21px;
`;

export const MenuItemHeader = styled(MenuItem)`
  && {
    position: relative;
    height: 70px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #cacfd9;
    }

    &:hover {
      background-color: transparent;
    }
  }
`;

export const MenuItemWrapper = styled.div`
  padding: 12px 15px 0 15px;
`;

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  line-height: 1.2;
`;

export const MenuItemContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 28px;
`;

export const UserName = styled.b`
  font-size: 16px;
`;

export const WrapperAssistantTour = styled.span``;
