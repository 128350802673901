import styled from 'styled-components';
import { List as ListBase } from 'antd';

import { Text as TextBase } from '~components/atoms';
import { styles } from '~config';

export const Container = styled.div`
  margin-top: 30px;
`;

export const List = styled(ListBase)`
  && {
    border-top: 1px solid ${styles.colors.listItemLine};
    margin-top: 12px;
  }
`;

export const Item = styled(ListBase.Item)`
  && {
    height: 84px;
    display: flex;
    align-items: flex-start;
  }
`;

export const Text = styled(TextBase)`
  && {
    margin: 0;
    font-size: ${({ isAssistantName }) => !isAssistantName && '0.8rem'};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Time = styled.time`
  color: ${styles.colors.listItemDate};
  text-align: right;
`;

export const Content = styled.div`
  width: 70%;
`;

export const Actions = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const RemoveButton = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  cursor: pointer;
`;
