import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CheckboxBase } from './Checkbox.styles';

const Checkbox = ({
  id,
  isDisabled,
  isChecked,
  testId,
  name,
  value,
  label,
  hidden,
  defaultChecked,
  setFieldValue,
}) => {
  const [checked, setChecked] = useState(isChecked);

  return (
    <CheckboxBase
      id={id}
      name={name}
      checked={checked}
      value={value}
      data-testid={testId}
      hidden={hidden}
      disabled={isDisabled}
      defaultChecked={defaultChecked}
      onChange={e => {
        setFieldValue(name, { value: e.target.value, checked: e.target.checked });
        setChecked(e.target.checked);
      }}
    >
      {label}
    </CheckboxBase>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.bool,
  label: PropTypes.string,
  hidden: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  setFieldValue: PropTypes.func,
  testId: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
  id: '',
  isDisabled: false,
  isChecked: false,
  label: '',
  hidden: false,
  defaultChecked: false,
  setFieldValue: () => undefined,
  name: '',
  value: '',
};

export default Checkbox;
