import React from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { Button } from '~/components/atoms';
import { FormWrapper } from '~/components/molecules';

import Form from './Form/Form';
import formSchema from './Form/Form.schema';

import { Drawer, WrapperButton } from './FormContactDrawer.styles';

const initialValues = {
  fullName: '',
  email: '',
  cellphone: '',
  message: '',
};

const FormContactDrawer = ({ closable, closeDrawer, visible, onSubmit, isLoading }) => {
  return (
    <Drawer
      visible={visible}
      width={400}
      icon={svg.iconHeadsetRotate}
      onClose={closeDrawer}
      closable={closable}
      destroyOnClose
    >
      <WrapperButton className="button-wrapper">
        <Button type="link" iconName="arrow-left" onClick={closeDrawer}>
          <span>Voltar</span>
        </Button>
      </WrapperButton>

      <FormWrapper
        enableReinitialize
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={onSubmit}
        render={props => (
          <Form
            {...props}
            testId="contact-us-form"
            isLoading={isLoading}
            closeModal={closeDrawer}
          />
        )}
      />
    </Drawer>
  );
};

FormContactDrawer.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  isLoading: PropTypes.bool,
  closeDrawer: PropTypes.func,
  openFeedbackModal: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  closable: PropTypes.bool.isRequired,
};

FormContactDrawer.defaultProps = {
  title: '',
  visible: false,
  isLoading: false,
  closeDrawer: () => undefined,
  openFeedbackModal: () => undefined,
};

export default FormContactDrawer;
