import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { LPText } from '~/components/atoms';

const UseTermsText = ({ testid }) => (
  <LPText testid={testid}>
    <br />
    Esta Plataforma foi desenvolvida e é operada e de titularidade da{' '}
    <strong>DIAGNÓSTICOS DA AMERICA S.A.</strong>, sociedade anônima, inscrita no CNPJ/ME sob o nº
    61.486.650/0001-83, com sede na Avenida Juruá, nº 434, Alphaville, CEP 06455-010, na cidade de
    Barueri, Estado de São Paulo (“<strong>DASA</strong>”). Todos os direitos reservados.
    <br />
    <br />A Plataforma pode ser acessada pela web mediante o endereço{' '}
    <a href="https://canalmedico.dasa.com.br" target="blank">
      https://canalmedico.dasa.com.br/
    </a>
    , e seu uso é destinado exclusivamente a médicos devidamente habilitados e terceiros indicados
    por eles ou pelos Pacientes, conforme as regras aqui previstas.
    <br />
    <br />
    Estes Termos de Uso têm por objeto definir as regras a serem seguidas pelos Usuários que
    acessarem a Plataforma, sem prejuízo da aplicação da legislação vigente aplicável.
    <br />
    <br />
    <strong>
      AO UTILIZAR A PLATAFORMA, VOCÊ AUTOMATICAMENTE CONCORDA COM TODAS AS REGRAS DESTES TERMOS DE
      USO E DEMAIS CONDIÇÕES AQUI MENCIONADAS, RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E
      QUAISQUER ATOS PRATICADOS POR VOCÊ NA PLATAFORMA OU RELACIONADOS AO USO DA PLATAFORMA FEITO
      POR VOCÊ. CASO VOCÊ NÃO CONCORDE COM QUALQUER DOS TERMOS E CONDIÇÕES ABAIXO ESTABELECIDOS,
      VOCÊ NÃO DEVE UTILIZAR A PLATAFORMA.
    </strong>
    <br />
    <br />
    Para entender como a <strong>DASA</strong> trata as Informações Pessoais dos Usuários, incluindo
    sua coleta, uso, armazenamento, processamento, segurança e controle, bem como os seus direitos
    em relação ao tratamento dos seus dados, consulte a Política de Privacidade da{' '}
    <strong>DASA</strong>, disponível em{' '}
    <a href="https://www.dasa.com.br/" target="blank">
      www.dasa.com.br
    </a>
    .
    <br />
    <br />
    Para o melhor entendimento destes Termos de Uso, favor considerar as seguintes definições:
    <br />
    <br />
    Assistente: terceiro indicado expressamente pelo Médico, que acessará a Plataforma com perfil
    destinado exclusivamente a visualização das Informações Clínicas, com a finalidade de apoiar o
    Médico na obtenção das Informações Clínicas do Paciente.
    <br />
    <br />
    Conteúdo DASA: todas as funcionalidades e conteúdo da Plataforma, inclusive no que diz respeito
    a seus textos, código fonte, algoritmos, manuais, guias, procedimentos, documentação técnica,
    imagens, layouts, software, códigos, gráficos, artigos, fotografias e demais conteúdos
    produzidos direta ou indiretamente pela DASA.
    <br />
    <br />
    Informações Clínicas: informações sobre a saúde do Paciente, que poderão incluir nome,sobrenome,
    sexo, idade, data de nascimento, e-mail, CPF, laboratório, consultório, ou clínica de origem,
    imagem do laudo, laudo médico, resultados de exames e histórico de atendimento, entre outras
    informações.
    <br />
    <br />
    Informações Pessoais: informações fornecidas pelo Usuário, como nome, sobrenome, CPF, unidade
    federativa, conselho, número de registro, e-mail, celular, telefone, especialidade médica, RG,
    sexo, CEP, endereço, número, complemento, estado, cidade e outras informações necessárias.
    <br />
    <br />
    Médico: profissional legalmente habilitado para a prática da medicina, responsável pela
    assistência do Paciente.
    <br />
    <br />
    Paciente: indivíduo titular das Informações Clínicas.
    <br />
    <br />
    Plataforma: plataforma “Portal Médico” e todo o seu conteúdo.
    <br />
    <br />
    Terceiro Outorgado: terceiro indicado expressamente pelo Paciente para acessar as suas
    Informações Clínicas através da Plataforma.
    <br />
    <br />
    Usuário ou Você: Assistente, Médico ou Terceiro Outorgado com acesso pessoal à Plataforma.
    <br />
    <br />
    <strong>1.O QUE A PLATAFORMA OFERECE?</strong>
    <br />
    <br />
    <strong>1.1.</strong> A Plataforma é uma ferramenta que tem por objetivo auxiliar os Usuários no
    atendimento a Pacientes a partir do oferecimento de Informações Clínicas com o laudo do exames
    realizados, número do protocolo, status do exame, data de atendimento, laudo evolutivo e gestão
    do paciente com perfil de glicemia, colesterol e perfil hepático..
    <br />
    <br />
    <strong>1.2.</strong> A PLATAFORMA FOI DESENVOLVIDA EXCLUSIVAMENTE PARA FINS DE CONSULTA PELOS
    USUÁRIOS ÀS INFORMAÇÕES CLÍNICAS DOS PACIENTES QUE SE ENCONTRAM ARMAZENADAS NO BANCO DE DADOS DA{' '}
    <strong>DASA</strong>, COM A FINALIDADE DE PERMITIR O ATENDIMENTO MÉDICO AO PACIENTE.
    <br />
    <br />
    <strong>1.3.</strong> O USUÁRIO CONCORDA QUE O SEU ACESSO ÀS INFORMAÇÕES CLÍNICAS É RESTRITO
    APENAS ÀQUELAS RELACIONADAS AO PACIENTE ESPECÍFICO QUE ESTEJA SOB OS SEUS CUIDADOS. QUALQUER USO
    COM FINALIDADE DISTINTA DA DESCRITA NESTA CLÁUSULA É EXPRESSAMENTE PROIBIDO, INCLUINDO, MAS NÃO
    SE LIMITANDO, AO ACESSO ÀS INFORMAÇÕES CLÍNICAS DE PACIENTES NÃO ATENDIDOS PELO USUÁRIO, BEM
    COMO O COMPARTILHAMENTO DAS INFORMAÇÕES CLÍNICAS DOS PACIENTES COM TERCEIROS QUE NÃO ESTEJAM
    DIRETAMENTE ENVOLVIDOS NO ATENDIMENTO AO PACIENTE TITULAR DAS INFORMAÇÕES CLÍNICAS OU QUE MESMO
    ENVOLVIDOS NÃO NECESSITEM TER ACESSO A TAIS INFORMAÇÕES.
    <br />
    <br />
    <strong>1.4.</strong> O USUÁRIO ENTENDE QUE É O ÚNICO RESPONSÁVEL PELO ACESSO E USO QUE FIZER
    DAS INFORMAÇÕES CLÍNICAS DISPONIBILIZADAS PELA PLATAFORMA.
    <br />
    <br />
    <strong>1.5.</strong> Ao navegar na Plataforma e acessar as Informações Clínicas, é necessário
    que o Usuário aceite as regras expressas nestes Termos de Uso e concorde em cumprir com a
    legislação em vigor. Ao aceitar esses Termos de Uso, o Usuário declara que leu, entendeu todos
    os seus direitos e obrigações, e concorda que cumprirá com todas as disposições aqui presentes,
    vinculando-se automaticamente a estes Termos de Uso.
    <br />
    <br />
    <strong>2. REQUISITOS PARA UTILIZAÇÃO DA PLATAFORMA</strong>
    <br />
    <br />
    <strong>2.1.</strong> O acesso à Plataforma é gratuito e restrito aos (i) Médicos, (ii)
    Assistentes expressamente indicados pelos Médicos e (iii) Terceiros Outorgados expressamente
    indicados pelos Pacientes.
    <br />
    <br />
    <strong>2.2.</strong> Para uso da Plataforma é necessário que o Usuário realize um cadastro
    prévio onde forneça voluntariamente Informações Pessoais.
    <br />
    <br />
    <strong>2.2.1.</strong> Será concedido acesso aos Médicos mediante cadastro prévio na
    Plataforma, por meio do qual será possível acessar exclusivamente as Informações Clínicas
    relativas aos exames solicitou ou prescreveu diretamente ao seu Paciente.
    <br />
    <br />
    <strong>2.2.1.1.</strong> Caso o Médico necessite de acesso às demais Informações Clínicas do
    Paciente, deve solicitar este acesso adicional via link ou token na Plataforma. Feita esta
    solicitação, o Paciente titular das Informações Clínicas será acionado para oferecer o seu
    consentimento expresso ao acesso adicional, via (i) token, (ii) SMS, ou (iii) e-mail..
    <br />
    <br />
    <strong>2.2.2.</strong> Será concedido acesso ao Assistente mediante solicitação expressa do
    Médico, seguida de cadastro prévio na Plataforma, por meio do qual será possível visualizar
    exclusivamente as Informações Clínicas de que tenha acesso o referido Médico, com a finalidade
    exclusiva de apoia-lo na assistência ao Paciente.
    <br />
    <br />
    <strong>2.2.2.1.</strong> O Médico que solicitar o acesso de Assistente será exclusivamente
    responsável por garantir que o Assistente observará o sigilo e confidencialidade próprio das
    Informações Clínicas a que terá acesso, inclusive mediante a assinatura de Termo de
    Confidencialidade.
    <br />
    <br />
    <strong>2.2.3.</strong> Será concedido acesso ao Terceiro Outorgado mediante solicitação
    expressa do Paciente, seguida de cadastro prévio na Plataforma, por meio do qual será possível
    visualizar todas as Informações Clínicas do Paciente.
    <br />
    <br />
    <strong>2.3.</strong> Uma vez realizado o cadastro, a <strong>DASA</strong> ratificará o acesso
    do Usuário à Plataforma, que se dará por meio login e senha definida pelo usuário. O Usuário
    declara que as Informações Pessoais fornecidas são fiéis e verdadeiras e compromete-se a manter
    seus dados sempre atualizados.
    <br />
    <br />
    <strong>2.4.</strong> Uma vez ratificado o cadastro do Usuário, o Usuário passará a ser titular
    de uma conta, que poderá ser acessada unicamente mediante a utilização do login e senha. O
    Usuário é o único e exclusivo responsável por manter a segurança, o sigilo e a confidencialidade
    de seu login e senha, a fim de impedir o acesso não autorizado por terceiros da Plataforma e de
    todo o seu conteúdo, em especial das Informações Clínicas dos pacientes. O Usuário não poderá,
    em nenhuma hipótese, ceder o seu acesso a qualquer terceiro.
    <br />
    <br />
    <strong>2.5.</strong> O Usuário também é o único responsável por todas as atividades associadas
    à sua conta. Em caso de perda, extravio ou suspeita de utilização indevida de sua conta, login
    ou senha, a <strong>DASA</strong> deverá ser imediatamente comunicada para que sejam tomadas as
    medidas cabíveis. Caso seja verificada a duplicidade de contas, a <strong>DASA</strong> poderá
    inabilitar de forma definitiva todos os cadastros duplicados, independentemente de qualquer
    notificação prévia.
    <br />
    <br />
    <strong>3. CANCELAMENTO DO CADASTRO</strong>
    <br />
    <br />
    <strong>3.1.</strong> O Usuário tem o direito de solicitar o cancelamento do seu cadastro na
    Plataforma a qualquer momento, mediante simples comunicação à <strong>DASA</strong>. Toda e
    qualquer solicitação de cancelamento do acesso à Plataforma deverá, necessariamente, ser feita
    diretamente por meio do e-mail suporte@canalmedico.com.br. O Usuário está ciente e concorda que
    seu cadastro também poderá ser cancelado automaticamente, sem qualquer lhe seja enviada qualquer
    comunicação, caso o paciente titular das Informações Clínicas assim solicite.
    <br />
    <br />
    <strong>3.2.</strong> O Usuário está ciente de que, ao desfazer seu cadastro, todas as suas
    Informações Pessoais serão apagadas, não sendo possível recuperar qualquer dado perdido,
    encerrando o seu acesso às Informações Clínicas até que seja feita novo cadastro prévio para
    utilização da Plataforma. A <strong>DASA</strong>, em hipótese alguma, poderá ser
    responsabilizada por qualquer dano proveniente do cancelamento do cadastro feito pelo Usuário ou
    por solicitação do paciente.
    <br />
    <br />
    <strong>3.3.</strong> A <strong>DASA</strong> reserva-se o direito de suspender ou cancelar, a
    qualquer momento, o acesso de qualquer Usuário à Plataforma em caso de comprovado desvio de
    finalidade, fraude, obtenção de benefício ou vantagem de forma ilícita ou pelo não cumprimento
    de quaisquer das condições destes Termos de Uso, sem que seja devido ao Usuário ou a terceiros
    qualquer indenização pelos danos causados em razão da ausência de acesso à Plataforma pelo
    Usuário. O Usuário está ciente e de acordo que a <strong>DASA</strong> poderá promover todas as
    medidas necessárias para perseguir e resguardar seus direitos, incluindo medidas judiciais.
    <br />
    <br />
    <strong>4. UTILIZAÇÃO DA PLATAFORMA</strong>
    <br />
    <br />
    <strong>4.1.</strong> A Plataforma se destina ao acesso e simples visualização, pelo Usuário,
    das Informações Clínicas relativas aos Pacientes.
    <br />
    <br />
    <strong>4.2.</strong> As ferramentas da Plataforma irão guiar o Usuário pelas Informações
    Clínicas e permitirão ao Usuário acessá-las em diferentes categorias, de acordo com a finalidade
    pretendida no atendimento e segundo o histórico individual de cada Paciente. Dessa forma, a
    Plataforma permite que o Usuário realize o atendimento ao Paciente de forma técnica e de acordo
    com as particularidades do caso.
    <br />
    <br />
    <strong>4.3.</strong> A Plataforma permite ao Usuário apenas a visualização das Informações
    Clínicas dos pacientes, de modo que as funcionalidades constantes na referida tecnologia não
    permitem qualquer forma de alteração, inserção, exclusão, edição ou compartilhamento das
    Informações Clínicas. Qualquer tentativa do Usuário em burlar as regras aqui previstas, como,
    por exemplo, tirar “prints” das Informações Clínicas e compartilhá-las em outras aplicações com
    terceiros, implicará em violação direta aos presentes Termos de Uso, assumindo o Usuário a
    responsabilidade de indenizar a <strong>DASA</strong> e terceiros em virtude desta conduta, nos
    termos da cláusula 6.8. abaixo.
    <br />
    <br />
    <strong>4.4.</strong> O Usuário reconhece que a <strong>DASA</strong> oferece tão somente uma
    ferramenta de software para visualização de Informações Clínicas, não oferecendo qualquer tipo
    de serviço, nem garantia, relacionada a serviços médicos, laboratoriais ou de diagnóstico.
    <br />
    <br />
    <strong>4.5.</strong> VOCÊ ENTENDE E CONCORDA QUE AS INFORMAÇÕES CLÍNICAS NÃO SÃO PRODUZIDAS
    PELA PLATAFORMA, MAS FAZEM PARTE DE UMA BASE DE DADOS DA <strong>DASA</strong>, LIMITANDO-SE O
    PAPEL DA <strong>DASA</strong> EM DISPONIBILIZAR TAIS INFORMAÇÕES AO USUÁRIO ATRAVÉS DA
    PLATAFORMA. DESSA FORMA, A <strong>DASA</strong> NÃO É RESPONSÁVEL PELA VERACIDADE, COMPLETUDE E
    ATUALIZAÇÃO DAS INFORMAÇÕES CLÍNICAS E, PORTANTO, NÃO ASSUME QUALQUER RESPONSABILIDADE SOBRE AS
    DECISÕES MÉDICAS REALIZADAS PELO USUÁRIO COM BASE NAS INFORMAÇÕES CLÍNICAS CONSTANTES NA
    PLATAFORMA.
    <br />
    <br />
    <strong>4.6.</strong> É DE RESPONSABILIDADE EXCLUSIVA DO USUÁRIO REALIZAR UMA ANÁLISE
    INDEPENDENTE E CUIDADOSA DAS INFORMAÇÕES CLÍNICAS PARA VERIFICAR, DE ACORDO COM SEUS
    CONHECIMENTOS TÉCNICOS, SE AS RESPECITVAS INFORMAÇÕES CONSTANTES NA PLATAFORMA CORRESPONDEM A UM
    QUADRO CLÍNICO CORRETO SOBRE O SEU PACIENTE. O USUÁRIO É O ÚNICO RESPONSÁVEL PELO ATENDIMENTO
    MÉDICO REALIZADO A PARTIR DA ANÁLISE DAS INFORMAÇÕES CLÍNICAS.
    <br />
    <br />
    <strong>4.7.</strong> O USUÁRIO DESDE JÁ ISENTA A <strong>DASA</strong> DE TODA E QUALQUER
    RESPONSABILIDADE PELAS PERDAS E DANOS, DIRETOS E INDIRETOS, CAUSADOS AO PRÓPRIO USUÁRIO OU A
    TERCEIROS EM RAZÃO DE ATENDIMENTOS MÉDICOS REALIZADOS PELO USUÁRIO COM BASE NAS INFORMAÇÕES
    CLÍNICAS DISPONIBILIZADAS NA PLATAFORMA.
    <br />
    <br />
    <strong>5. DIREITOS DA DASA SOBRE A PLATAFORMA</strong>
    <br />
    <br />
    <strong>5.1.</strong> Todos os direitos de propriedade intelectual relativos à Plataforma, bem
    como sobre todo o Conteúdo DASA, são de propriedade exclusiva da <strong>DASA</strong>. Nenhuma
    disposição destes Termos de Uso deve ser entendida como restrição ou renúncia de quaisquer
    direitos da
    <strong>DASA</strong> sobre o Conteúdo DASA, tampouco cessão ao Usuário dos direitos de
    propriedade intelectual da <strong>DASA</strong> inerentes ao Conteúdo <strong>DASA</strong>.
    <br />
    <br />
    <strong>5.2.</strong> O Conteúdo DASA é protegido pela lei de direitos autorais e de propriedade
    intelectual. É proibido realizar as seguintes atividades, para quaisquer finalidades, sem o
    consentimento prévio e por escrito da <strong>DASA</strong>: (i) usar, reproduzir, publicar,
    transmitir, executar, fazer o upload, exibir o Conteúdo DASA; (i) explorar, ceder, sublicenciar,
    vender, doar, alienar, alugar, distribuir, compartilhar, transmitir ou transferir, total ou
    parcialmente, a terceiros, sob quaisquer modalidades, a qualquer título, o Conteúdo DASA; (ii)
    modificar, ampliar, reduzir, adaptar, traduzir, descompilar, desmontar ou executar engenharia
    reversa do Conteúdo DASA; (iii) desenvolver, criar ou patrocinar quaisquer programas que possam
    alterar ou copiar o Conteúdo DASA, ainda que seja para lhe introduzir melhorias de qualquer
    natureza; e (iv) criar cópias, digitais ou físicas, do Conteúdo DASA de forma que viole os
    direitos da <strong>DASA</strong>; Qualquer uso não autorizado do Conteúdo DASA pelo Usuário
    será considerado como violação dos direitos autorais e de propriedade intelectual da DASA.
    <br />
    <br />
    <strong>5.3.</strong> A <strong>DASA</strong> poderá, a seu exclusivo critério e a qualquer
    momento, atualizar a Plataforma e o Conteúdo DASA com o objetivo de preservar ou aperfeiçoar
    suas características. Nesse caso, suspensões temporárias de acesso à Plataforma poderão
    acontecer, sem que seja devida qualquer indenização ao Usuário. Todo e qualquer desenvolvimento,
    arranjo, melhoria ou alteração, realizados pela <strong>DASA</strong>, na Plataforma, nas suas
    funcionalidades e/ou no Conteúdo DASA, ainda que por sugestão ou solicitação do Usuário, serão
    considerados de titularidade da <strong>DASA</strong>, podendo a <strong>DASA</strong>{' '}
    empregá-los livremente e, sem limitação, adaptá-los, aperfeiçoá-los, transformá-los,
    distribui-los, comercializá-los, levá-los a registro, cedê-los e licenciá-los, a qualquer título
    e a seu exclusivo critério.
    <br />
    <br />
    <strong>6. RESPONSABILIDADES DA DASA E DO USUÁRIO</strong>
    <br />
    <br />
    <strong>6.1.</strong> O Usuário concorda em utilizar a Plataforma de forma adequada, apenas para
    fins lícitos e de acordo com as regras previstas nestes Termos de Uso. O Usuário declara e
    garante que cumprirá integralmente com as regras destes Termos de Uso, bem como com a legislação
    aplicável.
    <br />
    <br />
    <strong>6.2.</strong> O Usuário declara e reconhece que:
    <br />
    <br />
    (i) A <strong>DASA</strong> NÃO OFERECE QUALQUER GARANTIA, EXPLICITA OU IMPLÍCITA, RELACIONADA À
    PLATAFORMA, SEJA REFERENTE À QUALIDADE, DESEMPENHO, FUNCIONAMENTO, PERFORMANCE, ADEQUAÇÃO A UMA
    FINALIDADE ESPECÍFICA, REGULATÓRIA OU TÉCNICA, BEM COMO EM RELAÇÃO A QUALQUER RESULTADO DERIVADO
    DE SEU USO PELO USUÁRIO;
    <br />
    <br />
    (ii) a <strong>DASA</strong> esforça-se continuamente para aprimorar a Plataforma, de maneira
    que mudanças poderão ser realizadas, a qualquer momento, na Plataforma e em suas
    funcionalidades, bem como na forma de disponibilização das Informações Clínicas, sem que seja
    devida a Você qualquer tipo de indenização;
    <br />
    <br />
    (iii) o funcionamento da Plataforma está sujeito a imprecisões inevitáveis que, em hipótese
    alguma, será considerado como mora ou inadimplemento de qualquer obrigação assumida pela{' '}
    <strong>DASA</strong>; e
    <br />
    <br />
    (iv) é proibido (a) salvar ou publicar e/ou transferir à Plataforma material publicitário não
    solicitado, utilizando-se de correntes ou de sistemas de bola de neve; (b) copiar, vender,
    compartilhar, publicar ou usar a Plataforma para fins comerciais ou para qualquer finalidade
    além daquelas permitidas por estes Termos de Uso; (c) bloquear, sobrescrever ou alterar o
    conteúdo da Plataforma, (d) usar e acessar a Plataforma ou praticar atos que prejudiquem a sua
    segurança, integridade, disponibilidade e/ou o seu funcionamento normal, ou (e) acessar a
    Plataforma, ou quaisquer dados nela contidos, de maneira não autorizada.
    <br />
    <br />
    <strong>6.3.</strong> A <strong>DASA</strong>, suas afiliadas, parceiras, empregados ou agentes
    não serão, em hipótese alguma, responsabilizados por danos diretos ou indiretos que resultem de,
    ou que tenham relação com (i) o acesso e o uso que o Usuário fizer da Plataforma e/ou das
    Informações Clínicas; e (ii) qualquer indisponibilidade ou falhas encontradas na Plataforma e/ou
    nas Informações Clínicas.
    <br />
    <br />
    <strong>6.4.</strong> A <strong>DASA</strong> não fornece acesso à Internet através da
    Plataforma. Assim, é de responsabilidade do Usuário contratar serviços de conexão de Internet
    com operadoras de telecomunicações e/ou provedores, a fim de viabilizar o seu acesso à
    Plataforma, responsabilizando-se, inclusive, pela obtenção dos equipamentos necessários para
    realizar a respectiva conexão.
    <br />
    <br />
    <strong>6.5.</strong> A <strong>DASA</strong> não oferece aos Usuários serviços de suporte
    técnico ou de manutenção da Plataforma ou qualquer outro serviço técnico necessário para uso da
    Plataforma. Assim, a <strong>DASA</strong> não se responsabiliza pelas falhas na Plataforma
    decorrentes de circunstâncias alheias à sua vontade e controle, sejam ou não ocasionadas por
    serviços prestados por terceiros dos quais o funcionamento da Plataforma dependa; caso fortuito
    ou força maior, como por exemplo, intervenções de hackers e software maliciosos; falhas técnicas
    de qualquer tipo, incluindo, falhas no acesso ou na navegação da Plataforma decorrentes de
    falhas na internet em geral, quedas de energia, mau funcionamento eletrônico e/ou físico de
    qualquer rede, interrupções ou suspensões de conexão e falhas de software e/ou hardware do
    Usuário; paralisações programadas para manutenção, atualização e ajustes de configuração da
    Plataforma; qualquer falha humana de qualquer outro tipo, que possa ocorrer durante o
    processamento das informações. O Usuário exime a <strong>DASA</strong> de qualquer
    responsabilidade proveniente de tais fatos, atos e/ou situações.
    <br />
    <br />
    <strong>6.6.</strong> O Usuário entende e concorda que a <strong>DASA</strong> poderá, a seu
    exclusivo critério e sem qualquer justificativa, interromper e/ou descontinuar o acesso do
    Usuário à Plataforma e/ou parte de suas funcionalidades, sem que seja devido ao Usuário qualquer
    indenização. A<strong>DASA</strong> não presta serviços de hospedagem e armazenamento de
    conteúdo, não sendo de sua responsabilidade a ausência de Informações Clínicas na Plataforma
    sobre determinado paciente.
    <br />
    <br />
    <strong>6.7.</strong> É de responsabilidade do Usuário: (i) manter seguro o ambiente de seus
    dispositivos de acesso à Plataforma, valendo-se de ferramentas específicas para tanto, tais como
    antivírus, firewall, entre outras, de modo a contribuir para a prevenção de riscos eletrônicos;
    (ii) utilizar navegadores e sistemas operacionais atualizados e eficientes para a plena
    utilização da Plataforma; (iii) equipar-se e responsabilizar-se pelos dispositivos de hardware
    necessários para o acesso à Plataforma, bem como pelo acesso desses à internet, e (iv) não
    disseminar ou instalar vírus ou qualquer outro código, arquivo ou software malicioso na
    Plataforma com o propósito de corromper informações e dados, interromper, destruir, acessar
    indevidamente, limitar ou interferir no funcionamento ou segurança da Plataforma e dos
    equipamentos de terceiros, como por exemplo, de outros Usuários.
    <br />
    <br />
    <strong>6.8.</strong> O USUÁRIO É RESPONSÁVEL POR TODA E QUALQUER VIOLAÇÃO DESTES TERMOS DE USO
    E RESPONDERÁ, PERANTE A <strong>DASA</strong> E TERCEIROS (INCLUINDO O PACIENTE TITULAR DAS
    INFORMAÇÕES CLÍNICAS), POR TODAS AS PERDAS, DANOS E PREJUÍZOS, DIRETOS E/OU INDIRETOS, A QUE DER
    CAUSA, E QUE SEJAM DECORRENTES DE RECLAMAÇÕES DE TERCEIROS (JUDICIAIS, EXTRAJUDICIAIS E
    ADMINISTRATIVAS), BEM COMO DE SANÇÕES CIVIS E PENAIS PREVISTAS NA LEGISLAÇÃO APLICÁVEL,
    COMPROMETENDO-SE A INDENIZAR A <strong>DASA</strong> DE TODOS OS CUSTOS, DESPESAS E HONORÁRIOS
    ADVOCATÍCIOS (JUDICIAIS E ADMINISTRATIVOS) ORIUNDOS DE TAIS PERDAS..
    <br />
    <br />
    <strong>7. CONFIDENCIALIDADE DAS INFORMAÇÕES CLÍNICAS</strong>
    <br />
    <br />
    <strong>7.1.</strong> O Usuário obriga-se a guardar e a manter o sigilo, a confidencialidade e a
    segurança de seu login e senha, de todas as Informações Clínicas e demais dados que venha a ter
    acesso por meio da Plataforma, obrigando-se, ainda, a utilizá-los apenas para as finalidades
    autorizadas nestes Termos de Uso exclusivamente na medida do que for estritamente necessário
    para o desempenho de suas atividades.
    <br />
    <br />
    <strong>7.2.</strong> O Usuário garante que as Informações Clínicas e demais dados acessados por
    meio da Plataforma não serão usados, reproduzidos, revelados, divulgados, editados e/ou
    modificados, sob qualquer justificativa, em desacordo com o previsto nestes Termos de Uso, e
    garante que adotará sempre todas as precauções necessárias para evitar que isso aconteça. O
    Usuário reconhece que é vedada qualquer publicação das Informações Clínicas e demais dados
    acessados por meio da Plataforma.
    <br />
    <br />
    <strong>7.2.</strong> O Usuário concorda em obedecer ao princípio da{' '}
    <strong>confidencialidade</strong>
    (garantia de que toda informação e dado contido na Plataforma é acessível somente por pessoas
    autorizadas a terem acesso); da <strong>integridade</strong> (preservação da exatidão das
    informações e dados contidos na Plataforma, a fim de que elas não sejam deletadas ou alteradas
    de forma indevida ou por indivíduos não autorizados).
    <br />
    <br />
    <strong>7.3.</strong> O Usuário entende que as obrigações mencionadas nesta cláusula
    permanecerão em vigor não só durante a sua utilização da Plataforma, mas também após o
    cancelamento do cadastro do Usuário.
    <br />
    <br />
    <strong>8. OUTRAS INFORMAÇÕES SOBRE ESSES TERMOS DE USO</strong>
    <br />
    <br />
    <strong>8.1.</strong> A <strong>DASA</strong> está sempre fazendo atualizações na Plataforma
    para melhorar as suas funcionalidades. Por esse motivo, estes Termos de Uso podem ser alterados,
    a qualquer tempo, a fim de refletir os ajustes realizados na Plataforma. No entanto, sempre que
    ocorrer qualquer modificação nestes Termos de Uso, a <strong>DASA</strong> postará uma nota em
    destaque na Plataforma para informar o Usuário sobre a alteração. Caso o Usuário não concorde
    com os novos Termos de Uso, o Usuário poderá rejeitá-los, mas isso significa que o Usuário não
    poderá mais utilizar a Plataforma e ter acesso às Informações Clínicas. Se de qualquer maneira o
    Usuário utilizar a Plataforma, mesmo após a alteração destes Termos de Uso, isso significa que o
    Usuário concorda com todas as modificações. Em caso de modificações substanciais nesses Termos
    de Uso que afetem os direitos dos Usuários, a <strong>DASA</strong> enviará um comunicado por
    e-mail ao Usuário, no endereço oferecido pelo Usuário no momento do cadastro, informando tais
    alterações.
    <br />
    <br />
    <strong>8.2.</strong> Se alguma disposição destes Termos de Uso for considerada inválida, a
    validade das demais disposições destes Termos de Uso não será afetada e estas continuarão
    produzindo efeitos.
    <br />
    <br />
    <strong>8.3.</strong> Nada nestes Termos de Uso deve ser interpretado como criação de qualquer
    modalidade de vínculo entre a <strong>DASA</strong> e o Usuário, seja vínculo trabalhista,
    mandato, sociedade, associação, parceria, consórcio, joint-venture ou representação comercial.
    DASA e Usuário permanecerão sendo independentes, autônomos e individualmente responsáveis por
    seus atos e obrigações, sem qualquer autoridade para assumir qualquer tipo de obrigação ou criar
    responsabilidade em nome da outra parte.
    <br />
    <br />
    <strong>8.4.</strong> A tolerância da <strong>DASA</strong> com relação à exigência do regular e
    tempestivo cumprimento das obrigações do Usuário não constituirá desistência, alteração,
    modificação, ou novação de quaisquer dos direitos ou obrigações estabelecidas por meio destes
    Termos de Uso, e não impedirá a <strong>DASA</strong> de exigir o cumprimento de tal obrigação
    pelo Usuário, a qualquer momento. Nenhuma renúncia ao exercício de direito assegurado nestes
    Termos de Uso será válida, exceto se formalizada por escrito pela parte renunciante.
    <br />
    <br />
    <strong>8.5.</strong> Esses Termos de Uso são regidos pelas leis da República Federativa do
    Brasil. Quaisquer dúvidas e situações não previstas nestes Termos de Uso serão primeiramente
    resolvidas pela <strong>DASA</strong> e, caso persistam, deverão ser solucionadas pelo foro da
    Comarca da Capital de São Paulo, Brasil, com exclusão de qualquer outro, por mais privilegiado
    que seja ou venha a ser.
    <br />
    <br />
    <strong>8.6.</strong> Caso o Usuário tenha alguma dúvida, comentário ou sugestão, o Usuário
    deverá entrar em contato com a <strong>DASA</strong> por meio do e-mail{' '}
    <a href="mail:suporte@canalmedico.com.br" target="blank">
      suporte@canalmedico.com.br
    </a>
    .
    <br />
    <br />
  </LPText>
);

UseTermsText.propTypes = {
  testid: PropTypes.string.isRequired,
};

export default memo(UseTermsText);
