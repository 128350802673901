import { applicationId } from "~/config/envManager";
import { formatListData } from 'utils/helpers/userManagementDasa/userManagementDasa'

export const toApi = (params = {}) => ({
    applicationId: params.applicationId || applicationId,
    page: params.page || params.initialPerPage || 0,
    limit: params.limit || params.totalPerPage || 50,
    status: params.status || undefined,
    active: params.active || undefined,
    name: params.fullName || undefined,
    cpf: params.cpf || undefined,
    email: params.email || undefined,
    role: params.role || undefined,
    documentState: (params.congregationState && params.congregationState.toUpperCase()) || undefined,
    congregation: (params.congregation && params.congregation.toUpperCase()) || undefined,
    congregationNumber: params.congregationNumber || undefined,
  });
  
  export const fromApi = (data = {}) => {
    const results = data.results || [];
  
    const mapCRM = ({ documentState, congregation, document }) => {
      if (documentState && document) {
        return `${documentState}-${congregation || 'CRM'}-${document}`;
      }
  
      return '';
    };
  
    const mapperUsers = results.map(item => {

      const { userData } = item;

      return {
        id: item.userId || '',
        userApplicationId: item.id || '',
        cpf: userData?.federalTaxId || '',
        name: userData?.name || '',
        email: userData?.email || '',
        congregation: (userData) ? mapCRM(userData) : '',
        congregationType: 'CRM',
        congregationState: userData?.documentState || '',
        congregationNumber: userData?.document || '',
        role: item.roleNames || '',
        status: item.status || '',
        // active: item.active === 'ATIVO', //não temos o active vindo dessa rota
        active: true,
        isVisible: true,
        documentFrontUrl: item.documentFrontUrl,
        documentBackUrl: item.documentBackUrl
      }
    });

    return {
      group: formatListData(mapperUsers),
      total: data.total,
      ...data.page
    };
  };
  