export default {
  mobile: '480px',
  tablet: '768px',
  desktop: '1024px',
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};
