import React from 'react';
import PropTypes from 'prop-types';

import { FormItem, SelectBase, Option, PreffixIcon } from './Select.styles';

const Select = ({
  isDisabled,
  isLoading,
  testId,
  label,
  size,
  touched,
  textNotFound,
  error,
  name,
  preffixIcon,
  options,
  placeholder,
  defaultValue,
  showSearch,
  setFieldValue,
  setFieldTouched,
  showEmptyOption,
  handleBlur,
  allowClear,
  onChange,
  onSelect,
}) => {
  const hasError = !!error;

  return (
    <FormItem
      help={touched && hasError && error}
      validateStatus={touched && hasError ? 'error' : ''}
      label={label}
    >
      {preffixIcon && <PreffixIcon>{preffixIcon}</PreffixIcon>}
      <SelectBase
        allowClear={allowClear}
        showSearch={showSearch}
        notFoundContent={textNotFound}
        data-testid={testId}
        name={name}
        size={size}
        loading={isLoading}
        disabled={isDisabled}
        placeholder={placeholder}
        hasPreffixIcon={preffixIcon}
        defaultValue={defaultValue}
        onBlur={handleBlur}
        onSelect={onSelect}
        onChange={val => {
          setFieldValue(name, val);
          setFieldTouched(name);
          onChange(val);
        }}
      >
        {showEmptyOption && <Option value="">&nbsp;</Option>}
        {options &&
          options.map(item => (
            <Option key={`${testId}-${item.value}`} value={item.value}>
              {item.text}
            </Option>
          ))}
      </SelectBase>
    </FormItem>
  );
};

Select.propTypes = {
  testId: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  preffixIcon: PropTypes.node,
  textNotFound: PropTypes.string,
  showSearch: PropTypes.bool,
  size: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  handleBlur: PropTypes.func,
  showEmptyOption: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  allowClear: PropTypes.bool,
};

Select.defaultProps = {
  testId: 'select',
  size: 'large',
  textNotFound: 'Não encontrado',
  name: '',
  label: '',
  error: '',
  placeholder: '',
  preffixIcon: undefined,
  defaultValue: undefined,
  touched: false,
  allowClear: false,
  showSearch: true,
  isDisabled: false,
  isLoading: false,
  showEmptyOption: true,
  handleBlur: () => undefined,
  setFieldValue: () => undefined,
  setFieldTouched: () => undefined,
  onChange: () => undefined,
  onSelect: () => undefined,
};

export default Select;
