import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import { styles } from '~/config';
import { Icon } from '~/components/atoms';

import { InputWrapper, InputBase, IconWrapper, AddonAfter } from './InputSearch.styles';

const InputSearch = ({
  name,
  size,
  mask,
  label,
  value,
  error,
  testId,
  onBlur,
  touched,
  htmlType,
  onSearch,
  onChange,
  minLength,
  maxLength,
  isDisabled,
  showButton,
  addonAfter,
  addonBefore,
  placeholder,
}) => {
  const hasMask = !!mask;
  const hasError = !!error;
  const hasTouched = !!touched;

  return (
    <InputWrapper
      label={label}
      validateStatus={hasTouched && hasError ? 'error' : ''}
      help={hasTouched && hasError && error}
    >
      {!hasMask ? (
        <InputBase
          autoComplete="off"
          data-testid={testId}
          name={name}
          size={size}
          onSearch={onSearch}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          defaultValue={value}
          enterButton={showButton}
          disabled={isDisabled}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
        />
      ) : (
        <>
          <InputMask
            autoComplete="off"
            className={`ant-input ant-input-lg ${isDisabled && 'ant-input-disabled'}`}
            data-testid={testId}
            disabled={isDisabled}
            type={htmlType}
            placeholder={placeholder}
            name={name}
            mask={mask}
            maskChar={null}
            maxLength={maxLength}
            minLength={minLength}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />

          <IconWrapper hasAddonAfter={addonAfter}>
            <div>
              <Icon name="search" size="24px" color={styles.colors.primary} />
            </div>
          </IconWrapper>

          <AddonAfter>
            <div>{addonAfter}</div>
          </AddonAfter>
        </>
      )}
    </InputWrapper>
  );
};

InputSearch.propTypes = {
  onBlur: PropTypes.func,
  testId: PropTypes.string,
  htmlType: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.string,
  mask: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  showButton: PropTypes.bool,
  addonAfter: PropTypes.node,
  addonBefore: PropTypes.node,
};

InputSearch.defaultProps = {
  mask: null,
  name: '',
  error: '',
  touched: false,
  size: 'large',
  label: '',
  value: '',
  testId: '',
  placeholder: '',
  isDisabled: false,
  showButton: false,
  htmlType: undefined,
  minLength: undefined,
  maxLength: undefined,
  addonAfter: undefined,
  addonBefore: undefined,
  onBlur: () => undefined,
  onSearch: () => undefined,
  onChange: () => undefined,
};

export default InputSearch;
