import styled from 'styled-components';
import { List as ListBase } from 'antd';

import { Text as TextBase } from '~components/atoms';
import { mediaQuery } from '~/utils/helpers';
import { styles } from '~config';

export const List = styled(ListBase)`
  && {
    ${mediaQuery.inDesktop(`
      overflow-y: auto;
      overflow-x: hidden;

      ::-webkit-scrollbar {
        width: 6px;
      }

      ::-webkit-scrollbar-thumb,
      ::-webkit-scrollbar-thumb:hover {
        background: ${styles.colors.scrollBarColor};
        border-radius: 30px;
        margin-right: 4px;
      }
    `)};
  }
`;

export const ListItem = styled(ListBase.Item)`
  && {
    display: flex;
    align-items: flex-start;
    padding: 20px 0;
    pointer-events: ${({ isLoadingExams }) => isLoadingExams && 'none'};

    & + & {
      border-top: 1px solid ${styles.colors.listItemLine};
    }

    ${mediaQuery.inDesktop(`
      padding: 10px 0 0;
      position: relative;
      border: none !important;

      & + &::after {
        content: '';
        width: 92%;
        height: 1px;
        background: ${styles.colors.listItemLine};
        position: absolute;
        top: 0;
        left: 4%;
      }

      &:hover {
        background-color: ${styles.colors.protocolHover};
      }

      &.is-active {
        color: ${styles.colors.white};
        background-color: ${styles.colors.primary};
        time{
          color: ${styles.colors.white};
        }
      }
    `)};
  }
`;

export const Text = styled(TextBase)`
  margin: 0;
`;

export const Time = styled.time`
  color: #66686b;
  text-align: right;
`;

export const ReportContent = styled.div`
  width: 50%;
`;

export const ActionContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const Action = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;

  ${mediaQuery.inDesktop(`
    display: none;
  `)};
`;

export const EmptyWrapper = styled.div`
  position: relative;
  top: -40px;

  p {
    max-width: 280px;
  }

  svg {
    width: 120px;
    height: 150px;
  }
`;

export const TimeWrapper = styled.div`
  margin-right: 24px;

  ${mediaQuery.inDesktop(`
    margin-right: 0;
  `)}
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0 16px;
  padding-bottom: 10px;
  cursor: pointer;

  ${mediaQuery.inMobile(`
    margin: 0;
    padding-bottom: 0;
  `)};
`;
