import { toogleActivateUser } from '~/api/services/adminUsers.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const ADMIN_ACTIVATE_USER_REQUEST = '@dasa-canal-medico/ADMIN_ACTIVATE_USER_REQUEST';
export const ADMIN_ACTIVATE_USER_SUCCESS = '@dasa-canal-medico/ADMIN_ACTIVATE_USER_SUCCESS';
export const ADMIN_ACTIVATE_USER_FAILURE = '@dasa-canal-medico/ADMIN_ACTIVATE_USER_FAILURE';

// Reducer
export const initialState = {
  data: {
    message: '',
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_ACTIVATE_USER_REQUEST:
      return {
        ...state,
        data: {
          message: '',
        },
        error: {
          message: '',
          status: '',
        },
        isLoading: true,
      };
    case ADMIN_ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: {
          message: '',
          status: '',
        },
        isLoading: false,
      };
    case ADMIN_ACTIVATE_USER_FAILURE:
      return {
        ...state,
        data: [],
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}

// Action Creators
export const adminActiveUserRequest = simpleAction(ADMIN_ACTIVATE_USER_REQUEST);
export const adminActiveUserSuccess = simpleAction(ADMIN_ACTIVATE_USER_SUCCESS);
export const adminActiveUserFailure = simpleAction(ADMIN_ACTIVATE_USER_FAILURE);

export const adminToogleActivateUserAction = userId => async dispatch => {
  dispatch(adminActiveUserRequest());

  try {
    const payload = await toogleActivateUser(userId);
    dispatch(adminActiveUserSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(adminActiveUserFailure(err));
  }
};
