import React from 'react';
import PropTypes from 'prop-types';

import { 
  UploadBase,
  UploadFloatingControl,
  UploadHint,
  UploadItem,
  UploadText,
  UploadWrapper,
  UploadFilename,
  UploadFilenameWrap
} from './Upload.styles';
import { svg } from '~assets';
import { IconCustom } from '..';

const Upload = ({
  isDisabled,
  onChange,
  name,
  label,
  error,
  testId,
  touched,
  beforeUpload,
  filename,
  text,
  hint,
  floatingControl,
  removeFile
}) => {
  const hasError = !!error;
  const hasTouched = !!touched;

  return (
    <UploadWrapper data-testid={testId} >
      {floatingControl ??
        <UploadFloatingControl>
          {floatingControl}
        </UploadFloatingControl>
      }
      <UploadItem
        label={label}
        validateStatus={hasTouched && hasError ? 'error' : ''}
        help={(hasTouched && hasError) ? error : null}
      >
        <UploadBase
          error={hasTouched && hasError}
          hasData={!(hasTouched && hasError) ? !!filename : false}
          disabled={isDisabled}
          name={name}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={val => onChange(name, val)}
        >
          {
            filename ? 
            <UploadFilenameWrap>
              <UploadFilename>
                {filename}
              </UploadFilename>
              <IconCustom name={svg.closeRound} size="15px" onClick={(e) => {
                e.stopPropagation();
                removeFile(name)
              }} />
            </UploadFilenameWrap> :
            (
              <>
                <UploadText>{text}</UploadText>
                <UploadHint>
                  {hint}
                </UploadHint>
              </>
            )
          }
        </UploadBase>
      </UploadItem>
    </UploadWrapper>
  );
};

Upload.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  testId: PropTypes.string,
  touched: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  beforeUpload: PropTypes.func,
  filename: PropTypes.string,
  text: PropTypes.string,
  hint: PropTypes.string,
  floatingControl: PropTypes.node,
  removeFile: PropTypes.func
};

Upload.defaultProps = {
  removeFile: () => undefined,
  floatingControl: null,
  onChange: () => undefined,
  name: '',
  label: '',
  testId: '',
  touched: false,
  isDisabled: false,
  error: '',
  beforeUpload: () => undefined,
  filename: '',
  text: '',
  hint: '',
};

export default Upload;
