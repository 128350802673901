import React from 'react';

import { TourContent } from '~/components/molecules';

export default {
  assistants: [
    {
      selector: '[data-tour="my-assistants"]',
      position: 'left',
      content: () => (
        <TourContent
          title="Meus assistentes"
          text="Agora no Canal Médico você pode convidar seus assistentes para terem acesso aos exames realizados por seus pacientes."
        />
      ),
    },
    {
      selector: '[data-tour="create-assistant"]',
      position: 'top',
      content: () => (
        <TourContent
          title="Adicionar novo assistente"
          text="Para adicionar um novo assistente clique no botão em destaque."
        />
      ),
    },
  ],
  examsHistoryOtherDoctors: [
    {
      selector: '[data-tour="exams-tab"]',
    },
  ],
  examsHistoryRequest: [
    {
      content: () => (
        <TourContent
          title="Histórico de exames"
          text="Agora você pode ver os exames Dasa que outros médicos prescreveram ao seu paciente."
        />
      ),
    },
    {
      selector: '[data-tour="request-history"]',
      content: () => (
        <TourContent
          title="Histórico de exames"
          text="Para acessar esse histórico, basta apenas clicar no ícone de opções ao lado."
        />
      ),
    },
    {
      selector: '[data-tour="request-history-open"]',
      content: () => (
        <TourContent
          title="Histórico de exames"
          text="Depois é só clicar em Solicitar histórico de exames."
        />
      ),
    },
  ],
  firstAccess: [
    {
      content: () => (
        <TourContent
          title="Bem-vindo ao Canal Médico"
          text="Sua plaforma de gestão, acompanhamento clínico, além de controle populacional de seus pacientes com o Grupo Dasa."
        />
      ),
    },
    {
      content: () => (
        <TourContent
          title="Exames realizados"
          text="Acompanhe, visualize e baixe os exames realizados por seus pacientes."
        />
      ),
    },
    {
      content: () => (
        <TourContent
          title="Gestão de pacientes"
          text="Identifique, direcione e faça a gestão dos seus pacientes."
        />
      ),
    },
  ],
  notifications: [
    {
      selector: '[data-tour="notifications"]',
      content: () => (
        <TourContent
          title="Notificação de aprovação"
          text="Quando a liberação de histórico de exame for aprovada pelo paciente, você receberá uma
          notificação como exemplo."
        />
      ),
      position: [716, 120],
    },
  ],
  patientList: [
    {
      content: () => (
        <TourContent
          title="Nova listagem de pacientes"
          text="Agora os protocolos de seus pacientes estão agrupados pelo nome do paciente. Assim, você terá acesso a todo histórico de exames em uma única página"
        />
      ),
    },
  ],
};
