export default {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  default: 'default',
  error: 'error',
  processing: 'processing',
  warning: 'warning',
  none: '',
};
