/* eslint-disable indent */
import styled from 'styled-components';
import { Button } from 'antd';

import { status } from '~/utils/enums';
import { lpStyles } from '~/config';

const isPrimary = (type = '') => type === status.primary;
const isSecondary = (type = '') => type === status.secondary;

const hasPrimary = (type, css) => (isPrimary(type) ? css : '');
const hasSecondary = (type, css) => (isSecondary(type) ? css : '');

export const Btn = styled(Button)`
  && {
    height: 48px;
    border-radius: 8px;

    span {
      font-weight: bold;
      font-family: ${lpStyles.fontFamily.default};
    }

    ${({ type }) => {
      const css = `
        border-color: ${lpStyles.colors.primary};
        background-color: ${lpStyles.colors.primary};

        &:hover {
          border-color: ${lpStyles.colors.button.primaryHover};
          background-color: ${lpStyles.colors.button.primaryHover};
        }

        &:focus {
          border: 2px solid ${lpStyles.colors.button.primaryFocusBorder} ;
          background-color: ${lpStyles.colors.button.primaryFocus};
        }

        &:active {
          border-color: ${lpStyles.colors.button.primaryActive};
          background-color: ${lpStyles.colors.button.primaryActive};
        }

        &:disabled {
          color: ${lpStyles.colors.button.primaryDisabledColor};
          border-color: ${lpStyles.colors.button.primaryDisabledBackground};
          background-color: ${lpStyles.colors.button.primaryDisabledBackground};
        }
      `;

      return hasPrimary(type, css);
    }}

    ${({ type }) => {
      const css = `
        border-color: ${lpStyles.colors.secondary};
        background-color: ${lpStyles.colors.white};

        &:hover {
          color: ${lpStyles.colors.button.secondaryHoverColor};
          border-color: ${lpStyles.colors.button.secondaryHoverColor};
          background-color: ${lpStyles.colors.button.secondaryHoverBackground};
        }

        &:focus {
          color: ${lpStyles.colors.button.secondaryFocusColor} ;
          border: 2px solid ${lpStyles.colors.button.secondaryFocusColor};
          background-color: ${lpStyles.colors.button.secondaryFocusBackground};
        }

        &:active {
          color: ${lpStyles.colors.button.secondaryActiveColor};
          border-color: ${lpStyles.colors.button.secondaryActiveColor};
          background-color: ${lpStyles.colors.button.secondaryActiveBackground};
        }

        &:disabled {
          color: ${lpStyles.colors.button.secondaryDisabledColor};
          border-color: ${lpStyles.colors.button.secondaryDisabledBorder};
          background-color: ${lpStyles.colors.white};
        }
      `;

      return hasSecondary(type, css);
    }}
  }
`;
