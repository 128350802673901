import styled from 'styled-components';
import { Table } from 'antd';

import { styles } from '~/config';

export const TableBase = styled(Table)`
  && {
    thead tr th {
      background-color: transparent;
      font-weight: bold;
    }

    .ant-pagination {
      display: ${({ isDefaultPagination }) => (isDefaultPagination ? 'flex' : 'none')};
      width: 100%;
      height: 40px;
      margin-top: 50px;
      align-items: center;
      flex-direction: row;

      .ant-pagination-item-active {
        background-color: ${styles.colors.primary};

        a {
          color: ${styles.colors.white};
        }
      }

      .ant-pagination-total-text {
        order: 2;
        flex: 1 30%;
        margin-left: 16px;
        text-align: right;
      }

      .ant-pagination-options {
        order: 3;
        border-left: 1px solid ${styles.colors.borderColorSecondary};
        text-align: right;

        &:before {
          content: 'Itens por página: ';
          margin-left: 16px;
        }
      }
    }
  }
`;
