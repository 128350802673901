import * as Yup from 'yup';

const FormSchema = Yup.object().shape({
  exam: Yup.string().trim().required('Não se esqueça de preencher todos os campos.'),
  message: Yup.string()
    .trim()
    .min(20, 'Mínimo de 20 caracteres')
    .max(250, 'Limite de caracteres ultrapassado')
    .required('Não se esqueça de preencher todos os campos.'),
});

export default FormSchema;
