import { isEmpty, removeFalsy } from '../objectManager/objectManager';

window.dataLayerHistory = window.dataLayerHistory || [];

// Mapper
export const userMapper = (data = {}, totalItens) => {
  if (isEmpty(data)) return undefined;

  function setGender(gender) {
    if (gender === 'Masculino') return 'M';
    if (gender === 'Feminino') return 'F';

    return undefined;
  }

  function getTotal(itens) {
    if (isEmpty(itens)) return undefined;

    return {
      results: itens.length,
    };
  }

  return removeFalsy({
    id: data.id || undefined,
    state: data.state || undefined,
    type: data.role || undefined,
    city: data.city || undefined,
    uf: data.uf || undefined,
    gender: setGender(data.gender),
    specialty: data.specialty || undefined,
    search: getTotal(totalItens),
  });
};

export const patientMapper = (data = []) => {
  if (isEmpty(data)) return undefined;

  function getExams(exams) {
    if (isEmpty(exams)) return [];
    return exams.map(({ laudoId, name }) => ({
      name,
      id: laudoId,
    }));
  }

  return {
    id: data[0].protocol,
    exams: getExams(data),
  };
};

// Tracking
export const gaDataLayer = ({
  event,
  pageData,
  user,
  userId,
  patient,
  identifier,
  exam,
  searchResults,
  age,
  role,
  origin,
  gender,
  periodo,
  visitDate,
  examOrigin,
  searchType,
}) => {
  const userData = userMapper(user, searchResults);
  const patientData = patientMapper(patient);

  const data = removeFalsy({
    event,
    exam,
    page: pageData,
    user: userData,
    userId,
    perfil: role,
    patient: patientData,
    periodo,
    cip: identifier,
    p_age: age,
    p_gender: gender,
    p_last_visit_date: visitDate,
    origemExame: examOrigin,
    exameColetadoEm: origin,
    searchType: searchType,
  });

  // queue
  window.dataLayerHistory.push(data);

  window.dataLayer.push(data);
};

export default {
  dataLayer: gaDataLayer,
};
