import { simpleAction } from '~/utils/helpers';

export const SEARCH_REQUEST = '@dasa-canal-medico/SEARCH_REQUEST';
export const SEARCH_SUCCESS = '@dasa-canal-medico/SEARCH_SUCCESS';
export const SEARCH_FAILURE = '@dasa-canal-medico/SEARCH_FAILURE';
export const SEARCH_CLEAR = '@dasa-canal-medico/SEARCH_CLEAR';

// Reducer
const initialState = {
  data: {
    group: [],
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: {
          ...action.payload
        },
        isLoading: false,
      };
    case SEARCH_FAILURE:
      return {
        ...state,
        error: {
          status: action.payload.status,
          message: action.payload.message,
        },
        isLoading: false,
      };
    case SEARCH_CLEAR:
      return{
        ...state,
        data: {
          ...action.payload
        }
      }
    default:
      return state;
  }
}

// Action Creators
export const searchRequest = simpleAction(SEARCH_REQUEST);
export const searchSuccess = simpleAction(SEARCH_SUCCESS);
export const searchFailure = simpleAction(SEARCH_FAILURE);
export const searchClear = simpleAction(SEARCH_CLEAR);


export const clearSearchAction = () => (dispatch, getState) => {
  dispatch(searchClear({}))
}
