import styled from 'styled-components';

import { styles } from '~/config';
import { mediaQuery } from '~/utils/helpers';

export const Container = styled.div`
  padding: 24px;

  img {
    margin-right: 5px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    width: 48%;
  }
`;

export const Info = styled.div`
  margin: 30px 0 20px;

  p {
    margin: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;

  .ant-btn {
    padding: 0 0 50px;

    .anticon {
      color: ${styles.colors.primary};
    }

    span {
      color: ${styles.colors.default};
      font-weight: normal;
    }
  }

  ${mediaQuery.inDesktop(`
    display: none; 
  `)}
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-form-item {
    width: 48%;
  }

  ${mediaQuery.inTablet(`
    flex-direction: column; 

    .ant-form-item {
      width: 100%;

      .ant-calendar-picker {
        width: 100%;
      }
    }
  `)}

  ${mediaQuery.inMobile(`
    flex-direction: column; 

    .ant-form-item {
      width: 100%;

      .ant-calendar-picker {
        width: 100%;
      }
    }
  `)}
`;
