export const toShareReportApi = (data = {}) => ({
  protocol: data.protocol || '',
  laudoId: data.laudoId.toString() || '',
  system: data.system || 'PDF',
  email: data.email || '',
  patientName: data.patientName || '',
});

export const toApi = (data = {}) => ({
  estado: data.uf || undefined,
  numero: data.number || undefined,
  conselho: data.conselho || undefined,
});
