import styled from 'styled-components';

import { mediaQuery } from '~/utils/helpers';
import { styles } from '~/config';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px 0;

  h3 {
    margin-top: 16px;
  }

  button {
    margin-top: 24px;
  }

  ${mediaQuery.inMobile(`
    max-width: calc(100vw - 16px);
    background: ${styles.colors.defaultBackground};

    .ant-tabs-bar {
      max-width: 100vw;
    }
  `)};
`;

export const TextWrapper = styled.div`
  max-width: 400px;
`;

export const HiddenContainer = styled.div`
  display: none;
`;
