import { makeInitialState, simpleAction, errorMessage } from '~/utils/helpers';
import { updateDiscussion } from '~api/services/discussion.service';

// Action Types
export const DISCUSSION_ITEM_REQUEST = '@dasa-canal-medico/DISCUSSION_ITEM_REQUEST';
export const DISCUSSION_ITEM_SUCCESS = '@dasa-canal-medico/DISCUSSION_ITEM_SUCCESS';
export const DISCUSSION_ITEM_FAILURE = '@dasa-canal-medico/DISCUSSION_ITEM_FAILURE';

// Reducer
export const initialState = makeInitialState({ data: [] });

export default function (state = initialState, action) {
  switch (action.type) {
    case DISCUSSION_ITEM_REQUEST:
      return {
        ...state,
        ...initialState,
        loading: true,
      };
    case DISCUSSION_ITEM_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: action.payload,
        isLoading: false,
      };
    case DISCUSSION_ITEM_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}

// Action Creators
export const updateDiscussionItemRequest = simpleAction(DISCUSSION_ITEM_REQUEST);
export const updateDiscussionItemSuccess = simpleAction(DISCUSSION_ITEM_SUCCESS);
export const updateDiscussionItemFailure = simpleAction(DISCUSSION_ITEM_FAILURE);

export const updateDiscussionItemAction = id => async dispatch => {
  dispatch(updateDiscussionItemRequest());

  try {
    const payload = await updateDiscussion(id);
    dispatch(updateDiscussionItemSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(updateDiscussionItemFailure(err));
  }
};
