import styled from 'styled-components';
import { Badge as BadgeBase } from 'antd';

import { styles } from '../../../../config';

export const Badge = styled(BadgeBase)`
  && {
    .ant-badge-status-dot {
      width: 17px;
      height: 17px;
    }

    .ant-badge-status-text {
      font-size: 1rem;
    }
  }
`;

export const Text = styled.p`
  font-size: 1rem;
  color: ${styles.colors.black};
  margin-bottom: 0;
`;
