import { combineReducers } from 'redux';

import examViewRequestTokenValidationReducer from './ducks/exams/patientViewRequestTokenValidation';
import examViewRequestActionReducer from './ducks/exams/examViewRequestAction';

import authReducer from './ducks/users/auth';
import checkUserReducer from './ducks/users/checkUser';
import featureToggleReducer from './ducks/configs/featureToggles';

// ADMIN
import {
  adminActivateUserReducer,
  adminApprovedUserReducer,
  adminPatientsReducer,
  adminPendingUsersReducer,
  adminResendUserPasswordReducer,
  adminUpdateRegisterReducer,
  adminUserDetailsReducer,
  adminReproveUseReducer,
} from './ducks/admin';

// ASSISTANT
import {
  assistantCheckTokenReducer,
  assistantCreateReducer,
  assistantGroupReducer,
  assistantInfoReducer,
  assistantCompleteRegisterReducer,
} from './ducks/assistant';

// PATIENT
import {
  patientDetailReducer,
  patientItemReducer,
  patientOtherItemReducer,
  patientGroupReducer,
  patientGroupLegacyReducer,
} from './ducks/patients';

// REPORTS
import {
  examReportReducer,
  examReportPdfReducer,
  examReportPdfDownloadReducer,
  examReportShareReducer,
} from './ducks/reports';

import { searchReducer } from './ducks/search';

import userConfigReducer from './ducks/users/configUser';
import requestPasswordReducer from './ducks/users/requestPasswordReset';
import resetPasswordReducer from './ducks/users/resetPassword';
import loggedUserInfoReducer from './ducks/users/loggedUserInfo';

import examGroupReducer from './ducks/exams/examGroup';
import examItemReducer from './ducks/exams/examItem';
import examPatientDetailReducer from './ducks/exams/examPatientDetail';
import createClinicalDiscussionReducer from './ducks/exams/clinicalDiscussion';

import downloadCovidExamsReducer from './ducks/exams/downloadCovidExams';
import otherExamsStatusReducer from './ducks/exams/otherExamsStatus';
import createExamsViewRequestReducer from './ducks/exams/createExamsViewRequest';

import userRegisterReducer from './ducks/users/userRegister';
import usersUpdateStatusReducer from './ducks/users/userStatus';

import useTermsReducer from './ducks/users/useTerms';
import createRatingReducer from './ducks/users/rating';

import notificationsExamsReducer from './ducks/notifications/exams';

import releasedLaboratoriesReducer from './ducks/exams/releasedLaboratories';

import discussionReducer from './ducks/exams/discussion';

import contactUsReducer from './ducks/users/contactUs';

import statusApplicationReducer from './ducks/usersManagement/statusApplication';

const userContactReducer = combineReducers({
  help: contactUsReducer,
});

const patientCombineReducer = combineReducers({
  item: patientItemReducer,
  group: patientGroupReducer,
  groupLegacy: patientGroupLegacyReducer,
  patientDetail: patientDetailReducer,
  examViewRequestTokenValidation: examViewRequestTokenValidationReducer,
  examViewRequestAction: examViewRequestActionReducer,
});

const reportCombineReducer = combineReducers({
  pdf: examReportPdfReducer,
  image: examReportReducer,
  share: examReportShareReducer,
  pdfDownload: examReportPdfDownloadReducer,
  downloadCovidExams: downloadCovidExamsReducer,
});

const examCombineReducer = combineReducers({
  group: examGroupReducer,
  item: examItemReducer,
  report: examReportReducer,
  reports: reportCombineReducer,
  patientDetail: examPatientDetailReducer,
  otherExams: patientOtherItemReducer,
  otherExamsStatus: otherExamsStatusReducer,
  examsViewRequest: createExamsViewRequestReducer,
  createClinicalDiscussion: createClinicalDiscussionReducer,
});

const userCombineReducer = combineReducers({
  requestPassword: requestPasswordReducer,
  resetPassword: resetPasswordReducer,
});

const usersCombineReducer = combineReducers({
  details: adminUserDetailsReducer,
  register: userRegisterReducer,
  pending: adminPendingUsersReducer,
  approved: adminApprovedUserReducer,
  createRating: createRatingReducer,
  updateStatus: usersUpdateStatusReducer,
  updateInfoAdmin: adminUpdateRegisterReducer,
  adminActivateUser: adminActivateUserReducer,
  reproveUser: adminReproveUseReducer,
});

const appConfigCombineReducer = combineReducers({
  user: userConfigReducer,
});

const adminUserReducer = combineReducers({
  resendPassword: adminResendUserPasswordReducer,
});

const assistantCombineReducer = combineReducers({
  info: assistantInfoReducer,
  group: assistantGroupReducer,
  token: assistantCheckTokenReducer,
  create: assistantCreateReducer,
  completeRegister: assistantCompleteRegisterReducer,
});

const notificationsCombineReducer = combineReducers({
  exams: notificationsExamsReducer,
});

const laboratoriesCombineReducer = combineReducers({
  releasedLaboratories: releasedLaboratoriesReducer,
});

const discussionCombieReducer = combineReducers({
  discussion: discussionReducer,
});

const usersManagementCombineReducer = combineReducers({
  statusApplication: statusApplicationReducer,
});

const reducers = combineReducers({
  adminUser: adminUserReducer,
  checkUser: checkUserReducer,
  assistant: assistantCombineReducer,
  config: appConfigCombineReducer,
  loggedUserInfo: loggedUserInfoReducer,
  featureToggle: featureToggleReducer,
  auth: authReducer,
  exams: examCombineReducer,
  adminPatients: adminPatientsReducer,
  users: usersCombineReducer,
  user: userCombineReducer,
  userTerms: useTermsReducer,
  notifications: notificationsCombineReducer,
  patient: patientCombineReducer,
  laboratories: laboratoriesCombineReducer,
  userContact: userContactReducer,
  discussion: discussionCombieReducer,
  search: searchReducer,
  usersManagement: usersManagementCombineReducer,
});

export default reducers;
