import styled from 'styled-components';

import { IconCustom } from '~components/atoms';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Icon = styled(IconCustom)`
  && {
    margin-bottom: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  button span {
    font-weight: normal;
  }
`;
