import React from 'react';
import PropTypes from 'prop-types';

const Iframe = ({ title, width, height, url }) => {
  return <iframe title={title} width={width} height={height} src={url} />;
};

Iframe.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  url: PropTypes.string.isRequired,
};

Iframe.defaultProps = {
  title: '',
  width: '100%',
  height: '100%',
};

export default Iframe;
