import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { Button, IconCustom, Text, Title } from '~/components/atoms';

import { Container, TextWrapper, HiddenContainer } from './BlockedAccess.styles';

const BlockedAccess = ({ requestAccess, isLabNotReleasedForExamsView }) => {
  return isLabNotReleasedForExamsView ? (
    <Container>
      <IconCustom name={svg.maintenanceIcon} size="100px" />
      <Title htmlType="h3">Em breve</Title>

      <TextWrapper>
        <Text>
          Estamos trabalhando para prover uma melhor experiência na gestão de seus pacientes
        </Text>
      </TextWrapper>

      <HiddenContainer>
        <Button type="primary" onClick={requestAccess}>
          Solicitar histórico de exames
        </Button>
      </HiddenContainer>
    </Container>
  ) : (
    <Container>
      <IconCustom name={svg.padlockOutline} size="100px" />
      <Title testId="other-exams-blocked-access-title" htmlType="h3">
        Acesso Bloqueado
      </Title>
      <TextWrapper id="request-other-exams-anchor">
        <Text testId="other-exams-blocked-access-text">
          Para visualizar os exames prescritos por outros médicos, clique no botão abaixo.
        </Text>
      </TextWrapper>
      <Button testId="other-exams-blocked-access-button" type="primary" onClick={requestAccess}>
        Solicitar histórico de exames
      </Button>
    </Container>
  );
};

BlockedAccess.propTypes = {
  requestAccess: PropTypes.func.isRequired,
  isLabNotReleasedForExamsView: PropTypes.bool.isRequired,
};

export default memo(BlockedAccess);
