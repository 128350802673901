import styled from 'styled-components';
import { Form, Input, Tooltip, Icon } from 'antd';

export const InputItem = styled(Form.Item)``;

export const InputBase = styled(Input)``;

export const InputTooltip = styled(Tooltip)``;

export const InputIcon = styled(Icon)`
  && {
    color: #747679;

    :hover {
      color: #000;
    }
  }
`;
