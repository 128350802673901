import { stringManager } from '~utils/helpers';

const { getDdd, getPhoneNumber } = stringManager;

export const toApi = (data = {}) => {
  const ddd = getDdd(data.cellphone) || '';
  const cellphone = getPhoneNumber(data.cellphone) || '';

  return {
    ddd,
    cellphone,
    cpf: data.cpf || '',
    fullName: data.fullName || '',
    password: data.password || '',
    confirmationPassword: data.confirmationPassword || '',
  };
};
