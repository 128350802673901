import styled from 'styled-components';

export const RulesContainer = styled.div`
  padding: 2px;
`;

export const RulesTitle = styled.h5`
  margin: 0px;
  text-align: left;
  font-weight: normal;
  color: #61636b;
`;

export const RulesTag = styled.ul`
  padding-left: 0;
  color: #797c86;
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  padding: 0px;
  margin-top: 8px;
`;

export const PasswordMatch = styled.li`
  line-height: 2;
  margin: 0 8px 8px 0;
  border-radius: 4px;
  padding: 0px 10px;
  background-color: #f7f7f8;
  color: ${props => props.className.letter};
  border: 1px solid ${props => props.className.border};
`;
