import produce from 'immer';

import { status } from '~utils/enums';
import { errorMessage, simpleAction, makeInitialState } from '~/utils/helpers';

import { checkTokenAssistant } from '~/api/services/assistant.service';

// Action Types
export const ASSISTANT_TOKEN_REQUEST = '@dasa-canal-medico/ASSISTANT_TOKEN_REQUEST';
export const ASSISTANT_TOKEN_SUCCESS = '@dasa-canal-medico/ASSISTANT_TOKEN_SUCCESS';
export const ASSISTANT_TOKEN_FAILURE = '@dasa-canal-medico/ASSISTANT_TOKEN_FAILURE';

// Reducer
export const initialState = makeInitialState({
  data: {
    isValid: false,
  },
});

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case ASSISTANT_TOKEN_REQUEST: {
        draft.loading = status.processing;
        break;
      }

      case ASSISTANT_TOKEN_SUCCESS: {
        draft.data = {
          isValid: action.payload,
        };
        draft.loading = status.success;
        break;
      }

      case ASSISTANT_TOKEN_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.loading = status.error;
        break;
      }

      default:
    }
  });
}

// Action Creators
export const checkTokenAssistantRequest = simpleAction(ASSISTANT_TOKEN_REQUEST);
export const checkTokenAssistantSuccess = simpleAction(ASSISTANT_TOKEN_SUCCESS);
export const checkTokenAssistantFailure = simpleAction(ASSISTANT_TOKEN_FAILURE);

export const checkTokenAssistantActions = token => async dispatch => {
  dispatch(checkTokenAssistantRequest());

  try {
    const payload = await checkTokenAssistant(token);
    dispatch(checkTokenAssistantSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(checkTokenAssistantFailure(err));
  }
};
