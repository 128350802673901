import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { IconCustom } from '~components/atoms';
import { svg } from '~assets';

import { ModalBase } from './Modal.styles';

const Modal = ({
  children,
  testId,
  isVisible,
  isClosable,
  isCentered,
  onCancel,
  width,
  isLP,
  fullScreen,
  removePadding,
  destroyOnClose,
  maskClosable,
}) => {
  const [visible, setVisible] = useState(isVisible);

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  return (
    <ModalBase
      data-testid={testId}
      visible={visible}
      centered={isCentered}
      closable={isClosable}
      footer={null}
      onCancel={onCancel}
      width={width}
      isLP={isLP}
      fullScreen={fullScreen}
      maskClosable={maskClosable}
      removePadding={removePadding}
      destroyOnClose={destroyOnClose}
      closeIcon={<IconCustom name={svg.iconClose} size="32px" />}
    >
      {children}
    </ModalBase>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func,
  testId: PropTypes.string,
  isLP: PropTypes.bool,
  isVisible: PropTypes.bool,
  isClosable: PropTypes.bool,
  isCentered: PropTypes.bool,
  width: PropTypes.string,
  fullScreen: PropTypes.bool,
  maskClosable: PropTypes.bool,
  removePadding: PropTypes.bool,
  destroyOnClose: PropTypes.bool,
};

Modal.defaultProps = {
  testId: 'modal',
  onCancel: () => undefined,
  isVisible: false,
  isClosable: false,
  isCentered: false,
  isLP: false,
  width: undefined,
  fullScreen: false,
  maskClosable: true,
  removePadding: false,
  destroyOnClose: false,
};

export default Modal;
