import React from 'react';
import PropTypes from 'prop-types';

import { ImageBase, ImageLink, ImageWrap } from './Image.style';
import { Icon } from '..';

const Image = ({ src, canDownload, testid }) => {
  if (canDownload) {
    return (
      <ImageWrap data-testid={testid} >
        <ImageLink href={src}>
          <Icon name="download" size="40px" />
        </ImageLink>
        <ImageBase src={src} canDownload={canDownload} />
      </ImageWrap>
    )
  } else {
    return <ImageBase src={src} data-testid={testid} />
  }
}

Image.propTypes = {
  src: PropTypes.string,
  testid: PropTypes.string,
  canDownload: PropTypes.boolean
};

Image.defaultProps = {
  testid: '',
  src: undefined,
  canDownload: false
};

export default Image;
