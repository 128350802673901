import styled from 'styled-components';

import { lpStyles } from '~/config';
import { mediaQuery } from '~/utils/helpers';

export const Container = styled.div`
  padding: 0 4px 24px 0;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;

  .use-terms-logo {
    margin-top: 48px;
  }

  .use-terms-update-date-text {
    font-size: 0.9rem;
  }
`;

export const ModalFooter = styled.footer`
  width: calc(100% + 4px);
  position: relative;
  text-align: center;
  border-top: 1px solid ${lpStyles.colors.border};

  button {
    min-width: 130px;

    & + button {
      margin-left: 10px;
    }
  }
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0 0;
`;

export const TextContent = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 16px;
  padding-left: 56px;
  margin: 4px 0;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${lpStyles.colors.white};
  }

  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb:hover {
    background: ${lpStyles.colors.outline};
    border-radius: 30px;
  }

  div {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 0;
  }

  ${mediaQuery.inMobile(`
    padding-left: 16px;
  `)}
`;
