import styled from 'styled-components';
import { Form, Input, Tooltip, Icon } from 'antd';
import { lpStyles } from '~/config';

export const InputItem = styled(Form.Item)`
  * { transition: none; }
  font-family: ${lpStyles.fontFamily.default};
  position: relative !important;
  
  label {
    color: ${({ validateStatus }) => (validateStatus === 'error' ? lpStyles.colors.error : '')};
    &::after {
      content: '';
      margin: 0;
    }
  }
  &:hover {
    label {
      color: ${({ validateStatus }) => (validateStatus === 'error' ? lpStyles.colors.error : lpStyles.colors.primary)};
    }
  }
  .ant-form-item-control {
    .ant-input {
      padding: 0 15px;
      height: 56px;
      &::placeholder {
        font-size: 14px;
      }
      &:hover,
      &:focus {
        border-color: ${lpStyles.colors.primary};
        label {
          color: ${lpStyles.colors.primary};
        }
      }
    }
    &.has-error {
      .ant-input {
        &:hover {
          border-color: ${lpStyles.colors.error};
          label {
            color: ${lpStyles.colors.error};
          }
        }
        &:focus {
          border-color: ${lpStyles.colors.error};
        }
      }
    }
  }
  .ant-col.ant-form-item-label {
    position: absolute;
    top: -6px !important;
    left: 12px;
    z-index: 9;
    background-color: ${lpStyles.colors.white};
    padding: 0 4px;
    width: auto;
    &:focus {
      label {
        color: ${lpStyles.colors.primary};
      }
    }
  }
`;

export const InputBase = styled(Input)`
  transition: none;
`;

export const InputTooltip = styled(Tooltip)``;

export const InputIcon = styled(Icon)`
  && {
    color: ${lpStyles.colors.textSecondary};
    :hover {
      color: ${lpStyles.colors.black};
    }
  }
`;

export const TipBelowField = styled.span`
  display: flex;
  justify-content: flex-start;
  margin-top: 0.4em;
  font-size: 0.8em;
  color: #61636b;
  line-height: 1rem;
`;

export const WrapperIconCustom = styled.div`
  margin-right: 0.5em;
`;
