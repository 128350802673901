import api from '../api';
import { PUT, GET } from "../httpMethods";
import { apiAdmins } from '../../config/envManager';
import { toApi as toStatusApplicationApi } from '../dto/usersManagementDasa/userManagementDasa.dto';
import { toApi as toUsersApi, fromApi as fromUsersApi} from '../dto/usersManagementDasa/users.dto'

export const sendStatusApplication = async apiParams => {
  const params = toStatusApplicationApi(apiParams);

  const { data } = await api({
    url: `${apiAdmins}/private/user/application/${params.id}`,
    method: PUT,
    data: params.body,
  });

  return data;
}

export const getUsers = async (apiParams) => {
  const params = toUsersApi(apiParams);
  const url = `${apiAdmins}/private/user/application/`

  delete params.applicationId

  const { data } = await api({
    url: url,
    method: GET,
    params: params,
  });

  return fromUsersApi(data);
}
