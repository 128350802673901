import React from 'react';
import PropTypes from 'prop-types';

import { TitleStyled } from './LPTitle.styles';

const mapperTitle = {
  h1: 1,
  h2: 2,
  h3: 3,
  h4: 4,
  h5: 4,
  h6: 4,
};

const Title = ({ children, testId, htmlType }) => (
  <TitleStyled data-testid={testId} level={mapperTitle[htmlType] || mapperTitle.h1}>
    {children}
  </TitleStyled>
);

Title.propTypes = {
  testId: PropTypes.string,
  htmlType: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Title.defaultProps = {
  testId: '',
  htmlType: '',
};

export default Title;
