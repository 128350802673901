import React from 'react';
import PropTypes from 'prop-types';

import { InputBase, InputItem } from './InputPassword.styles';

const Input = ({
  onBlur,
  onChange,
  name,
  placeholder,
  label,
  size,
  value,
  error,
  testId,
  touched,
  autoFocus,
  isDisabled,
}) => {
  const hasError = !!error;
  const hasTouched = !!touched;

  return (
    <InputItem
      label={label}
      validateStatus={hasTouched && hasError ? 'error' : ''}
      help={hasTouched && hasError ? error : ''}
    >
      <InputBase
        autoComplete="off"
        autoFocus={autoFocus}
        data-testid={testId}
        size={size}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue={value}
        disabled={isDisabled}
      />
    </InputItem>
  );
};

Input.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  testId: PropTypes.string,
  size: PropTypes.string,
  touched: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.string,
};

Input.defaultProps = {
  placeholder: '',
  label: '',
  testId: '',
  size: 'large',
  touched: false,
  autoFocus: false,
  isDisabled: false,
  error: '',
  value: '',
};

export default Input;
