import React from 'react';
import PropTypes from 'prop-types';

import { Header, Title, Description } from './PageHeader.styles';

const PageHeader = ({ testId, title, description, className }) => (
  <Header data-testid={testId} className={className}>
    {description && <Description data-testid={`${testId}-description`}>{description}</Description>}
    <Title data-testid={`${testId}-title`}>{title}</Title>
  </Header>
);

PageHeader.propTypes = {
  testId: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PageHeader.defaultProps = {
  testId: 'page-header',
  className: '',
  description: undefined,
};

export default PageHeader;
