export const fromExamApi = (data = []) => {
  const mapItem = info => {
    const itemPDF = info.find(item => item.type === 'PDF');
    const hasReportItemPDF = info.find(item => item.type === 'PDF' && item.hasReport);

    const pdf = hasReportItemPDF || itemPDF || {};
    const image = info.find(item => item.type === 'IMAGE') || {};

    return {
      pdf: {
        isActive: pdf.hasReport || false,
        laudoId: pdf.laudoId || '',
        origem: pdf.origem || 'pdf',
      },
      image: {
        isActive: image.hasReport || false,
        laudoId: image.laudoId || '',
        origem: pdf.origem || 'image',
      },
    };
  };

  const mapper = data.map(item => ({
    dataOfVisit: item.dataOfVisit || '',
    identifier: item.identifier || '',
    isParent: true,
    isComplete: !!item.isComplete,
    laudoId: item.laudoId || '',
    mimeType: item.mimeType || '',
    namePatient: item.namePatient || '',
    origem: item.origem || '',
    password: item.password || '',
    protocol: item.protocol || '',
    name: item.title || 'Sem dados',
    cpf: data.cpf || 'Sem dados',
    email: data.email || 'Sem dados',
    reports: mapItem(item.reports),
  }));

  return mapper;
};

export const fromExamDetailPatientApi = (data = {}) => {
  const mapper = {
    cpf: data.cpf || 'Sem dados',
    email: data.email || 'Sem dados',
    crm: data.crm || '',
    protocol: data.protocol || '',
    requestId: data.requestId || '',
    identifier: data.identifier || '',
    gender: data.sexo || '',
    name: data.namePatient || '',
    birthdate: data.dataNascimento || '',
    birthdateMs: data.dataNascimentoMes || '',
  };

  return mapper;
};
