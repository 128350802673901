/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { windowSize } from '~/utils/helpers';
import { IconCustom, Tooltip } from '~/components/atoms';
import { CopyText, CopyTextContent, Wrapper } from './CopyIcon.styles';

const CopyIcon = ({ testId, value }) => {
  const size = windowSize();
  const isMobile = size.width <= 768;
  const isDesktop = size.width > 768;

  const [isCopyIconHovered, setIsCopyIconHovered] = useState(false);
  const [isIconCLicked, setIsIconClicked] = useState(false);
  const [tooltipText, setTooltipText] = useState(isMobile ? 'Copiado' : '');

  const mouseIn = () => {
    setIsCopyIconHovered(true);
    setTimeout(() => {
      setTooltipText('Copiado');
    }, 100);
  };

  const mouseOut = () => {
    setIsCopyIconHovered(false);
    setIsIconClicked(false);

    if (isDesktop) setTooltipText('');
  };

  return (
    <Tooltip text={tooltipText} trigger={['click', 'hover']}>
      <Wrapper>
        <CopyText text={value} onCopy={() => setIsIconClicked(true)}>
          <CopyTextContent>
            <IconCustom
              testId={testId}
              size="24px"
              name={
                isMobile ? (
                  isCopyIconHovered ? svg.copyActive : svg.copyInactive
                ) : (
                  isIconCLicked ? svg.copyActive : (
                    isCopyIconHovered ? svg.copyHover : svg.copyInactive
                  )
                )
              }
              onMouseOver={() => mouseIn(true)}
              onMouseOut={() => mouseOut()}
            />
          </CopyTextContent>
        </CopyText>
      </Wrapper>
    </Tooltip>
  );
};

CopyIcon.propTypes = {
  testId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default CopyIcon;
