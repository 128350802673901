import styled from 'styled-components';
import { Form, DatePicker } from 'antd';
import { styles } from '~/config';

export const FormItem = styled(Form.Item)``;

export const DatePickerBase = styled(DatePicker)`
  && {
    .ant-calendar-picker-input {
      padding-left: 44px;
    }

    .ant-calendar-picker-icon {
      transform: rotate(90deg);
      margin-top: -4px;
      width: 8px;
    }

    .ant-calendar-picker-clear > svg {
      color: ${styles.colors.primary};
      height: 16px;
      width: 16px;
      position: relative;
      left: -6px;
    }
  }
`;

export const PreffixIcon = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  position: absolute;
  margin: 0 16px;
  z-index: 1;
`;
