import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '~components/atoms';
import { FormWrapper } from '~components/molecules';

import Form from './Form/Form';
import formSchema from './Form/Form.schema';

const initialValues = {
  email: '',
};

const ModalCreateAssistant = ({ testId, isVisible, closeModal, isLoading, onSubmit }) => {
  return (
    <Modal
      testId={testId}
      isVisible={isVisible}
      onCancel={closeModal}
      width="400px"
      maskClosable={false}
      isClosable
      removePadding
      destroyOnClose
    >
      <FormWrapper
        enableReinitialize
        initialValues={initialValues}
        validationSchema={formSchema}
        isLoading={isLoading}
        onSubmit={values => onSubmit(values)}
        render={props => (
          <Form {...props} testId={testId} isLoading={isLoading} closeModal={closeModal} />
        )}
      />
    </Modal>
  );
};

ModalCreateAssistant.propTypes = {
  testId: PropTypes.string,
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  closeModal: PropTypes.func,
};

ModalCreateAssistant.defaultProps = {
  testId: '',
  isVisible: true,
  isLoading: false,
  onSubmit: () => undefined,
  closeModal: () => undefined,
};

export default ModalCreateAssistant;
