export const fromApi = (data = {}) => {
  const firstOption = { id: '', value: '', text: 'Todos' };
  const mapperData = data.map(item => ({
    id: item.codigo || '',
    value: item.codigo || '',
    text: item.name || '',
  }));

  return [firstOption, ...mapperData];
};

export const fromReleasedLabsApi = (data = {}) => ({
  labName: data.labName || '',
  notificationsLabName: data.notificationsLabName || '',
  logo: data.logo || '',
});
