export default {
  navigation: 'navigation',
  hotjar: 'submit_hotjar_form',

  public: {
    register: 'cadastro_success',
    login: 'login_success',
  },

  private: {
    reload: 'login_persist',

    header: {
      menu: {
        editProfile: 'click_edit_profile',
        myAssistants: 'click_my_assistants',
      },
      contacts: 'click_contacts',
      notifications: 'click_notificatiions',
      adminNewRegister: 'click_admin_new_register',
    },

    sidebar: {
      exams: 'click_sidebar_exams',
      myPatients: 'click_sidebar_my_patients',
      pendingUsers: 'click_sidebar_pending_approvals',
      healthUsers: 'click_sidebar_health_users',
      operators: 'click_sidebar_operators',
      patientManagment: 'click_sidebar_patient_managment',
    },

    patients: {
      banner: {
        covid: {
          link: 'click_covid_banner_link',
          video: 'click_covid_banner_video',
        },
        dasaEduca: {
          link: 'click_dasa_educa_banner_link',
          video: 'click_dasa_educa_banner_video',
        },
      },
      search: 'search_semFiltro',
      patientDetail: 'click_patient_details',
      filteredSearch: 'search_comFiltro',
      covidExamsDownload: 'covid_exams_download',
    },

    patientDetail: {
      searchExams: 'search_exams',
      otherExamsTab: 'click_prescribed_by_other_doctors_tab',
      clinicalDiscussion: 'clinical_discussion',
      requestOtherExamsHistory: 'request_other_exams_history',
      examsAction: {
        pdf: 'click_open_pdf_exam',
        share: 'share_exam',
        image: 'click_image_exam',
        download: 'click_download_exam',
      },
    },
  },

  default: '',
};
