export default {
  colors: {
    default: '#ABABAD',
    primary: '#0037FF',
    primaryActive: '#7192f4',
    primaryHover: '#4770eb',
    secondary: '#002CCC',
    error: '#ff3867',
    success: '#2ECC71',
    warning: '#E74C3C',
    info: '',
    alert: '#E74C3C',
    white: '#fff',
    black: '#000',
    text: '#010b19',
    textSecondary: '#747679',
    borderColor: '',
    borderColorSecondary: '#747679',
    link: '#3d2892',
    linkHover: '#010B19',
    modalErrorBg: 'rgba(255, 56, 103, 0.31)',
    modalSuccessBg: '#CEC9E4',
    modalErrorColor: '#FF3867',
    modalSuccessColor: '#3d2892',
    scrollBarColor: '#C3C3C3',
    defaultBackground: '#FFFFFF',
    listItemDate: '#66686b',
    listItemTime: '#a0a9ba',
    listItemLine: '#CFD3DC',
    listItemHover: '#DDDFE7',
    protocolHover: '#EEF0F5',
    protocolActive: '#e3dff6',
    // mantive esse hex duplicado por conta da mudança da marca não interferir
    // na identificação dos status
    examsStatusLiberado: '#2ecc71',
    examsStatusParcial: '#ffba3f',
    examsStatusCancelado: '#ea486a',
    examsStatusSuspenso: '#797c86',
    examsStatusAberto: '#0037ff',
  },
  spacing: {
    default: '8px',
  },
  fontFamily: {
    default: 'Roboto',
  },
  fontSize: {
    default: '16px',
    small: '10px',
    big: '18px',
    large: '22px',
    xlarge: '24px',
  },
};
