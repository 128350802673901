import React from 'react';
import PropTypes from 'prop-types';

import { examsStatus } from '~/utils/enums';
import { examsPagination } from '~/utils/helpers';
import { Skeleton } from '~/components/atoms';
import { Empty, ExamsActions } from '..';

import {
  Badge,
  Header,
  HeaderTitle,
  List,
  ListItem,
  Text,
  Time,
  Title,
  ContentUser,
  ContentExam,
} from './ExamsHistoryList.styles';

const getConfigPagination = (qtdItens, total) => {
  if (qtdItens <= examsPagination.totalMinToShowPagination) {
    return false;
  }

  return {
    total,
    showTotal: examsPagination.showTotal,
    itemRender: examsPagination.itemRender,
    defaultCurrent: 1,
    showSizeChanger: true,
    pageSizeOptions: examsPagination.pageSizeOptions,
  };
};

const ExamsHistoryList = ({
  data,
  total,
  isAdmin,
  isLoading,
  isLoadingPdf,
  isLoadingImage,
  isLoadingPdfDowload,
  onClickPDF,
  onClickImage,
  onClickPDFDownload,
  validateDownload,
  openShareReportModal,
}) => {
  const hasData = !!data.length;
  const pagination = getConfigPagination(data.length, total);

  if (isLoading) {
    return <Skeleton />;
  }

  return hasData ? (
    <>
      <Header>
        <HeaderTitle>Nome</HeaderTitle>
      </Header>
      <List
        data-collect="exam-table"
        bordered={false}
        dataSource={data}
        pagination={pagination}
        renderItem={item => {
          const { reports, isComplete } = item;

          return (
            <ListItem data-collect="exam-table-line">
              <ContentUser>
                <Title data-collect="exam-title">{item.name}</Title>
                <Text>
                  <strong>Prot:</strong> {item.protocol}
                </Text>
                <Text data-collect="exam-status">
                  <Badge
                    status={isComplete ? examsStatus.success.status : examsStatus.processing.status}
                    text={isComplete ? examsStatus.success.text : examsStatus.processing.text}
                  />
                </Text>
              </ContentUser>
              <ContentExam>
                <Time dateTime={item.dataOfVisit}>{item.dataOfVisit}</Time>
                <Text>{item.origin}</Text>
                <ExamsActions
                  item={item}
                  report={reports}
                  isAdmin={isAdmin}
                  onClickPDF={onClickPDF}
                  onClickImage={onClickImage}
                  onClickPDFDownload={onClickPDFDownload}
                  validateDownload={validateDownload}
                  isLoadingPdf={isLoadingPdf}
                  isLoadingImage={isLoadingImage}
                  isLoadingPdfDowload={isLoadingPdfDowload}
                  openShareReportModal={openShareReportModal}
                />
              </ContentExam>
            </ListItem>
          );
        }}
      />
    </>
  ) : (
    <Empty
      testId="empty-exam"
      iconName="file-search"
      title="Não foram encontrados resultados para a sua pesquisa…"
    />
  );
};

ExamsHistoryList.propTypes = {
  data: PropTypes.arrayOf([
    PropTypes.shape({
      name: PropTypes.string,
      isComplete: PropTypes.string,
      dataOfVisit: PropTypes.string,
      origin: PropTypes.string,
      protocol: PropTypes.string,
    }),
  ]).isRequired,
  total: PropTypes.number,
  isAdmin: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingPdf: PropTypes.bool,
  isLoadingImage: PropTypes.bool,
  isLoadingPdfDowload: PropTypes.bool,
  onClickPDF: PropTypes.func,
  onClickImage: PropTypes.func,
  onClickPDFDownload: PropTypes.func,
  validateDownload: PropTypes.func,
  openShareReportModal: PropTypes.func,
};

ExamsHistoryList.defaultProps = {
  total: 0,
  isAdmin: false,
  isLoading: false,
  isLoadingPdf: false,
  isLoadingImage: false,
  isLoadingPdfDowload: false,
  onClickImage: () => undefined,
  onClickPDF: () => undefined,
  onClickPDFDownload: () => undefined,
  validateDownload: () => undefined,
  openShareReportModal: () => undefined,
};

export default ExamsHistoryList;
