import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { svg } from '~/assets';
import { styles } from '~/config';

import { types } from '~/utils/enums';
import { notification } from '~/utils/helpers';

import { Modal, Text, Title, Button, IconCustom } from '~/components/atoms';

import { Footer, Rate, Container, NotificationTitle } from './ModalRating.styles';

const ModalRating = ({ testId, onSubmit, showRatingModal }) => {
  const [values, setValues] = useState({ experience: 0, reports: 0 });
  const [visible, setVisible] = useState(false);
  const [isExperienceStep, setIsExperienceStep] = useState(true);

  const { loading, error } = useSelector(state => state.users.createRating);

  useEffect(() => {
    if (showRatingModal) setVisible(true);
  }, [showRatingModal]);

  const hasError = () => !!error.status;

  const closeModal = () => setVisible(false);

  const onClickBack = () => setIsExperienceStep(true);
  const onClickNext = () => setIsExperienceStep(false);

  const onClickRate = async () => {
    await onSubmit({ rating: values.experience, feature: 'Experiência no Canal Médico' });

    await onSubmit({ rating: values.reports, feature: 'Modelo de laudo' });

    if (hasError()) {
      return notification.open({
        title: <NotificationTitle>Erro ao enviar avaliação</NotificationTitle>,
        closeIcon: <IconCustom name={svg.iconClose} size="24px" />,
        style: { backgroundColor: styles.colors.warning },
        duration: 4,
      });
    }

    notification.open({
      title: <NotificationTitle>Obrigado pelo feedback</NotificationTitle>,
      closeIcon: <IconCustom name={svg.sendIcon} size="24px" />,
      style: { backgroundColor: styles.colors.success },
      duration: 4,
    });

    closeModal();
  };

  return (
    <Modal
      isClosable
      destroyOnClose
      width="380px"
      testId={testId}
      onCancel={closeModal}
      isVisible={visible}
      maskClosable={false}
    >
      <Container>
        <Title testId={`${testId}-title`} htmlType="h3">
          Avaliação
        </Title>

        {isExperienceStep && (
          <>
            <Text>
              Como está sendo até agora a sua <strong>experiência</strong> com o{' '}
              <strong>Livia Exames</strong>?
            </Text>
            <Rate
              defaultValue={values.experience}
              onChange={value => setValues({ ...values, experience: value })}
            />
          </>
        )}

        {!isExperienceStep && (
          <>
            <Text>
              Como você avalia o <strong>modelo de laudo</strong> que estamos exibindo?
            </Text>
            <Rate
              defaultValue={values.reports}
              onChange={value => setValues({ ...values, reports: value })}
            />
          </>
        )}

        <Footer>
          <Button
            id="rating-back-btn"
            isOutline
            testId={`${testId}-cancel-btn`}
            isDisabled={loading}
            onClick={isExperienceStep ? closeModal : onClickBack}
          >
            {isExperienceStep ? 'Avaliar depois' : 'Voltar'}
          </Button>

          <Button
            testId={`${testId}-submit-btn`}
            type={types.primary}
            isLoading={loading}
            isDisabled={
              (isExperienceStep && !values.experience) || (!isExperienceStep && !values.reports)
            }
            onClick={isExperienceStep ? onClickNext : onClickRate}
          >
            {isExperienceStep ? 'Próximo' : 'Avaliar'}
          </Button>
        </Footer>
      </Container>
    </Modal>
  );
};

ModalRating.propTypes = {
  showRatingModal: PropTypes.bool,
  testId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ModalRating.defaultProps = {
  showRatingModal: false,
};

export default ModalRating;
