import React from 'react';
import PropTypes from 'prop-types';
import { RulesContainer, RulesTitle, RulesTag, PasswordMatch } from './PasswordRules.styles';

const PasswordRules = ({ value }) => {
  const parseUpperLetter = value => /[A-Z]/g.test(value);
  const parseLowerLetter = value => /[a-z]/g.test(value);
  const parseContainsNumber = value => /[0-9]/g.test(value);
  const parseContainsSpecialCharacter = value => /[~`!#@$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(value);
  const parseContains8Characters = value => value.length >= 8;

  return (
    <RulesContainer>
      <RulesTitle>Sua a nova senha deve conter:</RulesTitle>
      <RulesTag>
        <PasswordMatch className={parseUpperLetter(value) ? successPassword : wrongPassword}>
          Letra maíuscula
        </PasswordMatch>
        <PasswordMatch className={parseLowerLetter(value) ? successPassword : wrongPassword}>
          Letra minúscula
        </PasswordMatch>
        <PasswordMatch
          className={parseContains8Characters(value) ? successPassword : wrongPassword}
        >
          Mínimo de 8 dígitos
        </PasswordMatch>
        <PasswordMatch className={parseContainsNumber(value) ? successPassword : wrongPassword}>
          Número
        </PasswordMatch>
        <PasswordMatch
          className={parseContainsSpecialCharacter(value) ? successPassword : wrongPassword}
        >
          Caracter especial
        </PasswordMatch>
      </RulesTag>
    </RulesContainer>
  );
};

const successPassword = {
  letter: '#29a34e',
  border: '#29a34e',
};

const wrongPassword = {
  border: '#F7F7F8',
  letter: '#797C86',
};

PasswordRules.propTypes = {
  value: PropTypes.string,
};

PasswordRules.defaultProps = {
  value: '',
};

export default PasswordRules;
