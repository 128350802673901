import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Title, Text } from '../../atoms';

import { ModalContent, ModalFooter, TextWrapper } from './ModalConfirmation.styles';

const ModalConfirmation = ({
  testId,
  title,
  text,
  actionText,
  cancelText,
  actionClickConfirm,
  actionClickCancel,
  isVisible,
  onCancel,
  width,
  isLoading,
}) => {
  return (
    <Modal isVisible={isVisible} onCancel={onCancel} width={width}>
      <ModalContent>
        <Title htmlType="h3" testId={`${testId}-title`}>
          {title}
        </Title>

        <TextWrapper>
          <Text testId={`${testId}-title`}>{text}</Text>
        </TextWrapper>

        <ModalFooter>
          <Button
            testId={`${testId}-btn-cancel`}
            onClick={actionClickCancel}
            isOutline
            isDisabled={isLoading}
          >
            {cancelText}
          </Button>
          <Button
            testId={`${testId}-btn-confirm`}
            onClick={actionClickConfirm}
            type="primary"
            isLoading={isLoading}
          >
            {actionText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ModalConfirmation.propTypes = {
  testId: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  actionText: PropTypes.string,
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  cancelText: PropTypes.string,
  actionClickConfirm: PropTypes.func,
  actionClickCancel: PropTypes.func,
  onCancel: PropTypes.func,
  width: PropTypes.string,
};

ModalConfirmation.defaultProps = {
  testId: 'modal-feedback',
  title: '',
  text: '',
  actionText: '',
  isVisible: false,
  isLoading: false,
  cancelText: '',
  actionClickConfirm: () => undefined,
  actionClickCancel: () => undefined,
  onCancel: () => undefined,
  width: '',
};

export default ModalConfirmation;
