import { objectManager, stringManager } from '~/utils/helpers';

const { isEmpty } = objectManager;
const {
  removeMask,
  addCpfMask,
  addRgMask,
  addCellphoneMask,
  addTelephoneMask,
  addCepMask,
} = stringManager;

export const toApi = (data = {}) => {
  const mapper = {
    fullName: data.fullName || '',
    email: data.email || '',
    rg: data.rg || '',
    specialty: data.specialty || '',
    gender: data.gender || '',
    telephone: [
      {
        type: 'TELEFONE',
        ddd: data.telephone.slice(1, 3) || '',
        telephone: data.telephone.slice(4) || '',
      },
      {
        type: 'CELULAR',
        ddd: data.cellphone.slice(1, 3) || '',
        telephone: data.cellphone.slice(4) || '',
      },
    ],
    address: [
      {
        cep: data.cep,
        city: data.city,
        address: data.address,
        addressComplement: data.complement,
        addressNumber: data.number,
        state: data.state,
      },
    ],
  };

  return mapper;
};

export const fromApi = (data = {}) => {
  const mapperAddress = address => ({ ...address });

  const mapperTelephone = phone => {
    const number = phone.find(item => item.type === 'TELEFONE');

    if (isEmpty(number)) return '';

    const telephone = `${number.ddd.trim()}${number.telephone.trim()}`;

    return addTelephoneMask(telephone, removeMask);
  };

  const mapperCellphone = phone => {
    const number = phone.find(item => item.type === 'CELULAR');

    if (isEmpty(number)) return '';

    const cellphone = `${number.ddd.trim()}${number.telephone.trim()}`;

    return addCellphoneMask(cellphone, removeMask);
  };

  const address = mapperAddress(data.address[0]);

  const mapper = {
    fullName: data.fullName || '',
    cpf: addCpfMask(data.cpf, removeMask) || '',
    uf: data.congregationState || '',
    conselho: data.congregation || '',
    registerNumber: data.congregationNumber || '',
    email: data.email || '',
    cellphone: mapperCellphone(data.telephone),
    telephone: mapperTelephone(data.telephone),
    specialty: data.specialty || '',
    rg: addRgMask(data.rg, removeMask) || '',
    gender: data.gender || '',
    cep: addCepMask(address.cep, removeMask) || '',
    address: address.address || '',
    number: address.addressNumber || '',
    complement: address.addressComplement || '',
    state: address.state || '',
    city: address.city || '',
  };

  return mapper;
};
