import produce from 'immer';

import { checkUser } from '~/api/services/auth.service';
import { simpleAction, makeInitialState } from '~/utils/helpers';

export const CHECK_USER_REQUEST = '@dasa-canal-medico/CHECK_USER_REQUEST';
export const CHECK_USER_SUCCESS = '@dasa-canal-medico/CHECK_USER_SUCCESS';
export const CHECK_USER_FAILURE = '@dasa-canal-medico/CHECK_USER_FAILURE';
export const CHECK_USER_CLEAN = '@dasa-canal-medico/CHECK_USER_CLEAN';

// Reducer
export const initialState = makeInitialState({ data: {} });

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case CHECK_USER_REQUEST: {
        draft.loading = true;
        draft.error = {
          message: '',
          status: '',
        };
        break;
      }
      case CHECK_USER_SUCCESS: {
        draft.data = action.payload;
        draft.loading = false;
        break;
      }
      case CHECK_USER_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.loading = false;
        break;
      }
      case CHECK_USER_CLEAN: {
        draft.data = {};
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

// Action Creators
export const checkUserRequest = simpleAction(CHECK_USER_REQUEST);
export const checkUserSuccess = simpleAction(CHECK_USER_SUCCESS);
export const checkUserFailure = simpleAction(CHECK_USER_FAILURE);
export const checkUserClean = simpleAction(CHECK_USER_CLEAN);

export const checkUserAction = params => async dispatch => {
  dispatch(checkUserRequest());

  try {
    const payload = await checkUser(params);

    dispatch(checkUserSuccess(payload));
  } catch (error) {
    const err = {
      message: error.response?.data?.message || '',
      status: error.response?.status || '',
    };

    dispatch(checkUserFailure(err));
  }
};

export const checkUserCleanAction = () => dispatch => {
  dispatch(checkUserClean());
};
