import produce from 'immer';

import { status } from '~utils/enums';
import { errorMessage, simpleAction, makeInitialState } from '~/utils/helpers';

import { completeRegisterAssistant } from '~/api/services/assistant.service';

// Action Types
export const ASSISTANT_COMPLETE_REGISTER_REQUEST =
  '@dasa-canal-medico/ASSISTANT_COMPLETE_REGISTER_REQUEST';
export const ASSISTANT_COMPLETE_REGISTER_SUCCESS =
  '@dasa-canal-medico/ASSISTANT_COMPLETE_REGISTER_SUCCESS';
export const ASSISTANT_COMPLETE_REGISTER_FAILURE =
  '@dasa-canal-medico/ASSISTANT_COMPLETE_REGISTER_FAILURE';

// Reducer
export const initialState = makeInitialState({ data: {} });

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case ASSISTANT_COMPLETE_REGISTER_REQUEST: {
        draft.loading = status.processing;
        break;
      }

      case ASSISTANT_COMPLETE_REGISTER_SUCCESS: {
        draft.data = action.payload;
        draft.loading = status.success;
        break;
      }

      case ASSISTANT_COMPLETE_REGISTER_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.loading = status.error;
        break;
      }

      default:
    }
  });
}

// Action Creators
export const completeRegisterRequest = simpleAction(ASSISTANT_COMPLETE_REGISTER_REQUEST);
export const completeRegisterSuccess = simpleAction(ASSISTANT_COMPLETE_REGISTER_SUCCESS);
export const completeRegisterFailure = simpleAction(ASSISTANT_COMPLETE_REGISTER_FAILURE);

export const completeRegisterActions = ({
  fullName,
  cpf,
  cellphone,
  password,
  confirmationPassword,
  token,
}) => async dispatch => {
  dispatch(completeRegisterRequest());

  try {
    const payload = await completeRegisterAssistant({
      fullName,
      cpf,
      cellphone,
      password,
      confirmationPassword,
      token,
    });
    dispatch(completeRegisterSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(completeRegisterFailure(err));
  }
};
