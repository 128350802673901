import { simpleAction, errorMessage } from '~/utils/helpers';
import { sendRegister, sendAdminRegister } from '~/api/services/users.service';

export const REGISTER_REQUEST = '@dasa-canal-medico/REGISTER_REQUEST';
export const REGISTER_SUCCESS = '@dasa-canal-medico/REGISTER_SUCCESS';
export const REGISTER_FAILURE = '@dasa-canal-medico/REGISTER_FAILURE';

// Reducer
const initialState = {
  data: {
    fullName: '',
    uf: '',
    congregation: '',
    congregationNumber: '',
    cpf: '',
    email: '',
    cellphone: '',
    gender: '',
    dateBirth: '',
    password: '',
    isRegister: false,
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        ...state,
        data: {},
        error: {
          message: '',
          status: '',
        },
        isLoading: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        data: { ...action.payload, isRegister: true },
        error: {
          message: '',
          status: '',
        },
        isLoading: false,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        data: {},
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };

    default:
      return state;
  }
}

// Action Creators
const registerRequest = simpleAction(REGISTER_REQUEST);
const registerSuccess = simpleAction(REGISTER_SUCCESS);
const registerFailure = simpleAction(REGISTER_FAILURE);

export const registerAction = params => async dispatch => {
  dispatch(registerRequest());

  try {
    const payload = await sendRegister(params);
    dispatch(registerSuccess(payload));
  } catch (err) {
    const error = {
      status: err.response.status,
      message: err.response.data.message || '',
    };
    dispatch(registerFailure(error));
  }
};

export const adminRegisterAction = params => async dispatch => {
  dispatch(registerRequest());

  try {
    const payload = await sendAdminRegister(params);
    dispatch(registerSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(registerFailure(err));
  }
};
