import { objectManager } from '~utils/helpers';

const { isEmpty, isArray } = objectManager;

export const fromApi = (data = {}) => {
  if (isEmpty(data)) return {};

  const mapperTelephone = phone => {
    if (isEmpty(phone) || !isArray(phone)) return '';

    const number = phone.find(item => item.type === 'TELEFONE') || {};

    if (isEmpty(number.ddd) || isEmpty(number.number)) return '';

    return `(${number.ddd.trim()}) ${number.number.trim()}`;
  };

  const mapperCellphone = phone => {
    if (isEmpty(phone) || !isArray(phone)) return '';

    const number = phone.find(item => item.type === 'CELULAR') || {};

    if (isEmpty(number.ddd) || isEmpty(number.number)) return '';

    return `(${number.ddd.trim()}) ${number.number.trim()}`;
  };

  const mapperCRM = crms => {
    return crms.map(item => ({
      uf: item.uf || '',
      conselho: item.conselho || '',
      number: item.number || '',
    }));
  };

  const mapperDoctors = doctors => {
    return doctors.map(item => ({
      id: item.doctorId || '',
      name: item.doctorName || '',
      crms: mapperCRM(item.crms) || [],
    }));
  };

  return {
    id: data.id || '',
    name: data.name || '',
    cpf: data.cpf || '',
    email: data.email || '',
    cellphone: mapperCellphone(data.telephones),
    telephone: mapperTelephone(data.telephones),
    doctors: mapperDoctors(data.doctors),
    currentDoctor: mapperDoctors(data.doctors)[0] || {},
  };
};
