import userProfile from '../../assets/sgvs/user.svg';
import config from '../../assets/sgvs/config.svg';

export default {
  default: [],
  user: [
    {
      key: 1,
      testId: 'menu-header-link-my-profile',
      icon: userProfile,
      size: '24px',
      label: 'Meu perfil',
      url: '#',
    },
  ],
  admin: [
    {
      key: 1,
      testId: 'menu-header-link-config',
      icon: config,
      size: '24px',
      label: 'Configurações',
      url: '#',
    },
  ],
};
