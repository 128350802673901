/* eslint-disable indent */
import api from '~/api';
import { GET, POST } from '~/api/httpMethods';
import { apiPatients, apiReports, apiDoctors } from '~/config/envManager';
import { toApi as toDiscussionApi } from '~/api/dto/exams/discussion.create';
import { fromApi as fromReportApi } from '~/api/dto/reports/examReport.dto';
import { fromExamApi, fromExamDetailPatientApi } from '~/api/dto/exams/exams.dto';
import { toShareReportApi, toApi as toParamsApi } from '~/api/dto/reports/share.dto';

export const getExam = async (id, crm) => {
  const { data } = await api({
    url: `${apiPatients}/private/admin/history/exams?requestId=${id}&crm=${crm}`,
    method: GET,
  });

  const response = fromExamApi(data);

  return response;
};

export const getAdminPatientDetail = async (protocol, id) => {
  const { data } = await api({
    url: `${apiPatients}/private/admin/history/patient-detail?protocol=${protocol}&identifier=${id}`,
    method: GET,
  });

  const response = fromExamDetailPatientApi(data);

  return response;
};

export const getDoctorPatientDetail = async (protocol, id) => {
  const { data } = await api({
    url: `${apiPatients}/private/doctor/history/patient-detail?protocol=${protocol}&identifier=${id}`,
    method: GET,
  });

  const response = fromExamDetailPatientApi(data);

  return response;
};

export const getExamReport = async ({
  crm,
  protocol,
  laudoId,
  origem = '',
  index = undefined,
  isAdmin = true,
  isMotion = false,
  uf = undefined,
  number = undefined,
  conselho = undefined,
  ...doctorInfo
}) => {
  const origin = isMotion ? 'motion' : origem;

  const url = isAdmin
    ? `${apiReports}/private/admin/protocol/${protocol}/report/${laudoId}/crm/${crm}/origem/${origin}`
    : `${apiReports}/private/doctor/open`;

  const params = isAdmin
    ? {}
    : {
        estado: uf,
        numero: number,
        conselho,
        index,
        laudoId,
        protocol,
        system: origem,
        motion: isMotion,
        ...doctorInfo,
      };

  const { data } = await api({
    url,
    params: { ...params, registerAction: false },
    method: GET,
  });

  const response = fromReportApi({ data, url, params, isAdmin });

  return response;
};

export const downloadExamReport = async ({
  crm,
  protocol,
  laudoId,
  origem = 'teste',
  index = undefined,
  isAdmin = true,
  isMotion = false,
  uf = '',
  number = '',
  conselho = '',
}) => {
  const origin = isMotion ? 'motion' : origem;

  const url = isAdmin
    ? `${apiReports}/private/admin/protocol/${protocol}/report/${laudoId}/crm/${crm}/origem/${origin}/download`
    : `${apiReports}/private/doctor/download`;

  const params = isAdmin
    ? {}
    : {
        estado: uf,
        numero: number,
        conselho,
        crm,
        index,
        laudoId,
        protocol,
        system: origem,
        motion: isMotion,
      };

  const { data } = await api({
    url,
    method: GET,
    params: { ...params, registerAction: false },
  });

  const response = fromReportApi({ data, url, params, isAdmin });

  return response;
};

export const shareReport = async params => {
  const body = toShareReportApi(params);
  const urlParams = toParamsApi(params);

  const { data } = await api({
    url: `${apiReports}/private/doctor/share`,
    method: POST,
    data: body,
    params: urlParams,
  });

  return data;
};

export const createDiscussion = async params => {
  const body = toDiscussionApi(params);

  const { data } = await api({
    url: `${apiDoctors}/private/report/post-discussion`,
    method: POST,
    data: body,
  });

  return data;
};
