import styled from 'styled-components';
import { Form, Input } from 'antd';

import { styles } from '~/config';
import { mediaQuery } from '~/utils/helpers';

export const InputWrapper = styled(Form.Item)`
  width: 100%;
`;

export const InputBase = styled(Input.Search)`
  && {
    width: 100%;
    ${mediaQuery.inDesktop(`
      padding-right: 10px;
    `)}
    @media (max-width: 1100px) {
      padding-right: 0px;
    }

    .ant-input-suffix {
      width: 46px;
      height: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      border-radius: 6px;

      ${mediaQuery.inMobile(`
        background: none;
      `)};

      .anticon svg {
        font-size: 24px;
        color: ${styles.colors.primary};
      }
    }

    > input {
      padding-right: 50px !important;

      &::placeholder {
        font-size: 16px;
        color: ${styles.colors.textSecondary};
      }

      ${mediaQuery.inMobile(`
        padding-right: 40px !important;
      `)};
    }
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -40px;
  left: ${({ hasAddonAfter }) => (hasAddonAfter ? '-82px' : '-9px')};
  cursor: pointer;

  div {
    position: absolute;

    svg {
      z-index: 1;
      cursor: pointer;
    }
  }
`;

export const AddonAfter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 20px;
  left: -36px;

  div {
    position: absolute;
    top: -32px;
    display: flex;
    justify-content: center;

    svg {
      z-index: 1;
      cursor: pointer;
    }
  }
`;
