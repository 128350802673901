export const toApi = (data = {}) => ({
  patientName: data.patientName || '',
  identifier: data.identifier || '',
  birthDate: data.birthDate || '',
  protocol: data.protocol || '',
  laudoId: data.laudoId ? data.laudoId.toString() : '',
  examDate: data.examDate || '',
  system: data.system || 'pdf',
  motion: data.motion || false,
  isImage: data.isImage || false,
  index: (data.index && data.index.toString()) || '0',
  examName: data.examName || '',
  labName: data.labName || '',
  message: data.message || '',
});
