import React from 'react';
import PropTypes from 'prop-types';

import { modalType } from '../../../utils/enums';

import { Button, Modal, Title, Text, Icon } from '../../atoms';

import { ModalContent, ModalIcon, ModalFooter, WrapperText } from './ModalFeedback.styles';

const ModalFeedback = ({
  testId,
  width,
  title,
  text,
  type,
  actionText,
  iconSize,
  actionClick,
  isVisible,
  noButton,
  fontBig,
}) => {
  const hasType = !!type;
  const iconInfo = modalType[type] || modalType.default;

  return (
    <Modal isVisible={isVisible} width={width}>
      <ModalContent type={type}>
        {hasType && (
          <ModalIcon bg={iconInfo.bg}>
            <Icon
              testid={`${testId}-icon`}
              name={iconInfo.name}
              color={iconInfo.color}
              size={iconSize}
            />
          </ModalIcon>
        )}

        <Title htmlType="h3" testId={`${testId}-title`}>
          {title}
        </Title>

        <WrapperText fontBig={fontBig}>
          <Text testId={`${testId}-title`}>{text}</Text>
        </WrapperText>

        {!noButton && (
          <ModalFooter>
            <Button testId={`${testId}-btn`} onClick={actionClick} type="primary">
              {actionText}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

ModalFeedback.propTypes = {
  testId: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  iconSize: PropTypes.string,
  actionText: PropTypes.string,
  actionClick: PropTypes.func,
  isVisible: PropTypes.bool,
  isCentered: PropTypes.bool,
  showActions: PropTypes.bool,
  fontBig: PropTypes.bool,
};

ModalFeedback.defaultProps = {
  testId: 'modal-feedback',
  title: '',
  text: '',
  type: '',
  iconSize: '40px',
  actionText: '',
  actionClick: () => undefined,
  isVisible: false,
  isCentered: false,
  showActions: true,
  fontBig: false,
};

export default ModalFeedback;
