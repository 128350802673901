import React from 'react';
import PropTypes from 'prop-types';

import { Title, Text } from '../../atoms';
import { ReactComponent as CustomIcon } from '../../../assets/sgvs/empty-search.svg';

import { OuterWrapper, InnerWrapper, WrapperIcon, ContentWrapper } from './Empty.styles';

const Empty = ({ testId, title, description }) => (
  <OuterWrapper>
    <InnerWrapper data-testid={testId}>
      <WrapperIcon className="image-wrapper">
        <CustomIcon width="237px" />
      </WrapperIcon>
      <ContentWrapper>
        <Title testId={`${testId}-title`} htmlType="h3" className="title-wrapper">
          {title}
        </Title>
        <Text testId={`${testId}-text`} className="description-wrapper">
          {description}
        </Text>
      </ContentWrapper>
    </InnerWrapper>
  </OuterWrapper>
);

Empty.propTypes = {
  title: PropTypes.string,
  testId: PropTypes.string,
  description: PropTypes.string,
};

Empty.defaultProps = {
  title: '',
  testId: 'empty',
  description: '',
};

export default Empty;
