import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { Modal, LPButton, LPTitle, LPText, IconCustom } from '~/components/atoms';

import UseTermsText from './UseTermsText/UseTermsText';

import {
  Container,
  ModalFooter,
  TextContent,
  ModalContent,
  FooterContent,
} from './ModalUseTerms.styles';

const ModalUseTerms = ({ testId, isVisible, isLoading, onUseTermsAccept, onUseTermsCancel }) => {
  const [read, setRead] = useState(false);

  const handleScroll = e => {
    const range = Array.from({ length: 3000 }, (x, index) => index);
    const scrollHeight = e.target.scrollHeight - e.target.scrollTop;
    const isScrolled = scrollHeight === e.target.clientHeight || range.includes(scrollHeight);

    return !read && isScrolled && setRead(true);
  };

  const handleCancel = () => {
    setRead(false);
    onUseTermsCancel();
  };

  return (
    <Modal
      width="648px"
      isLP
      removePadding
      destroyOnClose
      maskClosable={false}
      isVisible={isVisible}
    >
      <Container>
        <ModalContent>
          <TextContent onScroll={handleScroll}>
            <IconCustom className="use-terms-logo" name={svg.logoBrandDefault} size="46px" />
            <LPTitle htmlType="h1" testId={`${testId}-title`}>
              Termos de uso e Política de Privacidade
            </LPTitle>

            <LPText testId={`${testId}-text`} type="secondary">
              Introdução ao documento.
            </LPText>

            <LPTitle htmlType="h3" testId={`${testId}-subtitle`}>
              Termos de uso
            </LPTitle>

            <LPText
              type="secondary"
              testId={`${testId}-text`}
              className="use-terms-update-date-text"
            >
              Data de atualização
            </LPText>
            <br />
            <LPText type="secondary" testId={`${testId}-text`}>
              Introdução aos termos de uso
            </LPText>

            <div>
              <UseTermsText testId={`${testId}-text`} />
            </div>
          </TextContent>

          <ModalFooter>
            <FooterContent>
              <LPButton
                type="secondary"
                testId={`${testId}-cancel-button`}
                onClick={handleCancel}
                isDisabled={isLoading}
              >
                Voltar
              </LPButton>
              <LPButton
                type="primary"
                testId={`${testId}-submit-button`}
                onClick={onUseTermsAccept}
                isLoading={isLoading}
                isDisabled={!read}
              >
                Concordo
              </LPButton>
            </FooterContent>
          </ModalFooter>
        </ModalContent>
      </Container>
    </Modal>
  );
};

ModalUseTerms.defaultProps = {
  testId: '',
  isVisible: false,
  isLoading: false,
};

ModalUseTerms.propTypes = {
  testId: PropTypes.string,
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  onUseTermsAccept: PropTypes.func.isRequired,
  onUseTermsCancel: PropTypes.func.isRequired,
};

export default ModalUseTerms;
