import webRouter from '../../config/webRouter';
import { adminValidator } from './roleManage';

export const getHome = (isAuth, role = '') => {
  if (isAuth) {
    if (adminValidator(role)) {
      return webRouter.ADMIN_HOME_PRIVATE;
    }
    return webRouter.PATIENTS;
  }

  return webRouter.DEFAULT;
};

export const checkIfAdminRoute = currentRoute => currentRoute.includes('admin');

export default {
  getHome,
  checkIfAdminRoute,
};
