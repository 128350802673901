const deepFreeze = (object) => {
  const propNames = Object.getOwnPropertyNames(object);

  // eslint-disable-next-line no-restricted-syntax
  for (const name of propNames) {
    const value = object[name];

    // eslint-disable-next-line no-param-reassign
    object[name] = value && typeof value === 'object' ? deepFreeze(value) : value;
  }

  return Object.freeze(object);
}

export default deepFreeze;
