import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const CopyText = styled(CopyToClipboard)`
  display: block;
`;

export const CopyTextContent = styled.span`
  cursor: pointer;
  display: block;
  font-size: 1rem;
  margin-bottom: 16px;
  font-weight: ${props => props.strong && 'bold'};
`;

export const Wrapper = styled.div`
  width: 24px;
  margin-left: calc(1rem - 24px);
`;
