import { lazy } from 'react';

export { default as LoadingPage } from './LoadingPage/LoadingPage.container';

export const AssistantRegister = lazy(() => import('./AssistantRegister/AssistantRegister'));
export const NotFound = lazy(() => import('./Error/NotFound'));
export const InternalServerError = lazy(() => import('./Error/InternalServerError'));
export const PatientViewExamsError = lazy(() =>
  import('./Error/PatientViewExamsError/PatientViewExamsError'),
);
export const DiscussionConfirmation = lazy(() =>
  import('./DiscussionConfirmation/DiscussionConfirmation.container'),
);
export const Login = lazy(() => import('./Login/Login.container'));

export const PatientViewExamsApprove = lazy(() =>
  import('./PatientViewExamsApprove/PatientViewExamsApprove.container'),
);
export const PatientViewExamsConfirm = lazy(() =>
  import('./PatientViewExamsConfirm/PatientViewExamsConfirm.container'),
);
export const PatientViewExamsReject = lazy(() =>
  import('./PatientViewExamsReject/PatientViewExamsReject.container'),
);
export const Register = lazy(() => import('./Register/Register.container'));
export const RequestPassword = lazy(() => import('./RequestPassword/RequestPassword.container'));
export const ResetPassword = lazy(() => import('./ResetPassword/ResetPassword.container'));
