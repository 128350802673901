import { dateManager } from '~/utils/helpers';

const today = dateManager.getCurrentDate();
const lastYear = dateManager.getLastYear();

export const toApi = (data = {}) => {
  const { origin } = data;
  // eslint-disable-next-line no-param-reassign
  if (origin === 'all') data.origin = '';

  return {
    initialDate: data.initialDate || lastYear,
    endDate: data.endDate || today,
    initialPerPage: data.initialPerPage || 0,
    totalPerPage: data.totalPerPage || 50,
    totalItems: data.totalItems || 50,
    search: data.search || undefined,
    origin: data.origin || undefined,
    laboratory: data.laboratory || undefined,
  };
};

export const fromApi = (data = []) => {
  const mapper = data.map(item => ({
    birthdate: item.birthdate || undefined,
    crm: item.crm || undefined,
    dataOfVisit: item.dataOfVisit || undefined,
    doctorName: item.doctorName || undefined,
    identifier: item.identifier || undefined,
    isVisible: true,
    namePatient: item.namePatient || undefined,
    protocol: item.protocol || undefined,
    protocolKey: item.password || undefined,
    requestId: item.requestId || undefined,
    origin: item.origin.name || undefined,
  }));

  return mapper;
};
