import React from 'react';

import { Container } from './LoadingPage.styles';
import { LoadingIcon } from '~/components/atoms';

export const LoadingPage = () => (
  <Container>
    <LoadingIcon />
  </Container>
);

export default LoadingPage;
