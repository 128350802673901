import { svg } from '~/assets';

export default [
  {
    key: 1,
    group: svg.brandGroup1,
  },
  {
    key: 2,
    group: svg.brandGroup2,
  },
  {
    key: 3,
    group: svg.brandGroup3,
  },
  {
    key: 4,
    group: svg.brandGroup4,
  },
  {
    key: 5,
    group: svg.brandGroup5,
  },
];
