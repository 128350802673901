import { dateManager } from '~/utils/helpers';

const { toStamp } = dateManager;

export const toApi = (data = {}) => {
  const startDate = data.startDate && toStamp(data.startDate);
  const endDate = data.endDate && toStamp(data.endDate);

  return {
    startDate,
    endDate,
  };
};
