import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '~/components/atoms';

import { Group, Button, FormItem } from './RadioButton.styles';

const Radio = ({
  isDisabled,
  isLoading,
  testId,
  size,
  name,
  options,
  placeholder,
  defaultValue,
  value,
  setFieldValue,
  setFieldTouched,
  handleBlur,
  onChange,
  touched,
  error,
  label,
  buttonStyle,
}) => {
  const hasError = !error;

  return (
    <FormItem
      help={touched && hasError && error}
      validateStatus={touched && hasError ? 'error' : ''}
      label={label}
    >
      <Group
        buttonStyle={buttonStyle}
        data-testid={testId}
        name={name}
        size={size}
        loading={isLoading}
        disabled={isDisabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onBlur={handleBlur}
        value={value}
        onChange={val => {
          setFieldValue(name, val.target.value);
          setFieldTouched(name);
          onChange(val.target.value);
        }}
      >
        {options.map(item => (
          <Button key={item.value} value={item.value}>
            <Text>{item.text}</Text>
          </Button>
        ))}
      </Group>
    </FormItem>
  );
};

Radio.propTypes = {
  testId: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  textNotFound: PropTypes.string,
  showSearch: PropTypes.bool,
  size: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  handleBlur: PropTypes.func,
  showEmptyOption: PropTypes.bool,
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  testId: 'Radio',
  size: 'large',
  textNotFound: 'Não encontrado',
  name: '',
  label: '',
  error: '',
  placeholder: '',
  defaultValue: undefined,
  touched: false,
  showSearch: true,
  isDisabled: false,
  isLoading: false,
  showEmptyOption: true,
  handleBlur: () => undefined,
  setFieldValue: () => undefined,
  setFieldTouched: () => undefined,
  onChange: () => undefined,
};

export default Radio;
