import styled from 'styled-components';
import { Collapse as CollapseBase } from 'antd';

import { styles } from '../../../config';

export const Collapse = styled(CollapseBase)`
  && {
    position: relative;
    background: transparent;
    border: none;

    &:hover {
      background-color: ${styles.colors.listItemHover};
    }

    &::before {
      content: '';
      position: absolute;
      top: 70px;
      left: -100px;
      background-color: ${styles.colors.white};
      border-top: ${({ showBorder }) => `1px solid ${
      showBorder ? styles.colors.borderColorSecondary : styles.colors.defaultBackground
    }`};
      border-bottom: ${({ showBorder }) => `1px solid ${
      showBorder ? styles.colors.borderColorSecondary : styles.colors.defaultBackground
    }`};
      height: calc(100% - 70px);
      width: 200vw;
    }

    .ant-collapse-item {
      .ant-collapse-header {
        padding: 24px 0;
        padding-left: 0;

        .ant-collapse-arrow {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          svg {
            color: ${styles.colors.link};
            font-size: 18px;
            position: relative;
            bottom: 2px;
          }
        }
      }

      .ant-collapse-content-box {
        padding: 0 !important;

        .ant-row {
          display: flex;
          align-items: center;

          p {
            margin: 0;
          }
        }
      }
    }
  }
`;

export const Panel = styled(CollapseBase.Panel)``;
