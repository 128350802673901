import { getUsers } from '~/api/services/users.service';
import { getUsers as newGetUsers } from '~/api/services/userManagementDasa.service'
import { simpleAction, errorMessage, formatRequestNewGetUser } from '~/utils/helpers';
import { searchRequest, searchSuccess, searchFailure, clearSearchAction } from '../search/search'

export const USER_PENDING_REQUEST = '@dasa-canal-medico/USER_PENDING_REQUEST';
export const USER_PENDING_SUCCESS = '@dasa-canal-medico/USER_PENDING_SUCCESS';
export const USER_PENDING_FAILURE = '@dasa-canal-medico/USER_PENDING_FAILURE';

// Reducer
const initialState = {
  data: {
    total: 0,
    group: [],
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_PENDING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case USER_PENDING_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: {
          total: action.payload.total,
          group: action.payload.group,
        },
        isLoading: false,
      };
    case USER_PENDING_FAILURE:
      return {
        ...state,
        error: {
          status: action.payload.status,
          message: action.payload.message,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}

// Action Creators
const pendingRequest = simpleAction(USER_PENDING_REQUEST);
const pendingSuccess = simpleAction(USER_PENDING_SUCCESS);
const pendingFailure = simpleAction(USER_PENDING_FAILURE);

export const getUsersPendingAction = (params, controlInfoMDM, search=false) => async (dispatch, getState) => {
  dispatch(clearSearchAction())
  
  localStorage.setItem('search', JSON.stringify(params))
  const dispatchFunctions = {
    request: () => (!search) ? dispatch(pendingRequest()) : dispatch(searchRequest()),
    success: (payload) => (!search) ? dispatch(pendingSuccess(payload)) : dispatch(searchSuccess(payload)),
    failure: (err) =>(!search) ? dispatch(pendingFailure(err)) : dispatch(searchFailure(err))
  }
  
  dispatchFunctions.request();

  try {
    let payload

    if(controlInfoMDM){
      const paramsFormated = formatRequestNewGetUser(params);
      payload = await newGetUsers(paramsFormated);
    }
    else
      payload = await getUsers(params);

    dispatchFunctions.success(payload);

  } catch (error) {
    const err = errorMessage(error);
    dispatchFunctions.failure(err);
  }
};
