import React from 'react';
import PropTypes from 'prop-types';

import { TooltipBase, TooltipWrapper } from './Tooltip.styles';

const Tooltip = ({ children, testId, text, placement, trigger, type }) => (
  <TooltipWrapper type={type}>
    <TooltipBase
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      data-testid={testId}
      title={text}
      placement={placement}
      autoAdjustOverflow
      mouseEnterDelay={0.3}
      destroyTooltipOnHide
      trigger={trigger}
      align={{
        offset: [0, 0],
      }}
    >
      {children}
    </TooltipBase>
  </TooltipWrapper>
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
  text: PropTypes.string,
  placement: PropTypes.string,
  trigger: PropTypes.string,
};

Tooltip.defaultProps = {
  testId: '',
  text: '',
  placement: 'bottom',
  trigger: 'hover',
};

export default Tooltip;
