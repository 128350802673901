import styled from 'styled-components';

import { styles } from '~/config';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    width: 120px;
    align-self: center;
  }
`;

export const Content = styled.div`
  margin: 20px 5px;

  p {
    text-align: center;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  h3 {
    color: ${styles.colors.alert};
    margin: 0 6px 0;
    font-size: 1.7rem;
  }
`;
