import React from 'react';
import PropTypes from 'prop-types';

import { Modal, LPTitle, LPText, LPLink, LPSelect } from '~/components/atoms';
import { Accordion } from '~/components/molecules';

import { contactInfo, accordionItems } from './ModalNeedHelp.data';

import {
  Container,
  ModalContent,
  DesktopModalContent,
  MobileModalContent,
  WrapperButtonClose,
  Section,
  SectionTitle,
} from './ModalNeedHelp.styles';

const ModalNeedHelp = ({ testId, isVisible, onCloseModal }) => {
  const [region, setRegion] = React.useState('');

  const selectOptions = (options = []) => {
    return options.map(item => ({ text: item, value: item }));
  };

  const onChangeRegion = (currentRegion = '') => setRegion(currentRegion);

  const renderNumbers = (currentRegion = '') => {
    if (!currentRegion || currentRegion.length === 0) return null;
    const currentInfo = contactInfo[currentRegion];
    const numberList = currentInfo.map(contact => (
      <LPText type="secondary">
        {contact.name} -{' '}
        <a href={`tel: ${contact.number}`}>
          <b>{contact.number}</b>
        </a>
      </LPText>
    ));

    return (
      <Section>
        <SectionTitle>Números encontrados</SectionTitle>
        {numberList}
      </Section>
    );
  };

  return (
    <Modal width="480px" removePadding destroyOnClose maskClosable={false} isVisible={isVisible}>
      <Container>
        <ModalContent>
          <LPTitle htmlType="h1" testId={`${testId}-title`}>
            Precisa de ajuda?
          </LPTitle>
          <WrapperButtonClose>
            <LPLink type="secondary" testId={`${testId}-close-button`} onClick={onCloseModal}>
              X
            </LPLink>
          </WrapperButtonClose>
          <DesktopModalContent>
            <Section>
              <LPText type="secondary" testId={`${testId}-text`}>
                <b>Problemas com a conta</b>
              </LPText>
              <LPText type="secondary" testId={`${testId}-text`}>
                Contato por email:
                <br />
                <a href="mailto:suporte.canalmedico@dasa.com.br">
                  <u>suporte.canalmedico@dasa.com.br</u>
                </a>
              </LPText>
            </Section>
            <Section>
              <LPText type="secondary" testId={`${testId}-text`}>
                <b>Informações sobre exames</b>
              </LPText>
              <LPText type="secondary" testId={`${testId}-text`}>
                Entre em contato com o laboratório responsável.
              </LPText>
            </Section>
            <Section>
              <LPText type="secondary" testId={`${testId}-text`}>
                <b>Núcleo de assessoria médica</b>
              </LPText>
              <LPSelect
                options={selectOptions(Object.keys(contactInfo))}
                placeholder="Regionais para contato"
                onSelect={onChangeRegion}
              />
            </Section>
            {renderNumbers(region)}
          </DesktopModalContent>
          <MobileModalContent>
            <Section>
              <Accordion items={accordionItems} />
            </Section>
            <Section>
              <SectionTitle>Núcleo de assessoria médica</SectionTitle>
              <LPSelect
                options={selectOptions(Object.keys(contactInfo))}
                placeholder="Regionais para contato"
                onSelect={onChangeRegion}
              />
            </Section>
            {renderNumbers(region)}
          </MobileModalContent>
        </ModalContent>
      </Container>
    </Modal>
  );
};

ModalNeedHelp.defaultProps = {
  testId: '',
  isVisible: false,
};

ModalNeedHelp.propTypes = {
  testId: PropTypes.string,
  isVisible: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
};

export default ModalNeedHelp;

