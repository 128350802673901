import styled from 'styled-components';

import { styles } from '~/config';
import { mediaQuery } from '~/utils/helpers';

export const Container = styled.div`
  padding: 24px 2px 24px 24px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;

  h3 {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    ${mediaQuery.inMobile(`
      font-size: ${styles.fontSize.big};
      text-align: left;
    `)}
  }
`;

export const HeaderTextWrapper = styled.span`
  display: block;
  flex: 1;
`;

export const ModalFooter = styled.footer`
  text-align: center;
  width: 100%;

  button {
    min-width: 130px;

    & + button {
      margin-left: 10px;
    }
  }
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 24px 24px 0;
`;

export const TextContent = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 14px;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${styles.colors.white};
  }

  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb:hover {
    background: ${styles.colors.scrollBarColor};
    border-radius: 30px;
  }

  div {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 0;

    &::before {
      content: '';
      min-height: 100%;
      border-left: 1px solid ${styles.colors.scrollBarColor};
      position: relative;
      left: 12px;
    }
  }
`;

export const HeaderImageWrapper = styled.div`
  margin-right: 30px;
  ${mediaQuery.inMobile(`
    margin-right: 10px;
  `)}
`;

export const LogoNav = styled.img`
  width: 100px;
  display: block;
`;
