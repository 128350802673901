const setStorageTTL = item => {
  const expireDate = Date.now() + 600000;
  localStorage.setItem(item, expireDate);
};

const checkStorageItemTTL = itemName => {
  const ttlValue = Object.hasOwnProperty.call(localStorage, itemName)
    ? Number(localStorage.getItem(itemName))
    : null;
  const isExpiredTTL = new Date(ttlValue).getTime() < Date.now();
  if (isExpiredTTL) {
    setStorageTTL(itemName);
  }
  return isExpiredTTL;
};

const clearItemTTL = itemName => localStorage.removeItem(itemName);

export default {
  setStorageTTL,
  checkStorageItemTTL,
  clearItemTTL
};
