import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 30px;

  .assistants-table {
    td {
      span {
        margin-right: 10px;
      }
    }

    tr:hover,
    td:hover {
      cursor: default !important;
    }
  }
`;

export const WrapperSkeleton = styled.div`
  margin-top: 80px;
`;
