import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import { ModalNeedHelp } from '~/components/molecules';

import { ButtonHelp } from './NeedHelp.styles';

const NeedHelp = ({ testId }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <ButtonHelp testId={`${testId}-button-need-help`} onClick={() => setIsVisible(true)}>
        Precisa de Ajuda?
      </ButtonHelp>
      <ModalNeedHelp
        testId={`${testId}-modal-need-help`}
        isVisible={isVisible}
        onCloseModal={() => setIsVisible(false)}
      />
    </>
  );
};

NeedHelp.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default memo(NeedHelp);
