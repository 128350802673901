import * as Yup from 'yup';

const FormSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .min(10, 'Mínimo de 10 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .email('e-mail inválido')
    .required('este campo é obrigatório'),
});

export default FormSchema;
