import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DropdownBase, Menu } from './Dropdown.styles';

const renderMenu = (itens, tourId, disabled = false) => (
  <Menu data-tour={tourId} tourId={tourId}>
    {itens.map(item => (
      <Menu.Item disabled={disabled} onClick={item.event} key={item.key}>
        {item.label}
      </Menu.Item>
    ))}
  </Menu>
);

const Dropdown = ({ testId, tourId, children, visible, data, onVisibleChange }) => {
  const [open, setOpen] = useState(false);
  const isVisible = !!(open || visible);

  return (
    <DropdownBase
      visible={isVisible}
      disable={visible}
      onVisibleChange={onVisibleChange || (() => setOpen(!open))}
      data-testid={testId}
      trigger={['click']}
      placement="bottomRight"
      overlay={renderMenu(data, tourId)}
    >
      {children}
    </DropdownBase>
  );
};

Dropdown.propTypes = {
  testId: PropTypes.string,
  tourId: PropTypes.string,
  visible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onVisibleChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf([
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    }),
  ]),
};

Dropdown.defaultProps = {
  testId: 'dropdown',
  tourId: 'dropdown',
  visible: undefined,
  data: [],
};

export default Dropdown;
