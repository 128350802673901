import { AppConfigurationClient } from '@azure/app-configuration';
import { featureToggle } from '~/config/envManager';

const client = new AppConfigurationClient(featureToggle);

export const getFeatureToggle = async featureToggleType => {
  const { value: toggleString } = await client.getConfigurationSetting({
    key: `.appconfig.featureflag/${featureToggleType}`,
    label: 'featureToggles',
  },
    {
      requestOptions: {
        customHeaders: {
          'cache-control': 'no-cache',
        },
      },
    }
  );

  return JSON.parse(toggleString);
};
