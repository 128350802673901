import breakpoint from '~/utils/enums/breakpoint.enum';

export const inMobile = (style = '') => `@media screen and (max-width: ${breakpoint.tablet}) {
  ${style}
}`;

export const inTablet = (
  style = '',
) => `@media screen and (min-width: ${breakpoint.tablet}) and (max-width: ${breakpoint.desktop}) {
  ${style}
}`;

export const inDesktop = (style = '') => `@media screen and (min-width: ${breakpoint.desktop}) {
  ${style}
}`;

export const mediaQuery = {
  inMobile,
  inTablet,
  inDesktop,
};
