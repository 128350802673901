export default {
  success: {
    text: 'Liberado',
    status: 'success',
  },
  processing: {
    text: 'Processando',
    status: 'warning',
  },
  default: {
    text: '',
    status: '',
  },
};
