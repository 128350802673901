import { getPatients } from '~/api/services/patients.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

export const PATIENT_GROUP_LEGACY_REQUEST = '@dasa-canal-medico/PATIENT_GROUP_LEGACY_REQUEST';
export const PATIENT_GROUP_LEGACY_SUCCESS = '@dasa-canal-medico/PATIENT_GROUP_LEGACY_SUCCESS';
export const PATIENT_GROUP_LEGACY_FAILURE = '@dasa-canal-medico/PATIENT_GROUP_LEGACY_FAILURE';
export const PATIENT_GROUP_LEGACY_RESET = '@dasa-canal-medico/PATIENT_GROUP_LEGACY_RESET';

export const initialState = {
  data: [],
  error: {
    message: '',
    status: '',
  },
  params: {},
  isLoading: true,
  isLoadingMore: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PATIENT_GROUP_LEGACY_REQUEST:
      if (action.payload.params.isLoadingMore) {
        return {
          ...state,
          isLoadingMore: true,
        };
      }

      return {
        ...state,
        ...initialState,
        isLoading: true,
      };

    case PATIENT_GROUP_LEGACY_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: action.payload.data,
        params: action.payload.params,
        isLoading: false,
        isLoadingMore: false,
      };

    case PATIENT_GROUP_LEGACY_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
        isLoadingMore: false,
      };

    case PATIENT_GROUP_LEGACY_RESET:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}

export const getPatientsLegacyRequest = simpleAction(PATIENT_GROUP_LEGACY_REQUEST);
export const getPatientsLegacySuccess = simpleAction(PATIENT_GROUP_LEGACY_SUCCESS);
export const getPatientsLegacyFailure = simpleAction(PATIENT_GROUP_LEGACY_FAILURE);

export const resetPatientGroup = simpleAction(PATIENT_GROUP_LEGACY_RESET);

export const getPatientsLegacyAction = params => async dispatch => {
  dispatch(getPatientsLegacyRequest({ params: { isLoadingMore: false, ...params } }));

  try {
    const data = await getPatients(params);

    dispatch(getPatientsLegacySuccess({ data, params }));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(getPatientsLegacyFailure(err));
  }
};

export const resetPatientGroupAction = () => dispatch => {
  dispatch(resetPatientGroup());
};
