export default {
  colors: {
    default: '#eeeeee',
    primary: '#0037FF',
    error: '#ff3867',
    success: '#30c05b',
    outline: '#797c86',
    white: '#ffffff',
    black: '#000000',
    link: '#94969e',
    text: '#52555b',
    gray: '#797c86',
    border: '#61636B',

    button: {
      primaryHover: '#4770eb',
      primaryFocus: '#4770eb',
      primaryActive: '#7192f4',
      primaryFocusBorder: '#7192f4',
      primaryDisabledColor: '#797c86',
      primaryDisabledBackground: '#c9cbcf',
      secondaryHoverColor: '#797c86',
      secondaryHoverBackground: '#e4e5e7',
      secondaryFocusColor: '#61636b',
      secondaryFocusBackground: '#e4e5e7',
      secondaryActiveColor: '#61636b',
      secondaryActiveBackground: '#797c86',
      secondaryDisabledColor: '#94969e',
      secondaryDisabledBorder: '#c9cbcf',
    },
  },
  fontFamily: {
    default: 'Montserrat, sans-serif;',
  },
  fontSize: {
    default: '16px',
    small: '10px',
    medium: '14px',
    big: '18px',
    large: '22px',
    xlarge: '24px',
  },
};
