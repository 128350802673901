import { updateStatusUser } from '~/api/services/users.service';
import { simpleAction, errorMessage } from '~/utils/helpers';

export const USER_UPDATE_STATUS_REQUEST = '@dasa-canal-medico/USER_UPDATE_STATUS_REQUEST';
export const USER_UPDATE_STATUS_SUCCESS = '@dasa-canal-medico/USER_UPDATE_STATUS_SUCCESS';
export const USER_UPDATE_STATUS_FAILURE = '@dasa-canal-medico/USER_UPDATE_STATUS_FAILURE';

// Reducer
const initialState = {
  data: {
    message: '',
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case USER_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: {
          message: action.payload.message,
        },
        isLoading: false,
      };
    case USER_UPDATE_STATUS_FAILURE:
      return {
        ...state,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}

// Action Creators
const updateStatusRequest = simpleAction(USER_UPDATE_STATUS_REQUEST);
const updateStatusSuccess = simpleAction(USER_UPDATE_STATUS_SUCCESS);
const updateStatusFailure = simpleAction(USER_UPDATE_STATUS_FAILURE);

export const updateStatusAction = params => async dispatch => {
  dispatch(updateStatusRequest());

  try {
    const payload = await updateStatusUser(params);
    dispatch(updateStatusSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(updateStatusFailure(err));
  }
};
