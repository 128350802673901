import { objectManager } from '~/utils/helpers';

const { isEmpty, isObject, isArray } = objectManager;

export const toApi = (data = {}) => {
  if (!isObject(data)) {
    return { id: undefined };
  }

  return { id: data.id || undefined };
};

export const fromApi = (data = {}) => {
  if (!isObject(data)) {
    return {
      id: undefined,
      idSislu: '',
      birthDate: '',
      cpf: '',
      email: '',
      name: '',
      gender: '',
      isActive: false,
      role: '',
      rg: '',
      specialty: '',
      congregation: '',
      congregationNumber: '',
      congregationState: '',
      login: '',
      address: {
        address: '',
        addressComplement: '',
        addressNumber: '',
        cep: '',
        city: '',
        neightborhood: '',
        state: '',
      },
      telephone: '',
      cellphone: '',
    };
  }

  const addressData = data.address && data.address[0] ? data.address[0] : {};

  const mapperAddress = item => ({
    address: item.address || '',
    addressComplement: item.addressComplement || '',
    addressNumber: item.addressNumber || '',
    cep: item.cep || '',
    city: item.city || '',
    neightborhood: item.neightborhood || '',
    state: item.state || '',
  });

  const mapperTelephone = phone => {
    if (isEmpty(phone) || !isArray(phone)) return '';

    const number = phone.find(item => item.type === 'TELEFONE') || {};

    if (isEmpty(number.ddd) || isEmpty(number.telephone)) return '';

    return `${number.ddd.trim()}${number.telephone.trim()}`;
  };

  const mapperCellphone = phone => {
    if (isEmpty(phone) || !isArray(phone)) return '';

    const number = phone.find(item => item.type === 'CELULAR') || {};

    if (isEmpty(number.ddd) || isEmpty(number.telephone)) return '';

    return `${number.ddd.trim()}${number.telephone.trim()}`;
  };

  const mapper = {
    id: data.id || undefined,
    idSislu: data.idSislu || '',
    birthDate: data.birthDate || '',
    cpf: data.cpf || '',
    email: data.email || '',
    name: data.fullName || '',
    gender: data.gender === 'M' ? 'Masculino' : data.gender === 'F' ? 'Feminino' : '',
    isActive: data.active === 'ATIVO',
    role: data.role || '',
    rg: data.rg || '',
    specialty: data.specialty || '',
    congregation: data.congregation || '',
    congregationNumber: data.congregationNumber || '',
    congregationState: data.congregationState || '',
    login: data.login || '',
    address: mapperAddress(addressData),
    telephone: mapperTelephone(data.telephone),
    cellphone: mapperCellphone(data.telephone),
  };

  return mapper;
};
