import React from 'react';
import PropTypes from 'prop-types';

import Text from '~/components/atoms/Text/Text';

import { Group, Radio, FormItem } from './LPRadioGroup.styles';

const LPRadioGroup = ({
  isDisabled,
  isLoading,
  testId,
  size,
  name,
  options,
  placeholder,
  defaultValue,
  value,
  setFieldValue,
  setFieldTouched,
  handleBlur,
  onChange,
  touched,
  error,
  label,
}) => {
  const hasError = !!error;
  return (
    <FormItem
      help={touched && hasError && error}
      validateStatus={touched && hasError ? 'error' : ''}
      label={label}
    >
      <Group
        data-testid={testId}
        name={name}
        size={size}
        loading={isLoading}
        disabled={isDisabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onBlur={handleBlur}
        value={value}
        onChange={val => {
          setFieldValue(name, val.target.value);
          setFieldTouched(name);
          onChange(val.target.value);
        }}
        validateStatus={touched && hasError ? 'error' : ''}
      >
        {options.map(item => (
          <Radio key={item.value} value={item.value}>
            <Text>{item.text}</Text>
          </Radio>
        ))}
      </Group>
    </FormItem>
  );
};

LPRadioGroup.propTypes = {
  testId: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.string,
  size: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  handleBlur: PropTypes.func,
  onChange: PropTypes.func,
};

LPRadioGroup.defaultProps = {
  testId: 'Radio',
  size: 'large',
  name: '',
  label: '',
  error: '',
  value: '',
  placeholder: '',
  defaultValue: undefined,
  touched: false,
  isDisabled: false,
  isLoading: false,
  handleBlur: () => undefined,
  setFieldValue: () => undefined,
  setFieldTouched: () => undefined,
  onChange: () => undefined,
};

export default LPRadioGroup;
