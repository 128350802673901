import styled from 'styled-components';
import { Checkbox } from 'antd';

import { lpStyles } from '~/config';

export const CheckboxBase = styled(Checkbox)`
  && {
    display: ${({ hidden }) => hidden && 'none'};
    font-size: 1rem;
    font-family: ${lpStyles.fontFamily.default};

    &:hover {
      opacity: 0.8;
    }

    .ant-checkbox-inner {
      border: 2px solid ${lpStyles.colors.border};
      width: 1.25rem;
      height: 1.25rem;
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: ${lpStyles.colors.primary};
        background-color: ${lpStyles.colors.primary};
      }

      &::after {
        border: none;
      }
    }
  }
`;
