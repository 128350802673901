import { objectManager, stringManager } from '~/utils/helpers';
import { mapper } from '~utils/decorators';

const { isEmpty } = objectManager;
const {
  removeMask,
  addCpfMask,
  addRgMask,
  addCellphoneMask,
  addTelephoneMask,
  addCepMask,
} = stringManager;

@mapper
class UserInfo {
  constructor(data = {}) {
    if (isEmpty(data)) return;

    this.id = data.id;
    this.role = data.role;
    this.fullName = data.name;
    this.cpf = addCpfMask(data.cpf, removeMask);
    this.uf = data.congregationState;
    this.conselho = data.congregation;
    this.registerNumber = data.congregationNumber;
    this.email = data.email;
    this.cellphone = addCellphoneMask(data.cellphone, removeMask);
    this.telephone = addTelephoneMask(data.telephone, removeMask);
    this.specialty = data.specialty;
    this.rg = addRgMask(data.rg, removeMask);
    this.gender = data.gender;
    this.cep = data.address && addCepMask(data.address.cep, removeMask);
    this.address = data.address.address;
    this.number = data.address.addressNumber;
    this.complement = data.address.addressComplement;
    this.state = data.address.state;
    this.city = data.address.city;
  }
}

export default UserInfo;
