import React from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { styles } from '~/config';
import { Icon, IconCustom } from '~/components/atoms';

import { FormItem, SelectBase, Option, SearchButton, Clean } from './SelectSearch.styles';

const SelectSearch = ({
  isDisabled,
  isLoading,
  testId,
  label,
  size,
  touched,
  textNotFound,
  error,
  name,
  options,
  placeholder,
  defaultValue,
  showSearch,
  showArrow,
  setFieldValue,
  setFieldTouched,
  showEmptyOption,
  showCleanInput,
  onCleanInput,
  handleBlur,
  onChange,
  onSearch,
  onSelect,
  onSubmit,
}) => {
  const hasError = !!error;

  return (
    <FormItem
      help={touched && hasError && error}
      validateStatus={touched && hasError ? 'error' : ''}
      label={label}
    >
      <>
        <Clean isVisible={showCleanInput} onClick={onCleanInput}>
          <IconCustom name={svg.iconCloseSolid} size="22px" />
        </Clean>
        <SearchButton onClick={onSubmit}>
          <div>
            <Icon name="search" size="22px" color={styles.colors.white} />
          </div>
        </SearchButton>
      </>

      <SelectBase
        autoClearSearchValue={false}
        showArrow={showArrow}
        showSearch={showSearch}
        notFoundContent={textNotFound}
        data-testid={testId}
        name={name}
        size={size}
        loading={isLoading}
        disabled={isDisabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onBlur={handleBlur}
        onSearch={onSearch}
        onSelect={onSelect}
        onChange={val => {
          setFieldValue(name, val);
          setFieldTouched(name);
          onChange(val);
        }}
      >
        {showEmptyOption && <Option value="">&nbsp;</Option>}
        {options &&
          options.map(item => (
            <Option key={`${testId}-${item.value}`} value={item.value}>
              {item.text}
            </Option>
          ))}
      </SelectBase>
    </FormItem>
  );
};

SelectSearch.propTypes = {
  testId: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  textNotFound: PropTypes.string,
  showArrow: PropTypes.bool,
  showSearch: PropTypes.bool,
  onCleanInput: PropTypes.func,
  showCleanInput: PropTypes.bool,
  size: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  handleBlur: PropTypes.func,
  showEmptyOption: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  onSubmit: PropTypes.func,
};

SelectSearch.defaultProps = {
  testId: 'select',
  size: 'large',
  textNotFound: 'Não encontrado',
  name: '',
  label: '',
  error: '',
  placeholder: '',
  defaultValue: undefined,
  touched: false,
  showArrow: true,
  showSearch: true,
  isLoading: false,
  isDisabled: false,
  showCleanInput: false,
  showEmptyOption: true,
  handleBlur: () => undefined,
  onCleanInput: () => undefined,
  setFieldValue: () => undefined,
  setFieldTouched: () => undefined,
  onChange: () => undefined,
  onSearch: () => undefined,
  onSelect: () => undefined,
  onSubmit: () => undefined,
};

export default SelectSearch;
