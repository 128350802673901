export const NOT = x => !x;
export const isUndefined = value => value === undefined;
export const mod11 = value => value % 11;
export const isEquals = x => y => x === y;
export const removeMask = value =>
  value
    .replace('.', '')
    .replace('-', '')
    .replace('_', '')
    .replace('.', '');

export const getCpfNumbers = cpf => cpf.substr(0, 9).split('');
export const getTwoLastDigits = cpf => `${cpf[9]}${cpf[10]}`;
export const mergeDigits = (x, y) => `${x}${y}`;
export const toSumValue = multiplier => (result, number) => result + number * multiplier--;
export const getSumValues = (list, multiplier) => list.reduce(toSumValue(multiplier), 0);
export const getDigit = num => (num > 1 ? 11 - num : 0);
export const getValidationDigit = multiplier => cpf => getDigit(mod11(getSumValues(cpf, multiplier)));
export const isRepeatingNumbersCpf = str => str.split('').every(elem => elem === str[0]);

export const isValidCPF = (cpf) => {
  const CPF = getCpfNumbers(cpf);
  const firstDigit = getValidationDigit(10)(CPF);
  const secondDigit = getValidationDigit(11)(CPF.concat(firstDigit));
  return isEquals(getTwoLastDigits(cpf))(mergeDigits(firstDigit, secondDigit));
};

const validateCPF = (CPF) => {
  if (isUndefined(CPF)) return;
  return NOT(isRepeatingNumbersCpf(removeMask(CPF))) && isValidCPF(removeMask(CPF));
};

export default validateCPF;
