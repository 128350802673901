import api from '../api';
import { PUT } from '../httpMethods';
import { apiAdmins } from '../../config/envManager';

export const resendUserPassword = async ({ login, userId }) => {
  const { data } = await api({
    url: `${apiAdmins}/private/user/reset-password`,
    method: PUT,
    data: {
      login,
      userId,
    },
  });

  return data;
};

export const toogleActivateUser = async id => {
  const { data } = await api({
    url: `${apiAdmins}/private/user/activate`,
    method: PUT,
    data: { id },
  });

  return data;
};
