import { objectManager } from '~/utils/helpers';
import { ExamNotification } from '~/utils/entities';

const { isEmpty } = objectManager;

export const fromApi = (data = []) => {
  if (isEmpty(data)) return [];

  const mapper = data.map(item => new ExamNotification(item));

  return mapper;
};
