export const toApi = (params = {}) => {
  const mapper = {
    userId: params.id || undefined,
    cpf: params.cpf || undefined,
    congregation: params.congregationType || undefined,
    uf: params.congregationState ? params.congregationState.trim() : undefined,
    number: params.congregationNumber || undefined,
    status: params.status || undefined,
  };

  return mapper;
};
