import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const AccordionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
