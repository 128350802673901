import styles from '~/config/styles';

const { colors } = styles;

export default {
  Aberto: colors.examsStatusAberto,
  Cancelado: colors.examsStatusCancelado,
  Liberado: colors.examsStatusLiberado,
  Parcial: colors.examsStatusParcial,
  Suspenso: colors.examsStatusSuspenso,
};
