import styled from 'styled-components';
import { Link as LinkBase } from 'react-router-dom';
import { styles } from '~/config';
export const Content = styled.div``;

export const Header = styled.header`
  margin-bottom: 50px;
`;

export const Title = styled.h1`
  font-weight: bold;
  color: #010b19;
  font-size: 28px;
  margin-bottom: 0;
`;

export const Description = styled.p`
  color: #010b19;
  font-size: 16px;
`;

export const WrapperImage = styled.figure`
  margin-bottom: 30px;
`;

export const Image = styled.img`
  width: 100%;
  min-width: 280px;
  max-width: 630px;
`;

export const Link = styled(LinkBase)`
  && {
    padding: 15px 25px;
    border-radius: 5px;
    background-color: ${styles.colors.primary};
    font-weight: bold;
    color: #fff;
  }
`;
