import { lazy } from 'react';

export const Patients = lazy(() => import('./Patients/Patients.container'));
export const PatientsLegacy = lazy(() => import('./PatientsLegacy/PatientsLegacy.container'));
export const PatientDetails = lazy(() => import('./PatientDetails/PatientDetails.container'));
export const AssistantHome = lazy(() => import('./AssistantHome/AssistantHome.container'));
export const AssistantHomeLegacy = lazy(() => import('./AssistantHomeLegacy/AssistantHomeLegacy.container'));
export const AssistantPatientDetails = lazy(() => import('./AssistantPatientDetails/AssistantPatientDetails.container'));
export const Assistants = lazy(() => import('./Assistants/Assistants.container'));
export const NurseHome = lazy(() => import('./NurseHome/NurseHome.container'));
export const NurseHomeLegacy = lazy(() => import('./NurseHomeLegacy/NurseHomeLegacy.container'));
export const NursePatientDetails = lazy(() => import('./NursePatientDetails/NursePatientDetails.container'));
export const AdminHome = lazy(() => import('./AdminHome/AdminHome.container'));
export const AdminHealthUsers = lazy(() => import('./AdminHealthUsers/AdminHealthUsers.container'));
export const AdminOperatorUsers= lazy(() => import('./AdminOperatorUsers/AdminOperatorUsers.container'));
export const AdminUserDetails = lazy(() => import('./AdminUserDetails/AdminUserDetails.container'));
export const AdminExams = lazy(() => import('./AdminExams/AdminExams.container'));
export const AdminPatientDetails = lazy(() => import('./AdminPatientDetails/AdminPatientDetails.container'));
export const VisitorApprovedUsers = lazy(() => import('./VisitorApprovedUsers/VisitorApprovedUsers.container'));
export const VisitorOperatorUsers = lazy(() => import('./VisitorOperatorUsers/VisitorOperatorUsers.container'));
export const VisitorHome = lazy(() => import('./VisitorHome/VisitorHome.container'));
export const VisitorUserDetails = lazy(() => import('./VisitorUserDetails/VisitorUserDetails.container'));
