// VERIFICAR SE DUCKS PODE SER REMOVIDO
// import { getExams } from '../../api/services/exams.service';
import { simpleAction } from '~/utils/helpers';

// Action Types
export const EXAM_GROUP_REQUEST = '@dasa-canal-medico/EXAM_GROUP_REQUEST';
export const EXAM_GROUP_SUCCESS = '@dasa-canal-medico/EXAM_GROUP_SUCCESS';
export const EXAM_GROUP_FAILURE = '@dasa-canal-medico/EXAM_GROUP_FAILURE';
export const EXAM_GROUP_RESET = '@dasa-canal-medico/EXAM_GROUP_RESET';

// Reducer
export const initialState = {
  data: [],
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EXAM_GROUP_REQUEST:
      return {
        ...state,
        ...initialState,
        isLoading: true,
      };

    case EXAM_GROUP_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: action.payload,
        isLoading: false,
      };

    case EXAM_GROUP_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };

    case EXAM_GROUP_RESET:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}

export const resetExamGroup = simpleAction(EXAM_GROUP_RESET);

export const resetExamReportAction = () => dispatch => {
  dispatch(resetExamGroup());
};
