import styled from 'styled-components';
import { Drawer as DrawerBase } from 'antd';

import { styles } from '~/config';
import { mediaQuery } from '~/utils/helpers';

export const Drawer = styled(DrawerBase)`
  && {
    transform: translateX(0) !important;
    z-index: 2500;

    .ant-drawer-content-wrapper {
      ${mediaQuery.inTablet(`
        width: 100vw !important;
      `)}

      ${mediaQuery.inMobile(`
        width: 100vw !important;
      `)}

      &::before {
        content: 'Entrar em contato';
        display: ${({ visible }) => (visible ? 'flex' : 'none')};
        justify-content: space-between;
        align-items: center;
        right: 331px;
        white-space: nowrap;
        font-size: 1rem;
        height: 37px;
        top: calc(50vh - 40px);
        color: ${styles.colors.white};
        background: url(${({ icon }) => icon || ''}) no-repeat;
        background-color: ${styles.colors.primary};
        padding: 8px 8px 5px 35px;
        border-radius: 5px 5px 0 0;
        position: absolute;
        transform: rotate(-90deg);
        z-index: -1;
      }

      .ant-drawer-content {
        background-color: ${styles.colors.white};
      }
    }

    .ant-drawer-mask {
      background-color: transparent;
    }
  }
`;

export const WrapperButton = styled.div`
  display: none;

  .ant-btn {
    padding: 0;
    margin-bottom: 10px;
    .anticon {
      color: ${styles.colors.primary};
    }

    span {
      color: ${styles.colors.default};
      font-weight: normal;
    }
  }

  ${mediaQuery.inTablet(`
    display: inline-block;
  `)}

  ${mediaQuery.inMobile(`
    display: inline-block;
  `)}
`;
