/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
import { objectManager } from '~/utils/helpers';

const { isEmpty } = objectManager;

export const toApi = (data = {}) => ({
  approved: data.approved || false,
  patientName: data.patientName || undefined,
  birthdateMs: data.birthdateMs || undefined,
  identifier: data.identifier || undefined,
  estado: data.uf || '',
  numero: data.number || '',
  conselho: data.conselho || '',
  search: data.search || '',
});

export const fromApi = (data = {}) => {
  if (isEmpty(data)) throw new Error('Param `data` is required');

  const exams = data.groupExams;

  const mapItem = data => {
    const itemPDF = data.find(item => item.mimeType === 'PDF' || item.type === 'PDF');
    const hasReportItemPDF = data.find(item => {
      const { type, mimeType, hasReport } = item;
      return (type === 'PDF' && hasReport) || (mimeType === 'PDF' && hasReport);
    });

    const pdf = hasReportItemPDF || itemPDF || {};
    const image = data.find(item => item.type === 'IMAGE') || {};

    return {
      pdf: {
        isActive: pdf.hasReport || false,
        laudoId: pdf.laudoId || '',
        origem: 'pdf',
      },
      image: {
        isActive: image.hasReport || false,
        laudoId: image.laudoId || '',
        origem: 'image',
      },
    };
  };

  const mapDetails = exam => {
    const { ac, evo, rdi } = exam.details;
    const groupParents = [];
    const hasAC = !isEmpty(ac);
    const hasEvo = !isEmpty(evo);
    const hasRdi = !isEmpty(rdi);

    if (hasAC) {
      groupParents.push({
        title: ac.title || '',
        laudoId: ac.laudoId || '',
        protocol: exam.protocol || '',
        dataOfVisit: exam.dataOfVisit || '',
        patientName: exam.namePatient || '',
        origin: exam.origem || {},
        isAC: true,
        isParent: true,
        isComplete: !!exam.isComplete,
        reports: mapItem([{ mimeType: 'PDF', hasReport: true, laudoId: ac.laudoId }]),
      });
    }

    if (hasAC && hasEvo) {
      groupParents.push({
        title: evo.title || '',
        laudoId: evo.laudoId || '',
        protocol: exam.protocol || '',
        dataOfVisit: exam.dataOfVisit || '',
        patientName: exam.namePatient || '',
        origin: exam.origem || {},
        isParent: true,
        isComplete: !!exam.isComplete,
        reports: mapItem([{ mimeType: 'PDF', hasReport: true, laudoId: evo.laudoId }]),
      });
    }

    if (hasRdi) {
      const mapExamsRdi = exams =>
        exams.map((item, index) => ({
          index,
          isRDI: true,
          isComplete: item.exams.every(exam => exam.isComplete),
          title: item.title || '',
          laudoId: item.laudoId || '',
          protocol: exam.protocol || '',
          dataOfVisit: exam.dataOfVisit || '',
          patientName: exam.namePatient || '',
          origin: exam.origin,
          reports: item.exams && mapItem(item.exams),
        }));

      const groups = mapExamsRdi(rdi);
      groupParents.push(...groups);
    }

    return groupParents;
  };

  const mapper = exams.map(item => ({
    hasAc: !isEmpty(item.details.ac),
    isComplete: !!item.isComplete,
    protocol: item.protocol || '',
    identifier: item.identifier || '',
    dataOfVisit: item.dataOfVisit || '',
    crm: item.crm || '',
    origin: item.origin || {},
    exams: mapDetails(item),
  }));

  return mapper;
};

export const fromChildrenApi = (data = {}, laudo) => {
  const { acChildren } = data;

  const mapper = acChildren.map(i => ({
    title: i.name,
    laudoId: laudo.laudoId,
    protocol: laudo.protocol,
    dataOfVisit: laudo.dataOfVisit,
    origin: laudo.origin,
    isAC: true,
    isParent: false,
    isComplete: i.isComplete,
    identifier: laudo.identifier,
    hasAc: false,
    index: i.index,
    status: i.status,
    reports: {
      pdf: {
        isActive: i.hasReport || false,
        laudoId: i.laudoId || '',
        origem: 'pdf',
        index: i.index,
      },
      image: {
        hasReport: false,
        laudoId: '',
        origem: 'image',
        index: i.index,
      },
    },
  }));

  return mapper;
};
