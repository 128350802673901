import axios from 'axios';

import webRouter from '~/config/webRouter';
import { apiUsers } from '~/config/envManager';
import { tokenManager } from '~/utils/helpers';
import httpStatusCode from '../httpStatusCode';

const isUnauthorized = ({ status }) => status === httpStatusCode.Unauthorized;

const isTokenExpired = ({ message }) => message === 'Token não corresponde as credenciais.';

const isTokenExist = () => {
  const token = tokenManager.get();

  return !!token;
};

const getRefreshToken = () => {
  const token = tokenManager.get();
  const tokenData = tokenManager.toObject(token);

  return tokenData.refreshToken;
};

const saveTokenInLocalStore = token => {
  const tokenLocalStore = tokenManager.toString(token);
  tokenManager.save(tokenLocalStore);
};

const logout = () => {
  tokenManager.remove();
  localStorage.removeItem('persist:root');
  window.location = webRouter.DEFAULT_WITH_HASH;
};

export default async error => {
  const { config, response } = error;
  const originalRequest = config;

  if (isUnauthorized(response)) {
    const { data } = response;

    if (!isTokenExist()) {
      logout();
      return error;
    }

    if (isTokenExpired(data)) {
      const token = getRefreshToken();

      try {
        const { data: newToken } = await axios({
          url: `${apiUsers}/public/refresh-token`,
          method: 'post',
          data: {
            refresh_token: token,
          },
        });

        saveTokenInLocalStore(newToken);

        const retryOriginalRequest = new Promise(resolve => {
          originalRequest.headers.Authorization = `${newToken.token_type} ${newToken.access_token}`;
          resolve(axios(originalRequest));
        });

        return retryOriginalRequest;
      } catch (e) {
        logout();
        return error;
      }
    }

    logout();
    return error;
  }

  return error;
};
