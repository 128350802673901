export const appVersion = process.env.REACT_APP_VERSION || '';
export const appName = process.env.REACT_APP_NAME || '';
export const appEnv = process.env.REACT_APP_ENV || '';

export const apiUsers = process.env.REACT_APP_API_USERS || '';
export const apiDoctors = process.env.REACT_APP_API_DOCTORS || '';
export const apiAdmins = process.env.REACT_APP_API_ADMINS || '';
export const apiAssistant = process.env.REACT_APP_API_ASSISTANT || '';
export const apiPatients = process.env.REACT_APP_API_PATIENTS || '';
export const apiReports = process.env.REACT_APP_API_REPORTS || '';
export const apiCeps = process.env.REACT_APP_API_CEP || '';
export const apikey = process.env.REACT_APP_API_KEY || '';

export const featureToggle = process.env.REACT_APP_FEATURE_TOGGLE || '';

export const socketIoPort = process.env.REACT_APP_SOCKETIO_PORT || '';

export const hotjarHJID = process.env.REACT_APP_HOTJAR_HJID || '';
export const hotjarHJSV = process.env.REACT_APP_HOTJAR_HJSV || '';
export const gaID = process.env.REACT_APP_GA || '';

export const dasaUrl = process.env.REACT_APP_DASA_URL || '';
export const socketUrl = process.env.REACT_APP_SOCKET_URL || '';

export const applicationId = process.env.REACT_APP_APPLICATION_ID || '';
