import queryString from 'query-string';

export const parseQueryString = query => queryString.parse(query);

export const parseObjectToString = query => Object.keys(query)
    .filter(key => !!query[key])
    .map(key => `${key}=${query[key]}`)
    .join('&');


export default {
  parseQueryString,
  parseObjectToString,
};
