import { getExam } from '~/api/services/exams.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const EXAM_ITEM_REQUEST = '@dasa-canal-medico/EXAM_ITEM_REQUEST';
export const EXAM_ITEM_SUCCESS = '@dasa-canal-medico/EXAM_ITEM_SUCCESS';
export const EXAM_ITEM_FAILURE = '@dasa-canal-medico/EXAM_ITEM_FAILURE';
export const EXAM_ITEM_RESET = '@dasa-canal-medico/EXAM_ITEM_RESET';

// Reducer
export const initialState = {
  data: [],
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EXAM_ITEM_REQUEST:
      return {
        ...state,
        ...initialState,
        isLoading: true,
      };

    case EXAM_ITEM_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: action.payload,
        isLoading: false,
      };

    case EXAM_ITEM_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };

    case EXAM_ITEM_RESET:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}

// Action Creators
export const getExamItemRequest = simpleAction(EXAM_ITEM_REQUEST);
export const getExamItemSuccess = simpleAction(EXAM_ITEM_SUCCESS);
export const getExamItemFailure = simpleAction(EXAM_ITEM_FAILURE);

export const resetExamItem = simpleAction(EXAM_ITEM_RESET);

export const getExamAction = (id, crm) => async dispatch => {
  dispatch(getExamItemRequest());

  try {
    const payload = await getExam(id, crm);
    dispatch(getExamItemSuccess(payload));
  } catch (error) {
    const err = errorMessage();
    dispatch(getExamItemFailure(err));
  }
};

export const resetExamAction = () => dispatch => {
  dispatch(resetExamItem());
};
