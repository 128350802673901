export default [
  {
    title: 'Nome',
    dataIndex: 'nome',
    key: 'nome',
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Protocolo',
    dataIndex: 'codigoDestino',
    key: 'codigoDestino',
  },
  {
    title: 'Exame',
    dataIndex: 'nomeLaudo',
    key: 'nomeLaudo',
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Resultado',
    dataIndex: 'valor',
    key: 'valor',
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sortDirections: ['ascend', 'descend'],
  },
];
