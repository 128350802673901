import React from 'react';
import PropTypes from 'prop-types';

import { FormWrapper } from '~/components/molecules';

import Form from './Form/Form';

const SearchExams = ({ testId, onSearchExams }) => {
  const initialValues = {
    search: '',
  };

  return (
    <FormWrapper
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => onSearchExams(values)}
      render={params => <Form {...params} testId={testId} />}
    />
  );
};

SearchExams.propTypes = {
  testId: PropTypes.string,
  onSearchExams: PropTypes.func.isRequired,
};

SearchExams.defaultProps = {
  testId: '',
};

export default SearchExams;
