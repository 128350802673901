import { getAdminPatientDetail, getDoctorPatientDetail } from '~/api/services/exams.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const ADMIN_PATIENT_DETAIL_REQUEST = '@dasa-canal-medico/ADMIN_PATIENT_DETAIL_REQUEST';
export const ADMIN_PATIENT_DETAIL_SUCCESS = '@dasa-canal-medico/ADMIN_PATIENT_DETAIL_SUCCESS';
export const ADMIN_PATIENT_DETAIL_FAILURE = '@dasa-canal-medico/ADMIN_PATIENT_DETAIL_FAILURE';

export const DOCTOR_PATIENT_DETAIL_REQUEST = '@dasa-canal-medico/DOCTOR_PATIENT_DETAIL_REQUEST';
export const DOCTOR_PATIENT_DETAIL_SUCCESS = '@dasa-canal-medico/DOCTOR_PATIENT_DETAIL_SUCCESS';
export const DOCTOR_PATIENT_DETAIL_FAILURE = '@dasa-canal-medico/DOCTOR_PATIENT_DETAIL_FAILURE';

export const PATIENT_DETAIL_RESET = '@dasa-canal-medico/PATIENT_DETAIL_RESET';

// Reducer
export const initialState = {
  data: {},
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_PATIENT_DETAIL_REQUEST:
    case DOCTOR_PATIENT_DETAIL_REQUEST:
      return {
        ...state,
        ...initialState,
        isLoading: true,
      };

    case ADMIN_PATIENT_DETAIL_SUCCESS:
    case DOCTOR_PATIENT_DETAIL_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: action.payload,
        isLoading: false,
      };

    case ADMIN_PATIENT_DETAIL_FAILURE:
    case DOCTOR_PATIENT_DETAIL_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };

    case PATIENT_DETAIL_RESET:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}

// Action Creators
export const getAdminPatientDetailRequest = simpleAction(ADMIN_PATIENT_DETAIL_REQUEST);
export const getAdminPatientDetailSuccess = simpleAction(ADMIN_PATIENT_DETAIL_SUCCESS);
export const getAdminPatientDetailFailure = simpleAction(ADMIN_PATIENT_DETAIL_FAILURE);

export const getDoctorPatientDetailRequest = simpleAction(DOCTOR_PATIENT_DETAIL_REQUEST);
export const getDoctorPatientDetailSuccess = simpleAction(DOCTOR_PATIENT_DETAIL_SUCCESS);
export const getDoctorPatientDetailFailure = simpleAction(DOCTOR_PATIENT_DETAIL_FAILURE);

export const resetPatientDetail = simpleAction(PATIENT_DETAIL_RESET);

export const getAdminPatientDetailAction = (protocol, id) => async dispatch => {
  dispatch(getAdminPatientDetailRequest());

  try {
    const payload = await getAdminPatientDetail(protocol, id);
    dispatch(getAdminPatientDetailSuccess(payload));
  } catch (error) {
    const err = errorMessage();
    dispatch(getAdminPatientDetailFailure(err));
  }
};

export const getDoctorPatientDetailAction = (protocol, id) => async dispatch => {
  dispatch(getDoctorPatientDetailRequest());

  try {
    const payload = await getDoctorPatientDetail(protocol, id);
    dispatch(getDoctorPatientDetailSuccess(payload));
  } catch (error) {
    const err = errorMessage();
    dispatch(getDoctorPatientDetailFailure(err));
  }
};

export const resetExamsPatientDetailAction = () => dispatch => {
  dispatch(resetPatientDetail());
};
