import api from '../api';
import { GET } from '../httpMethods';
import { apiPatients, apiDoctors } from '../../config/envManager';
import { fromApi as laboratoriesFromApi, fromReleasedLabsApi } from '../dto/exams/laboratories.dto';

export const getLaboratories = async () => {
  const { data } = await api({
    url: `${apiPatients}/private/info/laboratories`,
    method: GET,
  });

  return laboratoriesFromApi(data);
};

export const getReleasedLaboratory = async params => {
  const { data } = await api({
    url: `${apiDoctors}/private/laboratory?labName=${params}`,
    method: GET,
  });

  return fromReleasedLabsApi(data);
};
