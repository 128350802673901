export const GET = 'GET';
export const PATH = 'PATH';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

export default {
  GET,
  PATH,
  POST,
  PUT,
  DELETE,
};
