const getDdd = value => {
  const regexNumber = /\d+/g;
  const onlyNumber = value.match(regexNumber).join('');
  return onlyNumber.substring(0, 2);
};

const getNumber = value => {
  const regexNumber = /\d+/g;
  const onlyNumber = value.match(regexNumber).join('');
  return onlyNumber.substring(2, 11);
};

export const toApi = (data = {}) => ({
  fullName: data.fullName,
  uf: data.uf,
  congregation: data.congregation,
  number: data.congregationNumber,
  specialty: data.specialty,
  cpf: data.cpf,
  email: data.email,
  ddd: getDdd(data.cellphone),
  cellphone: getNumber(data.cellphone),
  gender: data.gender,
  birthDate: data.dateBirth,
  password: data.password,
  confirmationPassword: data.passwordConfirmation,
  document: {
    front: data.document.front,
    back: data.document.back
  }
});
