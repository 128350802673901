import produce from 'immer';

import { createRating } from '~/api/services/doctor.service';
import { simpleAction, errorMessage, makeInitialState } from '~/utils/helpers';

export const CREATE_RATING_REQUEST = '@dasa-canal-medico/CREATE_RATING_REQUEST';
export const CREATE_RATING_SUCCESS = '@dasa-canal-medico/CREATE_RATING_SUCCESS';
export const CREATE_RATING_FAILURE = '@dasa-canal-medico/CREATE_RATING_FAILURE';

// Reducer
export const initialState = makeInitialState({ data: {} });

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case CREATE_RATING_REQUEST: {
        draft.loading = true;
        draft.error.status = '';
        break;
      }
      case CREATE_RATING_SUCCESS: {
        draft.data = action.payload;
        draft.loading = false;
        break;
      }
      case CREATE_RATING_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

// Action Creators
export const createRatingRequest = simpleAction(CREATE_RATING_REQUEST);
export const createRatingSuccess = simpleAction(CREATE_RATING_SUCCESS);
export const createRatingFailure = simpleAction(CREATE_RATING_FAILURE);

export const createRatingAction = params => async dispatch => {
  dispatch(createRatingRequest());

  try {
    const payload = await createRating(params);

    dispatch(createRatingSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(createRatingFailure(err));
  }
};
