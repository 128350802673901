import { simpleAction, errorMessage, formatRequestNewGetUser } from '~/utils/helpers';
import { getUsers } from '~/api/services/users.service';
import { getUsers as newGetUsers } from '~/api/services/userManagementDasa.service';
import { searchRequest, searchSuccess, searchFailure, clearSearchAction } from '../search/search'

export const USER_APPROVED_REQUEST = '@dasa-canal-medico/USER_APPROVED_REQUEST';
export const USER_APPROVED_SUCCESS = '@dasa-canal-medico/USER_APPROVED_SUCCESS';
export const USER_APPROVED_FAILURE = '@dasa-canal-medico/USER_APPROVED_FAILURE';

// Reducer
const initialState = {
  data: {
    group: [],
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_APPROVED_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case USER_APPROVED_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: {
          ...action.payload
        },
        isLoading: false,
      };
    case USER_APPROVED_FAILURE:
      return {
        ...state,
        error: {
          status: action.payload.status,
          message: action.payload.message,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}

// Action Creators
const approvedUsersRequest = simpleAction(USER_APPROVED_REQUEST);
const approvedUsersSuccess = simpleAction(USER_APPROVED_SUCCESS);
const approvedUsersFailure = simpleAction(USER_APPROVED_FAILURE);

export const getApprovedUsersAction = (params, controlInfoMDM, enableOldRequest=false, search=false) => async (dispatch) => {
  dispatch(clearSearchAction());

  const dispatchFunctions = {
    request: () => (!search) ? dispatch(approvedUsersRequest()) : dispatch(searchRequest()),
    success: (payload) => (!search) ? dispatch(approvedUsersSuccess(payload)) : dispatch(searchSuccess(payload)),
    failure: (err) =>(!search) ? dispatch(approvedUsersFailure(err)) : dispatch(searchFailure(err))
  }
  
  dispatchFunctions.request();

  let payload

  try {
    if(controlInfoMDM && !enableOldRequest){
      const paramsFormated = formatRequestNewGetUser(params);
      payload = await newGetUsers(paramsFormated);
    }
    else
      payload = await getUsers(params);

    dispatchFunctions.success(payload);

  } catch (error) {
    const err = errorMessage(error);
    
    dispatchFunctions.failure(err);
  }
};
