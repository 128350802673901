import produce from 'immer';

import { createAssistant } from '~api/services/assistant.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const CREATE_ASSISTANT_REQUEST = '@dasa-canal-medico/CREATE_ASSISTANT_REQUEST';
export const CREATE_ASSISTANT_SUCCESS = '@dasa-canal-medico/CREATE_ASSISTANT_SUCCESS';
export const CREATE_ASSISTANT_FAILURE = '@dasa-canal-medico/CREATE_ASSISTANT_FAILURE';
export const CREATE_ASSISTANT_RESET = '@dasa-canal-medico/CREATE_ASSISTANT_RESET';

// Reducer
export const initialState = {
  data: [],
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case CREATE_ASSISTANT_REQUEST: {
        draft.isLoading = true;
        break;
      }
      case CREATE_ASSISTANT_SUCCESS: {
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      }
      case CREATE_ASSISTANT_FAILURE: {
        draft.data = undefined;
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.isLoading = false;
        break;
      }
      default:
    }
  });
}

// Action Creators
export const createAssistantRequest = simpleAction(CREATE_ASSISTANT_REQUEST);
export const createAssistantSuccess = simpleAction(CREATE_ASSISTANT_SUCCESS);
export const createAssistantFailure = simpleAction(CREATE_ASSISTANT_FAILURE);

export const createAssistantAction = params => async dispatch => {
  dispatch(createAssistantRequest());

  try {
    const payload = await createAssistant(params);
    dispatch(createAssistantSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(createAssistantFailure(err));
  }
};
