import produce from 'immer';

import { createDiscussion } from '~/api/services/exams.service';
import { simpleAction, errorMessage, makeInitialState } from '~/utils/helpers';

export const CLINICAL_DISCUSSION_REQUEST = '@dasa-canal-medico/CLINICAL_DISCUSSION_REQUEST';
export const CLINICAL_DISCUSSION_SUCCESS = '@dasa-canal-medico/CLINICAL_DISCUSSION_SUCCESS';
export const CLINICAL_DISCUSSION_FAILURE = '@dasa-canal-medico/CLINICAL_DISCUSSION_FAILURE';

// Reducer
export const initialState = makeInitialState({ data: {} });

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case CLINICAL_DISCUSSION_REQUEST: {
        draft.loading = true;
        draft.error = {
          status: '',
        };
        break;
      }
      case CLINICAL_DISCUSSION_SUCCESS: {
        draft.data = action.payload;
        draft.loading = false;
        break;
      }
      case CLINICAL_DISCUSSION_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

// Action Creators
export const createClinicalDiscussionRequest = simpleAction(CLINICAL_DISCUSSION_REQUEST);
export const createClinicalDiscussionSuccess = simpleAction(CLINICAL_DISCUSSION_SUCCESS);
export const createClinicalDiscussionFailure = simpleAction(CLINICAL_DISCUSSION_FAILURE);

export const createClinicalDiscussionAction = params => async dispatch => {
  dispatch(createClinicalDiscussionRequest());

  try {
    const payload = await createDiscussion(params);

    return dispatch(createClinicalDiscussionSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);

    return dispatch(createClinicalDiscussionFailure(err));
  }
};
