import produce from 'immer';

import { getPatientDetail } from '~api/services/patients.service';
import { simpleAction, errorMessage } from '~/utils/helpers';

export const PATIENT_DETAIL_REQUEST = '@dasa-canal-medico/PATIENT_DETAIL_REQUEST';
export const PATIENT_DETAIL_SUCCESS = '@dasa-canal-medico/PATIENT_DETAIL_SUCCESS';
export const PATIENT_DETAIL_FAILURE = '@dasa-canal-medico/PATIENT_DETAIL_FAILURE';

// Reducer
const initialState = {
  data: {
    name: '',
    birthdate: '',
    birthdateMs: '',
    gender: '',
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case PATIENT_DETAIL_REQUEST: {
        draft.isLoading = true;
        break;
      }
      case PATIENT_DETAIL_SUCCESS: {
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      }
      case PATIENT_DETAIL_FAILURE: {
        draft.error = {
          message: action.payload.message,
          status: action.payload.status,
        };
        draft.isLoading = false;
        break;
      }
      default:
    }
  });
}

// Action Creators
const getPatientDetailRequest = simpleAction(PATIENT_DETAIL_REQUEST);
const getPatientDetailSuccess = simpleAction(PATIENT_DETAIL_SUCCESS);
const getPatientDetailFailure = simpleAction(PATIENT_DETAIL_FAILURE);

export const getPatientDetailAction = id => async (dispatch, getState) => {
  dispatch(getPatientDetailRequest());

  try {
    const patient = getState().patient?.group?.data.find(p => p.identifier === id.identifier);
    const payload = await getPatientDetail(patient || id);

    return dispatch(getPatientDetailSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    return dispatch(getPatientDetailFailure(err));
  }
};
