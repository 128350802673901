import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';

import { examsPagination } from '~/utils/helpers';

import { TableBase } from './Table.styles';

const getConfigPagination = (qtdItens, total, pageSize = 10) => {
  if (qtdItens <= examsPagination.totalMinToShowPagination) {
    return false;
  }

  return {
    total,
    showTotal: examsPagination.showTotal,
    itemRender: examsPagination.itemRender,
    defaultCurrent: 1,
    showSizeChanger: true,
    pageSizeOptions: examsPagination.pageSizeOptions,
    pageSize,
  };
};

const Table = ({
  data,
  total,
  testId,
  trackId,
  columns,
  defaultPageSize,
  className,
  onRowClick,
  isDefaultPagination,
  updateList = () => {}
}) => {
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [curentPage, setCurentPage] = useState(1)

  const stateData = useSelector((state)=> {
    return state.search
  })

  const pagination = getConfigPagination(data.length, total, pageSize);

  return (
    <TableBase
      className={className}
      data-testid={testId}
      data-collect={trackId}
      rowKey={`${testId}-${data.key}`}
      bordered={false}
      dataSource={(stateData.data.total != undefined )? stateData.data.group: data}
      columns={columns}
      textPagination="Itens por página: "
      pagination={pagination}
      loading={stateData.isLoading}
      isDefaultPagination={isDefaultPagination}
      onRow={(record, rowIndex) => ({
        onClick: () => onRowClick(record, rowIndex),
      })}
      onChange={(event) => {
        setPageSize(event.pageSize)
        const params = JSON.parse(localStorage.getItem('search'))
        params.initialPerPage = event.current - 1
        params.totalPerPage = event.pageSize
        updateList(params)
      }}
    />
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf([PropTypes.shape({})]).isRequired,
  columns: PropTypes.arrayOf([PropTypes.shape({})]).isRequired,
  total: PropTypes.number.isRequired,
  testId: PropTypes.string,
  trackId: PropTypes.string,
  pageSize: PropTypes.number,
  className: PropTypes.string,
  onRowClick: PropTypes.func,
  isDefaultPagination: PropTypes.bool,
};

Table.defaultProps = {
  testId: 'table',
  trackId: 'table',
  pageSize: 10,
  className: '',
  onRowClick: () => undefined,
  isDefaultPagination: true,
};

export default Table;
