import { svg } from '~/assets';
import { webRouter } from '~/config';
import { gaTypeEvents } from '.';

export default {
  admin: [
    {
      key: 1,
      testId: 'adm-pending-users-icon',
      icon: svg.pendingApprovals,
      size: '20px',
      label: 'Aprovações pendentes',
      url: webRouter.ADMIN_HOME_PRIVATE,
      event: gaTypeEvents.private.sidebar.pendingUsers,
    },
    {
      key: 2,
      testId: 'adm-health-users-icon',
      icon: svg.doctor,
      size: '33px',
      label: 'Profissionais cadastrados',
      url: webRouter.ADMIN_HEALTH_USERS,
      event: gaTypeEvents.private.sidebar.healthUsers,
    },
    {
      key: 3,
      testId: 'adm-operator-users-icon',
      icon: svg.approvedUsers,
      size: '21px',
      label: 'Operadores cadastrados',
      url: webRouter.ADMIN_OPERATOR_USERS,
      event: gaTypeEvents.private.sidebar.operators,
    },
    {
      key: 4,
      testId: 'adm-exams-icon',
      icon: svg.examsIcon,
      size: '24px',
      label: 'Exames realizados',
      url: webRouter.ADMIN_EXAMS,
      event: gaTypeEvents.private.sidebar.exams,
    },
  ],
  nam: [
    {
      key: 1,
      testId: 'adm-pending-users-icon',
      icon: svg.pendingApprovals,
      size: '20px',
      label: 'Aprovações pendentes',
      url: webRouter.ADMIN_HOME_PRIVATE,
      event: gaTypeEvents.private.sidebar.pendingUsers,
    },
    {
      key: 2,
      testId: 'adm-health-users-icon',
      icon: svg.doctor,
      size: '33px',
      label: 'Profissionais cadastrados',
      url: webRouter.ADMIN_HEALTH_USERS,
      event: gaTypeEvents.private.sidebar.healthUsers,
    },
    {
      key: 3,
      testId: 'adm-operator-users-icon',
      icon: svg.approvedUsers,
      size: '21px',
      label: 'Operadores cadastrados',
      url: webRouter.ADMIN_OPERATOR_USERS,
      event: gaTypeEvents.private.sidebar.operators,
    },
    {
      key: 4,
      testId: 'adm-exams-icon',
      icon: svg.examsIcon,
      size: '24px',
      label: 'Exames realizados',
      url: webRouter.ADMIN_EXAMS,
      event: gaTypeEvents.private.sidebar.exams,
    },
  ],
  visitador: [
    {
      key: 1,
      testId: 'visitor-pending-users-icon',
      icon: svg.pendingApprovals,
      size: '20px',
      label: 'Aprovações pendentes',
      url: webRouter.VISITOR_HOME_PRIVATE,
      event: gaTypeEvents.private.sidebar.pendingUsers,
    },
    {
      key: 2,
      testId: 'adm-health-users-icon',
      icon: svg.doctor,
      size: '33px',
      label: 'Profissionais cadastrados',
      url: webRouter.VISITOR_HEALTH_USERS,
      event: gaTypeEvents.private.sidebar.healthUsers,
    },
    {
      key: 3,
      testId: 'adm-operator-users-icon',
      icon: svg.approvedUsers,
      size: '21px',
      label: 'Operadores cadastrados',
      url: webRouter.VISITOR_OPERATOR_USERS,
      event: gaTypeEvents.private.sidebar.operators,
    },
  ],
  default: [
    {
      key: 1,
      testId: 'exams-main',
      icon: svg.examsIcon,
      size: '24px',
      label: 'Meus pacientes',
      url: webRouter.PATIENTS,
      event: gaTypeEvents.private.sidebar.myPatients,
    },
  ],
};
