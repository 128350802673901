class PatientsExamsEntity {
  constructor(data) {
    const examAC = data.filter(i => i.title === 'Analises Clinicas (PDF)');
    const examEVO = data.filter(i => i.title === 'Analises Clinicas Evolutivo (PDF)');
    const examsRDI = data.filter(i => !i.title.includes('Analises Clinicas'));
    const isRDIComplete = examsRDI.every(exam => exam.isRDI && exam.isComplete);

    const AC = examAC[0] ? [{ ...examAC[0], totalExams: examAC.length }] : examAC;
    const EVO = examEVO[0] ? [{ ...examEVO[0], totalExams: examEVO.length }] : examEVO;

    const RDIHeader = {
      isRDI: true,
      isParent: true,
      isComplete: isRDIComplete,
      dataOfVisit: (examsRDI.length && examsRDI[0].dataOfVisit) || '',
      patientName: (examsRDI.length && examsRDI[0].patientName) || '',
      title: 'Exames de imagem',
      totalExams: examsRDI.length,
    };

    const RDI = examsRDI.length > 0 ? [RDIHeader, ...examsRDI] : [];

    return {
      AC,
      EVO,
      RDI,
    };
  }
}

export default PatientsExamsEntity;
