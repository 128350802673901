import React from 'react';
import PropTypes from 'prop-types';

import Empty from '../Empty/Empty';

import {
  Header,
  HeaderTitle,
  List,
  ListItem,
  Text,
  Title,
  ContentUser,
  ContentExam,
} from './ExamsList.styles';

const ExamsList = ({ data, onClickItem }) => {
  const hasData = !!data.length;

  return (
    <>
      {hasData ? (
        <>
          <Header>
            <HeaderTitle>Nome</HeaderTitle>
          </Header>
          <List
            dataSource={data}
            renderItem={item => (
              <ListItem onClick={() => onClickItem(item)}>
                <ContentUser>
                  <Title className="name-hidden">{item.namePatient}</Title>
                  <Text>Último atendimento: {item.dataOfVisit}</Text>
                  <Text>Protocolo: {item.protocol}</Text>
                </ContentUser>
                <ContentExam>
                  <Text>{item.origin.name}</Text>
                </ContentExam>
              </ListItem>
            )}
          />
        </>
      ) : (
        <Empty
          testId="empty-exams"
          title="Não há pacientes atendidos no período selecionado"
          description="Em filtros, aplique um período de atendimento maior para ver seus pacientes"
        />
      )}
    </>
  );
};

ExamsList.propTypes = {
  onClickItem: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

ExamsList.defaultProps = {
  data: [],
  onClickItem: () => undefined,
};

export default ExamsList;
