import styled from 'styled-components';
import { Form, Select } from 'antd';

import { styles } from '~/config';

export const FormItem = styled(Form.Item)``;

export const SearchButton = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 37px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${styles.colors.primary};
      border-radius: 4px;
      height: 46px;
      width: 46px;
      position: absolute;
      top: -36px;
      z-index: 1;
      cursor: pointer;

      svg {
        position: relative;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const SelectBase = styled(Select)`
  && {
    height: 48px;
    width: 100%;

    .ant-select-selection {
      cursor: text;
    }

    .ant-select-selection-selected-value {
      margin-right: 10px;
      color: black;
    }

    .ant-select-arrow-icon > svg {
      color: ${styles.colors.primary};
    }
  }
`;

export const Option = styled(Select.Option)`
  && {
    height: 48px;
  }
`;

export const Clean = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 48px;
  height: 47px;
  width: 32px;
  z-index: 1;
  cursor: pointer;
`;
