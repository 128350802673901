import styled from 'styled-components';
import Tour from 'reactour';

import { mediaQuery } from '~/utils/helpers';
import { styles } from '~config';

export const Onboarding = styled(Tour)`
  && {
    display: ${({ showModal }) => !showModal && 'none'};
    min-width: 380px;
    border-radius: 5px;

    [data-tour-elem='controls'] {
      justify-content: ${({ steps }) => (steps.length < 2 ? 'flex-end' : 'flex-start')};
      justify-content: ${({ hidePrevButton }) => hidePrevButton && 'flex-end'};
      align-items: flex-start;

      nav {
        display: ${({ steps }) => (steps.length > 1 ? 'flex' : 'none')};
        flex-wrap: nowrap;
        position: relative;
        width: 100px;
        bottom: 20px;
      }

      button {
        outline: none;
      }

      ${mediaQuery.inMobile(`
        justify-content: space-between;

        nav {
          position: absolute;
          margin: 0 auto;
          width: calc(100% - 60px);
          margin-bottom: 80px;
          bottom: 0;
        }
      `)}
    }

    [data-tour-elem='right-arrow'],
    [data-tour-elem='left-arrow'] {
      margin: 0;

      button {
        min-width: 120px;
      }
    }

    ${mediaQuery.inMobile(`
      [data-tour-elem='right-arrow'] {
        margin-left: 7px;
      }
    `)}

    [data-tour-elem='left-arrow'] {
      display: ${({ steps }) => (steps.length <= 1 ? 'none' : 'block')};
    }

    .reactour__dot {
      border: 1px solid ${styles.colors.primary};
    }

    .reactour__dot--is-active {
      background: ${styles.colors.primary};
    }

    svg {
      width: 16px;
      color: white;
      position: relative;
      bottom: 50px;
      left: 10px;
    }

    ${mediaQuery.inMobile(`
      min-width: 300px;
    `)}
  }
`;

export const CloseButton = styled.div`
  position: relative;
  left: 30px;
  top: -54px;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  img {
    position: absolute;
    cursor: pointer;
  }
`;
