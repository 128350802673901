import styled from 'styled-components';
import { Badge as BadgeBase } from 'antd';

export const Badge = styled(BadgeBase)`
  && {
    .ant-badge-status-dot {
      width: 18px;
      height: 18px;
    }
  }
`;

export const Text = styled.span`
  display: inline-block;
  width: 250px;
  max-width: 250px;
  min-width: 150px;
`;
