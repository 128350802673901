import styled from 'styled-components';

import { styles } from '~/config';
import { mediaQuery } from '~/utils/helpers';

export const ButtonHelp = styled.div`
  white-space: nowrap;
  margin: 0 20px;
  cursor: pointer;

  &:hover {
    color: ${styles.colors.link};
  }

  ${mediaQuery.inMobile(`
    margin: 0;
    min-width: 60px;
    line-height: 1;
    font-size: 0.8rem;
    text-align: center;
    white-space: normal;
  `)};
`;
