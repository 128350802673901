import { isDoctor } from './roleManage';

const hasError = (errors, input) => errors[input];

const focusOnFirstError = (errors, form) => {
  for (let i = 0; i < form.length; i += 1) {
    if (hasError(errors, form[i].name)) {
      form[i].focus();
      break;
    }
  }
};

const showFormFields = role => isDoctor(role);

export default {
  hasError,
  focusOnFirstError,
  showFormFields,
};
