import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 40px;
  h1 {
    margin-bottom: 32px;
  }
  p {
    margin: 0;
    word-break: break-word;
  }
  a,
  b {
    color: #010b19;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  padding: 0px;
`;

export const DesktopModalContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  row-gap: 16px;

  @media (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1025px) {
    display: grid;
  }
`;

export const MobileModalContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  row-gap: 16px;

  @media (max-width: 1024px) {
    display: grid;
  }
  @media (min-width: 1025px) {
    display: none;
  }
`;

export const Section = styled.section`
  width: 100%;
  box-sizing: border-box;
`;

export const SectionTitle = styled.p`
  color: black;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 0 0 16px 0;
  margin: 0;
`;

export const WrapperButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  a {
    font-weight: bold !important;
    font-size: 1.5rem !important;
    text-decoration: none !important;
  }
`;
