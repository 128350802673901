import React from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { types } from '~/utils/enums';
import { dateManager } from '~/utils/helpers';
import { Button, LPCheckbox, Title, Text, IconCustom, DatePicker } from '~/components/atoms';
import { FormItem } from '~/components/atoms/DatePicker/DatePicker.styles';

import { Container, Info, DatePickerWrapper } from './Form.styles';

const Form = ({
  values,
  errors,
  testId,
  touched,
  isLoading,
  closeModal,
  handleBlur,
  handleChange,
  handleSubmit,
  className,
  ...rest
}) => {
  const { toStamp, aWeekBefore, aWeekLater } = dateManager;
  const { startDate, endDate } = values;

  const disabledStartDate = date => date > new Date() || date > toStamp(endDate) || date < aWeekBefore(endDate);
  const disabledEndDate = date => date > new Date() || date < toStamp(startDate) || date > aWeekLater(startDate);

  return (
    <Container>
      <Title testId={`${testId}-title`} htmlType="h3">
        Exames de Covid-19
      </Title>
      <Info>
        <Text>
          <IconCustom name={svg.iconWarnSolid} size="18px" />
          Selecione abaixo o período e o resultado dos exames de Covid-19 dos seus pacientes.
        </Text>
        <Text className="ant-form-item-label">
          *O período máximo para download dos exames, é de 7 dias.
        </Text>
      </Info>

      <form name="downloadCovidExamsForm" class={`${className}`} onSubmit={handleSubmit}>
        <DatePickerWrapper>
          <DatePicker
            {...rest}
            name="startDate"
            label="Data de início"
            placeholder="Selecione"
            testId={`${testId}-start-date-picker-input`}
            value={values.startDate}
            error={errors.startDate}
            touched={touched.startDate}
            disabledDate={disabledStartDate}
            allowClear
          />

          <DatePicker
            {...rest}
            name="endDate"
            label="Data final"
            placeholder="Selecione"
            testId={`${testId}-end-date-picker-input`}
            value={values.endDate}
            error={errors.endDate}
            touched={touched.endDate}
            disabledDate={disabledEndDate}
            allowClear
          />
          <FormItem label={' '} colon={false}>
            <Button
              htmlType="submit"
              testId={`${testId}-submit-button`}
              type={types.primary}
              isLoading={isLoading}
              isDisabled={!startDate || !endDate}
              onClick={() => values.covidResultsResume = true}
            >
              Visualizar resultados
          </Button>
          </FormItem>
          <FormItem label={' '} colon={false}>
          <Button
              htmlType="submit"
              testId={`${testId}-submit-button`}
              type={types.primary}
              isLoading={isLoading}
              isDisabled={!startDate || !endDate}
              onClick={() => values.covidResultsResume = false}
            >
              Download de resultados
          </Button>
          </FormItem>
        </DatePickerWrapper>
      </form>
    </Container>
  );
};

Form.propTypes = {
  testId: PropTypes.string,
  isLoading: PropTypes.bool,
  closeModal: PropTypes.func,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    startDate: PropTypes.bool,
    endDate: PropTypes.bool,
  }).isRequired,
};

Form.defaultProps = {
  testId: '',
  isLoading: false,
  closeModal: () => undefined,
};

export default Form;
