export const toApi = (params = {}) => {
  const mapper = {
      applicationId: params.applicationId || undefined,
      id: params.userApplicationId || undefined,
      body: {
          status: params.status || undefined,
          reason: params.reason || undefined,
      }
  }
  return mapper;
}

  
