import React from 'react';
import PropTypes from 'prop-types';

import { Btn } from './LPButton.styles';

const LPButton = ({
  id,
  type,
  size,
  testId,
  isBlock,
  onClick,
  trackId,
  children,
  htmlType,
  iconName,
  isLoading,
  isDisabled,
}) => (
  <Btn
    id={id}
    size={size}
    type={type}
    icon={iconName}
    block={isBlock}
    onClick={onClick}
    loading={isLoading}
    disabled={isDisabled}
    htmlType={htmlType}
    data-testid={testId}
    data-collect={trackId}
  >
    {children}
  </Btn>
);

LPButton.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  testId: PropTypes.string,
  onClick: PropTypes.func,
  trackId: PropTypes.string,
  isBlock: PropTypes.bool,
  htmlType: PropTypes.string,
  iconName: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

LPButton.defaultProps = {
  id: '',
  type: 'primary',
  size: 'default',
  testId: '',
  trackId: '',
  isBlock: false,
  iconName: '',
  htmlType: 'button',
  isLoading: false,
  isDisabled: false,
  onClick: () => undefined,
};

export default LPButton;
