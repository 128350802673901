import React from 'react';
import PropTypes from 'prop-types';

import { svg } from '~/assets';
import { status } from '~/utils/enums';
import { LPButton, Modal, LPTitle, LPText, IconCustom } from '~/components/atoms';

import { ModalContent, ModalFooter } from './LPModalFeedback.styles';

const LPModalFeedback = ({
  text,
  type,
  title,
  testId,
  noButton,
  isVisible,
  actionText,
  actionClick,
}) => (
  <Modal isLP isVisible={isVisible} width="510px" removePadding>
    <ModalContent type={type}>
      {type === status.success ? (
        <IconCustom testid={`${testId}-icon`} name={svg.iconCircleSuccess} size="36px" />
      ) : (
        <IconCustom testid={`${testId}-icon`} name={svg.iconCircleError} size="36px" />
      )}

      <LPTitle htmlType="h3" testId={`${testId}-title`}>
        {title}
      </LPTitle>

      <LPText testId={`${testId}-title`}>{text}</LPText>
      {!noButton && (
        <ModalFooter>
          <LPButton testId={`${testId}-btn`} onClick={actionClick} type="primary">
            {actionText}
          </LPButton>
        </ModalFooter>
      )}
    </ModalContent>
  </Modal>
);

LPModalFeedback.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  testId: PropTypes.string,
  noButton: PropTypes.bool,
  isVisible: PropTypes.bool,
  actionText: PropTypes.string,
  actionClick: PropTypes.func,
};

LPModalFeedback.defaultProps = {
  text: '',
  type: '',
  title: '',
  testId: 'modal-feedback',
  noButton: false,
  isVisible: false,
  actionText: '',
  actionClick: () => undefined,
};

export default LPModalFeedback;
