import styled from 'styled-components';
import { Badge as BadgeBase, List as ListBase } from 'antd';

import { styles } from '~config';

export const Badge = styled(BadgeBase)`
  && {
    .ant-badge-status-dot {
      width: 17px;
      height: 17px;
    }

    .ant-badge-status-text {
      font-size: 1rem;
      font-weight: ${({ isParent }) => isParent && 'bold'};
    }
  }
`;

export const Text = styled.p`
  font-size: 1rem;
  font-weight: ${({ isParent }) => isParent && 'bold'};
  color: ${styles.colors.black};
  margin-bottom: 0;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const List = styled(ListBase)``;

export const ListItem = styled(ListBase.Item)`
  && {
    padding: 10px 0;

    .ant-row {
      width: 100%;
    }
  }
`;
