import { objectManager } from '~/utils/helpers';

const { isEmpty } = objectManager;

export const fromApi = (data = {}) => {
  if (isEmpty(data)) return {};

  const mapper = {
    name: data.namePatient || '',
    birthdate: data.birthdate || '',
    birthdateMs: data.birthdateMs || '',
    gender: data.gender || '',
    cpf: data.cpf || 'Sem dados',
    email: data.email || 'Sem dados',
    identifier: data.identifier || '',
  };

  return mapper;
};
