import React from 'react';
import PropTypes from 'prop-types';

import { styles } from '~/config';

import { IconBase } from './Icon.style';

const Icon = ({ testid, name, size, color, rotate }) => (
  <IconBase data-testid={testid} type={name} color={color} size={size} rotate={rotate} />
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  rotate: PropTypes.number,
  testid: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
};

Icon.defaultProps = {
  testid: '',
  rotate: undefined,
  color: styles.colors.text,
  size: styles.fontSize.default,
};

export default Icon;
