import { loggedUserInfo } from '~/api/services/loggedUserInfo.service';
import { getUserProfileInfo, updateUserProfileInfo } from '~/api/services/users.service';
import { errorMessage, simpleAction } from '~/utils/helpers';

// Action Types
export const LOGGED_USER_INFO_REQUEST = '@dasa-canal-medico/LOGGED_USER_INFO_REQUEST';
export const LOGGED_USER_INFO_SUCCESS = '@dasa-canal-medico/LOGGED_USER_INFO_SUCCESS';
export const LOGGED_USER_INFO_FAILURE = '@dasa-canal-medico/LOGGED_USER_INFO_FAILURE';

export const UPDATE_LOGGED_USER_INFO_REQUEST = '@dasa-canal-medico/UPDATE_LOGGED_USER_INFO_REQUEST';
export const UPDATE_LOGGED_USER_INFO_SUCCESS = '@dasa-canal-medico/UPDATE_LOGGED_USER_INFO_SUCCESS';
export const UPDATE_LOGGED_USER_INFO_FAILURE = '@dasa-canal-medico/UPDATE_LOGGED_USER_INFO_FAILURE';

// Reducer
export const initialState = {
  data: {
    userInfo: {},
  },
  error: {
    message: '',
    status: '',
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGGED_USER_INFO_REQUEST:
      return {
        ...state,
        ...initialState,
        isLoading: true,
      };
    case LOGGED_USER_INFO_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: {
          userInfo: action.payload.userInfo,
          userHasPendingInfo: action.payload.userHasPendingInfo, // deprecated
          hasPendingInfo: action.payload.userHasPendingInfo,
          userProfile: action.payload.userProfile,
        },
        isLoading: false,
      };
    case LOGGED_USER_INFO_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };
    case UPDATE_LOGGED_USER_INFO_REQUEST:
      return {
        ...state,
        ...initialState,
        isLoading: true,
      };

    case UPDATE_LOGGED_USER_INFO_SUCCESS:
      return {
        ...state,
        ...initialState,
        data: {
          message: action.payload.message,
        },
        isLoading: false,
      };
    case UPDATE_LOGGED_USER_INFO_FAILURE:
      return {
        ...state,
        ...initialState,
        error: {
          message: action.payload.message,
          status: action.payload.status,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}

// Action Creators
export const loggedUserInfoRequest = simpleAction(LOGGED_USER_INFO_REQUEST);
export const loggedUserInfoSuccess = simpleAction(LOGGED_USER_INFO_SUCCESS);
export const loggedUserInfoFailure = simpleAction(LOGGED_USER_INFO_FAILURE);

export const updateLoggedUserInfoRequest = simpleAction(UPDATE_LOGGED_USER_INFO_REQUEST);
export const updateLoggedUserInfoSuccess = simpleAction(UPDATE_LOGGED_USER_INFO_SUCCESS);
export const updateLoggedUserInfoFailure = simpleAction(UPDATE_LOGGED_USER_INFO_FAILURE);

export const getLoggedUserInfoAction = () => async dispatch => {
  dispatch(loggedUserInfoRequest());

  try {
    const userInfoPayload = await loggedUserInfo();
    const userProfile = await getUserProfileInfo();

    const payload = {
      ...userInfoPayload,
      userProfile,
    };

    dispatch(loggedUserInfoSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);
    dispatch(loggedUserInfoFailure(err));
  }
};

export const updateLoggedUserInfoAction = params => async dispatch => {
  dispatch(updateLoggedUserInfoRequest());

  try {
    const payload = await updateUserProfileInfo(params);

    dispatch(updateLoggedUserInfoSuccess(payload));
  } catch (error) {
    const err = errorMessage(error);

    dispatch(updateLoggedUserInfoFailure(err));
  }
};
